import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';


//サーバータイムの取得
export const asyncNowPath = async () => {
    return await NowPath();
}

export const NowPath = () => {
    //パス取得
    const nowPath = useLocation().pathname;

    return new Promise((resolve, reject) => {
        sessionStorage.setItem('NowPath', nowPath);
    })
}

export const GetNowPath = () => {
    //パス取得
    const nowPath = window.location.pathname;
    // alert('nowPath: ' + JSON.stringify(nowPath));
    sessionStorage.setItem('NowPath', nowPath);
}

