import { useState } from "react"

 const SNSChallenge = () => {
    const userPoolClientId = process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID;//'606vuhp4iuoqnauatsfe3sb8t4';
    const cognitoURL = 'https://cognito-idp.ap-northeast-1.amazonaws.com/';

    let tokens;

    const [username, setUserName] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [challenge, setChallenge] = useState<object>();

    //test
    const RssParser = require('rss-parser'); //importではなくrequireで呼びます
    const url = 'https://www.bthacks.com/'
    //const url = 'https://t-n-clark.hatenadiary.jp/feed'
    const rssParser = new RssParser({
        headers: {'User-Agent': 'something different'},
      });
    rssParser.parseURL(url)
        .then((feed) => {
            const res = feed['items'];
            res.map(item => {
                console.log(item);
            });
        })
        .catch((error) => {
            console.error('Get Failed', error);
        });
//test end



    const do_post_aws = (url, body, target) => {
        const headers = new Headers({ "Content-type" : "application/x-amz-json-1.1", 'X-Amz-Target': target });
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: headers,
        })
        .then(res => {
            return res.json();
        });
    }
    const start_call = () => {
        var content = username;
        var params = {
            AuthFlow: 'CUSTOM_AUTH',
            ClientId: userPoolClientId,
            AuthParameters: {
                'USERNAME': content
            }
        };
        console.log(params);
        do_post_aws(cognitoURL, params, 'AWSCognitoIdentityProviderService.InitiateAuth')
            .then(( data:any ) => {
                console.log(data);
                setChallenge(data);
            });
    }

    const responsed_call = () => {
        const c:any = challenge;

        console.log(c);

        var content = answer;
        var params = {
            ChallengeName: 'CUSTOM_CHALLENGE',
            ClientId: userPoolClientId,
            ChallengeResponses: {
                'USERNAME': c.ChallengeParameters.USERNAME,
                'ANSWER': 'Google',
            },
            Session: c.Session,
        };
        console.log(params);
        do_post_aws(cognitoURL, params, 'AWSCognitoIdentityProviderService.RespondToAuthChallenge')
            .then(data => {
                console.log(data);
                tokens = data;
            });
    }
    const redirect_call = () => {
        //location.href = 'https://localhost:3001/auth.html' + '#access_token=' + tokens.AuthenticationResult.AccessToken + '&id_token=' + tokens.AuthenticationResult.IdToken + '&token_type=' + tokens.AuthenticationResult.TokenType;
    }
    return (
        <div>
            <div>
                <p>ユーザー名</p>
                <input type='text' onChange={(e) => setUserName(e.target.value)}></input>
                <button onClick={start_call}>startを呼び出し</button>
            </div>
            <div>
                <p>アンサー</p>
                <input type='text' onChange={(e) => setAnswer(e.target.value)}></input>
                <button onClick={responsed_call}>responseを呼び出し</button>         
            </div>
            <div>
                <button onClick={redirect_call}>redirect呼び出し</button>
            </div>
        </div>
    )
}

export default SNSChallenge