import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { asyncDevAPI, Decrypto, Encrypto, ErrorSwitchFunctions } from '../../../../functions';
import { getUrl } from '../../../../Reduxs/Mfa/selectors';
import { clearUrlAction } from '../../../../Reduxs/Mfa/actions';
import { Footer, Header } from '../../../../htmlParts';
import { changedAnswerText, changedCodeHandler, secretQuestionSelect } from '../../../../functions/ChangeFunction';
import { setErrorAction } from '../../../../Reduxs/Errors/actions';
import { MFACodeInputHead } from '../../../../htmlParts/headData';

const CodeInput = (props) => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const [code, setCode] = useState<string>('');
    const [codeError, setCodeError] = useState<string>('');
    const [selectSecretQuestion, setSelectSecretQuestion] = useState<string>('');
    const [selectSecretQuestionError, setSelectSecretQuestionError] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [answerErr, setAnswerErr] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [msg, setMsg] = useState<string>('');

    const returnUrl = getUrl(selector);
    clearUrlAction();

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    // 2段階認証の処理関数ここから。適宜変更
    const verification = () => {
        // SessionStorageからアクセストークンを取得
        const SessionAccessToken = loginUser.accessToken;

        const apiParams = {
            apiname: 'source_cognito_verify_user_attribute_put',
            AccessToken: SessionAccessToken,
            confirmation_code: code,
            type: 'phone_number',
        };
        // 検証コード認証API
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const result: any = res;
                const statusCode = result['body']['status'];

                if (statusCode === 200) {
                    const apiParams = {
                        apiname: 'source_cognito_mfa_post',
                        type: 'mfa_on',
                        AccessToken: SessionAccessToken,
                        question: selectSecretQuestion,
                        answer: answer,
                        phone_number: Decrypto(sessionStorage.getItem('pnb')),
                    };
                    // 2段階認証設定API
                    asyncDevAPI(apiParams)
                        .then((res: any) => {
                            const result: any = res;
                            const statusCode = result['body']['status'];
                            if (statusCode === 200) {
                                // sessionの2段階認証の状態Flagを立てておく
                                sessionStorage.setItem('tsvt', Encrypto(true));
                                // 2段階認証設定完了画面に遷移
                                dispatch(push('/account/security'));
                            } else if (res.body.status === 400) {
                                setErrMsg('未入力の項目があります。お手数ですがすべての項目の入力をお願いします。');
                            } else {
                                // 200以外のステータス
                                const setMsgs = {
                                    ErrMsg: setSelectSecretQuestionError,
                                }
                                const page = 'mfa';
                                const errorPage = ErrorSwitchFunctions(res.body, setMsgs, page);
                                if (errorPage.flg) {
                                    dispatch(setErrorAction({
                                        errorCode: res.body.status
                                    }));
                                    dispatch(push(errorPage.url));
                                }
                                return;
                            }
                        })
                        .catch(err => {
                            switch (err.errorType) {
                                default:
                                    setErrMsg('2段階認証の設定に失敗しました。お手数ですがもう一度お試しください。');
                                    break
                            }
                        })
                } else {
                    // 200以外のステータス
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                switch (err.errorType) {
                    default:
                        setErrMsg('コードの認証に失敗しました。お手数ですがもう一度お試しください。');
                        break
                }
            })
    }
    // 2段階認証の処理関数ここまで

    // 認証コード再送
    const reSend = () => {
        setMsg('');
        // SessionStorageからアクセストークンを取得
        const SessionAccessToken = loginUser.accessToken;

        const apiParams = {
            apiname: 'source_cognito_verify_user_attribute_get',
            AccessToken: SessionAccessToken,
            type: 'phone_number',
            phone_number: sessionStorage.getItem('pnb') ? Decrypto(sessionStorage.getItem('pnb')) : null,
        };
        // 検証コード発行API
        asyncDevAPI(apiParams)
            .then(res => {
                const result: any = res;
                const statusCode = result['body']['status'];

                if (statusCode !== 200) {
                    dispatch(setErrorAction({
                        errorCode: statusCode
                    }));
                    dispatch(push('/error'));
                }
                setMsg('コードを再送しました');
            })
            .catch(err => {
                switch (err.errorType) {
                    default:
                        setErrMsg('コードの送信に失敗しました。お手数ですがもう一度お試しください。');
                        break;
                }
            })
    }
    const resendMsg = () => {
        if (msg) {
            return (
                <div className="messagebox resend">
                    {msg}
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <MFACodeInputHead />
                <Header />
                <div id="two_step_code" className="mail_change">
                    <main>
                        <div className="contents_in">
                            <div className="back"><Link to='/account/security/mfa/phoneNumber'>&lt; 戻る</Link></div>
                            <div className="text_box_ptn02 form_box_ptn02">
                                <h1 className="title">2段階認証設定</h1>
                                <div className="lead">あなたがお持ちの携帯電話に送信された<br className="sp" />6桁のセキュリティコードを入力ください。<br />
                                    また、携帯電話紛失などに備えて<br className="sp" />秘密の質問を設定ください。<br />
                                </div>
                                <div className='form'>
                                    <dl className="form_g">
                                        <dt className="form_text">6桁のセキュリティコードを入力</dt>
                                        <dd>
                                            <input type='text' name='label' maxLength={6} placeholder='6桁のセキュリティコードを入力' onChange={(e: any) => changedCodeHandler(e, setCode, setCodeError, true)} />
                                            <div className="form_error_text">
                                                {codeError}
                                                {codeError ? <br /> : ''}
                                                {errMsg}
                                            </div>
                                        </dd>
                                        <dt className="form_text">秘密の質問</dt>
                                        <dd className="">
                                            <select
                                                name="secret_q"
                                                id=""
                                                className="select"
                                                defaultValue=""
                                                place-hpleder='秘密の質問を選択してください'
                                                onChange={(event) => secretQuestionSelect(event, setSelectSecretQuestion, setSelectSecretQuestionError)}
                                            >
                                                <option value="">秘密の質問を選択してください</option>
                                                <option value="生まれた病院は？">生まれた病院は？</option>
                                                <option value="初めて飼ったペットの名前は？">初めて飼ったペットの名前は？</option>
                                                <option value="座右の銘は？">座右の銘は？</option>
                                                <option value="子供の頃の親友のあだ名は？">子供の頃の親友のあだ名は？</option>
                                                <option value="小学生の時に好きだった先生の名前は？">小学生の時に好きだった先生の名前は？</option>
                                                <option value="卒業した小学校は？">卒業した小学校は？</option>
                                                <option value="初めて覚えた料理は？">初めて覚えた料理は？</option>
                                            </select>
                                            <div className="form_error_text">
                                                {selectSecretQuestionError ?? ''}
                                            </div>
                                        </dd>
                                        <dt className="form_text">秘密の質問の答え</dt>
                                        <dd>
                                            <input
                                                name="secret_a"
                                                type="text"
                                                placeholder="秘密の質問の答えを入力"
                                                maxLength={50}
                                                onChange={(e) => changedAnswerText(e, setAnswer, setAnswerErr)}
                                            />
                                            <div className="form_error_text">
                                                {answerErr}
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="btn_submit">
                                        <button
                                            disabled={
                                                codeError !== '' || selectSecretQuestionError !== '' ||
                                                answerErr !== '' || errMsg !== '' ||  answer === '' || code === '' ||
                                                selectSecretQuestion === ''
                                            }
                                            onClick={() => verification()}
                                        >2段階認証を設定</button>
                                    </div>
                                </div>
                                <div className="support_links">
                                    {resendMsg()}
                                    <div className="link"><span className="link_ptn01" style={{cursor: 'pointer'}} onClick={() => reSend()}>コードを再送する</span></div>
                                    <div className="link"><Link className='link_ptn01' style={{cursor: 'pointer'}} to='/account/security/mfa/phoneNumber'>&lt; 戻る</Link></div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}
export default CodeInput