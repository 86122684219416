import { push } from "connected-react-router";
import { encode } from "js-base64";
import { setErrorAction } from "../Reduxs/Errors/actions";
import { clearLoadingAction, setLoadingAction } from "../Reduxs/Loading/actions";
import { getIsLoading } from "../Reduxs/Loading/selectors";
import { apiFinAction, apiStartAction } from "../Reduxs/PageAct/actions";
import { setGoogleId, setNicknameAction, setTwitterId } from "../Reduxs/Users/actions";
import { getLoginStatus, getTwitterFlg } from "../Reduxs/Users/selectors";
import { loginHistoryCheck, setArticle } from "../templates/auth/login";
import { asyncDevAPI, asyncSnsChallenge } from "./asyncFunctions";
import { CheckDeviceBrowser } from "./CheckDeviceBrowser";
import { Decrypto, Encrypto } from "./Crypto";
import { LoginData } from "./LoginData";
import { TwitterGetOauthToken } from "./TwitterGetOauthToken";

const startAuth = 'AWSCognitoIdentityProviderService.InitiateAuth';
const responseAuth = 'AWSCognitoIdentityProviderService.RespondToAuthChallenge';

//Googleログイン用のAPI
export const GoogleLoginAPI = (googleId, email, dispatch) => {
    dispatch(setLoadingAction({
        isLoading: true,
    }));
    const devParams = {
        apiname: 'source_sns_ccode_get',
        snsType: 'gcode',
        snsId: googleId
    }
    /*
     * レスポンスからRDS接続、データ取得ができたらログイン機能として動作
     * レスポンスからデータが取れなかったらユーザー情報入力
     */
    asyncDevAPI(devParams)
        .then((res: any) => {
            //if (res.body.status === 200) {
                const username = res.body.response;
                const startParams = {
                    AuthFlow: 'CUSTOM_AUTH',
                    ClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                    AuthParameters: {
                        'USERNAME': username,
                    }
                }
                SnsChallengeAPI('google', startParams, username, googleId, dispatch, email);
                dispatch(clearLoadingAction());
                /*
            } else {
                dispatch(clearLoadingAction());
                dispatch(setErrorAction({
                    errorCode: res.body.status
                }));
                dispatch(push('/error'));
            }
            */
        })
        .catch(err => {
            console.log(err);
            dispatch(clearLoadingAction());
        }
        );
}


//Twitter情報の取得
export const GetTwitterData = (URL, dispatch) => {
    dispatch(setLoadingAction({
        isLoading: true,
    }));
    const params = {
        apiname: 'twitter_verification_get',
        callbackURL: process.env.REACT_APP_DOCUMENT_ROOT + URL,
    };
    asyncDevAPI(params)
        .then((res: any) => {
            //if (res.body.status === 200) {
                const response = res.body.response;
                if (response.request_token.oauth_callback_confirmed) {
                    //dispatch(clearLoadingAction());
                    sessionStorage.twflg = true;
                    window.location.href = response.authenticate_endpoint;
                }
                //dispatch(clearLoadingAction());
                /*
            } else {
                dispatch(clearLoadingAction());
                dispatch(setErrorAction({
                    errorCode: res.body.status
                }));
                dispatch(push('/error'));
            }
            */
        })
        .catch(err => {
            console.log(err);
            dispatch(clearLoadingAction());
            dispatch(apiFinAction());
        }
        );
}
//Twitterログイン用API
export const TwitterLoginAPI = (selector, dispatch) => {
    const twitterToken = Object(TwitterGetOauthToken());
    dispatch(setLoadingAction({
        isLoading: true,
    }));
    if (twitterToken.oauth_token && !getTwitterFlg(selector)) {
        const getAccessTokenParams = {
            apiname: 'twitter_access_token_get',
            oauth_token: twitterToken.oauth_token,
            oauth_verifier: twitterToken.oauth_verifier,
        };
        asyncDevAPI(getAccessTokenParams)
            .then((res: any) => {
                const response = res.body.response;
                //if (res.body.status === 200) {
                    const snsId = response.access_token.user_id;
                    const devParams = {
                        apiname: 'source_sns_ccode_get',
                        snsType: 'twitter_token',
                        snsId: response.access_token.user_id
                    }
                    asyncDevAPI(devParams)
                        .then((res: any) => {
                            //if (res.body.status === 200) {
                                const username = res.body.response;
                                const startParams = {
                                    AuthFlow: 'CUSTOM_AUTH',
                                    ClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                                    AuthParameters: {
                                        'USERNAME': username,
                                    }
                                }
                                SnsChallengeAPI('twitter', startParams, username, snsId, dispatch);
                                /*
                            } else {
                                dispatch(setErrorAction({
                                    errorCode: res.body.status
                                }));
                                dispatch(push('/error'));
                            }
                            */
                        })
                        .catch(err => {
                            console.log(err);
                            dispatch(clearLoadingAction());
                        }
                        );
                        /*
                } else {
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
                */
            })
            .catch(err => {
                console.log(err);
                dispatch(clearLoadingAction());
            }
        );
    }
}
//共通ログインアクション
export const LoginAction = (AccessToken, dispatch) => {
    dispatch(setLoadingAction({
        isLoading: true,
    }));
    const rdsParams = {
        apiname: 'source_user_get',
        AccessToken: AccessToken,
    }
    asyncDevAPI(rdsParams)
        .then((res: any) => {
            if (res.body.status === 200) {
                const useDeviceAndBrowser = CheckDeviceBrowser();
                loginHistoryCheck(AccessToken, useDeviceAndBrowser);
                const m_user = res.body.response.m_user;
                const m_code = res.body.response.m_code;
                const params = {
                    username: m_user.nickname,
                    year: String(m_user.birthday).substr(0, 4),
                    month: String(m_user.birthday).substr(4, 2),
                    day: String(m_user.birthday).substr(6, 2),
                    gender: m_user.gender,
                    m_code: m_code
                };
                let uiData:Number[] = [];
                m_code.map((item:any) => {
                  if(item.atb === 2){
                      uiData.push(item.mid);
                  }
                });
                const paramsjs = JSON.stringify(params);
                sessionStorage.urpj = Encrypto(paramsjs);
                sessionStorage.unkm = Encrypto(m_user.nickname);

                localStorage.Login = true;

                setArticle(AccessToken, uiData, dispatch);
                //dispatch(clearLoadingAction());
                //dispatch(push('/top'));
            } else {
                dispatch(clearLoadingAction());
                sessionStorage.clear();
                dispatch(push('/'));
            }

        })
        .catch(err => {
            console.log(err);
            sessionStorage.clear();
            dispatch(clearLoadingAction());
            alert('ログイン状態が切れました。再度ログインしてください。');
            dispatch(push('/'));
        });
}
//SNS連携用API
export const SnsChallengeAPI = (type, startParams, username, snsId, dispatch, email = '') => {
    dispatch(setLoadingAction({
        isLoading: true,
    }));
    asyncSnsChallenge(startParams, startAuth)
        .then((res: any) => {
            const responsParams = {
                ChallengeName: 'CUSTOM_CHALLENGE',
                ClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                ChallengeResponses: {
                    'USERNAME': res.ChallengeParameters.USERNAME,
                    'ANSWER': 'google',
                },
                Session: res.Session,
            };
            asyncSnsChallenge(responsParams, responseAuth)
                .then((res: any) => {
                    const response = res.AuthenticationResult
                    const refreshToken = encode(response.RefreshToken);
                    LoginData(refreshToken, false);
                    //ログイン処理
                    const sessionStorageparams = {
                        accessToken: encode(response.AccessToken),
                        verifyCount: 6,
                    }
                    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
                    const sessionparamsString = JSON.stringify(sessionStorageparams);
                    sessionStorage.setItem(envStorageKey, Encrypto(sessionparamsString));
                    dispatch(clearLoadingAction());
                    LoginAction(encode(response.AccessToken), dispatch);
                    return;
                })
                .catch(err => {
                    const SessionUserString = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
                    let user = SessionUserString ? Decrypto(SessionUserString) : null;
                    user = user ? JSON.parse(user) : null
                    if(user){
                        return;
                    }

                    console.log(err);
                    //Cognitoに登録されていないので新規登録へ
                    dispatch(clearLoadingAction());
                    if (type === 'google') {
                        dispatch(setGoogleId({
                            tf_mail: email,
                            google_flg: true,
                            google_id: snsId,
                        }));
                    }
                    if (type === 'twitter') {
                        dispatch(setTwitterId({
                            twitter_id: snsId,
                            twitter_flg: true,
                        }));
                    }
                    dispatch(push('/newRegistration/input'));
                }
                );
        })
        .catch(err => {
            const SessionUserString = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
            let user = SessionUserString ? Decrypto(SessionUserString) : null;
            user = user ? JSON.parse(user) : null
            if(user){
                return;
            }

            console.log(err);
            //Cognitoに登録されていないので新規登録へ
            dispatch(clearLoadingAction());
            if (type === 'google') {
                dispatch(setGoogleId({
                    tf_mail: email,
                    google_flg: true,
                    google_id: snsId,
                }));
            }
            if (type === 'twitter') {
                dispatch(setTwitterId({
                    twitter_id: snsId,
                    twitter_flg: true,
                }));
            }
            dispatch(push('/newRegistration/input'));
        }
        );
}