import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
    getNickName,
    getBirthday,
    getGender,
    getNativeLang,
    getComeFrom,
    getContinent,
    getTTSID,
    getCountry,
    getNativeLangValue,
    getYear,
    getMonth,
    getDay,
    getGoogleId,
    getTwitterId,
    getUserAll,
} from '../../../Reduxs/Users/selectors';
import { Footer, Header, NewRegistrationConfirmHead } from '../../../htmlParts';
import { useHistory } from 'react-router-dom';
import { asyncDevAPI, CheckDeviceBrowser, Decrypto, Encrypto, LoginData } from '../../../functions';
import { clearLoadingAction, setLoadingAction } from '../../../Reduxs/Loading/actions';
import { loginErrorsAction, setErrorAction } from '../../../Reduxs/Errors/actions';
import { loginAction, setNicknameAction } from '../../../Reduxs/Users/actions';
import { loginHistoryCheck, setArticle } from '../login';

const InputConfirmation = () => {

    document.title = 'アカウント登録|TTS-ID';

    const [errMsg, setErrMsg] = useState<string>('');
    const history = useHistory();

    const newRegistTmp = sessionStorage.getItem('newRegistration');
    if(!newRegistTmp){
      window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
    }
    let newRegist = Decrypto(newRegistTmp);
    newRegist = newRegist ? JSON.parse(newRegist) : null;

    const dispatch = useDispatch();
    const selector = useSelector(state => state);
    const name = getNickName(selector);
    const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
    const SessionEmail = newRegist ? newRegist.email : null;
    const SessionPass = newRegist ? newRegist.password : null;
    const gender = getGender(selector);
    const nativeLangKey = getNativeLang(selector)
    const nativeLangValue = getNativeLangValue(selector)
    const birthYear = getYear(selector);
    const birthMonth = getMonth(selector);
    const birthDay = getDay(selector);

    // ブラウザ更新を行った場合は、アカウント情報画面に戻る
    if (
        !name || !gender || !getBirthday(selector) || !nativeLangKey ||
        !getContinent(selector) || !getCountry(selector)
    ) {
        dispatch(push('/newRegistration/form'));
    }

    const newRegistrationVerification = () => {
        let params: object;
        const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
        const envPasswordKey = process.env.REACT_APP_SESSION_STORAGE_PASSWORD ?? '';


        // 使用デバイスとブラウザの取得
        const useDeviceAndBrowser = CheckDeviceBrowser();
        const email = sessionStorage.getItem(envEmailKey) ? Decrypto(sessionStorage.getItem(envEmailKey)) : SessionEmail;
        const password = sessionStorage.getItem(envPasswordKey) ? Decrypto(sessionStorage.getItem(envPasswordKey)) : SessionPass;
        //Cognitoユーザー情報送信内容設定ここから
        if (getGoogleId(selector) || getTwitterId(selector)) {
            params = {
                apiname: 'source_user_post',
                email: email,
                ccode: getTTSID(selector),
                gcode: getGoogleId(selector),
                twitter_token: getTwitterId(selector),
                nickname: name,
                gender: gender,
                birthday: getBirthday(selector),
                icon: '',
                mid: [nativeLangKey, getContinent(selector), getCountry(selector)],
            };
        } else {
            params = {
                apiname: 'source_user_post',
                email: email,
                ccode: getTTSID(selector),
                nickname: name,
                gender: gender,
                birthday: getBirthday(selector),
                icon: '',
                mid: [nativeLangKey, getContinent(selector), getCountry(selector)],
            };
        }
        // ローディング開始
        dispatch(setLoadingAction({
            isLoading: true
        }));
        //Cognitoユーザー情報送信内容設定ここまで
        //Cognitoユーザー登録処理ここから
        asyncDevAPI(params)
            .then((res: any) => {

                if (res.body.status === 200) {
                    const apiParams = {
                        apiname: 'source_login_post',
                        user_name: email,
                        password: password,
                        device: useDeviceAndBrowser
                    };
                    asyncDevAPI(apiParams)
                        .then(res => {
                            const result: any = res;
                            const statusCode = result['body']['status'];
                            const accessToken = result['body']['response']['AccessToken'];
                            const refreshToken = result['body']['response']['RefreshToken'];
                            if (statusCode === 200) {
                                loginHistoryCheck(accessToken, useDeviceAndBrowser);
                                LoginData(refreshToken, false);
                                const sessionStorageparams = {
                                    accessToken: accessToken,
                                    verifyCount: 6,
                                }
                                const rdsParams = {
                                    apiname: 'source_user_get',
                                    AccessToken: accessToken,
                                }
                                asyncDevAPI(rdsParams)
                                    .then((res: any) => {
                                        if (res.body.status === 200) {
                                            const m_user = res.body.response.m_user;
                                            const m_code = res.body.response.m_code;
                                            const params = {
                                                username: m_user.nickname,
                                                year: String(m_user.birthday).substr(0, 4),
                                                month: String(m_user.birthday).substr(4, 2),
                                                day: String(m_user.birthday).substr(6, 2),
                                                gender: m_user.gender,
                                                m_code: m_code
                                            };

                                            let uiData:Number[] = [];
                                            m_code.map((item:any) => {
                                              if(item.atb === 2){
                                                  uiData.push(item.mid);
                                              }
                                            });
                                            
                                            const paramsjs = JSON.stringify(params);
                                            sessionStorage.urpj = Encrypto(paramsjs);

                                            sessionStorage.removeItem('NickName');
                                            sessionStorage.removeItem('year');
                                            sessionStorage.removeItem('month');
                                            sessionStorage.removeItem('day');
                                            sessionStorage.removeItem('Gender');
                                            sessionStorage.removeItem('NativeLang');
                                            sessionStorage.removeItem('dispContinent');
                                            sessionStorage.removeItem('continentTmpId_CountryId');
                                            sessionStorage.removeItem('countryTmpId_AreaTmpId');
                                            const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
                                            const sessionparamsString = JSON.stringify(sessionStorageparams);
                                            sessionStorage.setItem(envStorageKey, Encrypto(sessionparamsString));
                                            sessionStorage.unkm = Encrypto(m_user.nickname);

                                            localStorage.Login = true;

                                            setArticle(accessToken, uiData, dispatch);
                                            //dispatch(clearLoadingAction());
                                            //dispatch(push('/top'));
                                        } else {
                                            dispatch(clearLoadingAction());
                                            dispatch(setErrorAction({
                                                errorCode: res.body.status
                                            }));
                                            dispatch(push('/error'));
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        sessionStorage.clear();
                                        alert('ログイン状態が切れました。再度ログインしてください。');
                                        dispatch(push('/'));
                                    });
                            } else {
                                dispatch(clearLoadingAction());
                                dispatch(setErrorAction({
                                    errorCode: statusCode
                                }));
                                dispatch(push('/error'));
                            }
                        })
                        .catch(err => {
                            if (err.errorType === 'UserNotConfirmedException') {
                                dispatch(loginErrorsAction({
                                    loginName: err.errorType,
                                    loginPassword: 'お手数ですがメールの認証を行ってください。'
                                }));
                                dispatch(push('/newRegistration/tempRegistSendMail'));
                            }
                            console.log(err);
                            dispatch(clearLoadingAction());
                            setErrMsg(err);
                        });
                    /*dispatch(push('/top'));*/
                } else {
                    dispatch(clearLoadingAction());
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
            })
            .catch(err => {
                console.log(err);
            }
            );
        //Cognitoユーザー登録処理ここまで
    }

    //画面表示ここから
    if(newRegistTmp){
        return (
            <div id="wrapper">
                <NewRegistrationConfirmHead />
                <Header />
                <div id="signup_confirm" className="signup_bg">
                    <main>
                        <div className="contents_in">
                            <div className="page_title_ptn01 white">
                                <h1>プロフィールの確認</h1>
                                <p className="first_text">下記の内容で間違いないかご確認ください。</p>
                            </div>
                            <div className="cnt_area">
                                <div className="form_box_ptn02">
                                    <h2>プロフィール内容</h2>
                                    <div className="form">
                                        <dl>
                                            <dt>メールアドレス</dt>
                                            <dd>
                                                <div>{SessionEmail}</div>
                                            </dd>
                                            <dt>お名前</dt>
                                            <dd>
                                                <div>{name}</div>
                                                <span style={{margin: '10px 0 0'}}>※ログイン後にお名前として表示されます</span>
                                            </dd>
                                            <dt>生年月日</dt>
                                            <dd>
                                                <div>{birthYear + ' / ' + birthMonth + ' / ' + birthDay}</div>
                                            </dd>
                                            <dt>性別</dt>
                                            <dd>
                                                <div>{!gender ? '' : (gender === '0') ? '男性' : '女性'}</div>
                                            </dd>
                                            <dt>第一言語</dt>
                                            <dd>
                                                <div>{nativeLangValue}</div>
                                            </dd>
                                            <dt>出身国</dt>
                                            <dd>
                                                <div>{getComeFrom(selector)}</div>
                                            </dd>
                                        </dl>
                                        <div className="privacy_box">
                                            <div className="checkbox">利用規約に同意する</div>
                                            <div className="checkbox">個人情報の取り扱いに同意する</div>
                                        </div>
                                        <div className="btn_submit"><button onClick={newRegistrationVerification}>プロフィールを登録</button></div>
                                    </div>
                                    <div className="btn_back"><input type="submit" name="submitBack" value="戻る" onClick={() => history.goBack()} /></div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        return (
            <></>
        )
    }
    
    //画面表示ここまで
}

export default InputConfirmation