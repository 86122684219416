import axios from 'axios';
import { loginAction } from '../Reduxs/Users/actions';

const apikey = process.env.REACT_APP_GOOGLE_APIKEY;
const latlngParam = '?latlng=';
const geocodingParam = '&language=ja&key=' + apikey + '&sensor=false';

//使用しているAPI群
const LambdaTwitterAPI = process.env.REACT_APP_API_TWITTER;
const AccessTokenGetURL = process.env.REACT_APP_API_TWITTER_GET_ACCESS_TOKEN;
const devAPIURL = process.env.REACT_APP_API_DEV;
const GeolocationAPI = process.env.REACT_APP_API_GEOLOCATION + apikey;
const GeoCodingAPI = process.env.REACT_APP_API_GEOCODING;
const cognitoURL = process.env.REACT_APP_API_COGNITO;

//開発環境のAPI
export const devAPI = (options:object) => {
  return new Promise((resolve, reject)=>{
    axios
      .post(
        devAPIURL,
        {params:options},
        {headers: {
          'Content-Type' : 'application/json'
        }}
      ).then( (res) => {
        if(res.data['errorMessage']){
          return reject(res.data);
        }
        return resolve(res.data);
      }).catch( (err) => {
        console.log(err)
        return reject(err);
    });
  })
}
//ここからGeolocationAPIテスト用
export const testGeolocation = () => {
  return new Promise((resolve, reject) => {
    axios
    .post(
      GeolocationAPI,
      {params: {
        "considerIp": true,
      }},
      {headers:{
        'Content-Type' : 'application/json'
      }}
    ).then( (res) => {
      const latlng:string = res.data.location.lat + ',' + res.data.location.lng;
      return resolve(latlng);
    }).catch( (err)=> {
      console.log(err);
      console.log(err.response.data.error);
      return reject(err);
    })
  });
}
//GeolocationAPIテスト用ここまで

export const GeocodingAPI = (latlng) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        GeoCodingAPI + latlngParam + latlng + geocodingParam,
      )
      .then((res:any) => {
        console.log(res);
        return resolve(res);
      })
      .catch(err => {
        console.log(err);
        return reject(err);
      });
  });
}

//サーバー時刻の取得
export const serverTime = () => {
  return new Promise((resolve, reject) => {
    axios.head(window.location.protocol + '//' + window.location.host)
    .then(res => {
      return resolve(new Date(res.headers.date)); // サーバー時刻
    })
    .catch(err => {
      return reject(err);
    });
  })
}
//サーバー時刻の取得
export const serverTiMemillisecond = () => {
  return new Promise((resolve, reject) => {
    axios.head(window.location.protocol + '//' + window.location.host)
    .then(res => {
      const severTime = new Date(res.headers.date)
      return resolve(severTime.getTime()); // サーバー時刻
    })
    .catch(err => {
      return reject(err);
    });
  })
}

//SNS連携のチャレンジ認証用関数
export const snsChallengeAPI = (body, target) => {
  const headers = new Headers({ "Content-type" : "application/x-amz-json-1.1", 'X-Amz-Target': target });
  return new Promise((resolve, reject) => {
    fetch(cognitoURL, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
    })
    .then(res => {
      if(res.ok){
        return resolve(res.json());
      } else {
        return reject(res);
      }
    });
  });
}
//SNS連携のチャレンジ認証用関数ここまで

export const TwitterAPI = (CallBackURL) => {
  return new Promise((resolve,reject) => {
    axios
    .get(LambdaTwitterAPI, {params:{
      callbackURL: process.env.REACT_APP_DOCUMENT_ROOT + CallBackURL
    }})
    .then(res => {
      return resolve(res);
    })
    .catch(err => {
      return err;
    });
  });
}
export const TwitterAccessToken = (oauth_token, oauth_verifier) => {
  return new Promise((resolve, reject) => {
    axios
    .get(AccessTokenGetURL, {params:{
      oauth_token: oauth_token,
      oauth_verifier: oauth_verifier
    }})
    .then((results) => {
      return resolve(results);
    })
    .catch((err) => {
      return reject(err);
    });
  });
}

export const getGoogleUserInfo = (dispatch) => {
  const query = new URLSearchParams(window.location.search);
  const code: string = String(query.get('code'));
  const params: URLSearchParams = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("redirect_uri", process.env.REACT_APP_DOCUMENT_ROOT + '/auth/callback');
  params.append("code", code);
  params.append("client_id", process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID);
  if (code) {
    // Tokenの取得
    axios.post(process.env.REACT_APP_AWS_COGNITO_URL + "/oauth2/token",
    params, {
      headers : {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }).then((token: any) => {
      const bearer: string = "Bearer " + token.data.access_token;
      // ユーザー情報の取得
      axios.post(process.env.REACT_APP_AWS_COGNITO_URL + "/oauth2/userInfo", {}, {
        headers : {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": bearer
        }
      }).then((userInfo: any) => {
        // Amplifyを利用するためにlocalStorageにセットする
        dispatch(loginAction({
          tts_id: userInfo.data.sub,
          google_username: userInfo.data.username,
          idtoken: '',
          access_token: userInfo.data.access_token,
          tf_mail: userInfo.data.email,
          phone_number: userInfo.data.phone_number,
          tf_nickname: userInfo.data.name,
          tf_birthday: '',
          tf_gender: '',
          tf_native_lang: '',
          tf_come_from: '',
        }));
      });
    }).catch((err: any) => {
      console.error('エラーですよ！' + err);
    });
  }
}