export const notLoginPage = [
    '/',
    '/login',
    '/newRegistration',
    '/newRegistration/input',
    '/newRegistration/tempRegistSendMail',
    '/newRegistration/form',
    '/newRegistration/inputConfirmation',
    '/newRegistration/confirmationMail',
    '/top/firstCategorySelection',
    '/auth/callback',
    '/passwordForget',
    '/forgetNewPassword',
    '/forgetComplete',
];