import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncDevAPI, Decrypto, Encrypto, LocalStorageLimitCheck } from "../functions";
import { setErrorAction } from "../Reduxs/Errors/actions";
import { articleUpdate } from "../templates/account/userData";

const FirstLogin = (visible, setVisible) => {
    const closeModal = () => {
        localStorage.isLogin = 'noFirstLogin';
        setVisible(!visible);
    }
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const [agree, setAgree] = useState<boolean>(true);

    const myTagEdit = sessionStorage.getItem('myTagEdit') ?? null;
    const [messageboxWordOk, setMessageboxWordOk] = useState<boolean>(myTagEdit ? Boolean(myTagEdit) : false);
    const [messageboxWordNg, setMessageboxWordNg] = useState<boolean>(false);

    // アクセストークの有無チェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    
    const urpj = String(sessionStorage.getItem('urpj'));
    if(!SessionUserObj){
        //window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
    }
    const loginUser = JSON.parse(String(SessionUserObj));
    let user_interest_tmp_id:number[] = [];

    const interestTmp = localStorage.getItem('interest');
    const interest = JSON.parse(Decrypto(interestTmp));

    const btdTmp = localStorage.getItem('btd');
    const btd = JSON.parse(Decrypto(btdTmp));

    let checkList:object[] = [];
    let checkItem:number[] = [];
    Object.keys(interest.interest).map(key => {
        let flg = false;
        if(user_interest_tmp_id.includes(interest.interest[key].mid)){
            flg = true;
            checkItem.push(interest.interest[key].mid);
        }
        checkList[key] = {mid:interest.interest[key].mid, checked: Boolean(flg)};
    });

    let user_btb:string = '50';
    const changedBtd = (e) => {
        user_btb = e.target.value;
    }

    const changedInterest = (event) => {
        checkList.map(item => {
            if(item['mid'] == event.value){
                item['checked'] = event.checked;
            }
        });
    }
    const saveData = () => {
        let mid_mail:number[] = [];
        if(agree){
            mid_mail.push(1);
            mid_mail.push(2);
            mid_mail.push(3);
            let params;
            params = {
                apiname: 'source_user_notification_post',
                AccessToken: loginUser.accessToken,
                mid_mail: mid_mail.join(),
            }
            changedNotification(params, loginUser, dispatch);
        }

        let update_interest:any[] = [];
        checkList.map(item => {
            if (item['checked']){
                update_interest.push(item['mid']);
            }
        });

        let mid:object[] = [];
        if(update_interest !== []){
            mid.push({"insert": user_btb + ',' + update_interest.join()});
        } else {
            mid.push({"insert": user_btb});
        }
        const hserch = /^,/;
        const eserch = /,$/;
        if(hserch.test(mid[0]['insert'])){
            mid[0]['insert'] = mid[0]['insert'].slice(1);
        }
        if(eserch.test(mid[0]['insert'])){
            mid[0]['insert'] = mid[0]['insert'].slice(0, -1);
        }

        const paramsFirst = {
            apiname: 'source_user_put',
            AccessToken: loginUser.accessToken,
            mid: mid
        }
        asyncDevAPI(paramsFirst)
            .then((res:any) => {
                if(res.body.status === 200){
                    const result = res.body;
                    const rdsParams = {
                        apiname: 'source_user_get',
                        AccessToken: loginUser.accessToken,
                    }
                    asyncDevAPI(rdsParams)
                        .then((res:any) => {
                            if(res.body.status === 200){
                                const m_user = res.body.response.m_user;
                                const m_code = res.body.response.m_code;
                                const params = {
                                    username: m_user.nickname,
                                    year: String(m_user.birthday).substr(0,4),
                                    month: String(m_user.birthday).substr(4,2),
                                    day: String(m_user.birthday).substr(6,2),
                                    gender: m_user.gender,
                                    m_code: m_code
                                };
                                let uiData:Number[] = [];
                                m_code.map((item:any) => {
                                  if(item.atb === 2){
                                      uiData.push(item.mid);
                                  }
                                });
                                const paramsjs = JSON.stringify(params);
                                sessionStorage.urpj = Encrypto(paramsjs);
                                localStorage.isLogin = 'noFirstLogin';
                                articleUpdate(loginUser.accessToken, uiData, dispatch, setMessageboxWordOk, setMessageboxWordNg);
                                closeModal();
                                //window.location.reload();
                            } else {
                                dispatch(setErrorAction({
                                    errorCode: res.body.status
                                }));
                                dispatch(push('/error'));
                            }
                        })
                        .catch(err => {
                            alert('ログイン状態が切れました。再度ログインしてください。');
                            dispatch(push('/'));
                        });
                } else {
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
            })
            .catch(err => {
                console.log(err);
            }
        );
    }

    const changedAgree = () => {
        setAgree(!agree);
    }
    return (
        <div className={visible? '' : "display-none"}>
            <div id="first_login">
                <div className="popup">
                    <div className="btn_close close" style={{cursor : 'pointer'}} onClick={closeModal}></div>
                    <h2>TTS-IDへようこそ</h2>
                    <p>TTS-IDアカウントのご登録ありがとうございます。<br />出張に関連した以下のキーワードで興味がある内容を選択ください。</p>
                    <div className="box">
                        <div className="inner">
                            <div className="form">
                                <dl className="keyword_g">
                                    <dd>
                                        <div className="checkbox_ptn02">
                                            {Object.keys(interest.interest).map(key => {
                                                return (
                                                    <label key={key}>
                                                        <input type="checkbox" value={interest.interest[key].mid} name="keyword" defaultChecked={checkItem.includes(interest.interest[key].mid) ? true : false} onClick={(e:any) => changedInterest(e.target)} />
                                                        <span className='new'>{interest.interest[key].svalue}</span>
                                                    </label>
                                                ); 
                                            })}
                                        </div>
                                    </dd>
                                </dl>
                                <dl className="country_g">
                                    <dt className="form_text">よく行く出張先</dt>
                                    <dd>
                                        <select name="country" id="" defaultValue={'50'} style={{cursor: 'pointer'}} className="select native_place" onChange={(e:any) => changedBtd(e)}>
                                            {Object.keys(btd.btd).map(key => {
                                                return (
                                                    <option key={"btd-" + key} value={btd.btd[key].mid}>{btd.btd[key].svalue}</option>
                                                );        
                                            })}
                                        </select>
                                    </dd>
                                </dl>
                                <dl className="country_g">
                                    <dt className="form_text">あなたが興味のある内容についてお知らせをお送りしてもよろしいですか？<br />
                                        <span>送信設定は、TTS-ID設定から変更できます。</span>
                                    </dt>
                                    <dd>
                                        <div className="radio">
                                            <input id="notification01" name="notification" type="radio" value="希望する" checked={agree ? true : false}　onChange={changedAgree}  />
                                            <label htmlFor="notification01">希望する</label>
                                        </div>
                                        <div className="radio">
                                            <input id="notification02" name="notification" type="radio" value="希望しない" checked={agree ? false : true} onChange={changedAgree}/>
                                            <label htmlFor="notification02">希望しない</label>
                                        </div>
                                    </dd>
                                </dl>
                                <div className="line"></div>
                                <div className="btn_g">
                                    <div className="btn_ptn02 close"　style={{cursor: 'pointer'}} onClick={closeModal}><a>また後で</a></div>
                                    <div className="btn_submit"><input type="submit" value="決定" onClick={saveData}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirstLogin


const changedNotification = (params, loginUser, dispatch) => {
    asyncDevAPI(params)
    .then((res:any) => {
        if (res.body.status === 200) {
            const result = res.body;
            const notificationParams = {
                apiname: 'source_user_notification_get',
                AccessToken: loginUser.accessToken,
            }
            let get_mail_notif:String[] = [];
            let get_browser_notif:String[] = [];
            let mail_notif = {
                receive: false,
                destination: false,
                tag: false,
            };
            let browser_notif = {
                receive: false,
                destination: false,
                tag: false,
            };
            asyncDevAPI(notificationParams)
                .then((res:any) => {
                    if(res.body.status === 200){
                        const response = res.body.response;
                        get_mail_notif = response[0].mid_mail.split(',');
                        get_browser_notif = response[0].mid_browser.split(',');
        
                        get_mail_notif.map(item => {
                            if(item == '1'){
                                mail_notif.receive = true;
                            }
                            if(item == '2'){
                                mail_notif.destination = true;
                            }
                            if(item == '3'){
                                mail_notif.tag = true;
                            }
                        });
                        get_browser_notif.map(item => {
                            if(item == '1'){
                                browser_notif.receive = true;
                            }
                            if(item == '2'){
                                browser_notif.destination = true;
                            }
                            if(item == '3'){
                                browser_notif.tag = true;
                            }
                        });
                        const paramsjs = JSON.stringify({
                            mail: mail_notif,
                            browser: browser_notif,
                        });
                        sessionStorage.nd = Encrypto(paramsjs);
                    } else {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push('/error'));
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            dispatch(setErrorAction({
                errorCode: res.body.status
            }));
            dispatch(push('/error'));
        }
    })
    .catch(err => {
        console.log(err);
    });
}