export const SET_URL = 'SET_URL';
export const setUrlAction = (mfaState) => {
    return {
        type: 'SET_URL',
        payload: {
            url: mfaState.url,
        }
    }
}

export const CLEAR_URL = 'CLEAR_URL';
export const clearUrlAction = () => {
    return {
        type: 'CLEAR_URL',
        payload: {
            url: '',
        }
    }
}