import * as Actions from './actions';
import initialState from '../Store/initialState';

export const TwitterTokenReducer = (state = initialState.twitterToken, action) => {
    switch(action.type){
        case Actions.SET_TWITTER_OAUTH_TOKEN:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}