import { Footer, Header, PasswordChangeCompleteHead } from '../../../../htmlParts';
import illust01_user from '../../../../assets/img/setting/user/illust01_user.png';
import { Decrypto, MoveOnryButton } from "../../../../functions"
import { useEffect } from 'react';

const PassComplete = () => {
    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    useEffect(() => {
        return () => {
            // SessionStorageをクリア
            sessionStorage.clear();
            localStorage.removeItem(envStorageKey);
        }
    })
    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <PasswordChangeCompleteHead />
                <Header />
                <div id="mail_change_done" className="signup_bg mail_change_ptn02">
                    <main>
                        <div className="contents_in">
                            <div className="decoration_img"><img src={illust01_user} alt="人のイラスト" /></div>
                            <div className="page_title_ptn01">
                                <h1>パスワードの変更が完了しました。</h1>
                            </div>

                            <div className="text_box_ptn01 form_box_ptn02">
                                <div className="cnt_text">
                                    <p>パスワードの変更が完了しました。<br />
                                        新しく設定したパスワードで再度ログインしてください。</p>
                                </div>
                                <div className="btn_ptn03">
                                    <MoveOnryButton buttonName={'ログイン'} path={'/login'} />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default PassComplete