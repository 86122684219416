import { Footer } from '../../../../htmlParts';
import { Decrypto, MoveOnryButton } from "../../../../functions";

const MailSendMail = () => {
    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <div id="mail_change_send" className="signup_bg mail_change_ptn02">
                    <main>
                        <div className="contents_in">
                            <div className="page_title_ptn01">
                                <h1>メールをご確認ください。</h1>
                            </div>
                            <div className="text_box_ptn01 form_box_ptn02">
                                <div className="cnt_text">
                                    <p>
                                        入力いただいたメールアドレスへ、<br />
                                        メールアドレス変更確認用のURLを記載した<br className="sp" />
                                        メールを送信しました。<br />
                                        メール本文に記載の内容をご確認いただき、<br className="sp" />
                                        変更の手続きを行ってください。<br />
                                        <br />
                                        時間をおいてもメールが届かない場合は、<br className="sp" />
                                        再度ご入力ください。
                                    </p>
                                </div>
                                <div className="btn_ptn03">
                                    <MoveOnryButton buttonName={'TTS-ID　トップに戻る'} path={'/top'} />
                                </div>
                            </div>

                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default MailSendMail
