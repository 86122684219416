import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { asyncDevAPI, Decrypto, Encrypto, ErrorSwitchFunctions } from '../../../../functions';
import { getUrl } from '../../../../Reduxs/Mfa/selectors';
import { clearUrlAction } from '../../../../Reduxs/Mfa/actions';
import { Footer, Header } from '../../../../htmlParts';
import { changedCodeHandler } from '../../../../functions/ChangeFunction';
import { setErrorAction } from '../../../../Reduxs/Errors/actions';
import { clearLoadingAction, setLoadingAction } from '../../../../Reduxs/Loading/actions';

const CodeInputRelease = (props) => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    document.title = '2段階認証（セキュリティコード）|TTS-ID';

    // アクセストークのチェック
    const [code, setCode] = useState<string>('');
    const [codeError, setCodeError] = useState<string>('');
    const [selectSecretQuestion, setSelectSecretQuestion] = useState<string>('');
    const [selectSecretQuestionError, setSelectSecretQuestionError] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [msg, setMsg] = useState<string>('');

    const returnUrl = getUrl(selector);
    clearUrlAction();

    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    const session = sessionStorage.getItem('Session');
    const sessionDec = session ? Decrypto(session) : null;
    const sessionJson = sessionDec ? JSON.parse(sessionDec) : null;

    // 2段階認証の処理関数ここから。適宜変更
    const verification = () => {
        // ローディング開始
        dispatch(setLoadingAction({
            isLoading: true,
        }));
        const apiParams = {
            apiname: 'source_cognito_auth_challenge',
            session: sessionJson.session,
            mfa_code: code,
            user_name: sessionJson.email,
        }
        // 検証コード認証API
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const result = res;
                const statusCode = result['body']['status'];
                const accessToken = result['body']['response']['AccessToken'];

                if (statusCode === 200) {
                    const apiParams = {
                        apiname: 'source_cognito_mfa_post',
                        type: 'mfa_off',
                        AccessToken: accessToken,
                    };
                    // 2段階認証設定API
                    asyncDevAPI(apiParams)
                        .then((res: any) => {
                            const result = res;
                            const statusCode = result['body']['status'];
                            if (statusCode === 200) {
                                // sessionの2段階認証の状態Flagを立てておく
                                sessionStorage.setItem('tsvt', Encrypto(false));
                                // 2段階認証設定完了画面に遷移
                                dispatch(push('/account/security'));
                            } else {
                                console.log('res: ' + JSON.stringify(res)); 
                                // 200以外のステータス
                                const setMsgs = {
                                    ErrMsg: setErrMsg,
                                }
                                const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                if (errorPage.flg) {
                                    dispatch(setErrorAction({
                                        errorCode: res.body.status
                                    }));
                                    dispatch(push(errorPage.url));
                                }
                                return;
                            }
                        })
                        .catch(err => {
                            switch (err.errorType) {
                                default:
                                    setErrMsg('2段階認証の設定に失敗しました。お手数ですがもう一度お試しください。');
                                    break
                            }
                            console.log('2段階認証設定APIでエラー エラー内容: ' + JSON.stringify(err));
                        })
                } else {
                    console.log('res: ' + JSON.stringify(res)); 
                    // 200以外のステータス
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                switch (err.errorType) {
                    default:
                        setErrMsg('コードの認証に失敗しました。お手数ですがもう一度お試しください。');
                        break
                }
                console.log('検証コード発行APIでエラー エラー内容: ' + JSON.stringify(err));
            })
        dispatch(clearLoadingAction());
    }
    // 2段階認証の処理関数ここまで

    // 認証コード再送
    const reSend = () => {
        setMsg('');
        // ローディング開始
        dispatch(setLoadingAction({ isLoading: true }));
        // ログイン時の2段階認証の場合再度ログイン処理を行う
        const apiParams = {
            apiname: 'source_login_post',
            user_name: sessionJson.email,
            password: sessionJson.password,
            device: sessionJson.useDeviceAndBrowser,
        };
        asyncDevAPI(apiParams)
            .then((res: any) => {
                if (res.body.response.Session) {
                    const params = {
                        email: sessionJson.email,
                        password: sessionJson.password,
                        useDeviceAndBrowser: sessionJson.useDeviceAndBrowser,
                        session: res.body.response.Session,
                        phoneNumber: res.body.response.phone_number,
                        keep: sessionJson.keepLogin,
                    }
                    const paramsJson = JSON.stringify(params);
                    sessionStorage.Session = Encrypto(paramsJson);
                    dispatch(clearLoadingAction());
                    return;
                } else {
                    setErrMsg('コードの送信に失敗しました。お手数ですがもう一度お試しください。');
                }
            })
            .catch((err) => {
                setErrMsg('コードの送信に失敗しました。お手数ですがもう一度お試しください。');
            })
    }

    const resendMsg = () => {
        if (msg) {
            return (
                <div className="messagebox resend">
                    {msg}
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <Header />
                <div id="two_step_code" className="mail_change">
                    <main>
                        <div className="contents_in">
                            <div className="back"><Link to='/account/security/mfa/mfaVerification'>&lt; 戻る</Link></div>
                            <div className="text_box_ptn02 form_box_ptn02">
                                <h1 className="title">2段階認証解除</h1>
                                <div className="lead">
                                    あなたがお持ちの携帯電話に送信された<br className="sp" />6桁のセキュリティコードを入力ください。<br />
                                    こちらの操作で、2段階認証の設定は解除されます。
                                </div>
                                <div className='form'>
                                    <dl className="form_g">
                                        <dt className="form_text">6桁のセキュリティコードを入力</dt>
                                        <dd>
                                            <input type='text' name='label' maxLength={6} placeholder='6桁のセキュリティコードを入力' onChange={(e: any) => changedCodeHandler(e, setCode, setCodeError, true)} />
                                            <div className="form_error_text">
                                                {codeError ? codeError : errMsg}
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="btn_submit">
                                        <button
                                            disabled={!(codeError === '') || code === ''}
                                            onClick={() => verification()}
                                        >2段階認証を解除</button>
                                    </div>
                                </div>
                                <div className="support_links">
                                    {resendMsg()}
                                    <div className="link"><span className="link_ptn01" style={{cursor: 'pointer'}} onClick={() => reSend()}>コードを再送する</span></div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default CodeInputRelease