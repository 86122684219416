import { useDispatch, useSelector } from 'react-redux';
import { asyncDevAPI, changedBirthday, changedNativeLang, changedNickName, Decrypto, EmailMask, Encrypto, ErrorSwitchFunctions, MoveOnryButton, nicknameErrorWord, numCheck } from '../../functions';
import { getNativeLangId, getNickName, getSelectFlg, getSetDispFrom } from '../../Reduxs/Users/selectors';
import { push } from 'connected-react-router';
import { SideBar } from '../account';
import { AccountProfileHead, Footer, Header } from '../../htmlParts';
import btHacksIconRect from '../../assets/img/common/bthacks-icon-rect.png';
import illust01_account from '../../assets/img/setting/illust01_account.png';
import { useEffect, useState } from 'react';
import DrilDownSample from '../../htmlParts/inputs/drilDownSample';
import { entryFormAction } from '../../Reduxs/Users/actions';
import { getAPIAction, getIsModalOpen } from '../../Reduxs/PageAct/selectors';
import { apiFinAction, apiStartAction, hanbergerOpenAction } from '../../Reduxs/PageAct/actions';
import ScrollAnimation from 'react-animate-on-scroll';
import { setErrorAction } from '../../Reduxs/Errors/actions';
import { setLoadingAction, clearLoadingAction } from '../../Reduxs/Loading/actions';
import { setArticle } from '../auth/login';

const params = new URLSearchParams(window.location.search);

//アカウントトップページ

const Profile = () => {
    document.title = 'プロフィール|TTS-ID';

    const bthacksToken = params.get('token');

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    // アクセストークの有無チェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = SessionUserObj ? JSON.parse(SessionUserObj) : '';

    const [nativeLangKey, setNativeLangKey] = useState<string>(getNativeLangId(selector));

    const [selectDisp, setSelectDisp] = useState<boolean>(getSelectFlg(selector));

    const [nLangError, setNLangError] = useState<string>('');
    const [cFromError, setCFromError] = useState<string>('');

    // エラーメッセージ
    const [errMsg, setErrMsg] = useState<string>('');

    // 保存ボタン下のメッセージ
    const [messageboxWordOk, setMessageboxWordOk] = useState<boolean>(false);
    const [messageboxWordNg, setMessageboxWordNg] = useState<boolean>(false);

    let ucpj = sessionStorage.getItem('ucpj');
    let urpj = sessionStorage.getItem('urpj');
    let froms = localStorage.getItem('fromsParts');
    let langs = localStorage.getItem('langsParts');

    ucpj = ucpj ? Decrypto(ucpj) : null;
    urpj = urpj ? Decrypto(urpj) : null;
    froms = froms ? Decrypto(froms) : null;
    langs = langs ? Decrypto(langs) : null;


    const c_user = ucpj ? JSON.parse(ucpj) : {};
    const r_user = urpj ? JSON.parse(urpj) : {};
    const fromsParts = froms ? JSON.parse(froms) : [];
    const langsParts = langs ? JSON.parse(langs) : [];

    const m_code = r_user ? r_user.m_code : null;
    const emailtmp = c_user ? c_user.email : '';

    let continentTmp = '';
    let continentTmpId = 0;
    let countryTmp = '';
    let countryTmpId = 0;
    let areaTmp = '';
    let areaTmpId = 0;
    let langTmp = '';
    let langTmpId = 0;
    let areaFlg = false;
    if (!window.onbeforeunload) {
        window.onbeforeunload = () => alert('testtest');;
    }

    if (m_code) {
        m_code.map((item) => {
            switch (item.atb) {
                case 0:
                    if (item.idx == 1) {
                        continentTmp = item.svalue;
                        continentTmpId = item.mid;
                    }
                    if (item.idx == 2) {
                        countryTmp = item.svalue;
                        countryTmpId = item.mid;
                    }
                    if (item.idx == 3) {
                        areaFlg = true;
                        areaTmp = item.svalue;
                        areaTmpId = item.mid;
                    }
                    break;
                case 6:
                    langTmp = item.svalue;
                    langTmpId = item.mid;
                    break;
                default:
                    break;
            }
        });
    }

    let dispFrom = continentTmp;
    if (areaFlg) {
        dispFrom += '/' + areaTmp;
    } else {
        dispFrom += '/' + countryTmp;
    }

    // 変更途中での離脱警告用Param
    const sessionNickName = sessionStorage.getItem('NickName') ? Decrypto(sessionStorage.getItem('NickName')) : null;
    const sessionYear = sessionStorage.getItem('year') ? Decrypto(sessionStorage.getItem('year')) : null;
    const sessionMonth = sessionStorage.getItem('month') ? Decrypto(sessionStorage.getItem('month')) : null;
    const sessionDay = sessionStorage.getItem('day') ? Decrypto(sessionStorage.getItem('day')) : null;
    const sessionGender = sessionStorage.getItem('Gender') ? Number(Decrypto(sessionStorage.getItem('Gender'))) : null;
    const sessionNativeLang = sessionStorage.getItem('NativeLang') ? Number(Decrypto(sessionStorage.getItem('NativeLang'))) : langTmpId;
    const sessionDispContinent = sessionStorage.getItem('dispContinent') ? Decrypto(sessionStorage.getItem('dispContinent')) : null;
    const sessionContinentTmp = sessionStorage.getItem('continentTmpId_CountryId') ? Decrypto(sessionStorage.getItem('continentTmpId_CountryId')) : null;
    const sessioncountryId = sessionStorage.getItem('countryTmpId_AreaTmpId') ? Decrypto(sessionStorage.getItem('countryTmpId_AreaTmpId')) : null;

    const [email, setEmail] = useState<string>(emailtmp ? EmailMask(emailtmp) : '');
    const [username, setUserName] = useState<string>(sessionNickName ?? getNickName(selector));
    const [usernameErr, setUserNameErr] = useState<string>('');
    const [year, setYear] = useState<string>(sessionYear ?? r_user.year);
    const [month, setMonth] = useState<string>(sessionMonth ?? r_user.month);
    const [day, setDay] = useState<string>(sessionDay ?? r_user.day);
    const [gender, setGender] = useState<number>(sessionGender ?? r_user.gender);
    const [nLang, setNLang] = useState<string>(langTmp);
    const [nLangId, setNLangId] = useState<Number>(sessionNativeLang ?? langTmpId);
    const [continent, setContinent] = useState<String>(sessionContinentTmp ?? continentTmp);
    const [continentId, setContinentId] = useState<Number>(continentTmpId);
    const [country, setCountry] = useState<string>(countryTmp ? countryTmp : areaTmp);
    const [countryId, setCountryId] = useState<Number>(sessioncountryId ?? countryTmpId ? countryTmpId : areaTmpId);
    const [inputWord, setInputWord] = useState<string>(dispFrom);
    const [yearErr, setYearErr] = useState<string>('');
    const [monthErr, setMonthErr] = useState<string>('');
    const [dayErr, setDayErr] = useState<string>('');
    const [birthdayErr, setBirthdayErr] = useState<string>('');

    const continentOptions = fromsParts.froms?.continentOptions || [];
    const countryOptions = fromsParts.froms?.countryOptions || [];
    const areaOptions = fromsParts.froms?.areaOptions || [];

    const changedYear = (e: any) => {
        if (!numCheck.test(e.target.value)) {
            const y: string = e.target.value;
            e.target.value = y.slice(0, -1);
        }
        const dateType = 'year';
        const temp = e.target.value;
        const values = {
            year: temp,
            month: month,
            day: day
        }
        changedBirthday(
            dateType,
            values,
            setYear,
            setYearErr,
            setBirthdayErr
        )
    }
    const changedMonth = (e: any) => {
        if (!numCheck.test(e.target.value)) {
            const y: string = e.target.value;
            e.target.value = y.slice(0, -1);
        }
        const dateType = 'month';
        const temp = e.target.value;
        const values = {
            year: year,
            month: temp,
            day: day
        }
        changedBirthday(
            dateType,
            values,
            setMonth,
            setMonthErr,
            setBirthdayErr
        )
    }
    const changedDay = (e: any) => {
        if (!numCheck.test(e.target.value)) {
            const y: string = e.target.value;
            e.target.value = y.slice(0, -1);
        }
        const dateType = 'day';
        const temp = e.target.value;
        const values = {
            year: year,
            month: month,
            day: temp
        }
        changedBirthday(
            dateType,
            values,
            setDay,
            setDayErr,
            setBirthdayErr
        )
    }
    const saveData = () => {
        dispatch(apiStartAction());
        const atb3 = countryTmpId ? countryTmpId : areaTmpId
        const params = {
            apiname: 'source_user_put',
            AccessToken: loginUser.accessToken,
            nickname: username,
            icon: '',
            gender: gender,
            birthday: String(year) + String(month) + String(day),
            mid: [
                { "update": langTmpId + ',' + nLangId },
                { "update": continentTmpId + ',' + continentId },
                { "update": atb3 + ',' + countryId },
            ]
        }
        asyncDevAPI(params)
            .then((res: any) => {
                if (res.body.status === 200) {
                    const result = res.body;
                    const rdsParams = {
                        apiname: 'source_user_get',
                        AccessToken: loginUser.accessToken,
                    }
                    asyncDevAPI(rdsParams)
                        .then((res: any) => {
                            if (res.body.status === 200) {
                                const m_user = res.body.response.m_user;
                                const m_code = res.body.response.m_code;
                                const params = {
                                    username: m_user.nickname,
                                    year: String(m_user.birthday).substr(0, 4),
                                    month: String(m_user.birthday).substr(4, 2),
                                    day: String(m_user.birthday).substr(6, 2),
                                    gender: m_user.gender,
                                    m_code: m_code
                                };
                                const paramsjs = JSON.stringify(params);
                                sessionStorage.urpj = Encrypto(paramsjs);

                                // 保存が成功したので、sessionのデータは削除
                                sessionStorage.removeItem('NickName');
                                sessionStorage.removeItem('year');
                                sessionStorage.removeItem('month');
                                sessionStorage.removeItem('day');
                                sessionStorage.removeItem('Gender');
                                sessionStorage.removeItem('NativeLang');
                                sessionStorage.removeItem('dispContinent');
                                sessionStorage.removeItem('continentTmpId_CountryId');
                                sessionStorage.removeItem('countryTmpId_AreaTmpId');
                                dispatch(apiFinAction());
                                // alert('変更しました。');
                                // window.location.reload();
                                setMessageboxWordOk(true);
                            } else {
                                // 200以外のステータス
                                const setMsgs = {
                                    ErrMsg: setErrMsg,
                                }
                                const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                if (errorPage.flg) {
                                    dispatch(setErrorAction({
                                        errorCode: res.body.status
                                    }));
                                    dispatch(push(errorPage.url));
                                }
                                return;
                            }
                        })
                        .catch(err => {
                            dispatch(apiFinAction());
                            alert('ログイン状態が切れました。再度ログインしてください。');
                            dispatch(push('/'));
                        });
                } else {
                    // 200以外のステータス
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                dispatch(apiFinAction());
                console.log(err);
                setMessageboxWordNg(true);
            });
    }
    delete continentOptions[0];
    delete countryOptions[0];
    const displaySelect = () => {
        if (getSelectFlg(selector)) {
            return (
                <div className='parent-div'>
                    <DrilDownSample
                        continentOptions={continentOptions}
                        countryOptions={countryOptions}
                        areaOptions={areaOptions}
                        setInputWord={setInputWord}
                        setContinentId={setContinentId}
                        setCountryId={setCountryId}
                    />
                </div>
            )
        } else {
            return undefined;
        }
    }

    const [hanVis, setHanVis] = useState<boolean>(false);
    const changedHanberger = () => {
        dispatch(hanbergerOpenAction());
        setHanVis(!hanVis);
    }
    const loginToBTHacks = () => {
        if (loginUser) {
            const btHacksLink = process.env.REACT_APP_BTHACKS_LINK;
            const suEnc = Decrypto(sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME]);
            const sessionStorageparams = JSON.parse(suEnc);
            const forBTHacksToken =  sessionStorageparams.accessToken;
            window.open(btHacksLink + '/?token=' + forBTHacksToken);
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('editSaveOn')) {
            // 警告モーダルで保存するを押下していた場合
            saveData();
            sessionStorage.removeItem('editSaveOn');
        }
    }, [])

    useEffect(() => {
        if ( bthacksToken ) {
          localStorage.Login = true;

          const sessionStorageparams = {
            accessToken: bthacksToken,
            verifyCount: 6,
          }
            const suEnc = JSON.stringify(sessionStorageparams);
            sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(suEnc);

          const rdsParams = {
            apiname: 'source_user_get',
            AccessToken: bthacksToken,
          }
          asyncDevAPI(rdsParams)
            .then((res: any) => {
              if (res.body.status && res.body.status === 200) {
                const m_user = res.body.response.m_user;
                const m_code = res.body.response.m_code;
                const params = {
                  username: m_user.nickname,
                  year: String(m_user.birthday).substr(0, 4),
                  month: String(m_user.birthday).substr(4, 2),
                  day: String(m_user.birthday).substr(6, 2),
                  gender: m_user.gender,
                  m_code: m_code
                };
                let uiData:Number[] = [];
                m_code.map((item:any) => {
                  if(item.atb === 2){
                      uiData.push(item.mid);
                  }
                });

                setArticle(bthacksToken, uiData, dispatch);
                
                const paramsjs = JSON.stringify(params);
                sessionStorage.urpj = Encrypto(paramsjs);
                sessionStorage.unkm = Encrypto(m_user.nickname);
                //dispatch(clearLoadingAction());
                //dispatch(push('/top'));
                dispatch(push('/account'));
              } else {
                dispatch(clearLoadingAction());
                dispatch(setErrorAction({
                  errorCode: res.body.status
                }));
                dispatch(push('/error'));
              }
            })

        }
      }, [bthacksToken]);

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper" className={getIsModalOpen(selector) ? 'no-scroll' : ''}>
                <AccountProfileHead />
                <Header />
                <div id="account_info" className="setting_page">
                    <main>
                        <div className="page_contents">
                            <h1 className="sp">TTS-ID設定</h1>
                            <div className={hanVis ? "btn_open sp act" : "btn_open sp"} onClick={changedHanberger}><span></span><span></span><span></span></div>
                            <SideBar />
                            <div className="main_contents">
                                <div className="contents_in">
                                    <h2 className="h2_ptn01">プロフィール</h2>
                                    <div className="form_title">ログイン中のTTS-ID</div>
                                    <div className="mail_box">
                                        <div className="email">{email}</div><div className="btn_edit"><MoveOnryButton buttonName={'変更'} path={'/account/security'} /></div>
                                    </div>
                                    <div className="line"></div>
                                    <div className="form_title">お使いのサービス</div>
                                     <div className="cnt_box01 cnt_box">
                                        <div className="btn_ptn03 bthacks_profile">
                                            <div><img src={btHacksIconRect} alt="BTHacks Icon" /></div>
                                            <div onClick={loginToBTHacks}><a href="https://www.bthacks.com/" target="_blank" rel="noreferrer">開く</a></div>
                                        </div>
                                     </div>
                                    <div className="form">
                                        <dl className="form_g">
                                            <h2 className="h2_ptn01">プロフィール情報</h2>
                                            <dt className="form_text">お名前</dt>
                                            <dd>
                                                <input
                                                    type="text" placeholder="お名前を入力" defaultValue={username} className="name"
                                                    onChange={(e: any) => changedNickName(e, setUserName, setUserNameErr)}
                                                />
                                                <div className="form_error_text">{usernameErr}</div>
                                            </dd>
                                            <dt className="form_text">生年月日</dt>
                                            <dd>
                                                <input
                                                    type="text" maxLength={4} pattern='^1[9][2-9][0-9]|20[012][0-9]$' placeholder="2000"
                                                    className="year" defaultValue={year} onChange={(e: any) => changedYear(e)}
                                                />年
                                                <input
                                                    type="text" maxLength={2} pattern='^(0[1-9]|1[0-2])$' placeholder="01"
                                                    className="month" defaultValue={month} onChange={(e: any) => changedMonth(e)}
                                                />月
                                                <input type="text" maxLength={2} pattern='^(0[1-9]|[12][0-9]|3[01])$' placeholder="01"
                                                    className="day" defaultValue={day} onChange={(e: any) => changedDay(e)}
                                                />日
                                                <div className="form_error_text">
                                                    {
                                                        (!yearErr && !monthErr && !dayErr) ? birthdayErr
                                                        : yearErr ? yearErr
                                                        : monthErr ? monthErr
                                                        : dayErr ? dayErr 
                                                        : birthdayErr ? birthdayErr
                                                        : ''
                                                    }
                                                </div>
                                            </dd>
                                            <dt className="form_text">性別</dt>
                                            <dd>
                                                <div className="radio">
                                                    <input
                                                        id="male" name="gender" type="radio" value="男性" defaultValue={gender}
                                                        checked={gender === 0 ? true : false}
                                                        onChange={
                                                            (e) => {
                                                                setGender(0);
                                                                sessionStorage.setItem('Gender', Encrypto('0'));
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor="male">男性</label>
                                                </div>
                                                <div className="radio">
                                                    <input
                                                        id="female" name="gender" type="radio" value="女性" defaultValue={gender}
                                                        checked={gender === 1 ? true : false}
                                                        onChange={
                                                            (e) => {
                                                                setGender(1)
                                                                sessionStorage.setItem('Gender', Encrypto('1'));
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor="female">女性</label>
                                                </div>
                                            </dd>
                                            <dt className="form_text">第一言語</dt>
                                            <dd>
                                                <select
                                                    name="native_language"
                                                    className="select"
                                                    defaultValue={String(nLangId)}
                                                    onChange={(e: any) =>
                                                        changedNativeLang(
                                                            e,
                                                            setNativeLangKey,
                                                            setNLang,
                                                            setNLangError,
                                                            setNLangId,
                                                        )
                                                    }>
                                                    {
                                                        Object.keys(langsParts.langOptions).map((key, index) => {
                                                            return (
                                                                <option key={index} value={langsParts.langOptions[key].value}>{langsParts.langOptions[key].label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <div className="form_error_text">{nLangError}</div>
                                            </dd>
                                            <dt className="form_text">出身国</dt>
                                            <dd className="country">
                                                <input
                                                    type='text'
                                                    className='cursor'
                                                    readOnly
                                                    value={sessionDispContinent ?? inputWord}
                                                    onClick={() => {
                                                        dispatch(entryFormAction({
                                                            select_disp_flg: !selectDisp,
                                                            set_disp_from: getSetDispFrom(selector)
                                                        }));
                                                        setSelectDisp(!selectDisp);
                                                    }}
                                                />
                                                {displaySelect()}
                                            </dd>
                                        </dl>
                                        <div className="form_error_text">{errMsg}</div>
                                        <div className="btn_submit">
                                            <input
                                                type="submit"
                                                value="変更内容を保存"
                                                disabled={
                                                    getAPIAction(selector) ||
                                                    !(birthdayErr === '') ||
                                                    !(yearErr === '') ||
                                                    !(monthErr === '') ||
                                                    !(dayErr === '') ||
                                                    !(nLangError === '') ||
                                                    !(cFromError === '') ||
                                                    !(usernameErr === '') ||
                                                    !username ||
                                                    !year ||
                                                    !month ||
                                                    !day ||
                                                    !nLang
                                                }
                                                onClick={saveData}
                                            />
                                        </div>
                                        <div style={{ display: messageboxWordOk ? 'block' : 'none' }} className="messagebox save">設定を保存しました</div>
                                        <div style={{ display: messageboxWordNg ? 'block' : 'none' }} className="messagebox save error">変更内容を保存できませんでした</div>
                                    </div>
                                </div>
                                <div className="cnt_img">
                                    <img src={illust01_account} alt="" />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollAnimation
                    offset={0}
                    animateIn='fadeIn'
                    animateOut='fadeOut'
                    duration={1}
                    animatePreScroll={true}
                /*initiallyVisible={true}*/
                >
                    <Footer />
                </ScrollAnimation>
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Profile
