import {CognitoUserAttribute} from 'amazon-cognito-identity-js';

//Cognito属性作成コンポーネント

export const GetAttributeParts = (name:string,value:string) => {
    const data = {
        Name: name,
        Value: value
    }
    return new CognitoUserAttribute(data);
}