//ログイン後お問い合わせ確認ページ

import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ContactConfirmHead, Footer, Header } from '../../../htmlParts';
import {
    getContactName,
    getContactEmail,
    getContactSelect,
    getContactText,
} from '../../../Reduxs/Contacts/selectors';
import { useState } from 'react';
import { clearLoadingAction, setLoadingAction } from '../../../Reduxs/Loading/actions';
import { asyncDevAPI, Decrypto, ErrorSwitchFunctions, MoveOnryButton } from '../../../functions';
import { confirmFinAction } from '../../../Reduxs/Contacts/actions';
import { setErrorAction } from '../../../Reduxs/Errors/actions';

const UserConfirm = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    // お問い合わせ情報取得
    const storeName = getContactName(selector);
    const storeMail = getContactEmail(selector);
    const storeSelect = getContactSelect(selector);
    const storeText = getContactText(selector);
    const styles: any = { whiteSpace: 'pre-wrap' };

    // バリデーション
    const [errMsg, setErrMsg] = useState<string>('');

    const confirmSend = () => {
        if (storeText !== '') {
            // ローディング開始
            dispatch(setLoadingAction({ isLoading: true }));
            const apiParams = {
                apiname: 'source_mail_inquiry',
                name: storeName,
                email: storeMail,
                category: storeSelect,
                inquiry: storeText,
            };
            // お問い合わせ
            asyncDevAPI(apiParams)
                .then((res: any) => {
                    const result = res;
                    const statusCode = result['body']['statusCode'];

                    if (statusCode === 200) {
                        // Storeのお問い合わせ情報をクリア
                        dispatch(confirmFinAction());

                        // お問い合わせ確認画面に遷移
                        dispatch(push('/account/contact/complete'));
                    } else {
                        // 200以外のステータス
                        const setMsgs = {
                            ErrMsg: setErrMsg,
                        }
                        const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                        if (errorPage.flg) {
                            dispatch(setErrorAction({
                                errorCode: res.body.status
                            }));
                            dispatch(push(errorPage.url));
                        }
                        return;
                    }
                })
                .catch(err => {
                    setErrMsg('お問い合わせの送信に失敗しました。お手数ですがもう一度お試しください。');
                })
            dispatch(clearLoadingAction());
        } else {
            setErrMsg('処理が正しく行われていないようです。お問い合わせ内容が確認できませんでした。お手数ですが、初めからやり直してください。');
        }
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <ContactConfirmHead />
                <Header />
                <div id="contact_confirm" className="public_page contact_page">
                    <main>
                        <div className="page_contents">
                            <div className="contents_in">
                                <h1>お問い合わせ内容の<br className="sp" />ご確認</h1>
                                <p>下記の内容で問い合わせをします。<br className="sp" />問題がなければ送信ボタンを押してください。</p>
                                <div className="form">
                                    <div className="form_box_ptn02">
                                        <div className="form">
                                            <dl>
                                                <dt className="form_text">お名前<span className="required">必須</span></dt>
                                                <dd>
                                                    <div>{storeName}</div>
                                                </dd>
                                                <dt className="form_text">メールアドレス<span className="required">必須</span></dt>
                                                <dd>
                                                    <div>{storeMail}</div>
                                                </dd>
                                                <dt className="form_text">お問い合わせの種類<span className="required">必須</span></dt>
                                                <dd>
                                                    <div>{storeSelect}</div>
                                                </dd>
                                                <dt className="form_text">お問い合わせ内容<span className="required">必須</span></dt>
                                                <dd>
                                                    <div style={styles}>{storeText}</div>
                                                </dd>
                                            </dl>
                                            <div className="privacy_box">
                                                <div className="checkbox">個人情報の取り扱いに同意する</div>
                                            </div>
                                            <div className="form_error_text">{errMsg}</div>
                                            <div className="btn_submit">
                                                <button onClick={() => confirmSend()}>送信</button>
                                            </div>
                                            <div className="btn_back btn_ptn02">
                                                <MoveOnryButton buttonName={'入力画面へ戻る'} path={'/account/contact'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default UserConfirm