import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GetAttributeParts, Userupdate, DisableUserButton, Decrypto} from '../../functions';
import {getLoginStatus, getMail, getNickName,getPassword,getPhoneNumber} from '../../Reduxs/Users/selectors';
import {userUpdateAction} from '../../Reduxs/Users/actions';
import {push} from 'connected-react-router';
import {changeCognitoPassword} from '../../functions/cognitoRS';

//アカウント情報更新用ページ

const Update = () => {

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    // アクセストークの有無チェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const loginUser = SessionUserString ? Decrypto(SessionUserString) : null;

    //state管理用宣言
    const [mail, setMail] = React.useState<string>(getMail(selector));
    const [phone_number ,setPhoneNumber] = React.useState<string>(getPhoneNumber(selector));
    const [nickname,setNickName] = React.useState<string>(getNickName(selector));
    const attributeList: object[] = [];

    //パスワード変更用
    const [password, setPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] =React.useState<string>('');

    const changedPassword = (e:any) => setPassword(e.target.value);
    const changedNewPassword = (e:any) => setNewPassword(e.target.value);
    //パスワード変更用ここまで

    //state変更処理系
    const changedMail = (e:any) => setMail(e.target.value);
    const changedPhoneNumber = (e:any) => setPhoneNumber(e.target.value);
    const changedNickName = (e:any) => setNickName(e.target.value);
    if (mail !== String(getMail(selector)) && mail !== void 0){
        attributeList.push(GetAttributeParts('email', mail));
    }
    if (phone_number !== String(getPhoneNumber(selector)) && phone_number !== void 0){
        attributeList.push(GetAttributeParts('phone_number', phone_number));
    }
    if (nickname !== String(getNickName(selector)) && nickname !== void 0){
        attributeList.push(GetAttributeParts('name', nickname));
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div>
                <h1>ユーザー情報更新</h1>
                <label>アカウント名</label>
                <div><input type='text' defaultValue={getNickName(selector)} placeholder='ユーザー名を入力してください。' onBlur={(changedNickName)} /></div>
                <label>メールアドレス</label>
                <div><input type='text' defaultValue={getMail(selector)} placeholder='メールアドレスを入力してください。' onBlur={(changedMail)} /></div>
                <label>電話番号</label>
                <div><input type='text' defaultValue={getPhoneNumber(selector)} placeholder='電話番号を入力してください。' onBlur={(changedPhoneNumber)} /></div>
                <div><button onClick={() => {
                    Userupdate(attributeList, getMail(selector), getPassword(selector));
                    dispatch(userUpdateAction({
                        tf_mail: mail,
                        phone_number: phone_number,
                        tf_nickname: nickname
                    }));
                }}>情報更新</button></div>

                <div>
                    <p>パスワードの変更</p>
                    <label>新しいパスワードの入力</label>
                    <div><input type='password' placeholder={'新しいパスワード'} onChange={changedNewPassword} /></div>
                    <label>現在のパスワードの入力</label>
                    <div><input type='password' placeholder={'現在のパスワード'} onChange={changedPassword} /></div>
                    <div><button onClick={() => changeCognitoPassword(getMail(selector), password, newPassword)}>パスワードを変更</button></div>
                </div>
                <div>
                    <DisableUserButton buttonName="退会" />
                </div>
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Update