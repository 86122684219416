import {createSelector} from 'reselect';

const errorsSelector = (state) => state.errors;

export const getLoginNameError = createSelector(
    [errorsSelector],
    state => state.loginName
)

export const getLoginPasswordError = createSelector(
    [errorsSelector],
    state => state.loginPassword
)

export const getErrorCode = createSelector(
    [errorsSelector],
    state => state.errorCode,
)