import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { getNickName } from "../Reduxs/Users/selectors";
import { Decrypto, MoveOnryButton, SignOutButton } from '../functions';
import TTS_ID_Log from '../assets/img/common/logo.svg';
import icon01_account from '../assets/img/common/icon01_account.png';
import { Info } from './notifications/info';
import { modalCloseAction, modalOpenAction } from '../Reduxs/PageAct/actions';
import { signOutAction } from '../Reduxs/Users/actions';

const urlParams = new URLSearchParams(window.location.search);

function useOutsideAlerter(ref, setLoginVisible, refIconUser) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !refIconUser.current.contains(event.target)) {
            setLoginVisible(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
export const LoginHeaderRight = () => {
    const logoutTrigger = urlParams.get('logout');
    const dispatch = useDispatch();
    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const [loginVisible, setLoginVisible] = useState<boolean>(false);
    // Logout from BTHacks
    const SignOut = () => {
        dispatch(signOutAction());
        sessionStorage.clear();
        localStorage.Login = false;
        localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);//確認後不要ならコメントアウトまたは削除
        window.location.href = '/';
    }
    
    useEffect(() => {
        if ( logoutTrigger ) {
            if ( logoutTrigger =='yes') {
                SignOut();
            }
        }
    }, [logoutTrigger]);

    const changedInfoVis = () => {
        if(!infoVisible){
            dispatch(modalOpenAction());
        } else {
            dispatch(modalCloseAction());
        }
        setInfoVisible(!infoVisible);
    }

    // 変更途中でページ遷移しようとした場合の警告用モーダル
    const isModal = (path) => {
        // 変更有無を確認
        let changeContent = false;
        if (
            sessionStorage.getItem('NickName')
            || sessionStorage.getItem('year')
            || sessionStorage.getItem('month')
            || sessionStorage.getItem('day')
            || sessionStorage.getItem('Gender')
            || sessionStorage.getItem('NativeLang')
            || sessionStorage.getItem('dispContinent')
            || sessionStorage.getItem('continentTmpId_CountryId')
            || sessionStorage.getItem('countryTmpId_AreaTmpId')
            // Myタグ
            || sessionStorage.getItem('Btd')
            || sessionStorage.getItem('CheckList')
            || sessionStorage.getItem('myTagEdit')
            // 通知
            || sessionStorage.getItem('ReceiveFlg')
            || sessionStorage.getItem('DestinationFlg')
            || sessionStorage.getItem('TagFlg')
            || sessionStorage.getItem('BReceiveFlg')
            || sessionStorage.getItem('BDestinationFlg')
            || sessionStorage.getItem('BTagFlg')
        ) {
            changeContent = true;
        }

        // 変更があった場合にモーダル表示フラグを立てる
        if (changeContent) {
            sessionStorage.setItem('isModal', '1');
            sessionStorage.setItem('NextPath', path);
            window.location.reload();
        } else {
            dispatch(push(path));
        }
    }

    return (
        <ul className="header_right">
            {/* お知らせモーダル */}
            <div className="info_area" style={{display:infoVisible ? "block" : "none"}}>
                <div className="info_box">
                    <div className="close" onClick={changedInfoVis}></div>
                    <div className="info_text">INFORMATION</div>
                    <Info />
                </div>
            </div>
            <li className="account btn" ><span onClick={() => setLoginVisible(!loginVisible)}></span>
                {/* ログインモーダル */}
                <div className={
                    loginVisible ? "account_nav_vis" : "account_nav"
                }>
                    <LoginModal />
                </div>
            </li>
        </ul>
    )
}
export const InfoModal = () => {
    return (
        <div className="info_box">
            <div className="close" ></div>
            <div className="info_text">INFORMATION</div>
            <dl>
                <dd>2021.04.13</dd>
                <dt><a href="">お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。</a></dt>
                <dd>2021.04.13</dd>
                <dt><a href="">お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。</a></dt>
                <dd>2021.04.13</dd>
                <dt><a href="">お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。</a></dt>
                <dd>2021.04.13</dd>
                <dt><a href="">お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。</a></dt>
                <dd>2021.04.13</dd>
                <dt><a href="">お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。</a></dt>
                <dd>2021.04.13</dd>
                <dt><a href="">お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。</a></dt>
            </dl>
        </div>
    )
}

export const LoginModal = () => {
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    let username = sessionStorage.getItem('unkm');
    username = username ? Decrypto(username) : null;

    // 変更途中でページ遷移しようとした場合の警告用モーダル
    const isModal = (path) => {
        // 変更有無を確認
        let changeContent = false;
        if (
            sessionStorage.getItem('NickName')
            || sessionStorage.getItem('year')
            || sessionStorage.getItem('month')
            || sessionStorage.getItem('day')
            || sessionStorage.getItem('Gender')
            || sessionStorage.getItem('NativeLang')
            || sessionStorage.getItem('dispContinent')
            || sessionStorage.getItem('continentTmpId_CountryId')
            || sessionStorage.getItem('countryTmpId_AreaTmpId')
            // Myタグ
            || sessionStorage.getItem('Btd')
            || sessionStorage.getItem('CheckList')
            || sessionStorage.getItem('myTagEdit')
            // 通知
            || sessionStorage.getItem('ReceiveFlg')
            || sessionStorage.getItem('DestinationFlg')
            || sessionStorage.getItem('TagFlg')
            || sessionStorage.getItem('BReceiveFlg')
            || sessionStorage.getItem('BDestinationFlg')
            || sessionStorage.getItem('BTagFlg')
        ) {
            changeContent = true;
        }

        // 変更があった場合にモーダル表示フラグを立てる
        if (changeContent) {
            if (sessionStorage.getItem('NowPath') !== '/account') {
                sessionStorage.setItem('isModal', '1');
                sessionStorage.setItem('NextPath', path);
                window.location.reload();
            }
        } else {
            dispatch(push(path));
        }
    }
    return (
        <nav>
            <div className="account_name"><a href=""　onClick={() => dispatch(push('/account'))}>
                <div className="icon" ><img src={icon01_account} alt="" /></div>
                <div className="name">{username}</div>
            </a></div>
            <ul>
                <li className="bignav"><Link onClick={() => isModal('/account')}>TTS-ID設定</Link></li>
                <li className="bignav"><SignOutButton /></li>
            </ul>
        </nav>
    )
}

export const NotLoginHeaderRight = () => {
    const dispatch = useDispatch();

    return (
        <ul className="header_right">
            <li className="btn_ptn02">
                <MoveOnryButton buttonName={'新規登録'} path={'/newRegistration'} />
            </li>
            <li className="btn_ptn03">
                <MoveOnryButton buttonName={'ログイン'} path={'/login'} />
            </li>
        </ul>
    )
}

export const HeaderLinkExists = (props) => {
    const dispatch = useDispatch();

    // 変更途中でページ遷移しようとした場合の警告用モーダル
    const isModal = (path) => {
        // 変更有無を確認
        let changeContent = false;
        if (
            sessionStorage.getItem('NickName')
            || sessionStorage.getItem('year')
            || sessionStorage.getItem('month')
            || sessionStorage.getItem('day')
            || sessionStorage.getItem('Gender')
            || sessionStorage.getItem('NativeLang')
            || sessionStorage.getItem('dispContinent')
            || sessionStorage.getItem('continentTmpId_CountryId')
            || sessionStorage.getItem('countryTmpId_AreaTmpId')
            // Myタグ
            || sessionStorage.getItem('Btd')
            || sessionStorage.getItem('CheckList')
            || sessionStorage.getItem('myTagEdit')
            // 通知
            || sessionStorage.getItem('ReceiveFlg')
            || sessionStorage.getItem('DestinationFlg')
            || sessionStorage.getItem('TagFlg')
            || sessionStorage.getItem('BReceiveFlg')
            || sessionStorage.getItem('BDestinationFlg')
            || sessionStorage.getItem('BTagFlg')
        ) {
            changeContent = true;
        }

        // 変更があった場合にモーダル表示フラグを立てる
        if (changeContent) {
            sessionStorage.setItem('isModal', '1');
            sessionStorage.setItem('NextPath', path);
            window.location.reload();
        } else {
            dispatch(push(path));
        }
    }

    return (
        <div className="header_left">
            <h1 className="logo">
                <Link onClick={() => isModal(props.path)}><img src={TTS_ID_Log} alt="ロゴ:TTS-ID" /></Link>
            </h1><span>世界が拡がる、道が拓ける</span>
        </div>
    )
}

export const HeaderLinkNone = (props) => {
    const dispatch = useDispatch();
    return (
        <div className="header_left">
            <div className="logo">
                <Link to={props.path}><img src={TTS_ID_Log} alt="ロゴ:TTS-ID" /></Link>
            </div>
            <span>世界が拡がる、道が拓ける</span>
        </div>
    )
}