//URLリンクなしリスト
export const noLinksData = [
    '/login',
    '/newRegistration',
    '/newRegistration/input',
    '/newRegistration/tempRegistSendMail',
    '/newRegistration/form',
    '/newRegistration/inputConfirmation',
    '/newRegistration/confirmationMail',
    '/top/firstCategorySelection',
    '/SMSverification',
    '/auth/callback',
    '/passwordForget',
    '/forgetNewPassword',
    '/account/security/emailChange/emailVerificationCheck',
    '/account/security/emailChange/mailVerification',
    '/account/security/emailChange/mailEdit',
    '/account/security/emailChange/mailSendMail',
    '/account/security/emailChange/mailComplete',
    '/account/security/passwordChange/passVerification',
    '/account/security/passwordChange/passEdit',
    '/account/security/passwordChange/passComplete',
    '/account/security/mfa/phoneNumber',
    '/account/security/mfa/codeInput',
    '/privacyPolicy',
    '/cookiePolicy',
    '/company',
    '/terms',
    '/faq',
    '/account/service/link',
    '/account/service/unlink',
    '/account/security/mfa/phoneNumber',
    '/account/security/mfa/codeInput',
    '/account/security/mfa/phoneNumberRelease',
    '/account/security/mfa/codeInputRelease',
]