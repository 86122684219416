import { useDispatch, useSelector } from 'react-redux';
import {
  GoogleLoginButton,
  Loading,
  MoveOnryButton,
  TwitterLoginAPI,
  TwitterLoginButton
} from '../../../functions';
import { Footer, Header, NewRegistrationHead } from '../../../htmlParts';
import { getIsLoading } from '../../../Reduxs/Loading/selectors';

const NewRegistration = () => {

  document.title = 'アカウント情報|TTS-ID';

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  sessionStorage.verifyURL = true;

  const twflg = sessionStorage.getItem('twflg');
  if(twflg){
    sessionStorage.removeItem('twflg');
    TwitterLoginAPI(selector, dispatch);
  }
  
  return (
    <div id="wrapper">
      <NewRegistrationHead />
      {getIsLoading(selector) ? <Loading /> : ''}
      <Header />

      <div id="new_account" className="signup_bg">
        <main>
          <div className="contents_in">
            <div className="page_title_ptn01 white">
              <h1> TTS-IDを作成する</h1>
              <p className="first_text">
                TTS-IDを作成すると、トッパントラベルサービスが提供する各サービスを便利にお使いいただけます。<br />
                ソーシャルアカウントを利用してTTS-IDを作成すると、TTS-ID利用時にソーシャルアカウントでログインできます。
              </p>
            </div>

            <div className="cnt_area">

              <div className="form_box_ptn02">
                <div className="login_top_box">
                  <h2>ソーシャルアカウントを利用して作成する</h2>
                  <div className="btn_ptn03 sns google"><GoogleLoginButton word={'作成'}/></div>
                  <div className="and">または</div>
                </div>
                <div className="btn_ptn03">
                  <MoveOnryButton buttonName={'メールアドレスで作成'} path={'/newRegistration/input'}/>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default NewRegistration