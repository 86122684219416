import Recaptcha from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import {handleOkAction} from '../Reduxs/Recaptcha/actions';

//Google reCaptchaコンポーネント

const GoogleRecaptcha = () => {
    const dispatch = useDispatch();
    const handleChange = (value:String) => {
        dispatch(handleOkAction({token: value}));
    }
    return (
        <div className='g-recaptcha'>
            <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleChange}
                />
        </div>
    )
}

export default GoogleRecaptcha