import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Encrypto } from '../../functions';
import { entryFormAction } from '../../Reduxs/Users/actions';
import { getSelectFlg } from '../../Reduxs/Users/selectors';

const DrilDownSample = ({continentOptions,countryOptions,areaOptions, setInputWord, setContinentId, setCountryId}) => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const [tab, setTab] =  useState<string>('continent');
    const [continent, setContinent] = useState<string>('');
    const [hist, setHist] = useState<string>('');
    const [mTitle, setMTitle] = useState<string>('出身国');
    const [dispContinent, setDispContinent] = useState<string>('');
    const [slider, setSlider] = useState<string>('');

    const setValues = (type, value, label) => {

        if (value === 'back') {
            setSlider("slideBack");
            type = value;
        } 
        if (value === '1') {
            setMTitle('出身地域');
        }
        if (type === 'complete') {
            setInputWord(dispContinent + '/' + label);
            setCountryId(value);
            dispatch(entryFormAction({
                tf_continent: continent,
                tf_country: value,
                select_disp_flg: !getSelectFlg(selector),
                set_disp_from: dispContinent + '/' + label
            }));
            sessionStorage.setItem('dispContinent', Encrypto(dispContinent + '/' + label));
            sessionStorage.setItem('countryTmpId_AreaTmpId', Encrypto(value));
        } else if (type === 'country') {
            setContinent(value);
            setDispContinent(label);
            setContinentId(value);
            sessionStorage.setItem('continentTmpId_CountryId', Encrypto(value));
        }
        switchTab(type);
    }
    const switchTab = (type) => {
        if (type === 'back') {
            setTab('continent');
        } else {
            setHist(tab);
            setTab(type);
        }
    }
    const slide = (tab, value, label) => {
        setSlider("slideOut");
        setValues(tab, value, label);
    }

    useEffect(() => {
        if (slider === 'slideOut') {
            setSlider('slideIn');
        } else if (slider === 'slideBack') {
            setSlider('slideReturn');
        }
    }, [slider]);

    switch (tab) {
        case 'continent':
            return (
                <div className="slide-scroll-bar select-area">
                    <div className="hide-box">
                        <div className={slider + ' ' + 'child_box blue_box'}>
                            {Object.keys(continentOptions).map((key) => {
                                return (
                                    <div key={'continent-' + key} className={continentOptions[key].value === "back" ? "select back" : "select item"}>
                                        <button className='button_area'　style={{font: "inherit"}} name={tab} value={continentOptions[key].value} onClick={(e: any) => slide('country', e.target.value, continentOptions[key].label)}>{continentOptions[key].label}</button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        case 'country':
            return (
                <div className="slide-scroll-bar select-area">
                    <div className="hide-box">
                        <div className={slider + ' ' + 'child_box blue_box'}>
                            <div>
                                {Object.keys(countryOptions[continent]).map((key) => {
                                    return (
                                        <div key={'country-' + key} className={countryOptions[continent][key].value === "back" ? "select back" : "select item"}>
                                            <button className='button_area' name={tab} value={countryOptions[continent][key].value} onClick={(e: any) => slide('complete', e.target.value, countryOptions[continent][key].label)}>{countryOptions[continent][key].label}</button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )
            case 'complete':
                return null;
        default :
                return null;
    }
}

export default DrilDownSample