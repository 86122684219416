import {
    emailCheck,
    emailFormatDifferent,
    emailNotEntered,
    passwordCheck,
    passwordErrorWord,
    passwordCountError,
    codeCheck,
    codeErrorWord,
    nicknameErrorWord,
    nicknameNullErrWord,
    birthdayErrWord,
    birthdayFErrWord,
    dateCheck,
    isDate,
    asyncServerTime,
  } from '../functions';
import { Encrypto } from './Crypto';
import { countryPhoneNumberCheck, numCheck, passwordNotEntered, phoneNumberCheckHead, phoneNumberCheckHyphen, phoneNumberCheckNoHyphen, phoneNumberCheckSpace, sCodeErrorWord } from './RegularExpression';

export const changedEmailHaldler = (e:any,setEmail, setEmailError) => {
    setEmail(e.target.value);
    if (!e.target.value) {
      setEmailError(emailNotEntered);
    } else if(!emailCheck.test(e.target.value)){
      setEmailError(emailFormatDifferent);
    } else {
        setEmailError('');
    }
  }

export const changedPasswordHandler = (e:any, setPassword,setPasswordError, login = false) => {
    setPassword(e.target.value);
    if (!e.target.value) {
      setPasswordError(passwordNotEntered);
    } else if (e.target.value.length < 8) {
      setPasswordError(passwordCountError);
    } else if(!passwordCheck.test(e.target.value) && !login){
        setPasswordError(passwordErrorWord);
    } else {
        setPasswordError('');
    }
}

export const changedCodeHandler = (e: any, setCode, setCodeError, mfa = false) => {
  setCode(e.target.value);

  if(!numCheck.test(e.target.value)){
    const y:string = e.target.value;
    e.target.value = y.slice(0, -1);
  }
  
  if (!codeCheck.test(e.target.value)) {
    setCodeError(mfa ? sCodeErrorWord : codeErrorWord);
  } else {
    setCodeError('');
  }
}

export const changedNickName = (e:any, setNickName, setNicknameError) => {
  setNickName(e.target.value);
  sessionStorage.setItem('NickName', Encrypto(e.target.value));
  if (!e.target.value){
    setNicknameError(nicknameNullErrWord);
    return;
  }
  if(e.target.value.length > 50){
    setNicknameError(nicknameErrorWord);
    return;
  }
  setNicknameError('');
}

export const changedBirthday = (type:string, values: any, setDate, setDateErr, setBirthdayErr) => {
  let errWord:string = '';
  switch(type){
    case 'year':
      errWord = '年';
      break;
    case 'month':
      errWord = '月';
      break;
    case 'day':
      errWord = '日';
      break;
    default:
      return 'error';
  }
  if(!dateCheck[type].test(values[type])){
    setDateErr('生年月日の' + errWord + 'を正しく入力してください。');
  } else {
    setDateErr('');
  }
  if(values[type].length === 1){
    values[type] = ('00' + values[type]).slice(-2);
  }
  
  setDate(values[type]);
  sessionStorage.setItem(type, Encrypto(values[type]));
  if(!(isDate(Number(values.year), Number(values.month), Number(values.day))) && (values.year && values.month && values.day)){
    setBirthdayErr(birthdayErrWord);
  } else {
    setBirthdayErr('');
  }

  asyncServerTime()
    .then( (res:any) => {
      const birthday = new Date(values.year + '/' + values.month + '/' + values.day);
      if (res.getTime() < birthday.getTime()){
        setBirthdayErr(birthdayFErrWord);
      }
    })
}

export const changedNativeLang = (e:any, setNativeLang, setNativeLangValue, setNLangErr, setSelectId) => {
  // 母国語のKeyとValueを取得する
  const test = e.target.value.split( ',' );
  const valueId = test[0];
  const valueLang = test[1];

  setNativeLang(valueId);
  setNativeLangValue(valueLang);
  setSelectId(valueId);

  sessionStorage.setItem('NativeLang', Encrypto(e.target.value));

  if(valueId === '第一言語を選択ください。'){
      setNLangErr('第一言語を選択ください。');
  } else {
      setNLangErr('');
  }
}
export const changedContinent = (e: any, setContinent, setCountry, setArea, setCountryFlg, setAreaFlg, setCFromErr, setContinentId) => {
  setContinent(e.value);
  setContinentId(e.id);
  setCountryFlg(true);
  setAreaFlg(true);
  setCountry('');
  setArea('');
  if (!e.value) {
    setCFromErr('出身地を選択してください。');
    setCountryFlg(true);
  } else if(e.label === '日本') {
    setCFromErr('');
    setAreaFlg(false);  
  } else {
    setCFromErr('');
    setCountryFlg(false);
  }
}
export const changedCountry = (e:any, setCountry, setArea, setAreaFlg, setCFromErr,setCountryId) => {
  setCountry(e.value);
  setCountryId(e.id);
  setAreaFlg(true);
  setArea('');
  if(!e.value){
      setCFromErr('出身国を選択してください。');
  }　else {
      setCFromErr('');
      setAreaFlg(false);
  }
  if(e.value !== 'jp'){
      setAreaFlg(true);
  }
}
export const changedArea = (e:any, setArea, setCFromErr,setAreaId) => {
  setArea(e.value);
  setAreaId(e.id);
  if(!e.value){
      setCFromErr('出身地域を選択してください。');
  }　else {
      setCFromErr('');
  }
}

export const changedName = (e: any, setName, setNameError) => {
  setName(e.target.value);
  if (!e.target.value) {
    setNameError('お名前は必須です。');
  } else {
    setNameError('');
  }
}

export const changedCompany = (e: any, setCompany, setCompanyError) => {
  setCompany(e.target.value);
  if (!e.target.value) {
    setCompanyError('');
  } else {
    setCompanyError('');
  }
}

export const changedPhoneNumber = (e: any, setphoneNumber, setPhoneNumberErrMsg) => {
  const replaced = e.target.value.replace(/ /g, '').replace(/-/g, '');
  setphoneNumber(replaced);
  if (!e.target.value) {
    setPhoneNumberErrMsg('携帯電話番号を入力してください。');
    return;
  } else {
    if (phoneNumberCheckHead.test(replaced)) {
      setPhoneNumberErrMsg('先頭の0は無しで入力してください。');
      return;
    }
    if (replaced.length !== 10 ) {
      setPhoneNumberErrMsg('携帯電話番号を正しく入力してください。');
      return;
    }
    if (
      !phoneNumberCheckNoHyphen.test(replaced) ||
      !phoneNumberCheckHyphen.test(replaced) ||
      !phoneNumberCheckSpace.test(replaced) 
    ) {
      setPhoneNumberErrMsg('携帯電話番号を正しく入力してください。');
    }
    setPhoneNumberErrMsg('');
  }
}

export const changedCountryPhoneNumber = (e: any, setPhoneNumber, setPhoneNumberError) => {
  const checkValue = e.target.value;
  setPhoneNumber(checkValue);
  if (!checkValue) {
    setPhoneNumberError('国番号を選択してください。');
  } else {
    if (checkValue.indexOf('+') === -1) {
      setPhoneNumberError('国番号の先頭に+を付けてください。');
      return;
    }
    if (!countryPhoneNumberCheck.test(checkValue)) {
      setPhoneNumberError('国番号の形式で入力してください。');
      return;
    }
    setPhoneNumberError('');
  }
}

export const changedPhoneNumberSelect = (e: any, setPhoneNumberSelect, setPhoneNumberSelectError) => {
  setPhoneNumberSelect(e.target.value);
  if (!e.target.value) {
    setPhoneNumberSelectError('国番号を選択してください。');
  } else {
    setPhoneNumberSelectError('');
  }
}

export const secretQuestionSelect = (e: any, setSelectSecretQuestion, setSelectSecretQuestionError) => {
  setSelectSecretQuestion(e.target.value);
  if (!e.target.value) {
    setSelectSecretQuestionError('秘密の質問を選択してください。');
  } else {
    setSelectSecretQuestionError('');
  }
}

export const changedEmail = (e: any, setEmail, setEmailError) => {
  setEmail(e.target.value);
  if (!e.target.value) {
    setEmailError('メールアドレスを入力してください。');
    return;
  } else {
    if(!emailCheck.test(e.target.value)){
      setEmailError('メールアドレスの形式で入力してください。');
      return;
    }
    setEmailError('');
  }
}

export const changedContactSelect = (e: any, setContactSelect, setContactSelectError) => {
  setContactSelect(e.target.value);
  if (!e.target.value) {
    setContactSelectError('お問い合わせの種類を選択してください。');
  } else {
    setContactSelectError('');
  }
}

export const changedContactText = (e: any, setContactText, setContactTextError, setTextCount) => {
  setContactText(e.target.value);
  setTextCount(e.target.value.length);
  if (!e.target.value) {
    setContactTextError('お問い合わせ内容を入力してください。');
  } else {
    setContactTextError('');
  }
}


export const changedAnswerText = (e:any, setAnswer, setAnswerErr) => {
  setAnswer(e.target.value);
  if(!e.target.value){
    setAnswerErr('秘密の質問の回答をしてください。');
    return;
  } else {
    setAnswerErr('');
  }
}