import CookieConsent from 'react-cookie-consent';
import {Link} from 'react-router-dom';
//GDPR　EU一般データ保護規則の表示コンポーネント

const GDPR = () => {
    const accept = () => {
        window.location.reload();
    }
    return (
        <div id="cookie">
            <CookieConsent
                location='bottom'
                buttonText='同意する'
                declineButtonText='同意しない'
                expires={365}
                overlay
                setDeclineCookie={false}
                onAccept={() => accept()}
                disableStyles={true}
                buttonWrapperClasses="btn_box"
                buttonClasses="btn_submit"
                declineButtonClasses="btn_submit"
                containerClasses = "cnt_wrap" 
                contentClasses = "cnt_text"
                enableDeclineButton
                >
                <p>当ウェブサイトでは、サービスの向上、またお客様により適したサービスを提供するため、クッキーを使用しています。また、お客様に合ったコンテンツや広告を表示させることを目的としてクッキーを使用する場合があります。当ウェブサイトをご利用された場合、クッキーの利用に同意されたものとみなされます。詳細は当社の<Link to='/cookiePolicy' target="_blank" rel="noreferrer">クッキーポリシー</Link>をご参照ください。</p>
            </CookieConsent>
        </div>
    );
}

export default GDPR