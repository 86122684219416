import * as Actions from './actions';
import initialState from '../Store/initialState';

export const LoadingReducer = (state = initialState.loading, action) => {
    switch(action.type){
        case Actions.SET_LOADING:
            return {
                ...state,
                ...action.payload,
            };
        case Actions.CLEAR_LOADING:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}