export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginErrorsAction = (errorsState) =>{
    return {
        type: 'LOGIN_ERROR',
        payload: {
            loginName: errorsState.loginName,
            loginPassword: errorsState.loginPassword
        }
    }
}

export const LOGIN_ERROR_RESET = 'LOGIN_ERROR_RESET';
export const loginErrorResetAction = () => {
    return {
        type:'LOGIN_ERROR_RESET',
        payload: {
            loginName: '',
            loginPassword: ''
        }
    }
}

export const SET_ERROR = 'SET_ERROR';
export const setErrorAction = (errorsState) => {
    return {
        type: 'SET_ERROR',
        payload:{
            errorCode: errorsState.errorCode,
        }
    }
}