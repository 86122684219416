import { create } from 'node:domain';
import {createSelector} from 'reselect';

const usersSelector = (state) => state.users;

export const getLoginStatus = createSelector(
    [usersSelector],
    state => state.isSignedIn
)
export const getTTSID = createSelector(
    [usersSelector],
    state => state.tts_id
)
export const getUserIdToken = createSelector(
    [usersSelector],
    state => state.idtoken
)
export const getAccessToken = createSelector(
    [usersSelector],
    state => state.access_token
)
export const getMail = createSelector(
    [usersSelector],
    state => state.tf_mail
)

export const getGoogleId = createSelector(
    [usersSelector],
    state => state.google_id
)

export const getTwitterId = createSelector(
    [usersSelector],
    state => state.twitter_id
)

export const getPhoneNumber = createSelector(
    [usersSelector],
    state => state.phone_number
)
export const getPassword = createSelector(
    [usersSelector],
    state => state.password
)
export const getNewPassword = createSelector(
    [usersSelector],
    state => state.new_password
)
export const getNickName = createSelector(
    [usersSelector],
    state => state.tf_nickname
)
export const getBirthday = createSelector(
    [usersSelector],
    state => state.tf_birthday
)
export const getYear = createSelector(
    [usersSelector],
    state => state.tf_year
)
export const getMonth = createSelector(
    [usersSelector],
    state => state.tf_month
)
export const getDay = createSelector(
    [usersSelector],
    state => state.tf_day
)
export const getGender = createSelector(
    [usersSelector],
    state => state.tf_gender
)
export const getNativeLang = createSelector(
    [usersSelector],
    state => state.tf_native_lang
)
export const getNativeLangId = createSelector(
    [usersSelector],
    state => state.tf_native_lang_id
)
export const getNativeLangValue = createSelector(
    [usersSelector],
    state => state.tf_native_lang_value
)
export const getComeFrom = createSelector(
    [usersSelector],
    state => state.tf_come_from
)
export const getContinent = createSelector(
    [usersSelector],
    state => state.tf_continent
)
export const getContinentId = createSelector(
    [usersSelector],
    state => state.tf_continent_id
)
export const getCountry = createSelector(
    [usersSelector],
    state => state.tf_country
)
export const getCountryId = createSelector(
    [usersSelector],
    state => state.tf_country_id
)
export const getCountryFlag = createSelector(
    [usersSelector],
    state => state.tf_country_flag
)
export const getArea = createSelector(
    [usersSelector],
    state => state.tf_area
)
export const getAreaId = createSelector(
    [usersSelector],
    state => state.tf_area_id
)
export const getAreaFlag = createSelector(
    [usersSelector],
    state => state.tf_area_flag
)
export const getUserAll = createSelector(
    [usersSelector],
    state => state
)
export const getTwitterFlg = createSelector(
    [usersSelector],
    state => state.twitter_flg
)
export const getGoogleFlg = createSelector(
    [usersSelector],
    state => state.google_flg
)

export const getSelectFlg = createSelector(
    [usersSelector],
    state => state.select_disp_flg
)

export const getSetDispFrom = createSelector(
    [usersSelector],
    state => state.set_disp_from
)

export const getModalStatus = createSelector(
    [usersSelector],
    state => state.isModalOpen
)

export const getUpDateStatus = createSelector(
    [usersSelector],
    state => state.isUpDate
)