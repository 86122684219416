import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { asyncDevAPI } from "../functions";
import { setErrorAction } from "../Reduxs/Errors/actions";

export const fromOptions = () => {
    const params = {
        apiname: 'master_code_get',
        atb: 0,
    }
    return new Promise ((resolve, reject) => {
        const dispatch = useDispatch();
        asyncDevAPI(params)
            .then((res:any) => {
                if (res.body.status === 200) {
                    const from = res.body.response
                    let continent: object = new Array();
                    let country: object = new Array();
                    let area: object[] = [];

                    area.push({ value: 'back', label: '戻る' });

                    Object.keys(from).forEach(key => {
                        switch (from[key].reference) {
                            case 0:
                                continent[Number(key) + 1] = { value: from[key].mid, label: from[key].svalue };
                                country[Number(key) + 1] = [];
                                break;
                            case 1:
                                if (country[1].length < 1) {
                                    country[1].push({ value: 'back', label: '戻る' });
                                }
                                country[1].push({ id: from[key].mid, value: from[key].mid, label: from[key].svalue });
                                area.push({ value: from[key].mid, label: from[key].svalue });
                                break;
                            case 2:
                                if (country[2].length < 1) {
                                    country[2].push({ value: 'back', label: '戻る' });
                                }
                                country[2].push({ value: from[key].mid, label: from[key].svalue });
                                break;
                            case 3:
                                if (country[3].length < 1) {
                                    country[3].push({ value: 'back', label: '戻る' });
                                }
                                country[3].push({ value: from[key].mid, label: from[key].svalue });
                                break;
                            case 4:
                                if (country[4].length < 1) {
                                    country[4].push({ value: 'back', label: '戻る' });
                                }
                                country[4].push({ value: from[key].mid, label: from[key].svalue });
                                break;
                            case 5:
                                if (country[5].length < 1) {
                                    country[5].push({ value: 'back', label: '戻る' });
                                }
                                country[5].push({ value: from[key].mid, label: from[key].svalue });
                                break;
                            default:
                                break;
                        }
                    });
                    return resolve({
                        continentOptions: continent,
                        countryOptions: country,
                        areaOptions: area,
                    });
                } else {
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
            })
            .catch(err => {
                return reject(err);
        });
    });
}

export const langOptions = () => {
    const params = {
        apiname: 'master_code_get',
        atb: 6,
    }
    return new Promise((resolve,reject) => {
        const dispatch = useDispatch();
        asyncDevAPI(params)
            .then((res:any) => {
                if (res.body.status === 200) {
                    const lang = res.body.response;
                    const langOptions:object[] = [];
                    Object.keys(lang).forEach(key => {
                        if(langOptions.length < 1){
                            langOptions.push({id:0, value:'第一言語を選択ください。' ,label:'第一言語を選択ください。'});
                        }
                        langOptions.push({id:lang[key].mid,value:lang[key].mid,label:lang[key].svalue});
                    });
                    return resolve(langOptions);
                } else {
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
            })
            .catch( err => {
                return reject(err);
        });
    });
}

export const ContactsType = [
    {value: null, label: 'お問い合わせの種類を選択'},
    {value: '01', label: 'TTS-IDのサービス内容などについて'},
    {value: '02', label: 'TTS-IDアカウントの登録・設定などについて'},
    {value: '03', label: 'その他のお問い合わせ'},
];