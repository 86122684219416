// ブラウザを特定する
export const CheckBrowser = () => {
    var userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('msie') !== -1 ||
        userAgent.indexOf('trident') !== -1) {
        return 'IE';
    } else if (userAgent.indexOf('edg') !== -1) {
        return 'Edge';
    } else if (userAgent.indexOf('chrome') !== -1) {
        return 'Chrome';
    } else if (userAgent.indexOf('safari') !== -1) {
        return 'Safari';
    } else if (userAgent.indexOf('firefox') !== -1) {
        return 'FireFox';
    } else if (userAgent.indexOf('opera') !== -1) {
        return 'Opera';
    } else {
    }
}

// デバイスを特定する
export const CheckDevice = () => {
    var useDevice = (function (userAgent) {
        var mobile = {
            0: (userAgent.indexOf("windows") !== -1 && userAgent.indexOf("phone") !== -1)
                || userAgent.indexOf("iphone") !== -1
                || userAgent.indexOf("ipod") !== -1
                || (userAgent.indexOf("android") !== -1 && userAgent.indexOf("mobile") !== -1)
                || (userAgent.indexOf("firefox") !== -1 && userAgent.indexOf("mobile") !== -1)
                || userAgent.indexOf("blackberry") !== -1,
            iPhone: (userAgent.indexOf("iphone") !== -1),
            Android: (userAgent.indexOf("android") !== -1 && userAgent.indexOf("mobile") !== -1)
        };
        var tablet = {
            0: (userAgent.indexOf("windows") !== -1 && userAgent.indexOf("touch") !== -1)
                || userAgent.indexOf("ipad") !== -1
                || (userAgent.indexOf("android") !== -1 && userAgent.indexOf("mobile") === -1)
                || (userAgent.indexOf("firefox") !== -1 && userAgent.indexOf("tablet") !== -1)
                || userAgent.indexOf("kindle") !== -1
                || userAgent.indexOf("silk") !== -1
                || userAgent.indexOf("playbook") !== -1,
            iPad: (userAgent.indexOf("ipad") !== -1),
            androidTab: (userAgent.indexOf("android") !== -1 && userAgent.indexOf("mobile") === -1)
        };
        var pc = !mobile[0] && !tablet[0];
        return {
            Mobile: mobile,
            Tablet: tablet,
            PC: pc
        };
    })(window.navigator.userAgent.toLowerCase());

    return useDevice;
}

// ブラウザとデバイス情報をまとめて返却する
export const CheckDeviceBrowser = () => {
    const useBrowser = CheckBrowser();
    let useDevice = '';
    
    const device = CheckDevice();
    // 使用デバイスの確認
    if (device.Mobile[0]) {
        if(device.Mobile['iPhone']){
            useDevice = 'iPhone';
        }
        if(device.Mobile['Android']){
            useDevice = 'Android';
        }
    }
    if(device.PC){
        useDevice = 'PC';
    }
    if(device.Tablet[0]){
        if(device.Mobile['iPad']){
            useDevice = 'iPad';
        }
        if(device.Mobile['androidTab']){
            useDevice = 'androidTab';
        }
    }
    const useDeviceAndBrowser = useDevice + '（' + useBrowser + '）';
    return useDeviceAndBrowser;
}