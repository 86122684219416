import {createSelector} from 'reselect';

const pageActSelector = (state) => state.pageAct;

export const getIsModalOpen = createSelector(
    [pageActSelector],
    state => state.isModalOpen
)

export const getIsHanbOpen = createSelector(
    [pageActSelector],
    state => state.isHanbOpen
)

export const getAPIAction = createSelector(
    [pageActSelector],
    state => state.isApiAction
)