import * as Actions from './actions';
import initialState from '../Store/initialState';

export const PageActReducer = (state = initialState.twitterToken, action) => {
    switch(action.type){
        case Actions.MODAL_OPEN_ACTION:
            return {
                ...state,
                ...action.payload
            }
        case Actions.MODAL_CLOSE_ACTION:
            return {
                ...state,
                ...action.payload
            }
        case Actions.HANBERGER_OPEN_ACTION:
            return {
                ...state,
                ...action.payload
            }
        case Actions.HANBEREGER_CLOSE_ACTION:
            return {
                ...state,
                ...action.payload
            }
        case Actions.API_START_ACTION:
            return {
                ...state,
                ...action.payload
            }
        case Actions.API_FIN_ACTION:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

