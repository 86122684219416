import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import { getLoginStatus } from "../../../Reduxs/Users/selectors";
import { Link } from 'react-router-dom';
import { AccountServiceHead, Footer, Header } from "../../../htmlParts";
import { SideBar } from "..";
import illust01_user from '../../../assets/img/setting/user/illust01_user.png';
import { useState } from "react";
import { hanbergerOpenAction } from "../../../Reduxs/PageAct/actions";
import { Decrypto } from "../../../functions";

//アカウント、サービス連携ページ

const Service = () => {

    document.title = 'アカウント情報|TTS-ID';

    const dispatch = useDispatch();

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    const [hanVis, setHanVis] = useState<boolean>(false);
    const changedHanberger = () => {
        dispatch(hanbergerOpenAction());
        setHanVis(!hanVis);
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <AccountServiceHead />
                <Header />
                <div id="status" className="setting_page">
                    <main>
                        <div className="page_contents">
                            <h1 className="sp">アカウント設定</h1>
                            <div className={hanVis ? "btn_open sp act" : "btn_open sp"} onClick={changedHanberger}><span></span><span></span><span></span></div>
                            <SideBar />
                            <div className="main_contents">
                                <div className="cnt_img"><img src={illust01_user} alt="人のイラスト" /></div>
                                <div className="contents_in">
                                    <h2 className="h2_ptn01">連携サービス</h2>
                                    <div className="lead">以下の各サービスのアカウントをお持ちですか？<br />
                                        各サービスアカウントとTTS-IDを連携することで、TTS-IDでログインできます。
                                    </div>
                                    <div className="cnt_g">
                                        <h3>個人向けサービス</h3>
                                        <table>
                                            <tbody>
                                                <tr className="item">
                                                    <th scope="col">サービス名</th>
                                                    <th scope="col">利用状況</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                <tr className="service">
                                                    <th scope="row"><a href="#" target="_blank">BTHacks</a></th>
                                                    <td>連携中</td>
                                                    <td>
                                                        <div className="btn_submit"><input type="submit" value="連携解除" pb-role="submit" /></div>
                                                    </td>
                                                </tr>
                                                <tr className="service">
                                                    <th scope="row"><a href="#" target="_blank">TTSコミュニティ</a></th>
                                                    <td>未使用</td>
                                                    <td>
                                                        <div className="btn_submit"><input type="submit" value="連携する" pb-role="submit" /></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="cnt_g">
                                        <h3>法人向けサービス</h3>
                                        <table>
                                            <tbody>
                                                <tr className="item">
                                                    <th scope="col">サービス名</th>
                                                    <th scope="col">利用状況</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                <tr className="service">
                                                    <th scope="row"><a href="#" target="_blank">BTMポータル</a></th>
                                                    <td>連携中</td>
                                                    <td>
                                                        <div className="btn_submit"><input type="submit" value="連携解除" pb-role="submit" /></div>
                                                    </td>
                                                </tr>
                                                <tr className="service">
                                                    <th scope="row"><a href="#" target="_blank">海外人事ポータル</a></th>
                                                    <td>未使用</td>
                                                    <td>
                                                        <div className="btn_submit"><input type="submit" value="連携する" pb-role="submit" /></div>
                                                    </td>
                                                </tr>
                                                <tr className="service">
                                                    <th scope="row"><a href="#" target="_blank">MICEポータル</a></th>
                                                    <td>未使用</td>
                                                    <td>
                                                        <div className="btn_submit"><input type="submit" value="連携する" pb-role="submit" /></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="cnt_g">
                                        <h3>他社運営サービス</h3>
                                        <table>
                                            <tbody>
                                                <tr className="item">
                                                    <th scope="col">サービス名</th>
                                                    <th scope="col">利用状況</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                <tr className="service">
                                                    <th scope="row"><a href="#" target="_blank">Slack</a></th>
                                                    <td>連携中</td>
                                                    <td>
                                                        <div className="btn_submit"><input type="submit" value="連携解除" pb-role="submit" /></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="link"><a href="#" target="_blank">他のサービスを探す</a></div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Service