import * as Actions from './actions';
import initialState from '../Store/initialState';

export const ContactReducer = (state = initialState.contact, action) => {
    switch(action.type){
        case Actions.CONFIRM:
            return {
                ...state,
                ...action.payload
            }
        case Actions.CONFIRM_FIN:
        return {
            ...state,
            ...action.payload
        }
        default:
            return state;
    }
}