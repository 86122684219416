import { push } from 'connected-react-router';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { confirmAction } from '../../../Reduxs/Contacts/actions';
import { ContactHead, Footer, Header, SPConfirm } from '../../../htmlParts';
import { Decrypto, GoogleRecaptcha } from '../../../functions';
import { handleClearAction } from '../../../Reduxs/Recaptcha/actions';
import { getToken } from '../../../Reduxs/Recaptcha/selectors';
import {
    getContactSelect,
    getContactText,
    getTextCount
} from '../../../Reduxs/Contacts/selectors';
import {
    changedName,
    changedEmail,
    changedContactSelect,
    changedContactText,
} from '../../../functions';

//ログイン後お問い合わせページ
//共通化していきたい

const UserContact = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    const token = getToken(selector);

    const ucpj:string = String(sessionStorage.getItem('ucpj'))
    const c_user = JSON.parse(Decrypto(ucpj));

    const urpj:string = String(sessionStorage.getItem('urpj'));
    const r_user = JSON.parse(Decrypto(urpj));

    const [name, setName] = useState<string>(r_user.username);
    const [email, setEmail] = useState<string>(c_user.email);
    const [contactSelect, setContactSelect] = useState<string>(getContactSelect(selector));
    const [contactText, setContactText] = useState<string>(getContactText(selector));
    const [textCount, setTextCount] = useState<string>(getTextCount(selector));

    const [service, setService] = useState<boolean>(false);
    const [privacy, setPrivacy] = useState<boolean>(false);

    const [serviceError, setServiceError] = useState<string>('');
    const [privacyError, setPrivacyError] = useState<string>('');

    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [contactSelectError, setContactSelectError] = useState<string>('');
    const [contactTextError, setContactTextError] = useState<string>('');

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <ContactHead />
                <Header />
                <div id="contact" className="public_page contact_page">
                    <main>
                        <div className="page_contents">
                            <div className="contents_in">
                                <h1>お問い合わせ</h1>
                                <p>下記フォームよりお問い合わせ内容を<br className="sp" />記入のうえ、お問い合わせください。</p>
                                <div className="text_box_ptn01">
                                    <dl className="form_g">
                                        <dt className="form_text">お名前<span className="required">必須</span></dt>
                                        <dd>
                                            <input
                                                type="text"
                                                placeholder="お名前を入力"
                                                defaultValue={name}
                                                readOnly
                                                onChange={(event) => changedName(event, setName, setNameError)}
                                            />
                                            <div className="form_error_text">{nameError}</div>
                                        </dd>
                                        <dt className="form_text">メールアドレス<span className="required">必須</span></dt>
                                        <dd className="email_text">
                                            <input
                                                type="email"
                                                placeholder="メールアドレスを入力"
                                                defaultValue={email}
                                                readOnly
                                                onChange={(event) => changedEmail(event, setEmail, setEmailError)}
                                            />
                                            <div className="form_error_text">{emailError}</div>
                                        </dd>
                                        <dt className="form_text">お問い合わせの種類<span className="required">必須</span></dt>
                                        <dd>
                                            <select
                                                name="inquiry_type"
                                                id=""
                                                className="select"
                                                defaultValue={contactSelect}
                                                onChange={(event) => changedContactSelect(event, setContactSelect, setContactSelectError)}
                                            >
                                                <option value="">お問い合わせの種類を選択</option>
                                                <option value="TTS-IDのサービス内容などについて">TTS-IDのサービス内容などについて</option>
                                                <option value="TTS-IDアカウントの登録・設定などについて">TTS-IDアカウントの登録・設定などについて</option>
                                                <option value="その他のお問い合わせ">その他のお問い合わせ</option>
                                            </select>
                                            <div className="form_error_text">{contactSelectError}</div>
                                        </dd>
                                        <dt className="form_text">お問い合わせ内容<span className="required">必須</span></dt>
                                        <dd>
                                            <textarea
                                                name="inquiry_details"
                                                maxLength={1000}
                                                defaultValue={contactText}
                                                onChange={(event) => changedContactText(event, setContactText, setContactTextError, setTextCount)}
                                            ></textarea>
                                            <div>{textCount} / 1000文字まで</div>
                                            <div className="form_error_text">{contactTextError}</div>
                                        </dd>
                                    </dl>
                                    <SPConfirm serviceConfirm={service} setServiseConfirm={setService} privacyConfirm={privacy} setPrivacyConfirm={setPrivacy} serviceError={serviceError} setServiceError={setServiceError} privacyError={privacyError} setPrivacyError={setPrivacyError} parts={'privacy'} />
                                    <div className='recaptcha'>
                                        <GoogleRecaptcha />
                                    </div>
                                    <div className="btn_submit blue">
                                        <button
                                            onClick={() => {
                                                dispatch(confirmAction({
                                                    name: name,
                                                    email: email,
                                                    contactSelect: contactSelect,
                                                    contactText: contactText,
                                                    textCount: textCount,
                                                }));
                                                // リキャプチャトークンの初期化
                                                dispatch(handleClearAction());
                                                dispatch(push('/account/contact/confirm'));
                                            }}
                                            disabled={
                                                !!nameError || !!emailError || !!contactTextError || !!contactSelectError ||
                                                !token || !name || !contactSelect || !contactText || !privacy
                                            }
                                        >確認画面へ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default UserContact