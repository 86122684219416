import { push } from 'connected-react-router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import { hanbergerCloseAction } from '../../Reduxs/PageAct/actions';
import { getIsHanbOpen } from '../../Reduxs/PageAct/selectors';

const SideBar = () => {

    let topActive = false;
    let userActive = false;
    let notificationActive = false;
    let securityActive = false;
    let serviceActive = false;

    switch(useLocation().pathname){
        case '/account':
            topActive = true;
            break;
        case '/account/user':
            userActive = true;
            break;
        case '/account/notification':
            notificationActive = true;
            break;
        case '/account/security':
            securityActive = true;
            break;
        case '/account/service':
            serviceActive = true;
            break;
        default:
            break;
    }
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const [hanVis, setHanVis] = useState<boolean>();
    const changedHanberger = () => {
        dispatch(hanbergerCloseAction());
        setHanVis(!hanVis);
    }

    // 変更途中警告用モーダル
    const isModal = (path) => {
        // 変更有無を確認
        let changeContent = false;
        if (
            sessionStorage.getItem('NickName')
            || sessionStorage.getItem('year')
            || sessionStorage.getItem('month')
            || sessionStorage.getItem('day')
            || sessionStorage.getItem('Gender')
            || sessionStorage.getItem('NativeLang')
            || sessionStorage.getItem('dispContinent')
            || sessionStorage.getItem('continentTmpId_CountryId')
            || sessionStorage.getItem('countryTmpId_AreaTmpId')
            // Myタグ
            || sessionStorage.getItem('Btd')
            || sessionStorage.getItem('CheckList')
            || sessionStorage.getItem('myTagEdit')
            // 通知
            || sessionStorage.getItem('ReceiveFlg')
            || sessionStorage.getItem('DestinationFlg')
            || sessionStorage.getItem('TagFlg')
            || sessionStorage.getItem('BReceiveFlg')
            || sessionStorage.getItem('BDestinationFlg')
            || sessionStorage.getItem('BTagFlg')
        ) {
            changeContent = true;
        }

        // 変更があった場合にモーダル表示フラグを立てる
        if (changeContent) {
            sessionStorage.setItem('isModal', '1');
            sessionStorage.setItem('NextPath', path);
            window.location.reload();
            // dispatch(setEditingAction({ isEditing: true}));
        } else {
            dispatch(push(path));
        }
    }
    return (
        <div className={getIsHanbOpen(selector) ? "side_nav show" :"side_nav"}>
            <h1>TTS-ID設定</h1>
            <ul className="nav">
                <li className={topActive ? "active" : ''}><Link onClick={() => isModal('/account')}>プロフィール</Link></li>
                <li className={userActive ? "active" : ''}><Link onClick={() => isModal('/account/user')}>Myタグ</Link></li>
                <li className={securityActive ? "active" : ''}><Link onClick={() => isModal('/account/security')}>セキュリティ</Link></li>
                {/*<li className={serviceActive ? "active" : ''}><Link to="/account/service">連携サービス</Link></li>*/}
            </ul>
            <div className="btn_top"><Link onClick={() => isModal('/top')}>トップに戻る</Link></div>
            <div className="btn_close sp" onClick={changedHanberger}></div>
        </div>
    )
}
export default SideBar