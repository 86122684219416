import { ContactCompleteHead, Footer, Header } from "../../htmlParts"
import illust01_contact from '../../assets/img/contact/illust01_contact.png';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmFinAction } from "../../Reduxs/Contacts/actions";
import { getContactEmail, getContactName, getContactSelect, getContactText } from "../../Reduxs/Contacts/selectors";
import { push } from "connected-react-router";

const Complete = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    // お問い合わせ情報取得
    const storeName = getContactName(selector);
    const storeMail = getContactEmail(selector);
    const storeSelect = getContactSelect(selector);
    const storeText = getContactText(selector);
    const sessionUser = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);

    let URL: string = '';
    if (sessionUser) {
        URL = '/top';
    } else {
        URL = '/';
    }


    if (!storeName || !storeMail || !storeSelect || !storeText) {
        dispatch(push('/'));
    }

    useEffect(() => {
        return() => {
            // Storeのお問い合わせ情報をクリア
            dispatch(confirmFinAction());
        }
    })
    return (
        <div id="wrapper">
            <ContactCompleteHead />
            <Header />
            <div id="contact_done" className="public_page contact_page">
                <main>
                    <div className="page_contents">
                        <div className="contents_in">
                            <h1>お問い合わせを<br className="sp" />受け付けました。</h1>
                            <p className="lead">お問い合わせいただき<br className="sp" />ありがとうございました。<br />お問い合わせを受け付けました。<br />担当者より折り返しご連絡いたしますので、<br className="sp" />恐れ入りますがお待ち下さい。</p>
                            <p>なお、ご入力いただいたメールアドレス宛に<br className="sp" />受付完了メールを配信しております。<br />受付完了メールが届かない場合は入力したメールアドレスが誤っている可能性などがございます。<br />その場合は大変お手数ですが、再度お問い合わせいただきますようお願いいたします。</p>
                            <div className="btn_ptn02">
                                <Link onClick={() => dispatch(push(URL))}>TTS-ID トップに戻る</Link>
                            </div>
                        </div>
                        <div className="cnt_img"><img src={illust01_contact} alt="人のイラスト" /></div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default Complete