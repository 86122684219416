//利用規約、個人情報などのリンク用コンポーネント

import { useState } from "react";

const PSConfirm = ({ serviceConfirm, setServiseConfirm, privacyConfirm, setPrivacyConfirm, serviceError, setServiceError, privacyError, setPrivacyError, parts = '' }) => {

    const [checkServiceError, setCheckServiceError] = useState<string>('firstClick');
    const [checkPrivacyError, setCheckPrivacyError] = useState<string>('firstClick');

    const changedService = () => {
        setServiseConfirm(!serviceConfirm);
        if (!serviceConfirm) {
            setServiceError('');
            setCheckServiceError('');
        } else {
            setServiceError('利用規約に同意してください。');
            setCheckServiceError('check error');
        }
    }
    const changedPrivacy = () => {
        setPrivacyConfirm(!privacyConfirm)
        if (!privacyConfirm) {
            setPrivacyError('');
            setCheckPrivacyError('');
        } else {
            setPrivacyError('個人情報の取り扱いに同意してください。');
            setCheckPrivacyError('check error');
        }
    }

    const serviceMsg = () => {
        if(serviceError){
            return (
                <div className="form_error_text">
                    {serviceError}
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const privacyMsg = () => {
        if(privacyError){
            return (
                <div className="form_error_text">
                    {privacyError}
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }
    if (parts === 'privacy') {
        return (
            <div className="privacy_box">
                <div className="checkbox">
                    <input type='checkbox' id="check_privacy" name='privacy' className={checkPrivacyError} readOnly onClick={changedPrivacy} />
                    <label htmlFor="check_privacy"><a href='/privacyPolicy' target="_brank" rel="noreferrer">個人情報の取り扱い</a>に同意する</label>
                    {privacyMsg()}
                </div>
            </div>
        )
    } else {
        return (
            <div className="privacy_box">
                <div className="checkbox">
                    <input type='checkbox' id="check_term" name='service' className={checkServiceError} onClick={changedService} />
                    <label htmlFor="check_term"><a style={{ color: 'blue' }} href='/terms' target="_blank" rel="noreferrer">利用規約</a>に同意する</label>
                    {serviceMsg()}
                </div>
                <div className="checkbox">
                    <input type='checkbox' id="check_privacy" name='privacy' className={checkPrivacyError} readOnly onClick={changedPrivacy} />
                    <label htmlFor="check_privacy"><a style={{ color: 'blue' }} href='/privacyPolicy' target="_brank" rel="noreferrer">個人情報の取り扱い</a>に同意する</label>
                    {privacyMsg()}
                </div>
            </div>
        )
    }
}

export default PSConfirm