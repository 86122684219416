const termsContent = [
  {
    title: "第1条（利用規約の適用範囲および変更）",
    content: (
      <div>
        TTS-ID利用規約（以下「本利用規約」といいます）は、株式会社トッパントラベルサービス（以下「当社」といいます）が運営するインターネット・サービス（以下「本サービス」といいます）をご利用いただく際の、当社と利用者との間の一切の関係に適用します。<br />
        会員とは、所定の方法により会員登録をした者をいい、利用者とは、会員を含む当社が提供する本サービスを利用する全ての者とします。利用者は、本利用規約を遵守のうえ、本サービスを利用するものとします。<br />
        当社は、利用者に事前告知をすることなく、本利用規約を変更することができます。本利用規約が変更された場合、変更後の規約が適用されるものとし、利用者は変更後の規約に同意したものとみなされます。
      </div>
    ),
  },
  {
    title: "第2条（本サービスの概要と範囲）",
    content: (
      <div>
        本サービスは、会員間における質問と回答の形式による情報交換、利用者における情報収集および閲覧の機会、ならびにそれらの環境を提供することを目的とします。<br />
        当社は利用者に事前告知することなく、本サービスに新しいサービスを追加、または変更することができるものとします。<br />
        本サービスは、複数の他のウェブサイトのサービスと連携しており、本サイト上にて投稿された内容は、一定条件の下、他のウェブサイトにも公開されます。
      </div>
    ),
  },
  {
    title: "第3条（会員に関する情報の開示と利用）",
    content: (
      <div>
        当社は原則として、会員が会員登録および本サービスの利用において当社に対して開示した個人情報（以下「会員情報」といいます）のうち、本サービス上で開示される会員情報以外の会員情報について、事前の同意なく当社が第三者に対しこれらの会員情報を開示することはありません。ただし、公的機関からの照会および当社が法令によって開示義務を負う場合などはその限りではありません。<br />
        会員は、会員登録の際に当社に開示した情報に変更が生じた場合には、速やかに変更登録を行うものとします。<br />
        当社は、会員情報を当社が有益だと判断する情報（当社、広告主および提携先の商品、サービスなどの情報を含む）を提供する目的で利用することができるものとします。<br />
        当社は、本サービスの向上および当社のマーケティングなどの目的で会員情報を集計および分析などするものとします。<br />
        会員情報は、当社の「TTS-ID及びBTHacksに関するプライバシーポリシー」に従い、当社が管理します。<br />
        会員は、本条に定めるとおりに当社が会員情報を扱い、保有することに同意し、異議を申し立てないこととします。
      </div>
    ),
  },
  {
    title: "第4条（パスワードの管理）",
    content: (
      <div>
        会員は、会員本人の責任の下でパスワードを管理するものとし、パスワードの盗用、不正利用その他の事情により当該会員以外の者が本サービスを利用し会員に損害が生じた場合でも、入力された会員ＩＤおよびパスワードが登録されたものと一致することを所定の方法により確認したとき、当該会員による本サービスの利用があったものとみなし、当社は一切の責任を負わないものとします。
      </div>
    ),
  },
  {
    title: "第5条（利用料金および支払方法）",
    content: (
      <div>
        会員は、本サービスの有料部分の対価として、当社が別途定め、本サービス上に表示する利用料金を、当社が指定する方法により支払うものとします。<br />
        会員が利用料金の支払を遅滞した場合には、会員は年14．6％の割合による遅延損害金を支払うものとします。
      </div>
    ),
  },
  {
    title: "第6条（退会）",
    content: (
      <div>
        当社は、会員が以下の事由に該当すると判断した場合には、事前に通知することなく、かつ、会員の承諾を得ることなく、当該会員による本サービスの利用停止、当該会員のパスワードなどの変更、または当該会員の会員資格の取消しを行うことができるものとし、当該理由を開示する義務を負わないものとします。<br />
        <ol className="list-group-reset">
          <li className="list-item-parenthesis">本利用規約に違反した場合</li>
          <li className="list-item-parenthesis">会員登録の際の記入事項に虚偽があることが判明した場合</li>
          <li className="list-item-parenthesis">パスワードを不正に使用した場合</li>
          <li className="list-item-parenthesis">本サービスによって提供された情報を不正に使用した場合</li>
          <li className="list-item-parenthesis">当該会員が利用者、第三者および当社に損害を与える危険があると判断した場合</li>
          <li className="list-item-parenthesis">第7条に定める禁止行為を行った場合</li>
          <li className="list-item-parenthesis">その他、当社が本サービスの利用について不適当と判断した場合</li>
        </ol>
        会員が前項各号に該当するため、当社が前項に定める措置をとった場合において、当該会員に損害が発生したとしても、当社は一切責任を負わないものとします。
      </div>
    ),
  },
  {
    title: "第7条（免責事項）",
    content: (
      <div>
        当社は、本サービスによって提供する情報の正確性、完全性などを保証するものではありません。当該情報に起因して利用者および第三者に損害が発生したとしても、当社は一切責任を負わないものとします。<br />
        本サービス上に投稿された回答の履歴は本サービス上で公開されます。回答履歴の公開に伴い損害が発生したとしても、当社は一切責任を負わないものとします。<br />
        当社は、利用者に発生した使用機会の逸失、データの滅失、業務の中断、またはあらゆる種類の損害（間接損害、特別損害、付随損害、派生損害、逸失利益を含む）に対して、たとえ当社がかかる損害の可能性を事前に通知されていたとしても、当社は一切責任を負わないものとします。<br />
        当社は、利用者が本サービスの利用によって、他の利用者および第三者に対して損害を与え、生じた侵害および紛争に対し、また、利用者自身に損害が生じ、発生した侵害および紛争に対して、一切責任を負わないものとします。<br />
        本サービスを受けるためのウェブサイトへの接続は、利用者が自己の費用で行うものとし、当社は一切の費用および責任を負わないものとします。<br />
        会員登録の際に当社に開示した情報の変更登録がなされなかったことにより生じた損害について、当社は一切責任を負わないものとします。
      </div>
    ),
  },
  {
    title: "第8条（禁止事項）",
    content: (
      <div>
        当社は、利用者が本サービスを利用するにあたり、以下の事由に該当する行為を禁止します。<br />
        <ol className="list-group-reset">
          <li className="list-item-parenthesis">公序良俗に反する行為</li>
          <li className="list-item-parenthesis">犯罪的行為を助長、またはその実行を暗示する行為</li>
          <li className="list-item-parenthesis">利用者、第三者および当社の知的財産権、肖像権、パブリシティ権などの正当な権利を侵害する、または侵害のおそれがある行為</li>
          <li className="list-item-parenthesis">利用者または第三者の財産、信用、名誉またはプライバシーを侵害する、または侵害のおそれがある行為</li>
          <li className="list-item-parenthesis">他の利用者または第三者に無断で当該人物の個人を特定できる情報を公開する行為</li>
          <li className="list-item-parenthesis">会員が自己の責任に基づき掲載した内容の責任を負うことについて会員自身の明確な同意を条件に会員自身に関わる個人を特定できる情報の公開を許諾し可能とするものとして当社が提供するスペース以外において、当該スペースで公開された情報以外の会員自身に関わる個人を特定できる情報を公開する行為</li>
          <li className="list-item-parenthesis">本サービスの提供される地域において法令に反する行為</li>
          <li className="list-item-parenthesis">他の利用者または第三者に不利益または損害を与える行為</li>
          <li className="list-item-parenthesis">他の利用者または第三者に対する誹謗中傷</li>
          <li className="list-item-parenthesis-v2">営業や広告などに関する宣伝を目的にする行為</li>
          <li className="list-item-parenthesis-v2">過度あるいは不適切に特定の外部Webサイトへ誘導することが目的と判断される行為</li>
          <li className="list-item-parenthesis-v2">児童・若年者に対し悪影響があると判断される行為</li>
          <li className="list-item-parenthesis-v2">アダルトサイト・出会い系サイトなど年齢制限を有するサイトや、違法・有害サイトなどへのリンク行為</li>
          <li className="list-item-parenthesis-v2">会員の資格を第三者に譲渡、貸与すること、または第三者と共用する行為</li>
          <li className="list-item-parenthesis-v2">本サービスの運営を妨げる行為</li>
          <li className="list-item-parenthesis-v2">当社の信用を毀損する行為</li>
          <li className="list-item-parenthesis-v2">本利用規約に違反する行為</li>
          <li className="list-item-parenthesis-v2">その他、当社が不適当と判断する行為</li>
        </ol>
        前項各号に該当する行為によって、当社が何らかの損害を被った場合には、当社が被った損害を、その利用者に賠償するよう請求することができるものとします。
      </div>
    ),
  },
  {
    title: "第9条（投稿内容の変更および削除）",
    content: (
      <div>
        当社は、以下の事由がある場合には、会員に承諾を得ることなく投稿、表示される内容を変更および削除することができるものとします。また、当社は投稿者である会員への対応業務に従事した者に係る人件費、その他の費用に相当する金額を含め、当社が被った損害を当該会員に賠償するよう請求することができるものとします。<br />
        <ol className="list-group-reset">
          <li className="list-item-parenthesis">法令または本利用規約などに違反したものである場合</li>
          <li className="list-item-parenthesis">第8条に定める禁止事項に該当する場合</li>
          <li className="list-item-parenthesis">当社が直ちに変更または削除する必要があると判断した場合</li>
        </ol>
        当社は、会員から、その会員自身が投稿し、表示されている内容の変更、修正または削除の申し出を受けた場合でも、投稿し、表示されている内容の変更、修正 および削除を行わないものとします。ただし、その投稿が、本サービス内で会員自身が任意に登録情報を削除・編集可能とする当社提供のスペース以外においてなされたものであって、かつ、その投稿し、表示されている内容が前項各号に該当する場合を除きます。
      </div>
    ),
  },
  {
    title: "第10条（情報の無断使用の禁止）",
    content: (
      <div>
        本サービス上の文章、画像、イラストおよび動画などを含めた情報の無断使用を禁止します。<br />
        本サービスに関わる記載（ウェブ上での告知、メールマガジンなどを含む）について、無断で編集、複製または転載することを禁止します。<br />
        第１項または第２項に違反し、当社が何らかの損害を被った場合には、利用者は当社に対して損害の賠償をしなければならないものとします。
      </div>
    ),
  },
  {
    title: "第11条（投稿内容の利用権）",
    content: (
      <div>
        当社は、会員が本サービス上に投稿することで公開される質問、回答、補足質問、お礼あるいは公開プロフィールの自己紹介などのテキストデータ（以下「投稿テキスト」といいます）、および画像や動画・音声ファイルおよび手書き機能などのマルチメディアデータ添付アップロード機能を利用して投稿テキストに付随的に投稿されたデータ（以下「投稿マルチメディアデータ」といい、投稿テキストと投稿マルチメディアデータを総称して「投稿内容」といいます）を保存し、 また本サービス上で適宜利用することができるものとします。なお、当社が必要と認めた場合には、投稿者の承諾を得ることなく、保存されている投稿内容の削除または修正を行う場合があります。<br />
        投稿テキストの著作権等の帰属については以下の各号に定めるとおりとします。<br />
        <ol className="list-group-reset">
          <li className="list-item-parenthesis">日本国著作権法第18条から第20条までにおいて定義される権利については、会員は当社または当社の指定する第三者に対して行使しないものとします。</li>
          <li className="list-item-parenthesis">日本国著作権法21条から第28条までにおいて定義される権利については、会員から譲渡され当社に帰属します。</li>
          <li className="list-item-parenthesis">第１号および第２号の対価として、当社は、会員に対し、何らの支払も要しないものとします。</li>
        </ol>
        投稿マルチメディアデータの著作権等の扱いについては、以下の各号に定めるとおりとします。<br />
        <ol className="list-group-reset">
          <li className="list-item-parenthesis">会員は、自身が投稿した投稿マルチメディアデータに記録されている内容に関する一切の特許権、商標権、企業秘密、著作権若しくはその他の財産権および個人情報を含むプライバシー権、肖像権などの諸権利について保有するか、または、当社をして本項第３号に定められる投稿マルチメディアデータの利用をさせるのに必要なライセンス、権利、同意及び許可を有していることを保証するものとします。万一、第三者との間で何らかの紛争が発生した場合には、会員の費用と責任において問題を解決するとともに、当社に何等の迷惑または損害を与えないものとします。</li>
          <li className="list-item-parenthesis">著作権法第18条から第20条までにおいて定義される権利については、会員は、自らまたは第三者をして、当社または当社の指定する第三者に対して行使せず、またはさせないものとします。</li>
          <li className="list-item-parenthesis">著作権法第21条から第28条までにおいて定義される権利については、会員または第三者に留保されます。その上で、会員は当社に対し、非独占的に、世界中で投稿マルチメディアデータの表示、編集、複製、転載、配布などを行い、かつ当社が有益であると判断した場合にはその内容を利用（出版、映像、翻訳、放送、演劇化などの派生著作物の作成および利用の場合を含む）することができるためのライセンス（サブライセンスが可能であり、譲渡が可能なライセンス）を、無償で付与するものとします。</li>
        </ol>
        当社は、第２項に基づく著作権の帰属に基づき、また前項に基づくライセンスに基づき、投稿内容の編集、複製、転載などを行い、当社が有益であると判断した場合にはその内容を利用（出版、映像、翻訳、放送、演劇化などの利用の場合を含む）することができます。これらを行う場合でも、当社は会員に対し、何らの支払も要しないものとし、また、当社は会員の氏名、会員IDおよびハンドルネーム、その他の会員を表象する名称および情報を表示しないことができるものとします。<br />
        投稿内容の利用について、利用者および第三者に損害が発生したとしても、当社は一切責任を負わないものとします。

      </div>
    ),
  },
  {
    title: "第12条（本サービスの一時的な中断）",
    content: (
      <div>
        当社は以下の事由により、利用者に事前に連絡することなく、一時的に本サービスの提供を中断することがあります。本サービスの中断による損害について、当社は一切責任を負わないものとします。<br />
        <ol className="list-group-reset">
          <li className="list-item-parenthesis">当社のシステムの保守、点検、修理などを行う場合</li>
          <li className="list-item-parenthesis">火災、停電または天災地変により本サービスの提供ができなくなった場合</li>
          <li className="list-item-parenthesis">運用上または技術上、本サービスの提供ができなくなった場合</li>
          <li className="list-item-parenthesis">その他、当社が中断をせざるを得ないと判断した場合</li>
        </ol>
      </div>
    ),
  },
  {
    title: "第13条（提供サービスの変更・停止・廃止）",
    content: (
      <div>
        当社は、本サービスの内容を、利用者への事前告知なく変更することができます。<br />
        当社は本サービスを、事前告知なしに停止または廃止することができるものとします。<br />
        本サービスにおいて、利用者および第三者に損害が発生したとしても、当社は一切責任を負わないものとします。
      </div>
    ),
  },
  {
    title: "第14条（国外からの利用）",
    content: (
      <div>
        日本国外から本サービスを利用する場合には、日本国の法律および関係する国際条約に従うものとします。<br />
        日本国外からの本サービスの利用者につき、当該利用者の本サービスの利用が居住国の法律および関係する国際条約に違反しないにもかかわらず、本サービスの 利用が、日本法に違反し当該利用者に損害が発生した場合、または、第三者に損害を発生させた場合、当社は一切責任を負わないものとします。
      </div>
    ),
  },
  {
    title: "第15条（準拠法）",
    content: (
      <div>
        本利用規約は、日本法に準拠して解釈されるものとします。
      </div>
    ),
  },
  {
    title: "第16条（管轄裁判所）",
    content: (
      <div>
        本サービスに関して当社と利用者または第三者間で紛争が生じた際には、東京地方裁判所を第一審の合意管轄裁判所とします。
      </div>
    ),
  },
  {
    title: "第17条（本利用規約の効力）",
    content: (
      <div>
        本利用規約は2023年10月1日から発効するものとし、過去の規約に優先して適用されるものとします。
      </div>
    ),
  },
];

export default termsContent;