import { useDispatch } from "react-redux"
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { asyncDevAPI, Decrypto, Encrypto, ErrorSwitchFunctions } from '../../../../functions';
import { Footer, Header } from "../../../../htmlParts";
import { useEffect, useState } from "react";
import { changedCountryPhoneNumber, changedPhoneNumber, changedPhoneNumberSelect } from "../../../../functions/ChangeFunction";
import { setErrorAction } from "../../../../Reduxs/Errors/actions";
import { MFAPhoneNumberHead } from "../../../../htmlParts/headData";

const MfaPhoneNumber = () => {
    const dispatch = useDispatch();

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));
    // state管理系宣言
    const [countryPhoneNumber, setCountryPhoneNumber] = useState<string>('');
    const [countryPhoneNumberErrMsg, setCountryPhoneNumberErrMsg] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [phoneNumberErrMsg, setPhoneNumberErrMsg] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');

    // API呼び出し
    const checkMfa = () => {
        // SessionStorageからアクセストークンを取得
        const SessionAccessToken = loginUser.accessToken;

        const apiParams = {
            apiname: 'source_cognito_verify_user_attribute_get',
            AccessToken: SessionAccessToken,
            type: 'phone_number',
            phone_number: countryPhoneNumber + phoneNumber,
        };
        // 検証コード発行API
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const result: any = res;
                const statusCode = result['body']['status'];

                if (statusCode === 200) {
                    sessionStorage.setItem('pnb', Encrypto(countryPhoneNumber + phoneNumber));

                    // 認証コード入力画面に遷移
                    dispatch(push('/account/security/mfa/codeInput'));
                } else {
                    // 200以外のステータス
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                switch (err.errorType) {
                    default:
                        setErrMsg('コードの送信に失敗しました。お手数ですがもう一度お試しください。');
                        break;
                }
            })
    }

    useEffect(() => {
        setCountryPhoneNumber("+81");
    }, [])

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <MFAPhoneNumberHead />
                <Header />
                <div id="two_step_tel" className="mail_change">
                    <main>
                        <div className="contents_in">
                            <div className="back"><Link to='/account/security'>&lt; 戻る</Link></div>
                            <div className="text_box_ptn01 form_box_ptn02">
                                <h1 className="title">2段階認証設定</h1>
                                <div className="lead">2段階認証を設定するとTTS-IDログイン時に、<br />あなたの携帯電話にログイン用のセキュリティコードを送信します。<br />
                                    ログイン用のコードを受け取るために、<br />SMS(ショートメッセージサービス)が利用可能な携帯電話が必要です。<br />
                                    あなたがお持ちの携帯電話の電話番号を入力ください。
                                </div>
                                <div className='form'>
                                    <dt className="form_text">国番号を入力</dt>
                                    <dl className="form_g">
                                        <dd className="tel_country">
                                            <input
                                                className="select" type="tel" defaultValue="+81" placeholder='+81' maxLength={6}
                                                onChange={(event) => changedCountryPhoneNumber(event, setCountryPhoneNumber, setCountryPhoneNumberErrMsg)}
                                            />
                                            <div className="form_error_text">{countryPhoneNumberErrMsg}</div>
                                        </dd>
                                        <dt className="form_text">携帯電話番号を入力</dt>
                                        <dd>
                                            <input
                                                type="tel" placeholder="90 1234 5678" maxLength={12}
                                                onChange={(event) => changedPhoneNumber(event, setPhoneNumber, setPhoneNumberErrMsg)}
                                            />
                                            <div className="form_error_text">
                                                {phoneNumberErrMsg ? phoneNumberErrMsg : errMsg}
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="btn_submit blue">
                                        <button
                                            disabled={
                                                !(phoneNumberErrMsg === '') ||
                                                phoneNumber === '' ||
                                                countryPhoneNumber === ''
                                            }
                                            onClick={() => {
                                                checkMfa();
                                            }}
                                        >次へ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default MfaPhoneNumber
