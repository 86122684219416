import {createSelector} from 'reselect';

const contactSelector = (state) => state.contact;

export const getContactName = createSelector(
    [contactSelector],
    state => state.name
)

export const getContactCompany = createSelector(
    [contactSelector],
    state => state.company
)

export const getContactEmail = createSelector(
    [contactSelector],
    state => state.email
)

export const getContactSelect = createSelector(
    [contactSelector],
    state => state.contactSelect
)

export const getContactText = createSelector(
    [contactSelector],
    state => state.contactText
)
export const getTextCount = createSelector(
    [contactSelector],
    state => state.textCount
)