import { SideBar } from '.';
import { Header, Footer } from '../../htmlParts';
import illust01_user from '../../assets/img/setting/user/illust01_user.png';
import { useEffect, useState } from 'react';
import { asyncDevAPI, Decrypto, Encrypto, ErrorSwitchFunctions } from '../../functions';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { getAPIAction, getIsModalOpen } from '../../Reduxs/PageAct/selectors';
import { apiFinAction, apiStartAction, hanbergerOpenAction } from '../../Reduxs/PageAct/actions';
import { setErrorAction } from '../../Reduxs/Errors/actions';
import ScrollAnimation from 'react-animate-on-scroll';
import { setArticle } from '../auth/login';
import { clearLoadingAction, setLoadingAction } from '../../Reduxs/Loading/actions';
import { AccountMyTagHead } from '../../htmlParts';

const UserData = () => {
    document.title = 'Myタグ|TTS-ID';

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    // エラーメッセージ
    const [errMsg, setErrMsg] = useState<string>('');

    // 保存ボタン下のメッセージ
    const myTagEdit = sessionStorage.getItem('myTagEdit') ?? null;
    const [messageboxWordOk, setMessageboxWordOk] = useState<boolean>(myTagEdit ? Boolean(myTagEdit) : false);
    const [messageboxWordNg, setMessageboxWordNg] = useState<boolean>(false);

    // アクセストークの有無チェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = SessionUserObj ? JSON.parse(SessionUserObj) : null;

    const sessionArticles = sessionStorage.getItem('articles');
    let articles:any = sessionArticles ? JSON.parse(sessionArticles) : null;

    const urpj = sessionStorage.getItem('urpj');
    const r_user = urpj ? JSON.parse(Decrypto(urpj)) : null;
    const m_code = r_user ? r_user.m_code : null;
    let user_btd_tmp;
    let user_btd_tmp_id;
    let user_interest_tmp: string[] = [];
    const sessionCheckList: any = sessionStorage.getItem('CheckList') ? Decrypto(sessionStorage.getItem('CheckList')) : null
    let user_interest_tmp_id: number[] = sessionCheckList ? JSON.parse(sessionCheckList) : [];
    let before_interest: number[] = [];
    if (m_code) {
        m_code.map((item) => {
            switch (item.atb) {
                case 1:
                    user_btd_tmp = item.svalue;
                    user_btd_tmp_id = item.mid;
                    break;
                case 2:
                    user_interest_tmp.push(item.svalue);
                    user_interest_tmp_id.push(item.mid);
                    before_interest.push(item.mid);
                    break;
                default:
                    break;

            }
        });
    }

    // よく行く出張先の国
    const sessionBtd = sessionStorage.getItem('Btd') ? Decrypto(sessionStorage.getItem('Btd')) : null;
    const [user_btd, setUserBtd] = useState<{}>(JSON.parse(sessionBtd) ?? {});

    const interestTmp = localStorage.getItem('interest');
    const interest = JSON.parse(Decrypto(interestTmp));

    const btdTmp = localStorage.getItem('btd');
    const btd = JSON.parse(Decrypto(btdTmp));

    let checkList: object[] = [];
    let checkItem: number[] = sessionCheckList ? JSON.parse(sessionCheckList) : [];
    Object.keys(interest.interest).map(key => {
        let flg = false;
        if (user_interest_tmp_id.includes(interest.interest[key].mid)) {
            flg = true;
            checkItem.push(interest.interest[key].mid);
        }
        checkList[key] = { mid: interest.interest[key].mid, checked: Boolean(flg) };
    });

    const changedBtd = (e) => {
        sessionStorage.setItem('Btd', '1');

        if (user_btd_tmp_id) {
            const saveBtd = { type: 'update', value: user_btd_tmp_id + ',' + e.target.value }
            setUserBtd(saveBtd);
            // sessionに選択した国を保存しておく
            sessionStorage.setItem('Btd', Encrypto(JSON.stringify(saveBtd)));
        } else {
            const saveBtd = { type: 'insert', value: e.target.value }
            setUserBtd(saveBtd);
            // sessionに選択した国を保存しておく
            sessionStorage.setItem('Btd', Encrypto(JSON.stringify(saveBtd)));
        }
    }

    const changedInterest = (event) => {
        sessionStorage.setItem('myTagEdit', '1');

        checkList.map(item => {
            if (item['mid'] == event.value) {
                item['checked'] = event.checked;
            }
        });

        // sessionにチェック済みを保存しておく
        let sessionCheckList: any = [];
        // チェック済みのみ取得
        sessionCheckList = checkList.filter(item => item['checked']);
        sessionCheckList.map((value, key) => {
            if (value['checked']) {
                // チェック済みのidを取得
                sessionCheckList[key] = value['mid'];
            }
        });
        sessionStorage.setItem('CheckList', Encrypto(JSON.stringify(sessionCheckList)));
    }
    const saveData = () => {
        dispatch(setLoadingAction({
            isLoading: true,
        }));
        dispatch(apiStartAction());
        let update_interest: any[] = [];
        // SessionStorageに興味のあるキーワードの変更情報がある場合は、それを使用する
        if (sessionStorage.getItem('CheckList')) {
            const checkListSave = JSON.parse(Decrypto(sessionStorage.getItem('CheckList')));
            checkListSave.map(item => {
                update_interest.push(item);
            });
        } else {
            checkList.map(item => {
                if (item['checked']) {
                    update_interest.push(item['mid']);
                }
            });
        }
        let paramsInsert;
        let mid: object[] = [];
        let deleteFlg = false;
        let user_btd_save = '';
        // SessionStorageによく行く出張先の変更情報がある場合は、それを使用する
        if (sessionBtd) {
            user_btd_save = JSON.parse(sessionBtd);
        }
        if (user_btd_save['type'] == 'update') {
            mid.push({ "update": user_btd['value'] });
        } else if (user_btd_save['type'] == 'insert') {
            mid.push({ "insert": user_btd_save['value'] });
        }
        if (before_interest.length > 0) {
            deleteFlg = update_interest.length > 0 ? true : false;
            mid.push({ "delete": before_interest.join() });
            paramsInsert = {
                apiname: 'source_user_put',
                AccessToken: loginUser.accessToken,
                mid: [
                    { "insert": update_interest.join() }
                ]
            }
        } else {
            mid.push({ "insert": update_interest.join() });
        }
        const paramsFirst = {
            apiname: 'source_user_put',
            AccessToken: loginUser.accessToken,
            mid: mid
        }

        asyncDevAPI(paramsFirst)
            .then((res: any) => {
                if (res.body.status === 200) {
                    const result = res.body;
                    const rdsParams = {
                        apiname: 'source_user_get',
                        AccessToken: loginUser.accessToken,
                    }
                    if (deleteFlg) {
                        asyncDevAPI(paramsInsert)
                            .then((res: any) => {
                                if (res.body.status === 200) {
                                    asyncDevAPI(rdsParams)
                                        .then((res: any) => {
                                            if (res.body.status === 200) {
                                                const m_user = res.body.response.m_user;
                                                const m_code = res.body.response.m_code;
                                                const params = {
                                                    username: m_user.nickname,
                                                    year: String(m_user.birthday).substr(0, 4),
                                                    month: String(m_user.birthday).substr(4, 2),
                                                    day: String(m_user.birthday).substr(6, 2),
                                                    gender: m_user.gender,
                                                    m_code: m_code
                                                };
                                                let uiData:Number[] = [];
                                                m_code.map((item:any) => {
                                                  if(item.atb === 2){
                                                      uiData.push(item.mid);
                                                  }
                                                });
                                                const paramsjs = JSON.stringify(params);
                                                sessionStorage.urpj = Encrypto(paramsjs);

                                                // 変更成功後sessionのデータは削除
                                                sessionStorage.removeItem('Btd');
                                                sessionStorage.removeItem('CheckList');
                                                sessionStorage.removeItem('myTagEdit');
                                                articleUpdate(loginUser.accessToken, uiData, dispatch, setMessageboxWordOk, setMessageboxWordNg);
                                                // alert('変更しました。');
                                                //window.location.reload();
                                                // setMessageboxWordOk(true);
                                                
                                                // dispatch(push(sessionStorage.getItem('NowPath')));
                                            } else {
                                                // 200以外のステータス
                                                dispatch(clearLoadingAction());
                                                setMessageboxWordNg(true);
                                                const setMsgs = {
                                                    ErrMsg: setErrMsg,
                                                }
                                                const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                                if (errorPage.flg) {
                                                    dispatch(setErrorAction({
                                                        errorCode: res.body.status
                                                    }));
                                                    dispatch(push(errorPage.url));
                                                }
                                                return;
                                            }
                                        })
                                        .catch(err => {
                                            dispatch(clearLoadingAction());
                                            setMessageboxWordNg(true);
                                            dispatch(apiFinAction());
                                            alert('ログイン状態が切れました。再度ログインしてください。');
                                            dispatch(push('/'));
                                        });
                                } else {
                                    // 200以外のステータス
                                    dispatch(clearLoadingAction());
                                    setMessageboxWordNg(true);
                                    const setMsgs = {
                                        ErrMsg: setErrMsg,
                                    }
                                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                    if (errorPage.flg) {
                                        dispatch(setErrorAction({
                                            errorCode: res.body.status
                                        }));
                                        dispatch(push(errorPage.url));
                                    }
                                    return;
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                dispatch(apiFinAction());
                                dispatch(clearLoadingAction());
                                setMessageboxWordNg(true);
                            });
                    } else {
                        asyncDevAPI(rdsParams)
                            .then((res: any) => {
                                if (res.body.status === 200) {
                                    const m_user = res.body.response.m_user;
                                    const m_code = res.body.response.m_code;
                                    const params = {
                                        username: m_user.nickname,
                                        year: String(m_user.birthday).substr(0, 4),
                                        month: String(m_user.birthday).substr(4, 2),
                                        day: String(m_user.birthday).substr(6, 2),
                                        gender: m_user.gender,
                                        m_code: m_code
                                    };
                                    let uiData:Number[] = [];
                                    m_code.map((item:any) => {
                                      if(item.atb === 2){
                                          uiData.push(item.mid);
                                      }
                                    });
                                    const paramsjs = JSON.stringify(params);
                                    sessionStorage.urpj = Encrypto(paramsjs);

                                    // 変更成功後sessionのデータは削除
                                    sessionStorage.removeItem('Btd');
                                    sessionStorage.removeItem('CheckList');
                                    sessionStorage.removeItem('myTagEdit');

                                    articleUpdate(loginUser.accessToken, uiData, dispatch, setMessageboxWordOk, setMessageboxWordNg);
                                    //setMessageboxWordOk(true);
                                } else {
                                    // 200以外のステータス
                                    dispatch(clearLoadingAction());
                                    setMessageboxWordNg(true);
                                    const setMsgs = {
                                        ErrMsg: setErrMsg,
                                    }
                                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                    if (errorPage.flg) {
                                        dispatch(setErrorAction({
                                            errorCode: res.body.status
                                        }));
                                        dispatch(push(errorPage.url));
                                    }
                                    return;
                                }
                            })
                            .catch(err => {
                                dispatch(clearLoadingAction());
                                setMessageboxWordNg(true);
                                dispatch(apiFinAction());
                                alert('ログイン状態が切れました。再度ログインしてください。');
                                dispatch(push('/'));
                            });
                    }
                } else {
                    // 200以外のステータス
                    dispatch(clearLoadingAction());
                    setMessageboxWordNg(true);
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(clearLoadingAction());
                setMessageboxWordNg(true);
                dispatch(apiFinAction());
                setMessageboxWordNg(true);
            });
    }

    const [hanVis, setHanVis] = useState<boolean>(false);
    const changedHanberger = () => {
        dispatch(hanbergerOpenAction());
        setHanVis(!hanVis);
    }

    useEffect(() => {
        if (sessionStorage.getItem('editSaveOn')) {
            // 警告モーダルで保存するを押下していた場合
            saveData();
            sessionStorage.removeItem('editSaveOn');
        }
    }, [])

    return (
        <div id="wrapper" className={getIsModalOpen(selector) ? 'no-scroll' : ''}>
            <AccountMyTagHead />
            <Header />
            <div id="user_info" className="setting_page">
                <main>
                    <div className="page_contents">
                        <h1 className="sp">TTS-ID設定</h1>
                        <div className={hanVis ? "btn_open sp act" : "btn_open sp"} onClick={changedHanberger}><span></span><span></span><span></span></div>
                        <SideBar />
                        <div className="main_contents">
                            <div className="contents_in">
                                <h2 className="h2_ptn01">Myタグ</h2>
                                <p>
                                    自分にあった情報をMyタグで効率的に集める！<br />
                                    Myタグを設定することで、よく行く出張先やよく使う空港など、おすすめ記事をピックアップしてお知らせします！よりスマートな出張や海外ビジネスのためにぜひMyタグをご利用ください。
                                </p>
                                <div className="form">
                                    <dt className="form_title">Myタグの設定</dt>
                                    <dl className="country_g">
                                        <dt className="form_text">よく行く出張先</dt>
                                        <dd>
                                            <label htmlFor="country">国</label>
                                            <select name="country" id="" defaultValue={sessionBtd ?? user_btd_tmp_id} className="select native_place" onChange={(e: any) => changedBtd(e)}>
                                                {Object.keys(btd.btd).map((key, index) => {
                                                    return (
                                                        <option key={index} value={btd.btd[key].mid}>{btd.btd[key].svalue}</option>
                                                    );
                                                })}
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl className="keyword_g">
                                        <dt className="form_text">興味のあるキーワード</dt>
                                        <dd>
                                            <div className="checkbox_ptn02">
                                                {Object.keys(interest.interest).map(key => {
                                                    return (
                                                        <label>
                                                            <input type="checkbox" value={interest.interest[key].mid} name="keyword" defaultChecked={checkItem.includes(interest.interest[key].mid) ? true : false} onClick={(e: any) => changedInterest(e.target)} />
                                                            <span className={checkItem.includes(interest.interest[key].mid) ? "" : "new"}>{interest.interest[key].svalue}</span>
                                                        </label>
                                                    );
                                                })}
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="form_error_text">{errMsg}</div>
                                    <div className="btn_submit">
                                        <input
                                            type="submit"
                                            value="変更内容を保存"
                                            disabled={getAPIAction(selector)}
                                            onClick={saveData} />
                                    </div>
                                    <div style={{ display: messageboxWordOk ? 'block' : 'none' }} className="messagebox save">設定を保存しました</div>
                                    <div style={{ display: messageboxWordNg ? 'block' : 'none' }} className="messagebox save error">変更内容を保存できませんでした</div>
                                </div>
                            </div>

                            <div className="cnt_img">
                                <img src={illust01_user} alt="人のイラスト" />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <ScrollAnimation
                offset={0}
                animateIn='fadeIn'
                animateOut='fadeOut'
                duration={1}
                animatePreScroll={true}
            /*initiallyVisible={true}*/
            >

                <Footer />
            </ScrollAnimation>
        </div>
    )
}

export default UserData



export const articleUpdate = (accessToken, mytags, dispatch, setMessageboxWordOk, setMessageboxWordNg) => {
    const rssParams = {
      apiname: 'source_rss_article_get',
      type: "new_article",
      AccessToken: accessToken,
  };
    const userNewsParams = {
      apiname: 'source_rss_article_get',
      type: "my_article",
      AccessToken: accessToken,
      my_tag: mytags.join(),
  }
    asyncDevAPI(rssParams)
    .then((res:any) => {
        const body = res.body;
        if(body.status === 200){
            const newParams = {
                new_Article: body.response,
            };
            const newArticle = JSON.stringify(newParams);
            asyncDevAPI(userNewsParams)
            .then((res:any) => {
                const body = res.body;
                let myArticle;
                if(body.status === 200){
                    if(body.response.length > 0){
                        const myParams = {
                            mytag_article: body.response,
                        }
                        myArticle = JSON.stringify(myParams);
                    } else {
                        myArticle = null;
                    }
                    const params = {
                        newArticle: newArticle,
                        myArticle: myArticle,
                    }
                    sessionStorage.articles = JSON.stringify(params);
                    dispatch(clearLoadingAction());
                    dispatch(apiFinAction());
                    setMessageboxWordOk(true);
                }
            })
            .catch(err => {
                dispatch(clearLoadingAction());
                setMessageboxWordNg(true);
                console.log(err);
            });
        }
    })
    .catch(err => {
        dispatch(clearLoadingAction());
        setMessageboxWordNg(true);
        console.log(err);
    });
  }