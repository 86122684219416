import React, { useEffect } from 'react';
import '../../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  TwitterLoginButton,
  Encrypto,
  loginErrorWord,
  GoogleLoginButton,
  GoogleRecaptcha,
  asyncDevAPI,
  CheckDeviceBrowser,
  changedEmailHaldler,
  changedPasswordHandler,
  reChaptchaErrWord,
  MoveOnryButton,
  TwitterLoginAPI,
  limitExceededErrWord,
} from '../../functions';
import { loginAction, setNicknameAction, signUpAction } from '../../Reduxs/Users/actions';
import { setLoadingAction, clearLoadingAction } from '../../Reduxs/Loading/actions';
import { getToken } from '../../Reduxs/Recaptcha/selectors'
import { Footer, Header, LoginPageHead } from '../../htmlParts';
import { handleClearAction } from '../../Reduxs/Recaptcha/actions';
import { InputPassword } from '../../htmlParts';
import pic01 from '../../assets/img/login/pic01.png';
import logo_login from '../../assets/img/login/logo_login.png';
import { Link } from 'react-router-dom';
import { asyncServerTimeMillisecond, asyncTestGeolocation } from '../../functions/asyncFunctions';
import { loginErrorsAction, setErrorAction } from '../../Reduxs/Errors/actions';
import { LoginData } from '../../functions/LoginData';

const params = new URLSearchParams(window.location.search);

const Login = () => {

  document.title = 'ログイン｜TTS-ID';

  const bthacksToken = params.get('token');

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const reChaptchaToken = getToken(selector);
  const twflg = sessionStorage.getItem('twflg');

  sessionStorage.verifyURL = true;
  
  if(twflg){
    sessionStorage.removeItem('twflg');
    TwitterLoginAPI(selector, dispatch);
  }

  let developFlg = true;

  if(process.env.REACT_APP_DOCUMENT_ROOT === 'https://tts-id.com' ||
      process.env.REACT_APP_DOCUMENT_ROOT === 'https://www.tts-id.com'){
      developFlg = false;
  }
  
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  //バリデーション処理ここから
  const [emailError, setEmailError] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<string>('');
  const [errMsg, setErrMsg] = React.useState<string>('');
  const [errReChaptcha, setErrReChaptcha] = React.useState<string>('');
  //バリデーション処理ここまで

  //パスワード表示用Stateここから
  const [passDisp, setPassDisp] = React.useState<boolean>(false);
  //パスワード表示用Stateここまで

  const [keepLogin, setKeepLogin] = React.useState<boolean>(false);

  const keepLoginHandler = () => {
    setKeepLogin(!keepLogin);
  }

  const login = () => {
    if (!reChaptchaToken) {
      setErrReChaptcha(reChaptchaErrWord);
      return;
    }

    // ローディング開始
    dispatch(setLoadingAction({
      isLoading: true
    }));

    // 使用デバイスとブラウザの取得
    const useDeviceAndBrowser = CheckDeviceBrowser();

    const apiParams = {
      apiname: 'source_login_post',
      user_name: email,
      password: password,
      device: useDeviceAndBrowser,
      type: 'login'
    };

    asyncDevAPI(apiParams)
      .then((res: any) => {
        const result: any = res;
        const statusCode = result['body']['status'];
        const accessToken = result['body']['response']['AccessToken'];
        const apiIdToken = result['body']['response']['IdToken'];
        const ccode = result['body']['response']['ccode'];
        loginHistoryCheck(accessToken, useDeviceAndBrowser);

        if(res.body.response.Session){
          const urlParams = {
            url: '/top',
            button: 'ログイン'
          }
          const params = {
            email: email,
            password: password,
            useDeviceAndBrowser: useDeviceAndBrowser,
            session: res.body.response.Session,
            phoneNumber: res.body.response.phone_number,
            keep: keepLogin,
          }
          const paramsJson = JSON.stringify(params);
          sessionStorage.urlData = JSON.stringify(urlParams);
          sessionStorage.Session = Encrypto(paramsJson);
          localStorage.Login = true;
          dispatch(clearLoadingAction());
          dispatch(push('/SMSverification'));
          return;
        }
        const refreshToken = result['body']['response']['RefreshToken'];

        if (statusCode === 200) {
          dispatch(handleClearAction());//リキャプチャトークンの初期化
          
          localStorage.Login = true;

          LoginData(refreshToken, keepLogin);

          const sessionStorageparams = {
            accessToken: accessToken,
            verifyCount: 6,
          }
          const suEnc = JSON.stringify(sessionStorageparams);
          sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(suEnc);

          const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
          const envPasswordKey = process.env.REACT_APP_SESSION_STORAGE_PASSWORD ?? '';
          sessionStorage[envEmailKey] = Encrypto(email);
          sessionStorage[envPasswordKey] = Encrypto(password);
          const rdsParams = {
            apiname: 'source_user_get',
            AccessToken: accessToken,
          }
          asyncDevAPI(rdsParams)
            .then((res: any) => {
              if (res.body.status && res.body.status === 200) {
                const m_user = res.body.response.m_user;
                const m_code = res.body.response.m_code;
                const params = {
                  username: m_user.nickname,
                  year: String(m_user.birthday).substr(0, 4),
                  month: String(m_user.birthday).substr(4, 2),
                  day: String(m_user.birthday).substr(6, 2),
                  gender: m_user.gender,
                  m_code: m_code
                };
                let uiData:Number[] = [];
                m_code.map((item:any) => {
                  if(item.atb === 2){
                      uiData.push(item.mid);
                  }
                });

                setArticle(accessToken, uiData, dispatch);
                
                const paramsjs = JSON.stringify(params);
                sessionStorage.urpj = Encrypto(paramsjs);
                sessionStorage.unkm = Encrypto(m_user.nickname);
                dispatch(loginAction({
                  tts_id: ccode,
                  google_username: '',
                  idtoken: apiIdToken,
                  access_token: accessToken,
                  tf_mail: email,
                  password: password,
                }));
                //dispatch(clearLoadingAction());
                //dispatch(push('/top'));
              } else if (res.body.response.message === "ユーザーマスターテーブルでccodeが見つからない") {
                sessionStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);
                localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);
                const newRegistration = {
                  email: email,
                  password: password,
                }
                let newRegistTmp = JSON.stringify(newRegistration);
                sessionStorage.newRegistration = Encrypto(newRegistTmp);
                alert('プロフィールの登録がありません。次の画面でプロフィールのご登録をお願いします。');

                const params = {
                  apiname: 'source_cognito_get',
                  AccessToken: accessToken,
                };
                asyncDevAPI(params)
                  .then((res: any) => {
                    if (res.body.status === 200) {
                      
                      dispatch(clearLoadingAction());
                      dispatch(signUpAction({
                        tts_id: res.body.response.ccode,
                        tf_mail: email,
                        password: password,
                        phone_number: ''
                      }));
                      dispatch(push('/newRegistration/form'));
                      return;
                    } else if(res.body.response.Message === "ユーザーマスターテーブルでccodeが見つからない"){
                      dispatch(clearLoadingAction());
                      dispatch(signUpAction({
                        tts_id: res.body.response.ccode,
                        tf_mail: email,
                        password: password,
                        phone_number: ''
                      }));
                      dispatch(push('/newRegistration/form'));
                      return;
                    } else {
                      dispatch(clearLoadingAction());
                      dispatch(setErrorAction({
                        errorCode: res.body.status
                      }));
                      dispatch(push('/error'));
                    }
                  });
              } else {
                dispatch(clearLoadingAction());
                dispatch(setErrorAction({
                  errorCode: res.body.status
                }));
                dispatch(push('/error'));
              }
            })
            .catch(err => {
              console.log(err);
              sessionStorage.clear();
              dispatch(clearLoadingAction());
              alert('ログイン状態が切れました。再度ログインしてください。');
            }
          );
        } else {
          dispatch(clearLoadingAction());
          const response = res.body.response;
          switch (response.Code) {
            case 'UserNotConfirmedException':
              dispatch(signUpAction({
                tts_id: '',
                tf_mail: email,
                password: password,
                phone_number: 'no_verification',
              }));

              const newRegistration = {
                email: email,
                password: password,
              }
              let newRegistTmp = JSON.stringify(newRegistration);
              sessionStorage.newRegistration = Encrypto(newRegistTmp);

              dispatch(loginErrorsAction({
                loginName: response.Code,
                loginPassword: 'お手数ですがメールの認証を行ってください。'
              }));
              dispatch(push('/newRegistration/tempRegistSendMail'));
              break;
              case 'NotAuthorizedException':
                if(response.Message === 'Password attempts exceeded'){
                  setErrMsg(limitExceededErrWord);
                } else {
                  setErrMsg(loginErrorWord);
                }
                break;
            default:
              setErrMsg(loginErrorWord);
              break;
          }
        }
      })
      .catch(err => {
        if (err.errorType === 'UserNotConfirmedException') {
          dispatch(signUpAction({
            tts_id: '',
            tf_mail: email,
            password: password,
            phone_number: 'no_verification',
          }));
          dispatch(loginErrorsAction({
            loginName: err.errorType,
            loginPassword: 'お手数ですがメールの認証を行ってください。'
          }));
          dispatch(clearLoadingAction());
          dispatch(push('/newRegistration/tempRegistSendMail'));
        } else {
          console.log(err);
          dispatch(clearLoadingAction());
          setErrMsg(loginErrorWord);
        }
      });
  }
  const ErrorMsg = () => {
    if (errMsg) {
      return (
        <div className="form_error_text">{errMsg}</div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  const buttonAble = () => {
    if(developFlg){
      return (
        <div className="btn_submit">
          <button
            id='login-button'
            onClick={() => {
              login();
            }}
            disabled={!email || !password}
          >
            メールアドレスでログイン
          </button>
        </div>
      )
    } else {
      return (
        <div className="btn_submit">
          <button
            id='login-button'
            onClick={() => {
              login();
            }}
            disabled={
              !(emailError === '') || !(passwordError === '') ||
              !reChaptchaToken || !email || !password
            }
          >
            メールアドレスでログイン
          </button>
        </div>
      )
    }
  }
  useEffect(() => {
    if ( bthacksToken ) {
      localStorage.Login = true;

      const sessionStorageparams = {
        accessToken: bthacksToken,
        verifyCount: 6,
      }
        const suEnc = JSON.stringify(sessionStorageparams);
        sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(suEnc);

      const rdsParams = {
        apiname: 'source_user_get',
        AccessToken: bthacksToken,
      }
      asyncDevAPI(rdsParams)
        .then((res: any) => {
          if (res.body.status && res.body.status === 200) {
            const m_user = res.body.response.m_user;
            const m_code = res.body.response.m_code;
            const params = {
              username: m_user.nickname,
              year: String(m_user.birthday).substr(0, 4),
              month: String(m_user.birthday).substr(4, 2),
              day: String(m_user.birthday).substr(6, 2),
              gender: m_user.gender,
              m_code: m_code
            };
            let uiData:Number[] = [];
            m_code.map((item:any) => {
              if(item.atb === 2){
                  uiData.push(item.mid);
              }
            });

            setArticle(bthacksToken, uiData, dispatch);
            
            const paramsjs = JSON.stringify(params);
            sessionStorage.urpj = Encrypto(paramsjs);
            sessionStorage.unkm = Encrypto(m_user.nickname);
            //dispatch(clearLoadingAction());
            //dispatch(push('/top'));
            dispatch(push('/account'));
          } else {
            dispatch(clearLoadingAction());
            dispatch(setErrorAction({
              errorCode: res.body.status
            }));
            dispatch(push('/error'));
          }
        })
    }
  }, [bthacksToken]);

  //画面表示ここから
  return (
    <div id="wrapper">
      <LoginPageHead />
      <Header />
      <div id="login">
        <main>
          <div className="contents_in">
            <div className="cnt_box">
              <div className="box_l">
                <div className="text"><img src={logo_login} alt="ロゴ:TTS-ID" /></div>
                <div className="cnt_img"><img src={pic01} alt="" /></div>
                <div className="btn_ptn01 pc"><MoveOnryButton buttonName={'はじめての方はアカウントを新規登録'} path={'/newRegistration'} /></div>
              </div>
              <div className="box_r text_box_ptn01">
                <div className="text">ログインして次に進む</div>
                <div className="btn_box">
                  <div className="btn_ptn03 google"><GoogleLoginButton word={'ログイン'} /></div>
                </div>
                <div className="text">または</div>
                <div className='form'>
                  <dl className="form_g">
                    <dt className="form_text">メールアドレス</dt>
                    <dd>
                      <input
                        type="text"
                        placeholder='メールアドレスを入力'
                        onChange={(event) => changedEmailHaldler(event, setEmail, setEmailError)}
                      // onChange={(event) => setEmail(event.target.value)}
                      />
                    </dd>
                    <dt className="form_text">パスワード</dt>
                    <dd>
                      <InputPassword
                        passDisp={passDisp}
                        changedPasswordHandler={(event) => changedPasswordHandler(event, setPassword, setPasswordError, true)}
                        setPassDisp={setPassDisp}
                      />
                    </dd>
                    <dd>
                      <div style={{ textAlign: "right" }}><Link className="link_ptn01" to='/passwordForget'>パスワードを忘れた場合</Link></div>
                      {ErrorMsg()}
                    </dd>
                  </dl>
                  <div className="checkbox">
                    <input id="keep_login" type='checkbox' onChange={keepLoginHandler} className="check" />
                    <label htmlFor="keep_login">ログイン状態を保持する</label>
                  </div>
                  <div className="recaptcha">
                    <GoogleRecaptcha />
                    <div className="form_error_text">{errReChaptcha}</div>
                  </div>
                  <div className="btn_submit">
                    {buttonAble()}
                  </div>
                </div>
              </div>
              <div className="btn_ptn01 sp"><Link to='/newRegistration'>はじめての方は<br />アカウントを新規登録</Link></div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  )
  //画面表示ここまで
}

export default Login


export const loginHistoryPost = (params) => {
  asyncDevAPI(params)
  .then((res: any) => {
    const result: any = res;
    const statusCode = result['body']['status'];
    if (statusCode !== 200) {
      console.log(res);
    }
  })
  .catch((err: any) => {
    console.log('ログイン履歴追加 失敗: ' + JSON.stringify(err));
  })
}

export const loginHistoryCheck = (accessToken, useDeviceAndBrowser) => {
  const loginHistoryuparams = {
    apiname: 'source_user_login_history_check',
    AccessToken: accessToken,
    type: 'latlng',
  }
  // ログイン履歴確認API
  asyncDevAPI(loginHistoryuparams)
    .then((res: any) => {
      const result: any = res;
      const statusCode = result['body']['status'];
      if (statusCode === 200) {
        // ログイン履歴があるのでOK
        const loginHistoryuparams = {
          apiname: 'source_user_login_history_post',
          AccessToken: accessToken,
          device: useDeviceAndBrowser,
          latlng: res.body.response.latlng,
        }
        loginHistoryPost(loginHistoryuparams);
      } else {
        // ログイン履歴がない場合は追加
        asyncTestGeolocation()
          .then((res: any) => {
            // 使用デバイスとブラウザの取得
            const useDeviceAndBrowser = CheckDeviceBrowser();
            // GeolocationAPI
            const loginHistoryuparams = {
              apiname: 'source_user_login_history_post',
              AccessToken: accessToken,
              device: useDeviceAndBrowser,
              latlng: res,
            }
            // ログイン履歴追加
            loginHistoryPost(loginHistoryuparams);
          })
          .catch((err: any) => {
            console.log('GeolocationAPI latlng取得 失敗: ' + JSON.stringify(err));
          })
      }
    })
    .catch((err: any) => {
      console.log('ログイン履歴確認API 失敗: ' + JSON.stringify(err));
    })
}

export const setArticle = (accessToken, uiData, dispatch, url = '') => {
  const rssParams = {
    apiname: 'source_rss_article_get',
    type: "new_article",
    AccessToken: accessToken,
};
  const userNewsParams = {
    apiname: 'source_rss_article_get',
    type: "my_article",
    AccessToken: accessToken,
    my_tag: uiData.join(),
}
  asyncDevAPI(rssParams)
  .then((res:any) => {
      const body = res.body;
      if(body.status === 200){
          const newParams = {
              new_Article: body.response,
          };
          const newArticle = JSON.stringify(newParams);
          asyncDevAPI(userNewsParams)
          .then((res:any) => {
              const body = res.body;
              let myArticle;
              if(body.status === 200){
                  if(body.response.length > 0){
                      const myParams = {
                          mytag_article: body.response,
                      }
                      myArticle = JSON.stringify(myParams);
                  } else {
                      myArticle = null;
                  }
                  const params = {
                      newArticle: newArticle,
                      myArticle: myArticle,
                  }
                  sessionStorage.articles = JSON.stringify(params);
                  dispatch(clearLoadingAction());
                  dispatch(push('/top'));
              }
          })
          .catch(err => {
              console.log(err);
          });
      }
  })
  .catch(err => {
      console.log(err);
  });
}