import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import {
    GoogleRecaptcha,
    changedEmailHaldler,
    changedPasswordHandler,
    accountRegistFailureErrWord,
    passwordPolicyError,
    Encrypto,
    emailNotAvailable,
    ErrorSwitchFunctions,
    Decrypto,
    limitExceededErrWord,
    loginErrorWord
} from '../../../functions'

import { asyncDevAPI } from '../../../functions';

import { Footer, SPConfirm, InputPassword, Header, NewRegistrationHead } from '../../../htmlParts';
import { signUpAction } from '../../../Reduxs/Users/actions';
import { getToken } from '../../../Reduxs/Recaptcha/selectors';
import { handleClearAction } from '../../../Reduxs/Recaptcha/actions';
import { getGoogleFlg, getMail, getTwitterFlg, getUserAll } from '../../../Reduxs/Users/selectors';
import { clearLoadingAction, setLoadingAction } from '../../../Reduxs/Loading/actions';
import { loginErrorsAction, setErrorAction } from '../../../Reduxs/Errors/actions';

const NewRegistrationInput = () => {
    // タブのタイトル
    document.title = 'アカウント作成|TTS-ID';

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const redirectFlg = sessionStorage.getItem('verifyURL') ? sessionStorage.getItem('verifyURL') : null;
    if(!redirectFlg){
        dispatch(push('/error'));
    }

    let developFlg = true;

    if(process.env.REACT_APP_DOCUMENT_ROOT === 'https://tts-id.com' ||
        process.env.REACT_APP_DOCUMENT_ROOT === 'https://www.tts-id.com'){
        developFlg = false;
    }

    // ボタンの名前
    const buttonName = 'TTS-IDを作成';
    const token = getToken(selector);

    //state管理系宣言
    const [email, setEmail] = useState<string>(getMail(selector));
    const [password, setPassword] = useState<string>('');

    const [emailError, setEmailError] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');

    const [service, setService] = useState<boolean>(false);
    const [privacy, setPrivacy] = useState<boolean>(false);

    const [serviceError, setServiceError] = useState<string>('');
    const [privacyError, setPrivacyError] = useState<string>('');

    const [emailErrMsg, setEmailErrMsg] = React.useState<string>('');
    const [passErrMsg, setPassErrMsg] = React.useState<string>('');

    const [errMsg, setErrMsg] = React.useState<string>('');

    //パスワード表示用Stateここから
    const [passDisp, setPassDisp] = React.useState<boolean>(false);
    //パスワード表示用Stateここまで

    const newRegistrationVerification = () => {
        dispatch(setLoadingAction({
            isLoading: true
        }));
        //Cognitoユーザー情報送信内容設定ここから
        const params = {
            apiname: 'source_cognito_post',
            user_name: email,
            password: password,
        };
        const newRegistration = {
            email: email,
            password: password,
        }
        let newRegistTmp = JSON.stringify(newRegistration);
        sessionStorage.newRegistration = Encrypto(newRegistTmp);
        //Cognitoユーザー情報送信内容設定ここまで

        const apiParams = {
            apiname: 'source_login_post',
            user_name: email,
            password: password,
          };

        //登録されている情報でメール認証が済んでいるか確認
        asyncDevAPI(apiParams)
            .then((res:any) => {
                const response = res.body.response;
                switch (response.Code) {
                    case 'UserNotConfirmedException':
                      dispatch(signUpAction({
                        tts_id: '',
                        tf_mail: email,
                        password: password,
                        phone_number: 'no_verification',
                      }));
                      dispatch(loginErrorsAction({
                        loginName: response.Code,
                        loginPassword: 'お手数ですがメールの認証を行ってください。'
                      }));
                      dispatch(push('/newRegistration/tempRegistSendMail'));
                      break;
                    default:
                      break;
                  }
            });
        asyncDevAPI(params)
            .then((res: any) => {
                if (res.body.status === 200) {
                    dispatch(signUpAction({
                        tf_mail: email,
                        password: password,
                        tts_id: res.body.response.ccode,
                    }));
                    // リキャプチャトークンの初期化
                    dispatch(handleClearAction());
                    const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
                    const envPasswordKey = process.env.REACT_APP_SESSION_STORAGE_PASSWORD ?? '';
                    sessionStorage[envEmailKey] = Encrypto(email);
                    sessionStorage[envPasswordKey] = Encrypto(password);
                    dispatch(clearLoadingAction());
                    dispatch(push('/newRegistration/tempRegistSendMail'));
                } else {
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                        Email: setEmailErrMsg,
                        Pass: setPassErrMsg,
                    };
                    const errorFlg = ErrorSwitchFunctions(res.body, setMsgs);
                    dispatch(clearLoadingAction());
                    if(errorFlg.flg){
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorFlg.url));
                    }
                    return;
                }
            })
            .catch(err => {
                switch (err.errorType) {
                    case 'UsernameExistsException':
                        setEmailErrMsg(emailNotAvailable);
                        setPassErrMsg('');
                        break;
                    case 'InvalidPasswordException':
                        setEmailErrMsg('');
                        setPassErrMsg(passwordPolicyError);
                        break;
                    default:
                        console.log(err);
                        setEmailErrMsg(accountRegistFailureErrWord);
                        break;
                }
                dispatch(clearLoadingAction());
                return;
            }
            );
        //Cognitoユーザー登録処理ここまで
    }

    const snsType = () => {
        let snsType: string = '';
        let classname: string = '';
        if (getGoogleFlg(selector)) {
            snsType = 'Googleアカウントで作成';
            classname = 'google sns_text';
        }
        if (getTwitterFlg(selector)) {
            snsType = 'Twitterアカウントで作成';
            classname = 'twitter sns_text';
        }
        if (snsType) {
            return (
                <div className={classname}>{snsType}</div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const buttonAble = () => {
        if(developFlg){
            return (
                <div>
                    <button
                        type='submit'
                        id="login-button"
                        onClick={() => {
                            newRegistrationVerification();
                        }}
                        disabled={!email || !password || !token || !service || !privacy}
                    >{buttonName}</button>
                </div>
            )
        } else {
            return (
                <div>
                    <button
                        type='submit'
                        id="login-button"
                        onClick={() => {
                            newRegistrationVerification();
                        }}
                        disabled={!(emailError === '') || !(passwordError === '') || !email || !password ||!token || !service || !privacy}
                    >次へ</button>
                </div>
            )
        }
    }
    //画面表示処理ここから
    return (
        <div id="wrapper">
            <NewRegistrationHead />
            <Header />
            <div id="mail_temporary_registration" className="signup_bg">
                <main>
                    <div className="contents_in">
                        <div className="page_title_ptn01 white">
                            <h1>メールアドレス・パスワードを入力</h1>
                            <p className="first_text">TTS-IDログインに使用するメールアドレスとパスワードを入力ください。</p>
                        </div>
                        <div className="cnt_area">
                            <div className="box_r text_box_ptn01">
                                {snsType()}
                                <div className="form">
                                    <dl className="form_g">
                                        <dt className="form_text">メールアドレス</dt>
                                        <dd>
                                            <input type="text" placeholder="メールアドレスを入力" defaultValue={getMail(selector)} onChange={(event) => changedEmailHaldler(event, setEmail, setEmailError)} />
                                            <div className="form_error_text">
                                                {emailErrMsg ? emailErrMsg : emailError}
                                            </div>
                                        </dd>
                                        <dt className="form_text">パスワード</dt>
                                        <dd>
                                            <InputPassword
                                                passDisp={passDisp}
                                                changedPasswordHandler={(event) => changedPasswordHandler(event, setPassword, setPasswordError)}
                                                setPassDisp={setPassDisp} />
                                            <div className="kome">※8文字以上の大文字含む半角英数字</div>
                                            <div className="form_error_text">
                                                {passErrMsg ? passErrMsg : passwordError}
                                            </div>
                                        </dd>
                                    </dl>
                                    <SPConfirm serviceConfirm={service} setServiseConfirm={setService} privacyConfirm={privacy} setPrivacyConfirm={setPrivacy} serviceError={serviceError} setServiceError={setServiceError} privacyError={privacyError} setPrivacyError={setPrivacyError} />
                                    <div className='form_error_text'>{errMsg}</div>
                                    <div className="recaptcha">
                                        <GoogleRecaptcha />
                                    </div>
                                    <div className="btn_submit">
                                        {buttonAble()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
    //画面表示処理ここまで
}

export default NewRegistrationInput