const initialState = {
    users: {
        icon: '',
        isSignedIn: false,
        tts_id: '',
        google_flg: false,
        google_id: '',
        twitter_flg: false,
        twitter_id: '',
        idtoken: '',
        access_token: '',
        tf_mail: '',
        password: '',
        new_password: '',
        phone_number: '',
        tf_nickname: '',
        tf_birthday: '',
        tf_year: '',
        tf_month: '',
        tf_day: '',
        tf_gender: '',
        tf_native_lang: '',
        tf_native_lang_id: '0',
        tf_native_lang_value: '',
        tf_come_from: '',
        tf_continent: '',
        tf_continent_id: '0',
        tf_country: '',
        tf_country_id: '0',
        tf_country_flag: true,
        tf_area: '',
        tf_area_id: '0',
        tf_area_flag: true,
        select_disp_flg:false,
        set_disp_from: '',
        isModalOpen: false,
        isUpDate: false,
    },
    recaptcha: {
        token: '',
    },
    twitterToken: {
        oauth_token: '',
        oauth_verifier: '',
    },
    contact: {
        name: '',
        company: '',
        email: '',
        contactSelect: '',
        contactText: '',
        textCount: '0',
    },
    errors: {
        loginName: '',
        loginPassword: '',
        entryName: '',
        entryBirthday: '',
        entryGender: '',
        entryNativeLang: '',
        entryComeFrom: '',
        errorCode: '',
    },
    mfa: {
        url: '',
    },
    loading: {
        isLoading: false,
    },
    editing: {
        isEditing: false,
    },
    pageAct: {
        isModalOpen: false,
        isHanbOpen: false,
        isApiAction: false,
    }
};
export default initialState