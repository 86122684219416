import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Decrypto } from "../functions";
import { getLoginStatus } from "../Reduxs/Users/selectors";
import { accountPage } from "./accountPage";
import { FooterLinkExists, FooterLinkNone, FooterNav } from "./footerParts";
import { noLinksData } from "./noLinksData";

//フッター表示コンポーネント

const Footer = () => {
    const selector = useSelector((state) => state);
    //URL取得
    const URL = useLocation().pathname;

    // ログイン状況の確認
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const loginUser = SessionUserString ? Decrypto(SessionUserString) : null;

    // 初期値
    let footerClassName = '';
    let link = <></>;
    let nav = <></>;
    let path;
    if (loginUser) {
        path = '/top';
    } else {
        path = '/';
    }

    if (noLinksData.includes(URL)) {
        //リンクなしフッター
        footerClassName = 'no_links';
        link = <FooterLinkNone path={path} />;
    } else {
        //リンクありフッター
        link = <FooterLinkExists path={path}/>;
        nav = <FooterNav />;
    }

    return (
        <footer className={footerClassName}>
            <div className="contents_in center_contents">
                <div className="footer_top">
                    <div className="logo">{link}</div>
                    {nav}
                </div>
                <div className="copyright">Copyright 2021 TOPPAN TRAVEL SERVICE CORP. All Rights Reserved.</div>
            </div>
        </footer>
    )
}

export default Footer