import {useDispatch, useSelector} from 'react-redux';
import {getGoogleUserInfo} from '../../functions/API';
import {Loading, MoveOnryButton, TwitterGetOauthToken} from '../../functions';
import { getTwitterFlg } from '../../Reduxs/Users/selectors';
import { asyncDevAPI, asyncSnsChallenge, asyncTwitterAccessToken } from '../../functions/asyncFunctions';
import { push } from 'connected-react-router';
import { loginAction, setTwitterId, signUpAction } from '../../Reduxs/Users/actions';
import { clearLoadingAction, setLoadingAction } from '../../Reduxs/Loading/actions';
import { getIsLoading } from '../../Reduxs/Loading/selectors';
import { setErrorAction } from '../../Reduxs/Errors/actions';


const startAuth = 'AWSCognitoIdentityProviderService.InitiateAuth';
const responseAuth = 'AWSCognitoIdentityProviderService.RespondToAuthChallenge';

//Google認証後に遷移してくるページ

const CallBack = () => {
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    if(localStorage.getItem('twitterFlg')){
        localStorage.removeItem('twitterFlg');
        const twitterToken = Object(TwitterGetOauthToken());
        dispatch(setLoadingAction({
            isLoading: true,
        }));
        asyncTwitterAccessToken(twitterToken)
            .then((res:any) => {
                const twitterId = res.data.access_token.user_id;
                const devParams = {
                    apiname:'source_sns_ccode_get',
                    snsType: 'twitter_token',
                    snsId: twitterId
                }
                dispatch(setTwitterId({
                    twitter_id: '',
                    twitter_flg: true,
                }));
                asyncDevAPI(devParams)
                    .then((res:any) => {
                        if (res.body.status === 200) {
                            const username = res;
                            const startParams = {
                                AuthFlow: 'CUSTOM_AUTH',
                                ClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                                AuthParameters: {
                                    'USERNAME': username,
                                }
                            }
                            asyncSnsChallenge(startParams, startAuth)
                                .then((res: any) => {
                                    const responseParams = {
                                        ChallengeName: 'CUSTOM_CHALLENGE',
                                        ClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
                                        ChallengeResponses: {
                                            'USERNAME': res.ChallengeParameters.USERNAME,
                                            'ANSWER': 'twitter',
                                        },
                                        Session: res.Session,
                                    };
                                    asyncSnsChallenge(responseParams, responseAuth)
                                        .then((res: any) => {
                                            //ログイン処理
                                            dispatch(loginAction({
                                                tts_id: username,
                                                google_username: '',
                                                idtoken: '',
                                                access_token: '',
                                                tf_mail: '',
                                                password: '',
                                                phone_number: '',
                                                tf_nickname: '',
                                                tf_birthday: '',
                                                tf_gender: '',
                                                tf_native_lang: '',
                                                tf_come_from: '',
                                            }));
                                            dispatch(clearLoadingAction());
                                            dispatch(push('/top'));
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            //Cognitoに登録されていないので新規登録へ
                                            dispatch(setTwitterId({
                                                twitter_flg: true,
                                                twitter_id: twitterId,
                                            }));
                                            dispatch(clearLoadingAction());
                                            dispatch(push('/newRegistration/input'));
                                        });
                                })
                                .catch(err => {
                                    console.log(err);
                                    //Cognitoに登録されていないので新規登録へ
                                    dispatch(setTwitterId({
                                        twitter_flg: true,
                                        twitter_id: twitterId,
                                    }));
                                    dispatch(clearLoadingAction());
                                    dispatch(push('/newRegistration/input'));
                                });
                        } else {
                            dispatch(setErrorAction({
                                errorCode: res.body.status
                            }));
                            dispatch(push('/error'));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
                //TwitterRDS情報参照し、ページ振り分け
                //暫定的に新規登録ページへ遷移
                dispatch(clearLoadingAction());
                dispatch(push('/newRegistration/input'));
            })
            .catch( err => {
                //Twitterエラー取得
                dispatch(clearLoadingAction());
                console.log(err);
            }
        );
    }
    return (
        <div>
            {getIsLoading(selector) ? <Loading /> : ''}
            <p>認証が完了しました。</p>
        </div>
    );
}

export default CallBack