import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { useState } from "react"
import { useSelector } from "react-redux";
import { GetAttributeParts, getUserPool, Userupdate } from "../../../../functions";
import { getMail, getPassword } from "../../../../Reduxs/Users/selectors";

const TestPhoneNumber = () => {
    const selector = useSelector((state) => state);
    const [phone, setPhone] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [code, setCode] =useState<string>('');

    const updatePhone = () => {
        const attributeList: object[] = [];
        attributeList.push(GetAttributeParts('phone_number', phone));
        Userupdate(attributeList, getMail(selector), password);
    }

    const VerificationCode = () => {
        const cognitoUser = new CognitoUser({
            Username:  getMail(selector),
            Pool: getUserPool()
        });
        const authDetail = new AuthenticationDetails({
            Username: getMail(selector),
            Password: getPassword(selector),
        })
    
    
        cognitoUser.authenticateUser(authDetail, {
            onSuccess: () => {
                cognitoUser.verifyAttribute('phone_number', code, {
                    onSuccess: (res) => {
                        console.log(res);
                    },
                    onFailure: (err) => {
                        console.log(err);
                    }
                });
            },
            onFailure: () => {
                console.log('ng');
            }
        })
      }

    return (
        <div>
            <input type='text' placeholder='電話番号' onChange={(e:any) => setPhone('+81' + e.target.value.slice(1))} />
            <input type='password' placeholder='パスワード' onChange={(e:any) => setPassword(e.target.value)} />

            
            <input type='submit' value='電話番号追加' onClick={updatePhone} />

            <input type='text' onChange={(e:any) => setCode(e.target.value)} />
            <input type='submit' value='確認コード' onClick={VerificationCode} />
        </div>
    )
}

export default TestPhoneNumber