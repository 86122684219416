import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import TTS_ID_Log from '../assets/img/common/footer_logo.svg';
import { Decrypto } from '../functions';
import { push } from 'connected-react-router';

export const FooterNav = () => {
    const dispatch = useDispatch();

    // ログイン状況の確認
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const loginUser = SessionUserString ? Decrypto(SessionUserString) : null;

    // お問い合わせのURL取得
    const contact = loginUser ? '/account/contact' : '/contact';

    // 変更途中でページ遷移しようとした場合の警告用モーダル
    const isModal = (path) => {
        // 変更有無を確認
        let changeContent = false;
        if (
            sessionStorage.getItem('NickName')
            || sessionStorage.getItem('year')
            || sessionStorage.getItem('month')
            || sessionStorage.getItem('day')
            || sessionStorage.getItem('Gender')
            || sessionStorage.getItem('NativeLang')
            || sessionStorage.getItem('dispContinent')
            || sessionStorage.getItem('continentTmpId_CountryId')
            || sessionStorage.getItem('countryTmpId_AreaTmpId')
            // Myタグ
            || sessionStorage.getItem('Btd')
            || sessionStorage.getItem('CheckList')
            || sessionStorage.getItem('myTagEdit')
            // 通知
            || sessionStorage.getItem('ReceiveFlg')
            || sessionStorage.getItem('DestinationFlg')
            || sessionStorage.getItem('TagFlg')
            || sessionStorage.getItem('BReceiveFlg')
            || sessionStorage.getItem('BDestinationFlg')
            || sessionStorage.getItem('BTagFlg')
        ) {
            changeContent = true;
        }

        // 変更があった場合にモーダル表示フラグを立てる
        if (changeContent) {
            sessionStorage.setItem('isModal', '1');
            sessionStorage.setItem('NextPath', path);
            window.location.reload();
        } else {
            // window.location.href = path;
            if (path === contact) {
                dispatch(push(path));
            }
            window.open(path, '_blank');
        }
    }

    return (
        <nav>
            <ul className="footer_nav">
                <li><Link onClick={() => isModal(contact)}>お問い合わせ</Link></li>
                <li><a onClick={() => isModal('/company')} style={{cursor :'pointer'}}>運営会社</a></li>
                <li><a onClick={() => isModal('/privacyPolicy')} style={{cursor :'pointer'}}>プライバシーポリシー</a></li>
                <li><a onClick={() => isModal('/terms' )} style={{cursor :'pointer'}}>利用規約</a></li>
            </ul>
        </nav>
    )
}

export const FooterLinkExists = (props) => {
    const dispatch = useDispatch();

    // 変更途中でページ遷移しようとした場合の警告用モーダル
    const isModal = (path) => {
        // 変更有無を確認
        let changeContent = false;
        if (
            sessionStorage.getItem('NickName')
            || sessionStorage.getItem('year')
            || sessionStorage.getItem('month')
            || sessionStorage.getItem('day')
            || sessionStorage.getItem('Gender')
            || sessionStorage.getItem('NativeLang')
            || sessionStorage.getItem('dispContinent')
            || sessionStorage.getItem('continentTmpId_CountryId')
            || sessionStorage.getItem('countryTmpId_AreaTmpId')
            // Myタグ
            || sessionStorage.getItem('Btd')
            || sessionStorage.getItem('CheckList')
            || sessionStorage.getItem('myTagEdit')
            // 通知
            || sessionStorage.getItem('ReceiveFlg')
            || sessionStorage.getItem('DestinationFlg')
            || sessionStorage.getItem('TagFlg')
            || sessionStorage.getItem('BReceiveFlg')
            || sessionStorage.getItem('BDestinationFlg')
            || sessionStorage.getItem('BTagFlg')
        ) {
            changeContent = true;
        }

        // 変更があった場合にモーダル表示フラグを立てる
        if (changeContent) {
            sessionStorage.setItem('isModal', '1');
            sessionStorage.setItem('NextPath', path);
            window.location.reload();
        } else {
            dispatch(push(path));
        }
    }

    return (
        <Link onClick={() => isModal('/')} style={{cursor :'pointer'}}><img src={TTS_ID_Log} alt="ロゴ:TTS-ID" /></Link>
    )
}

export const FooterLinkNone = (props) => {
    return (
        <Link to={props.path}>
            <img src={TTS_ID_Log} alt="ロゴ:TTS-ID" />
        </Link>
    )
}