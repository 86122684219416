//利用規約ページ

import { Footer, Header, TermHead } from "../../htmlParts"
import termsContent from "./termsContent";

const Terms = () => {
    document.title = '利用規約|TTS-ID';
    return (
        <div>
            <TermHead />
            <Header />
            <div id="terms" className="public_page policy_page">
                <main>
                    <div className="page_contents">
                        <div className="contents_in">
                            <h1>TTS-ID 利用規約</h1>
                            {termsContent.map((section, index) => (
                                <div key={index}>
                                  <h2>{section.title}</h2>
                                  <p>{section.content}</p>
                                </div>
                            ))}
                            <p className="align_right">以上</p>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default Terms