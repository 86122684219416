import Router from './Router';
import {
    GDPR, Loading, UserLoginStatus, RefreshToken, ScrollToTop,
    GetNextPath, GetNowPath
} from './functions';
import { EditingModal } from './modal';
import { EditWarnModal } from './modal';

const App = () => {
    document.title = 'TTS-ID';
    UserLoginStatus();
    ScrollToTop();
    GetNowPath();

    return (
        <main>
            <Loading />
            <EditWarnModal />
            <Router />
            <GDPR />
        </main>
    )
}
export default App