import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changedPasswordHandler,
    failureChangePasswordErrWord,
    failureMailAuthErrWord,
    passwordDifferWord,
    asyncDevAPI,
    expiredCodeErrWord,
    limitExceededErrWord,
    passwordForgotSendMailErrWord,
    passwordForgotErrWord,
    changedCodeHandler,
    Decrypto,
    ErrorSwitchFunctions,
} from '../../../functions';
import { Footer, Header, InputPassword, PasswordForgetNewFormHead } from '../../../htmlParts';
import { clearLoadingAction, setLoadingAction } from '../../../Reduxs/Loading/actions';
import { getMail } from '../../../Reduxs/Users/selectors';
import logo02 from '../../../assets/img/common/logo02.png';
import { Link } from 'react-router-dom';
import { setErrorAction } from '../../../Reduxs/Errors/actions';

const ForgetNewPassword = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const fgma = sessionStorage.getItem('fgma');
    const email = fgma ? Decrypto(fgma) : null
    if (!email) {
        dispatch(push('/'));
    }
    const [code, setCode] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [passCheck, setCheckPass] = React.useState<string>('');

    const [codeError, setCodeError] = useState<string>('');

    const [newPasswordError, setNewPasswordError] = React.useState<string>('');
    const [passCheckError, setPassCheckError] = React.useState<string>('');
    const [errMsg, setErrMsg] = React.useState<string>('');
    const [passDisp, setPassDisp] = React.useState<boolean>(false);
    const [passCheckDisp, setPassCheckDisp] = React.useState<boolean>(false);

    const forgotPassword = () => {
        if (newPassword === passCheck) {
            // ローディング開始
            dispatch(setLoadingAction({ isLoading: true }));
            const params = {
                apiname: 'source_cognito_confirm_forgot_password_put',
                user_name: email,
                password: newPassword,
                confirmation_code: code,
            };
            asyncDevAPI(params)
                .then((res: any) => {
                    if (res.body.status === 200) {
                        // ローディング終了
                        dispatch(clearLoadingAction());
                        dispatch(push('/forgetComplete'));
                    } else {
                        // ローディング終了
                        const setMsgs = {
                            ErrMsg: setErrMsg,
                        }
                        const pageFlg = ErrorSwitchFunctions(res.body, setMsgs);
                        dispatch(clearLoadingAction());
                        if(pageFlg.flg){
                            dispatch(setErrorAction({
                                errorCode: res.body.status
                            }));
                            dispatch(push(pageFlg.url));
                        }
                    }
                })
                .catch(err => {
                    switch (err.errorType) {
                        case 'UserNotConfirmedException':
                            setErrMsg(failureMailAuthErrWord);
                            break;
                        case 'KeyError':
                        case 'ExpiredCodeException':
                        case 'CodeMismatchException':
                            setErrMsg(expiredCodeErrWord);
                            break;
                        default:
                            setErrMsg(failureChangePasswordErrWord);
                    }
                    // ローディング終了
                    dispatch(clearLoadingAction());
                });
        } else {
            // ローディング終了
            dispatch(clearLoadingAction());
            setErrMsg(passwordDifferWord);
        }
    }

    // 認証コード再送
    const resendCode = () => {
        // ローディング開始
        dispatch(setLoadingAction({ isLoading: true }));
        const params = {
            apiname: 'source_cognito_forgot_password_post',
            user_name: email,
        };
        asyncDevAPI(params)
            .then((res: any) => {
                const result: any = res;
                const statusCode = result['body']['status'];
                if (res.body.status !== 200) {
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
                // ローディング終了
                dispatch(clearLoadingAction());
            })
            .catch(err => {
                switch (err.errorType) {
                    case 'LimitExceededException':
                        setErrMsg(limitExceededErrWord);
                        break;
                    default:
                        setErrMsg(passwordForgotSendMailErrWord);
                        break;
                }
                // ローディング終了
                dispatch(clearLoadingAction());
            });
    }

    return (
        <div id="wrapper">
            <PasswordForgetNewFormHead />
            <Header />
            <div id="password_reset" className="signup_bg">
                <main>
                    <div className="contents_in">
                        <div className="page_title_ptn01 white">
                            <h1>新しいパスワードを設定</h1>
                            <div className="lead">
                                本人確認のために、入力いただいたメールアドレス宛に確認コードを送信しました。<br />
                                メールに記載の確認コードを入力し、新しいパスワードを入力ください。
                            </div>
                        </div>
                        <div className="cnt_area">
                            <div className="box_r text_box_ptn01">
                                <div className="lead">確認コードを送信したメールアドレス</div>
                                <div className="mail">{email}</div>
                                <div className="form">
                                    <dl className="form_g">
                                        <dt className="form_text">確認コード</dt>
                                        <dd>
                                            <input type='text' name='code' placeholder='6桁のコードを入力' maxLength={6} onChange={(e: any) => changedCodeHandler(e, setCode, setCodeError)} />
                                        </dd>
                                        <dt className="form_text">新しいパスワード</dt>
                                        <dd>
                                            <InputPassword
                                                passDisp={passDisp}
                                                changedPasswordHandler={(event) => changedPasswordHandler(event, setNewPassword, setNewPasswordError)}
                                                setPassDisp={setPassDisp} />
                                            <div className="form_error_text">{newPasswordError}</div>
                                        </dd>
                                        <dt className="form_text">新しいパスワードの確認</dt>
                                        <dd>
                                            <InputPassword
                                                passDisp={passCheckDisp}
                                                changedPasswordHandler={(event) => changedPasswordHandler(event, setCheckPass, setPassCheckError)}
                                                setPassDisp={setPassCheckDisp} />
                                            <div className="form_error_text">{passCheckError}</div>
                                            <div className="form_error_text">{errMsg}</div>
                                        </dd>
                                    </dl>
                                    <div className="btn_submit">
                                        <button
                                            disabled={
                                                !newPassword || !code || newPasswordError !== "" || passCheckError !== ''
                                            }
                                            onClick={forgotPassword}
                                        >新しいパスワードを設定</button>
                                    </div>
                                </div>
                                <div className="support_links">
                                    <div className="link">
                                        <Link to='/faq' className="link_ptn01">サポートページへ</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default ForgetNewPassword