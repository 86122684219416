import { useState } from 'react';
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import {
  getUserPool,
  changedCodeHandler,
  expiredCodeErrWord,
  asyncDevAPI,
  limitExceededErrWord,
  ErrorSwitchFunctions,
} from '../../../functions';
import { getMail, getPassword, getPhoneNumber　} from '../../../Reduxs/Users/selectors';
import { getLoginNameError　} from '../../../Reduxs/Errors/selectors';
import { Footer, Header, TempRegistrationHead } from '../../../htmlParts';
import { Link } from 'react-router-dom';
import { signUpAction } from '../../../Reduxs/Users/actions';
import { setErrorAction } from '../../../Reduxs/Errors/actions';
import {Cookies, useCookies} from 'react-cookie';
import cookie from 'cookie';

const TempRegistSendMail = () => {
  document.title = 'アカウント登録|TTS-ID';
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  sessionStorage.removeItem('verifyURL');

  const cookies = cookie.parse(document.cookie);
  cookies['CookieConsent'] = 'true';

  const newRegistTmp = sessionStorage.getItem('newRegistration');
  if(!newRegistTmp){
    window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
  }

  // state管理系宣言
  const [code, setCode] = useState<string>('');

  // バリデーション処理ここから
  const [codeError, setCodeErr] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');
  const [msg, setMsg] = useState<string>('');
  const [firstAccess, setFirstAccess] = useState<boolean>(true);

  if (getPhoneNumber(selector) === 'no_verification' && firstAccess) {
    setFirstAccess(false);
    const params = {
      apiname: 'source_cognito_resend_confirmation_code_get',
      user_name: getMail(selector),
    };
    asyncDevAPI(params)
      .then((res: any) => {
        if (res.body.status !== 200) {
          const setMsgs = {
            ErrMsg: setErrMsg,
        }
          const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
          if(errorPage.flg){
            dispatch(setErrorAction({
              errorCode: res.body.status
            }));
            dispatch(push(errorPage.url));
          }
        }
      })
      .catch(err => {
        const errorCode = err['code'];
        switch (errorCode) {
          case 'ExpiredCodeException':
            // 認証コードが期限切れ
            setErrMsg(expiredCodeErrWord);
            break;
          case "NotAuthorizedException":
            // 認証済みアカウント
            dispatch(push('/newRegistration/form'));
            break;
          case 'LimitExceededException':
            setErrMsg(limitExceededErrWord);
            break;
        }
        return
      }
    );
  }
  const verifyCode = () => {
    const params = {
      apiname: 'source_cognito_confirm_put',
      user_name: getMail(selector),
      confirmation_code: code,
    }
    asyncDevAPI(params)
      .then((res: any) => {
        if (res.body.status === 200) {

          dispatch(signUpAction({
            tts_id: res.body.response.ccode,
            tf_mail: getMail(selector),
            password: getPassword(selector),
            phone_number: '',
          }));
          setCode('')
          dispatch(push('/newRegistration/form'));

        } else {

          const setMsgs = {
            ErrMsg: setErrMsg,
        }
          const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
          if(errorPage.flg){
            dispatch(setErrorAction({
              errorCode: res.body.status
            }));
            dispatch(push(errorPage.url));
          }
        }
      })
      .catch(err => {
        const errorCode = err['code'];
        switch (errorCode) {
          case 'ExpiredCodeException':
            // 認証コードが期限切れ
            setErrMsg(expiredCodeErrWord);
            break;
          case "NotAuthorizedException":
            // 認証済みアカウント
            dispatch(push('/newRegistration/form'));
            break;
          case 'LimitExceededException':
            setErrMsg(limitExceededErrWord);
            break;
        }
        return
      })
  }

  const resendCode = () => {
    setMsg('');
    const params = {
      apiname: 'source_cognito_resend_confirmation_code_get',
      user_name: getMail(selector),
    };
    asyncDevAPI(params)
      .then((res: any) => {
        if (res.body.status === 200) {
          setMsg('コードを再送しました');
        } else {
          let setMsgs = {
            ErrMsg: setErrMsg,
          }
          const pageFlg = ErrorSwitchFunctions(res.body, setMsgs);
          if(pageFlg.flg){
            dispatch(setErrorAction({
              errorCode: res.body.status
            }));
            dispatch(push(pageFlg.url));
            return;
          }
        }
      })
      .catch(err => {
        const errorCode = err['code'];
        switch (errorCode) {
          case 'ExpiredCodeException':
            // 認証コードが期限切れ
            setErrMsg(expiredCodeErrWord);
            break;
          case "NotAuthorizedException":
            // 認証済みアカウント
            dispatch(push('/newRegistration/form'));
            break;
          case 'LimitExceededException':
            setErrMsg(limitExceededErrWord);
            break;
        }
        return
      }
      );
  }

  const verifyMessage = () => {
    if (getLoginNameError(selector) === 'UserNotConfirmedException') {
      return (
        <p className="first_text">
          メールアドレスの確認ができておりません。<br />
          お手数ですが、再度登録したメールアドレスを<br />
          ご確認頂き確認コードを入力してください。
        </p>
      )
    } else {
      return (
        <p className="first_text">
          入力いただいたメールアドレスが正しいことを確認するために、確認コードを送信しました。<br />
          メールに記載の確認コードを入力ください。
        </p>
      )
    }
  }
  const resendMsg = () => {
    if(msg){
      return (
        <div className="messagebox resend">
          {msg}
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  if(newRegistTmp){
    return (
      <div id="wrapper">
        <TempRegistrationHead />
        <Header />
  
        <div id="mail_send" className="signup_bg">
          <main>
            <div className="contents_in">
              <div className="page_title_ptn01 white">
                <h1 className="title blue">確認コードを入力</h1>
                {verifyMessage()}
              </div>
  
              <div className="text_box_ptn01 form_box_ptn02">
                <div className="lead">確認コードを送信したメールアドレス</div>
                <div className="form">
                  <div className="mail">{getMail(selector)}</div>
                  <dl className="form_g">
                    <dt className="form_text">確認コード</dt>
                    <dd>
                      <input
                        id='code'
                        type='text'
                        placeholder='6桁のコードを入力'
                        maxLength={6}
                        name='label'
                        onChange={(event) => changedCodeHandler(event, setCode, setCodeErr)}
                      />
                      <div className="form_error_text">{codeError ? codeError : errMsg}</div>
                    </dd>
                  </dl>
                  <div className="btn_submit">
                    <input
                      type='submit'
                      value='確認コードを確認'
                      onClick={verifyCode}
                      disabled={!(codeError === '') || !code}
                    />
                  </div>
                </div>
                <div className="support_links">
                  {resendMsg()}
                  <div className="link"><span className="link_ptn01" style={{cursor: 'pointer'}} onClick={resendCode}>確認コードを再送する</span></div>
                  <div className="link"><a className="link_ptn01" href="/faq">サポートページへ</a></div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    )
  } else {
    return (
      <></>
    )
  }
  
}

export default TempRegistSendMail
/*
const AutoResendCode = (email) => {
  const params = {
    apiname: 'source_cognito_resend_confirmation_code_get',
    user_name: email,
  };
  asyncDevAPI(params)
    .then((res: any) => {
      if (res.body.status !== 200) {
        console.log(res);
        dispatch(setErrorAction({
          errorCode: res.body.status
        }));
        dispatch(push('/error'));
      }
    })
    .catch(err => {
      const errorCode = err['code'];
      switch (errorCode) {
        case 'ExpiredCodeException':
          // 認証コードが期限切れ
          setErrMsg(expiredCodeErrWord);
          break;
        case "NotAuthorizedException":
          // 認証済みアカウント
          dispatch(push('/newRegistration/form'));
          break;
        case 'LimitExceededException':
          setErrMsg(limitExceededErrWord);
          break;
      }
      return
    }
  );
}
*/