import { useHistory, Link } from 'react-router-dom';
import png404 from '../assets/img/common/404.png';
import { Error404Head, Footer, Header } from "../htmlParts"

//404ページ
const NotFound = () => {

    document.title= '404|TTS-ID';

    const history = useHistory();
    let URL: string = '';
    const loginUser = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
    if (loginUser) {
        URL = '/top';
    } else {
        URL = '/';
    }
    return (
        <div id="wrapper">
            <Error404Head />
            <Header />
            <div id="error404">
                <main>
                    <div className="contents_in">
                        <div className="page_title_ptn01">
                            <h1>404 Not Found</h1>
                            <p className="first_text">お探しのページは見つかりませんでした</p>
                        </div>
                        <div className="cnt_img"><img src={png404} alt="Not found" /></div>
                        <p>大変申し訳ございませんが、<br className="sp" />お探しのページは、<br className="pc" />
                            削除されたか、<br className="sp" />ファイル名が間違っている可能性があります。<br />
                            アドレスを確認してください。</p>
                        <div className="btn_box">
                            <div className="btn_ptn02 btn"><Link onClick={() => history.goBack()}>前のページへ戻る</Link></div>
                            <div className="btn_ptn02 btn"><Link to={URL}>TTS-ID トップに戻る</Link></div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default NotFound