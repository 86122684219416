import React from 'react';
import {Link} from 'react-router-dom';
export const Info = () => {
/*
    ーテンプレートー
    date : 日時
    url : リンク
    body : 本文

    追加する際は配列の一番上にお願い致します。

    {
        date: '2021.06.12',
        body: 'お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。'
    },
*/
    const infos = [
        {
            date: '2021.06.12',
            body: 'お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。お知らせの本文がここに入ります。これはダミーです。'
        },
        {
            date: '2021.05.13',
            body: '昨日のメンテナンスについて。◯◯◯◯◯◯◯◯◯◯の機能を調整いたしました。'
        },
        {
            date: '2021.04.13',
            body: '本日20時より定期メンテナンスを行います。ご不便をお掛け致しますがよろしくお願いいたします。'
        },
        {
            date: '2021.03.13',
            body: '新しい機能を追加いたしました！◯◯◯◯◯◯◯◯◯◯を◯◯◯◯◯◯◯◯してください！'
        },
        {
            date: '2021.02.13',
            body: '新着情報の更新を行いました。'
        }
        ,
        {
            date: '2021.01.13',
            body: '本日TTS-IDがオープンしました！'
        }
    ];
    return (
        <dl>
            {infos.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <dd>{item.date}</dd>
                        <dt>{item.body}</dt>
                    </React.Fragment>
                )
            })}
        </dl>
    )
}