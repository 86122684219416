//サインインアクション
export const LOGIN = 'LOGIN';
export const loginAction = (userState) => {
    return {
        type: 'LOGIN',
        payload: {
            isSignedIn: true,
            tts_id: userState.tts_id,
            google_username: userState.google_username,
            idtoken: userState.idtoken,
            access_token: userState.access_token,
            tf_mail: userState.tf_mail,
            password: userState.password,
            phone_number: userState.phone_number,
            tf_nickname: userState.tf_nickname,
            tf_birthday: userState.tf_birthday,
            tf_gender: userState.tf_gender,
            tf_native_lang: userState.tf_native_lang,
            tf_come_from: userState.tf_come_from,
        }
    }
}
//ユーザー登録系アクション
export const SIGN_UP = 'SIGN_UP';
export const signUpAction = (userState) => {
    return {
        type: 'SIGN_UP',
        payload: {
            tts_id: userState.tts_id,
            tf_mail: userState.tf_mail,
            password: userState.password,
            phone_number: userState.phone_number,
        }
    }
}
export const UPDATE = 'UPDATE';
export const userUpdateAction = (userState) => {
    return {
        type: 'UPDATE',
        payload: {
            tf_mail: userState.tf_mail,
            phone_number: userState.phone_number,
            tf_nickname: userState.tf_nickname
        }
    }
}
export const UPDATE_MAIL_ADDRES = 'UPDATE_MAIL_ADDRES';
export const updateMailAddresAction = (userState) => {
    return {
        type: 'UPDATE_MAIL_ADDRES',
        payload: {
            tf_mail: userState.tf_mail
        }
    }
}
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const updatePasswordAction = (userState) => {
    return {
        type: 'UPDATE_PASSWORD',
        payload: {
            new_password: userState.new_password
        }
    }
}
//ユーザー登録詳細系アクション
export const ENTRY_FORM = 'ENTRY_FORM';
export const entryFormAction = (userState) => {
    return {
        type: 'ENTRY_FORM',
        payload: {
            tf_nickname: userState.tf_nickname,
            tf_birthday: userState.tf_birthday,
            tf_year: userState.b_year,
            tf_month: userState.b_month,
            tf_day: userState.b_day,
            tf_gender: userState.tf_gender,
            tf_native_lang: userState.tf_native_lang,
            tf_native_lang_id: userState.tf_native_lang_id,
            tf_native_lang_value: userState.tf_native_lang_value,
            tf_come_from: userState.tf_come_from,
            tf_continent: userState.tf_continent,
            tf_continent_id: userState.tf_continent_id,
            tf_country: userState.tf_country,
            tf_country_id: userState.tf_country_id,
            tf_country_flag: userState.tf_country_flag,
            tf_area: userState.tf_area,
            tf_area_id: userState.tf_area_id,
            tf_area_flag: userState.tf_area_flag,
            select_disp_flg: userState.select_disp_flg,
            set_disp_from: userState.set_disp_from,

        }
    }
}
//ユーザー情報をクリアするアクション
export const CLEAR_FORM = 'CLEAR_FORM';
export const clearFormAction = () => {
    return {
        type: 'CLEAR_FORM',
        payload: {
            tf_nickname: '',
            tf_birthday: '',
            tf_year: '',
            tf_month: '',
            tf_day: '',
            tf_gender: '',
            tf_native_lang: '',
            tf_native_lang_id: '',
            tf_native_lang_value: '',
            tf_come_from: '',
            tf_continent: '',
            tf_continent_id: '',
            tf_country: '',
            tf_country_id: '',
            tf_country_flag: '',
            tf_area: '',
            tf_area_id: '',
            tf_area_flag: '',
            select_disp_flg: '',
            set_disp_from: '',
            tf_mail: ''
        }
    }
}
export const TEST_ENTRY_FORM = 'TEST_ENTRY_FORM';
export const testEntryFormAction = (userState) => {
    return {
        type: 'ENTRY_FORM',
        payload: {
            idtoken: userState.idtoken,
            tf_mail : userState.tf_mail,
            tf_nickname: userState.tf_nickname,
            tf_birthday: userState.tf_birthday,
            tf_gender: userState.tf_gender,
            tf_native_lang: userState.tf_native_lang,
            tf_come_from: userState.tf_come_from,
        }
    }
}
//サインアウト用アクション
export const SIGN_OUT = 'SIGN_OUT';
export const signOutAction = () => {
    return {
        type: 'SIGN_OUT',
        payload: {
            icon: '',
            isSignedIn: false,
            idtoken: '',
            tf_mail: '',
            password: '',
            phone_number: '',
            tf_nickname: '',
            tf_birthday: '',
            tf_gender: '',
            tf_native_lang: '',
            tf_come_from: '',
        }
    }
}

export const PASSWORD_FORGET = 'PASSWORD_FORGET';
export const passwordForgetAction = (userState) => {
    return {
        type: 'PASSWORD_FORGET',
        payload: {
            tf_mail: userState.tf_mail,
            password: userState.password,
        }
    }
}

export const SET_GOOGLE_ID = 'SET_GOOGLE_ID';
export const setGoogleId = (userState) => {
    return {
        type: 'SET_GOOGLE_ID',
        payload: {
            tf_mail: userState.tf_mail,
            google_id: userState.google_id,
            google_flg: true,
        }
    }
}

export const SET_TWITTER_ID = 'SET_TWITTER_ID';
export const setTwitterId = (userState) => {
    return {
        type: 'SET_TWITTER_ID',
        payload: {
            twitter_id: userState.twitter_id,
            twitter_flg: true,
        }
    }
}

export const MODAL_OPEN = 'MODAL_OPEN';
export const modalOpenAction = () => {
    return {
        type: 'MODAL_OPEN',
        payload: {
            isModalOpen: true,
        }
    }
}

export const MODAL_CLOSE = 'MODAL_CLOSE';
export const modalCloseAction = () => {
    return {
        type: 'MODAL_CLOSE',
        payload: {
            isModalOpen: false,
        }
    }
}

export const UPDATE_FLG = 'UPDATE_FLG';
export const updateFlgAction = () => {
    return {
        type: 'UPDATE_FLG',
        payload: {
            isUpDate: true,
        }
    }
}

export const UPDATE_FLG_CLEAR = 'UPDATE_FLG_CLEAR';
export const updateFlgClearAction = () => {
    return {
        type: 'UPDATE_FLG_CLEAR',
        payload: {
            isUpDate: false,
        }
    }
}

export const SET_NICKNAME = 'SET_NICKNAME';
export const setNicknameAction = (userState) => {
    return {
        type: 'SET_NICKNAME',
        payload: {
            tf_nickname: userState.tf_nickname,
        }
    }
}

export const SET_FROM_ACTION = 'SET_FROM_ACTION';
export const setFromAction = (userState) => {
    return {
        type: 'SET_FROM_ACTION',
        payload: {
            select_disp_flg: userState.select_disp_flg,
            set_disp_from: userState.set_disp_from,
        }
    }
}