import React, { useState } from 'react';
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import {
  limitExceededErrWord,
  Decrypto,
  codeMismatchErrWord,
  asyncDevAPI,
  ErrorSwitchFunctions,
  changedCodeHandler
} from '../../../../functions';
import { useHistory, Link } from 'react-router-dom';
import { EmailCheckHead, Footer, Header } from '../../../../htmlParts';
import illust01_account from '../../../../assets/img/setting/illust01_account.png';
import { setErrorAction } from '../../../../Reduxs/Errors/actions';

const MailVerificationCheck = () => {

  document.title = 'メールアドレス変更|TTS-ID';

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const history = useHistory();

  const [code, setCode] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');
  const [codeError, setCodeError] = useState<string>('');
  const [msg, setMsg] = useState<string>('');

  // アクセストークのチェック
  const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
  const SessionUserString = sessionStorage.getItem(envStorageKey);
  const sessionUser = SessionUserString ? Decrypto(SessionUserString) : null;
  const loginUser = JSON.parse(String(sessionUser));
  // SessionStorageからメールアドレス、パスワードを取得し複合
  const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
  const encryptoEmail = sessionStorage.getItem(envEmailKey);
  const SessionEmail = encryptoEmail ? Decrypto(sessionStorage.getItem(envEmailKey)) : null;

  // SessionStorageからアクセストークンを取得
  const SessionAccessToken = loginUser.accessToken;

  const VerificationCode = () => {
    const apiParams = {
      apiname: 'source_cognito_verify_user_attribute_put',
      AccessToken: SessionAccessToken,
      confirmation_code: code,
      type: 'email'
    };

    // メールアドレス変更
    asyncDevAPI(apiParams)
      .then(res => {
        const result: any = res;
        const statusCode = result['body']['status'];

        if (statusCode === 200) {
          // メールアドレス変更完了画面に遷移
          dispatch(push('/account/security/emailChange/mailComplete'));
        } else {
          const setMsgs = {
            ErrMsg: setErrMsg,
          }
          const errorPage = ErrorSwitchFunctions(result.body, setMsgs);
          if(errorPage.flg){
            dispatch(setErrorAction({
              errorCode: statusCode
            }));
            dispatch(push(errorPage.url));
          }
        }
      })
      .catch(err => {
        switch (err.errorType) {
          case 'LimitExceededException':
            setErrMsg(limitExceededErrWord);
            break;
          case "CodeMismatchException":
          case "ExpiredCodeException":
            setErrMsg(codeMismatchErrWord);
            break;
          default:
            console.log(err);
            setErrMsg('メールアドレス変更に失敗しました。お手数ですがもう一度お試しください。');
        }
      })
  }

  const resendCode = () => {
    setMsg('');
    // SessionStorageからアクセストークンを取得
    const SessionAccessToken = loginUser.accessToken;

    const apiParams = {
      apiname: 'source_cognito_verify_user_attribute_get',
      AccessToken: SessionAccessToken,
      type: 'email',
    };

    // 確認コード再送
    asyncDevAPI(apiParams)
      .then(res => {
        // 再送した場合は、バリデーションメッセージをクリア
        setErrMsg('');
        const result: any = res;
        const statusCode = result['body']['status'];

        if (statusCode === 200) {
          // メールアドレス変更完了画面に遷移
          setMsg('メールアドレスに確認コードを再送しました。');
        } else {
          dispatch(setErrorAction({
            errorCode: statusCode
          }));
          dispatch(push('/error'));
        }
      })
      .catch(err => {
        console.log('NG:確認コード再送(確認コード再送API)でエラー エラー内容: ' + JSON.stringify(err));
      }
    )
  }
  const resendMsg = () => {
    if(msg){
      return (
        <div className="messagebox resend">
          {msg}
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  if (loginUser) {
    // ログイン後の画面
    return (
      <div id="wrapper">
        <EmailCheckHead />
        <Header />
        <div id="code" className="mail_change">
          <main>
            <div className="decoration_img"><img src={illust01_account} alt="人のイラスト" /></div>
            <div className="contents_in">
              <div className="back"><Link onClick={() => history.goBack()}>&lt; 戻る</Link></div>
              <div className="text_box_ptn01 form_box_ptn02">
                <h1 className="title">確認コードの確認</h1>
                <div className="lead">
                  入力いただいたメールアドレスが正しいことを確認するために、確認コードを送信しました。<br />
                  メールに記載の確認コードを入力ください。
                </div>
                <div className="mail">{SessionEmail}</div>
                <div className="form">
                  <dl className="form_g">
                    <dt className="form_text">確認コード</dt>
                    <dd>
                      <input type='text' name='label' placeholder="6桁の確認コードを入力" maxLength={6} onChange={(e: any) => changedCodeHandler(e, setCode, setCodeError)} />
                      <div className="form_error_text">
                        {codeError}
                        {codeError ? <br /> : ''}
                        {errMsg}
                      </div>
                    </dd>
                  </dl>
                  <div className="btn_submit blue">
                    <button
                      disabled={
                        !code ||
                        !(codeError === '')
                      }
                      onClick={VerificationCode}
                    >確認コードを送信</button>
                  </div>
                </div>
                <div className="support_links">
                  {resendMsg()}
                  <div className="link"><span className="link_ptn01" style={{ cursor: 'pointer' }} onClick={resendCode}>コードを再送する</span></div>
                  <div className="link"><a className="link_ptn01" href="/faq">サポートページへ</a></div>
                </div>

              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    )
  } else {
    // ログイン前は何も表示させない
    return (
      <></>
    )
  }
}

export default MailVerificationCheck