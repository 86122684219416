import { MoveOnryButton, testGeolocation} from '../functions';
import {Footer, Header, TopHead} from '../htmlParts';
import illust01 from '../assets/img/top/illust01.png';
import illust02 from '../assets/img/top/illust02.png';
import illust03 from '../assets/img/top/illust03.png';
import illust04 from '../assets/img/top/illust04.png';
import pic1_top from '../assets/img/top/pic01_top.png';
import pic2_top from '../assets/img/top/pic02_top.png';
import pic01_top_jpg from '../assets/img/top/pic01_top.jpg';
import pic01_top from '../assets/img/top/pic01_top.png';
import pic02_top from '../assets/img/top/pic02_top.png';
import pic03_top from '../assets/img/top/pic03_top.png';
import pic04_top from '../assets/img/top/pic04_top.png';
import pic05_top from '../assets/img/top/pic05_top.png';
import pic06_top from '../assets/img/top/pic06_top.png';
import logo01 from '../assets/img/top/logo01.png';
import logo02 from '../assets/img/top/logo02.png';
import pic01_top_sp from '../assets/img/top/pic01_top_sp.jpg';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PasswordCount from '../functions/PasswordCount';

//ログイン前トップページ
const Top = () => {
    const dispatch = useDispatch();
    document.title = 'TTS-ID - あなたの出張に新しい体験をプラスする';
    
    return (
        <div id="wrapper">
            <TopHead />
            <Header />
            <div id="top">
                <main>
                    <div className="bg">
                        <div className="cnt_area00 cnt_area">
                            <div className="contents_in">
                                <div className="lead">TTS-IDであなたの出張に新しい体験をプラスする<br />様々なサービスが利用できます。</div>
                                <div className="btn_ptn01">
                                    <MoveOnryButton buttonName={'無料でTTS-IDに登録する'} path={'/newRegistration'} />
                                </div>
                            </div>
                        </div>
                        <div className="cnt_area01 cnt_area">
                            <div className="contents_in">
                                <div className="cnt_left">
                                    <div className="title">TTS-IDとは</div>
                                    <div className="body">
                                        <p>
                                            トッパントラベルサービスが提供するビジネスでの旅行を快適にする複数のサービスをひとつのIDで利用できるようになるサービスです。<br /><br />
                                            旅券の手配や出張者の管理、出張に向けた準備や、現地での急なトラブルなど、<br />
                                            これまで煩雑だった国内外の出張をより楽しく、よりスマートに行えるようにとの想いから誕生しました。<br /><br />
                                            出張管理の最適化から、国内外の視察や研修旅行の手助け、喜ばれるお土産や出張先での国別のお役立ち情報のピックアップまであなたの出張に新しい発見をあたえます。TTS-IDを使えばトッパントラベルサービスが提携するサービスをひとつに連携して利用することができます。
                                        </p>
                                    </div>
                                    <div className="btn_ptn01 pc">
                                        <MoveOnryButton buttonName={'今すぐ使ってみる'} path={'/newRegistration'} />
                                    </div>
                                </div>
                                <div className="cnt_right">
                                    <img src={pic01_top_jpg} alt="画像:スーツケースを持って歩く男女" className="pc" />
                                    <img src={pic01_top_sp} alt="画像:スーツケースを持って歩く男女" className="sp" />
                                </div>
                                <div className="btn_ptn01 sp">
                                    <MoveOnryButton buttonName={'今すぐ使ってみる'} path={'/newRegistration'} />
                                </div>
                                <div className="cnt_illust02">
                                    <img src={illust02} alt="イラスト:パソコンを打つ女性" />
                                </div>
                            </div>
                        </div>

                        {/* ニュースここから */}
                        
                        <div className="cnt_news">
                            <div className="contents_in">
                                {/*
                                <div className="news_box">
                                    <div className="date">2021.04.13</div>
                                    <div className="news_text"><a href="">TTS-IDのサービスを開始しました。</a></div>
                                </div>
                                <div className="news_box">
                                    <div className="date">2021.03.30</div>
                                    <div className="news_text"><a href="">TTS-IDのサービスを開始しました。TTS-IDのサービスを開始しました。TTS-IDのサービスを開始しました。TTS-IDのサービスを開始しました。</a></div>
                                </div>
                                <div className="news_box">
                                    <div className="date">2021.03.30</div>
                                    <div className="news_text"><a href="">TTS-IDのサービスを開始しました。TTS-IDのサービスを開始しました。TTS-IDのサービスを開始しました。TTS-IDのサービスを開始しました。</a></div>
                                </div>
                                */}
                            </div>
                        </div>
                        {/* ニュースここまで */}
                        <div className="cnt_area02 cnt_area">
                            <div className="contents_in">
                                <div className="title">サービスを連携して<br className="sp" />出張に新しい<br />体験をプラスする</div>
                                <div className="flex_box">
                                    <div className="flex_in01">
                                        <div className="sub_title">ビジネスマン向け(裏技)<br />情報発信サイト</div>
                                        <div className="cnt_logo01"><img src={logo01} alt="ロゴ:BTHacks" /></div>
                                        <div className="lead"><span>連携すると</span> 海外・国内出張に役立つ<br />様々な情報が届く</div>
                                        <ul>
                                            <li>ラウンジ情報</li>
                                            <li>レストラン情報</li>
                                            <li>お土産おすすめ</li>
                                            <li>出張便利グッズ</li>
                                            <li>喫煙所の場所</li>
                                            <li>異文化マナー</li>
                                        </ul>
                                    </div>
                                    <div className="flex_in02">
                                        <div className="sub_title">出張管理システム<br />「Gra-Pe(グレープ)」</div>
                                        <div className="cnt_logo02"><img src={logo02} alt="ロゴ:Gra-Pe" /></div>
                                        <div className="lead"><span>連携すると</span> 今後の出張予定情報や、<br className="sp" />過去の出張履歴などの<br />出張データがいつでも確認できる</div>
                                        <ul>
                                            <li>航空チケット予約</li>
                                            <li>現地リスク情報</li>
                                            <li>宿泊先予約</li>
                                            <li>行動傾向のデータ化</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="cnt_illust01"><img src={illust01} alt="イラスト:タクシーと人間" /></div>
                            </div>
                        </div>
                        <div className="cnt_area03">
                            <div className="contents_in">
                                <div className="title">今後もさらに<br className="sp" />連携サービスは<br />追加されていきます。</div>
                                <div className="cnt_illust03"><img src={illust04} alt="イラスト" /></div>
                                <div className="flex_box">
                                    <div className="round"><img src={pic03_top} alt="MICE事業" /></div>
                                    <div className="round"><img src={pic04_top} alt="海外人事" /></div>
                                    <div className="round"><img src={pic05_top} alt="コミュニティ" /></div>
                                </div>
                                <div className="body">今後サービスが増えていくことで、出張だけでなく、イベントやコンベンションの開催情報や、長期滞在、海外赴任者向けのサポート、コミュニティ内でのコミュニケーションの場の提供など随時あたらしいサービス体験を提供してまいります。</div>
                            </div>
                        </div>
                        <div className="cnt_area04 cnt_area">
                            <div className="contents_in">
                                <div className="title"><img src={pic06_top} alt="旅行からウェルビーイングへ" /></div>
                                <div className="body"><p>さまざまな場所を訪れ、見聞きし、触れることができる旅行という特別な体験を<br />誰もが快適で安全に、そして何より手軽に楽しむことができるようにし、<br />世の中の人々に満ち足りた日々を過ごしてもらいたい。<br />TTS-IDは旅行を通じたウェルビーイングの提供を目指しています。</p></div>
                                <div className="sub_title">TTS-IDを通じて世界が<br className="sp" />広がる体験を。<br />今すぐはじめましょう。</div>
                                <div className="btn_ptn01"><MoveOnryButton buttonName={'今すぐ使ってみる'} path={'/newRegistration'} /></div>
                                <div className="cnt_illusto"></div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default Top