import {useState} from 'react';
import {Link} from 'react-router-dom';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Userupdate} from '../functions';
import {getMail, getPassword} from '../Reduxs/Users/selectors';
import {loginAction, setGoogleId, setNicknameAction, setTwitterId, signOutAction, signUpAction} from '../Reduxs/Users/actions';
import {GetAttributeParts} from './GetAttributeParts';
import {GoogleLogin} from 'react-google-login';
import { asyncDevAPI, asyncSnsChallenge, asyncTwitterAPI } from './asyncFunctions';
import { clearLoadingAction, setLoadingAction } from '../Reduxs/Loading/actions';
import {encode} from 'js-base64';
import { useLocation } from "react-router-dom";
import { Encrypto } from './Crypto';
import { GetTwitterData, GoogleLoginAPI, TwitterLoginAPI } from './APIActions';

/*
 *各種簡単なボタンここから
 *画面遷移だけのボタンbuttonNameでボタンに表示する文字、pathで遷移先のパスを指定
 */
const startAuth = 'AWSCognitoIdentityProviderService.InitiateAuth';
const responseAuth = 'AWSCognitoIdentityProviderService.RespondToAuthChallenge';

export const MoveOnryButton = (props) => {
    const dispatch = useDispatch();
    return (
            <Link to={props.path} /*onClick={() => dispatch(push(props.path))}*/>{props.buttonName}</Link>
    )
}
/*
 *ログアウトボタン
 */
export const SignOutButton = () => {
    const dispatch = useDispatch();
    const SignOut = () => {
        dispatch(signOutAction());
        sessionStorage.clear();
        localStorage.Login = false;
        localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);//確認後不要ならコメントアウトまたは削除
        window.location.href = '/';
      }
    return (
            <Link to='' onClick={() => SignOut()}>
                ログアウト
            </Link>
    )
}

/*
 * 退会ボタン
 */
export const DisableUserButton = (props) => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const attributeList: object[] = [];
    attributeList.push(GetAttributeParts('custom:status', 'disable'));

    const changeUserStatus = () => {
        sessionStorage.clear();
        signOutAction();
        Userupdate(attributeList, getMail(selector), getPassword(selector));
        dispatch(push(props.nextUrl));
    }

    return (
        <div>
            <button onClick={() => changeUserStatus()}>
                {props.buttonName}
            </button>
        </div>
    )
}
export const AbledUserButton = (props) => {
    const [email, setEmail] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const dispatch = useDispatch();
    const attributeList: object[] = [];
    attributeList.push(GetAttributeParts('custom:status', 'able'));

    const changeUserStatus = () => {
        Userupdate(attributeList, email, pass);
        dispatch(push(props.nextUrl));
    }

    return (
        <div>
            <input type='text' placeholder='メールアドレス' onChange={(e:any) => setEmail(e.target.value)} />
            <input type='text' placeholder='パスワード' onChange={(e:any) => setPass(e.target.value)} />
            <button onClick={() => changeUserStatus()}>
                {props.buttonName}
            </button>
        </div>
    )
}
//各種簡単なボタンここまで

/*
 *Twitter認証用ボタンここから
 */
export const TwitterLoginButton = (props) => {
    const dispatch = useDispatch();
    const URL = useLocation().pathname;
    const twitterLogin = () => {
        GetTwitterData(URL, dispatch);
    }
    return (
            <a style={{cursor: "pointer"}} onClick={() => twitterLogin()}>
                Twitterアカウントで{props.word}
            </a>
    );
}
/*
 *Googleログイン用ボタン。レスポンスからAPIで画面の遷移先を切り替えて新規登録と併用想定
 */
export const GoogleLoginButton = (props) => {
    const dispatch = useDispatch();
    const responseSuccess = (res:any) =>{
        const email = res.profileObj.email;
        const googleId = res.profileObj.googleId;
        GoogleLoginAPI(googleId, email, dispatch);
    }
    const responseFailure = () => {
        console.log('Google認証が失敗しました。');
    }
    return (
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={renderProps => (
                    <a style={{cursor: "pointer"}} target='_self' onClick={renderProps.onClick}>Googleアカウントで{props.word}</a>
                )}
                onSuccess={responseSuccess}
                onFailure={responseFailure}
                cookiePolicy={'single_host_origin'}
            />
    )
}