//TwitterのOauthトークン取得用コンポーネント

export const TwitterGetOauthToken = () => {

    let urlParams = window.location.search;

    if (urlParams) {
      //?を除去
      urlParams = urlParams.substring(1)

      let oauth_token = '';
      let oauth_verifier = '';

  
      //urlパラメータをオブジェクトにまとめる
      urlParams.split('&').forEach( param => {
        const temp = param.split('=')
        if (temp[0] === 'oauth_token'){
          oauth_token = temp[1];
        }
        if (temp[0] === 'oauth_verifier'){
          oauth_verifier = temp[1];
        }
      })
      
      return {oauth_token: oauth_token, oauth_verifier: oauth_verifier};
    }
}