//正規表現での入力チェック用のコンポーネント
//認証系エラー文言
export const accountRegistFailureErrWord = '新規アカウント登録に失敗しました。お手数ですが、初めからやり直してください。'
export const loginErrorWord = 'このメールアドレスとパスワードの組み合わせではログインができません。入力内容をお確かめの上、再度ご入力ください。';
export const userAuthErrWord = 'ユーザー認証に失敗しました。お手数ですが、初めからやり直してください。';
export const limitExceededErrWord = '試行制限を超えました。しばらくしてから、再度お試してください。';
export const failureMailAuthErrWord = 'メールの認証が確認できておりません。';
export const deletedAccountErrWord = 'すでに削除されたアカウントです。';

//メールアドレス系エラー文言
export const emailFormatDifferent = 'メールアドレスの形式が正しくありません。';
export const emailNotEntered = 'メールアドレスを入力してください';
export const emailNotAvailable = 'このメールアドレスは利用できません。';
export const emailNotValid = 'このメールアドレスは有効ではありません。';
export const noChangedEmailAddressErrWord = 'すでに登録されているメールアドレスには変更できません。別のメールアドレスを指定してください。';
export const usernameExistsErrWord = 'このメールアドレスでは登録できません。別のメールアドレスを指定してください。';
export const passwordForgotSendMailErrWord = 'メールアドレスの送信に失敗しました。お手数ですが、初めからやり直してください。';
export const passwordForgotErrWord = 'このメールアドレスではパスワードの変更ができません。別のメールアドレスを指定してください。';

//パスワード系エラー文言
// export const passwordErrorWord = 'パスワードは大文字を一つ以上含めて8文字以上256文字以内で入力してください。';
export const passwordErrorWord = 'パスワードは半角アルファベット大文字、半角アルファベット小文字、半角数字を含んだ内容で入力ください。';
export const passwordNotEntered = 'パスワードを入力してください。';
export const passwordCountError = 'パスワードは8文字以上で設定してください。';
export const passwordDifferWord = 'パスワードが一致しませんでした。もう一度お試しください。';
export const passwordNotAuthWord = 'パスワードの認証に失敗しました。お手数ですがもう一度お試しください。';
export const passwordFailureWord = 'パスワードの変更に失敗しました。お手数ですが、初めからやり直してください。';
export const noChangePasswordErrWord = 'すでに設定済みのパスワードです。別のパスワードを指定してください。';
export const failureChangePasswordErrWord = 'パスワードの変更ができませんでした。お手数ですが、初めからやり直して下さい。';
export const passwordPolicyError = 'パスワードは半角英字の大文字小文字、半角数字を含んだ8文字以上で入力してください。';
export const notExistUserErrWord = '存在しないユーザーです。';

//確認コード系エラー文言
export const notSendCodeMailWord = '確認コードの送信に失敗しました。';
export const codeMismatchErrWord = '無効な確認コードが送信されました。確認コードをご確認の上、もう一度お試しください。';
export const expiredCodeErrWord = '無効な確認コードです。確認コードをご確認の上、もう一度お試しください。';
export const codeErrorWord = '確認コードは6桁の数字で入力してください。';
export const sCodeErrorWord = 'セキュリティコードは6桁の数字で入力してください。';
export const codeNotVerifyWord = '確認コードが違います。再度入力してください。';
export const securityCodeNotVerifyWord = 'セキュリティコードが違います。再度入力してください。';

//アカウント名エラー文言
export const nicknameCheck = /^$/;
export const nicknameErrorWord = 'お名前名は50文字以内で入力してください。';
export const nicknameNullErrWord = 'お名前名を入力してください。';

//生年月日エラー文言
export const birthdayFErrWord = '生年月日を未来で登録することはできません。';
export const birthdayErrWord = '生年月日を正しい形で入力してください。';

//リキャプチャエラー
export const reChaptchaErrWord = 'リキャプチャが確認されておりません。';

//システムエラー系エラー文言
export const cognitoErrorWord = '認証システムでエラーが発生しました。しばらくしてから再度お試しください。';
export const cognitoEmailIdNotAuthWord = '認証システムで権限エラーが発生しました。';
export const lambdaResponseErrorWord = 'サーバーから無効な応答を受け取りました。';
export const paramErrorWord = '無効なパラメータを取得しました。';
export const resourceNotFoundWord = 'エラーが発生しました。';
export const manyRequestWord = '試行回数制限が多すぎます。';
export const unexpectedLambdaWord = 'サーバーで予期しないエラーが発生しました。';
export const userNotFoundWord = 'ユーザーが見つかりません。';
export const userNotConfirmWord = 'ユーザー確認でエラーが発生しました。';

export const emailCheck = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,256}$/;
//export const emailCheck = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*{1,256}$/
export const phoneNumberCheckHead = /^0/;
export const phoneNumberCheckNoHyphen = /^[789]0[0-9]{4}[0-9]{4}$/;
export const phoneNumberCheckHyphen = /^[789]0-[0-9]{4}-[0-9]{4}$/;
export const phoneNumberCheckSpace = /^[789]0 [0-9]{4} [0-9]{4}$/;
export const countryPhoneNumberCheck = /^\+[0-9]{1,6}$/;
// export const passwordCheck = /^(?=.*[A-Z])[a-zA-Z0-9.?/-]{8,256}$/; //元々のバリデーション
// export const passwordCheck = /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i; // 記号も含むパターン
export const passwordCheck = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!-~]{8,256}$/;
export const codeCheck = /^[0-9]{6}$/;
export const dateCheck = {
    year: /^1[9][2-9][0-9]|20[012][0-9]$/,
    month: /^(0?[1-9]|1[0-2])$/,
    day: /^(0?[1-9]|[12][0-9]|3[01])$/
}
export const countryNum = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

export const numCheck = /^[0-9]+$/;

export const isDate = (year, month, day) => {
    if (year === '' || month === '' || day === ''){
        return false;
    }
    /*
     * 日付変換された日付が入力値と同じ事を確認
     * new Date()の引数に不正な日付が入力された場合、相当する日付に変換されてしまうため
    */ 
    var date = new Date(year + '/' + month + '/' + day);
    if(date.getFullYear() !==  year
        || date.getMonth() + 1 !== month
        || date.getDate() !== day
    ){
        return false;
    }

    return true;
}