import {useDispatch, useSelector} from 'react-redux';
import { Footer, Header, LoginTopHead } from '../../../htmlParts';
import btHacksIcon from '../../../assets/img/common/bthacks-icon.png';
import pic06 from '../../../assets/img/login_top/pic06.jpg';
import pic07 from '../../../assets/img/login_top/pic07.jpg';
import pic08 from '../../../assets/img/login_top/pic08.jpg';
import { FirstLogin } from '../../../modal';
import { useEffect, useState } from 'react';
import { Decrypto, MoveOnryButton } from '../../../functions';
import { clearFormAction } from '../../../Reduxs/Users/actions';
import { getNickName } from '../../../Reduxs/Users/selectors';
import {Helmet} from "react-helmet";

//ログイン後トップページ

const Top = () => {
    //document.title = 'TTS-ID';
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const isLogined = localStorage.getItem('isLogin');

    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';

    let user_btd_tmp;
    let user_btd_tmp_id;
    let user_interest_tmp:string[] = [];
    let user_interest_tmp_id:number[] = [];
    let before_interest:number[] = [];
    const [getNewAFlg, setGetNewAFlg] = useState<boolean>(true);
    const [visible, setVisible] = useState<boolean>(!isLogined ? true : false);
    const [hanVis, setHanVis] = useState<boolean>(false);

    //アクセストークの有無チェック
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const loginUser = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUserJson = loginUser ? JSON.parse(loginUser) : null;
    const interestEncString = sessionStorage.getItem('interest');
    const interestDecString = interestEncString ? Decrypto(interestEncString) : null;
    const interestJson = interestDecString ? JSON.parse(interestDecString) : null;

    let articles = sessionStorage.getItem('articles');
    const articlesObj = articles ? JSON.parse(articles) : null;
    const newArticles = articlesObj ? JSON.parse(articlesObj.newArticle) : null;
    const myArticles = articlesObj ? JSON.parse(articlesObj.myArticle) : null;

    let newArticle = [];
    let myArticle = [];
    if(newArticles){
        newArticle = newArticles.new_Article;
    }
    if(myArticles){
        myArticle = myArticles.mytag_article;
    }
    
    const sessionUrpj = sessionStorage.getItem('urpj');

    const urpj = sessionUrpj ? Decrypto(sessionUrpj) : '';
    const r_user = urpj ? JSON.parse(urpj) : '';
    const m_code = r_user.m_code ? r_user.m_code : null;

    if(m_code){
        m_code.map((item) => {
            switch(item.atb){
                case 1:
                    user_btd_tmp = item.svalue;
                    user_btd_tmp_id = item.mid;
                    break;
                case 2:
                    user_interest_tmp.push(item.svalue);
                    user_interest_tmp_id.push(item.mid);
                    before_interest.push(item.mid);
                    break;
                default:
                    break;
            }
        });
    }

    const changedHanberger = () => {
        setHanVis(!hanVis);
    }

    const tagViewSwitch = () => {
        if(user_interest_tmp.length > 0){
            return(
                <div className="g01 cnt_g">
                    <div className="title_area">
                        <h2 className="box_title">興味のあるキーワードの情報</h2>
                        <div className="link"><MoveOnryButton buttonName={'キーワードを編集'} path={'/account/user'} /></div>
                    </div>
                    {myArticle.map((item:any, index) => {
                        return (
                            <div className="cnt_box" key={index}>
                            <a href={item.link} target="_blank" rel="noreferrer">
                                <div className={item.new_article ? "cnt_img new" : "cnt_img"}><img src={item.img !== '' ? item.img : "https://placehold.jp/ababab/ffffff/300x200.jpg?text=No%20Images"} alt="" /></div>
                                <div className="data">
                                    <ul>
                                        <li className="tag" key={index}>
                                            {Object.keys(item.category).map((key:any, index) => {
                                                return (
                                                    <span key={index}>{item.category[key].svalue}</span>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                    <h3>{item.title}</h3>
                                </div>
                            </a>
                        </div>
                        )
                    })}
                </div>
            )
        } else {
            return(
                <div className="g01 cnt_g">
                    <div className="title_area">
                        <h2 className="box_title">興味のあるキーワードの情報</h2>
                    </div>
                    <div className="cnt_box">
                        <div className="tagempty_text">
                            <div className="text">
                                <p>まだ「興味のあるキーワード」を設定していたないため、表示できるものがありません。<br />
                                    設定をするとあなたの興味にあわせた、おすすめの情報が表示されます！
                                </p>
                                <div className="link">
                                    <MoveOnryButton buttonName={'キーワードの編集はこちら'} path={'/account/user'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const loginToBTHacks = () => {
        if (loginUser) {
            const btHacksLink = process.env.REACT_APP_BTHACKS_LINK;
            const suEnc = Decrypto(sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME]);
            const sessionStorageparams = JSON.parse(suEnc);
            const forBTHacksToken =  sessionStorageparams.accessToken;
            window.open(btHacksLink + '?token=' + forBTHacksToken);
        }
    }

    useEffect(() => {
        if (getNickName(selector)) {
            // Formの登録state情報をクリア
            dispatch(clearFormAction());
        }
    }, [])

    if (loginUserJson) {
        // ログイン後の画面
        return (
            <div id="wrapper" className={visible ? 'fixed' : ''}>
                <LoginTopHead />
                <Header />
                <div id="login_top" className="page_contents">
                    <main>
                        {FirstLogin(visible, setVisible)}
                        <div className={hanVis ? "side_btn sp act": "side_btn sp"} onClick={changedHanberger}><span></span></div>
                        <div className="cnt_outwrap">
                            <section className="main_box">
                                <div className="inner_wrap">
                                    {tagViewSwitch()}
                                    <div className="g02 cnt_g">
                                        <div className="cnt_box">
                                            <div className="cnt_img"><img src={pic06} alt="" /></div>
                                            <h2 className="cnt_text">ここには今後あなたの出張を<br /><span>よりよくするための情報が表示されます。</span></h2>
                                        </div>
                                    </div>
                                    <div className="g04 cnt_g gray_box">
                                        <h2 className="service_title">その他のTTSサービス</h2>
                                        <ul>
                                            <li>
                                                <div className="cnt_img"><img src={pic07} alt="" /></div>
                                                <div className="text_box">
                                                    <div className="cnt_text"><p>BTHacks＝ビジネス×トラベル×ハック トッパントラベルサービスが運営する、ビジネスマン向け(裏技)情報発信サイト。 海外・国内出張に役立つ情報や、国内外で活躍するビジネスマンへのインタビュー記事、新しいビジネスについて考察するウェビナーや、討論会など様々なコンテンツを配信中です。</p></div>
                                                    <div className="btn"><a href="https://www.bthacks.com/" onClick={loginToBTHacks} target="_blank" rel="noreferrer">もっと詳しく</a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cnt_img"><img src={pic08} alt="" /></div>
                                                <div className="text_box">
                                                    <div className="cnt_text">
                                                        <div className="cnt_text">
                                                            <p>BTMポータルは海外・国内の出張手配を簡素化だけでなく、出張旅程に連動した現地情報やリスク情報もいち早くお知らせ。<br />
                                                                位置情報を元にした安否確認や、行動傾向をデータ化し分析、ガバナンス強化へ活用もでき、新たな出張管理の在り方を体現可能な出張管理システムです。</p>
                                                        </div>
                                                    </div>
                                                    <div className="btn_g">
                                                        <div className="btn"><a href="https://www.toppantravel.com/btm/gra-pe/" target="_blank" rel="noreferrer">もっと詳しく</a></div>
                                                        <div className="btn"><a href="https://btm.tts.co.jp/portal/apl/login.aspx" target="_blank" rel="noreferrer">アカウントがある方はこちら</a></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cnt_img"><div className="service_text">MICE<br />ポータル</div></div>
                                                <div className="text_box">
                                                    <div className="cnt_text"><p>国内外での会議や視察・研修、社員・報奨旅行などあらゆるニーズにお応え。最適なコミュニケーションプランを提案し、運営の実施までワンストップで、ソリューションを提供します。</p></div>
                                                    <div className="btn"><a href="https://www.toppantravel.com/mice/" target="_blank" rel="noreferrer">もっと詳しく</a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cnt_img"><div className="service_text">海外人事<br />ポータル</div></div>
                                                <div className="text_box">
                                                    <div className="cnt_text"><p>海外進出、海外事業拡充に向けたグローバルな人事管理体制が必要不可欠になっているなか人材のダイバーシティ化に対応した幅広いサービスをご提案いたします。</p></div>
                                                    <div className="btn"><a href="https://www.toppantravel.com/mobility/" target="_blank" rel="noreferrer">もっと詳しく</a></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section className={hanVis ? "box_r act" : "box_r"}>
                                <div className="r_box01 cnt_rbox">
                                    <h2>連携中のサービス</h2>
                                    <div className="cnt_wrap">
                                        <ul>
                                            <li><div className="cnt_img service" onClick={loginToBTHacks}><a href="https://www.bthacks.com" target="_blank" rel="noreferrer"><img src={btHacksIcon} alt="BTHacks Icon" /></a></div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="r_box02 cnt_rbox">
                                    <h2>BTHacks最新情報</h2>
                                    <div className="cnt_wrap">
                                        <ul>
                                            {newArticle.map((item:any, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={item.link} target="_blank" rel="noreferrer">{item.title}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <div className="link"><a href="https://www.bthacks.com" target="_blank" rel="noreferrer">もっと見る</a></div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return(
            <></>
        )
    }
}

export default Top