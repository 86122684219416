import * as Actions from './actions';
import initialState from '../Store/initialState';

export const RecaptchaReducer = (state = initialState.recaptcha, action) => {
    switch (action.type) {
        case Actions.HANDLE_OK:
            return {
                ...state,
                ...action.payload
            }
        case Actions.HANDLE_CLEAR:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}