import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import { Decrypto } from "../../../functions";
import { Footer, Header } from "../../../htmlParts";
import { getLoginStatus } from "../../../Reduxs/Users/selectors";

//アカウント、サービス連携ページ

const Unlink = () => {
    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <Header />
                <div id="status_stop" className="signup_bg mail_change_ptn02">
                    <main>
                        <div className="contents_in">
                            <div className="text_box_ptn01 form_box_ptn02">
                                <h1 className="title blue">サービス連携の解除</h1>
                                <div className="cnt_text">
                                    <p>以下のボタンから連携を解除するサービスにログインすることで<br />TTS-IDとの連携を解除します。</p>
                                </div>
                                <div className="together">連携を解除するサービス：TTSコミュニティ</div>
                                <div className="btn_ptn03"><a href="">サービス連携を解除</a></div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div >
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Unlink
