import { useEffect, useState } from 'react';
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import { AccountSecurityHead, Footer, Header, InputPassword } from "../../../htmlParts";
import { SideBar } from "..";
import {
    asyncDevAPI, CheckDeviceBrowser, Decrypto, EmailMask,
    deletedAccountErrWord, MoveOnryButton, changedCodeHandler,
    Encrypto, ErrorSwitchFunctions, securityCodeNotVerifyWord
} from "../../../functions";
import illust02 from '../../../assets/img/setting/illust02.png';
import { signOutAction } from '../../../Reduxs/Users/actions';
import { apiFinAction, apiStartAction, hanbergerOpenAction } from '../../../Reduxs/PageAct/actions';
import { Link } from 'react-router-dom';
import { setErrorAction } from '../../../Reduxs/Errors/actions';
import PasswordCount from '../../../functions/PasswordCount';
import ScrollAnimation from 'react-animate-on-scroll';
import { clearLoadingAction } from '../../../Reduxs/Loading/actions';
import TagManager from 'react-gtm-module';
import { getAPIAction } from '../../../Reduxs/PageAct/selectors';

//アカウント、セキュリティ設定ページ

const Security = () => {

    document.title = 'セキュリティ|TTS-ID';

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = SessionUserObj ? JSON.parse(SessionUserObj) : null;
    const accessToken = loginUser ? loginUser.accessToken : null;
    const countTmp = loginUser ? loginUser.verifyCount : null;

    let ucpj = sessionStorage.getItem('ucpj');
    ucpj = ucpj ? Decrypto(ucpj) : null;
    const c_user = ucpj ? JSON.parse(ucpj) : null;

    const emailTmp = c_user ? c_user.email : '';

    let uutmString = sessionStorage.getItem('uutm');
    uutmString = uutmString ? Decrypto(uutmString) : null;
    const uutm = uutmString ? JSON.parse(uutmString) : null;

    const update_time = uutm ? uutm.update_date : null;

    const [cGetFlg, setCGetFlg] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(EmailMask(emailTmp));
    const [visibleLogin, setVisibleLogin] = useState<boolean>(false);
    const [visibleDel, setVisibleDel] = useState<boolean>(false);


    // state管理系宣言
    const [password, setPassword] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [codeError, setCodeError] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [loginHst, setLoginHst] = useState<object[]>([]);

    const session = sessionStorage.getItem('Session');
    const sessionDec = session ? Decrypto(session) : '';
    const sessionJson = sessionDec ? JSON.parse(sessionDec) : '';

    const [count, setCount] = useState<Number>(countTmp);//ここ追加しました

    // 2段階認証の設定状態を確認
    const sissionTsv = sessionStorage.getItem('tsvt') ? Decrypto(sessionStorage.getItem('tsvt')) : false
    const sissionTsvCheck:any = (sissionTsv === 'true') ? 1 : 0;
    const [twoStepVerifyTxt, setTwoStepVerifyTxt] = useState<boolean>(sissionTsvCheck);
    // パスワード表示用Stateここから
    const [passDisp, setPassDisp] = useState<boolean>(false);

    // バリデーション処理ここから
    const [passwordError, setPasswordError] = useState<string>('');
    const [passwordCheckError, setPasswordcheckError] = useState<string>('');

    const [page, setPage] = useState<string>('verify');

    useEffect(() => {
        const sessionPage = sessionStorage.getItem('verifyAfter') ?? '';
        if (sessionPage) {
            setVisibleDel(true);
            setPage(sessionPage);
            sessionStorage.removeItem('verifyAfter');
        }
    }, [])

    let mfaURL = '';
    let mfaButtonWord = '';
    if (sissionTsvCheck) {
        //有効だったら
        mfaButtonWord = '解除';
    } else {
        //無効だったら
        mfaButtonWord = '設定';
    }

    const mfaNext = () => {
        if (sissionTsvCheck) {
            //有効だったら
            mfaURL = '/account/security/mfa/codeInputRelease';
        } else {
            //無効だったら
            mfaURL = '/account/security/mfa/phoneNumber';
        }
        sessionStorage.setItem('mfaURL', Encrypto(mfaURL));
        dispatch(push('/account/security/mfa/mfaVerification'));
    }

    const passCnt = () => {
        if(2 < count){
            return (
                <></>
            )
        } else {
            return (
                <p className='deferment_text'>
                    あと{count}回まで間違えられます。
                </p>
            )
        }
    }

    // アカウント削除 認証コード送信
    const passVerification = () => {
        dispatch(apiStartAction());
        // 使用デバイスとブラウザの取得
        const useDeviceAndBrowser = CheckDeviceBrowser();

        const apiParams = {
            apiname: 'source_login_post',
            user_name: c_user.email,
            password: password,
            device: useDeviceAndBrowser
        };
        // ログインAPIで本人確認
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const resultLogin: any = res;
                const statusCode = resultLogin['body']['status'];
                const sessionLogin = resultLogin['body']['response']['Session'];
                const sessionPhoneNumber = resultLogin['body']['response']['phone_number'];
                const sessionStorageparams = {
                    accessToken: accessToken,
                    verifyCount: count,
                }
                const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
                const sessionparamsString = JSON.stringify(sessionStorageparams);
                sessionStorage.setItem(envStorageKey, Encrypto(sessionparamsString));

                if (statusCode === 200) {
                    // SessionStorageからアクセストークンを取得
                    const SessionUserStringEnc = sessionStorage.getItem(envStorageKey);
                    const SessionUserStringDec = SessionUserStringEnc ? Decrypto(SessionUserStringEnc) : null;
                    const SessionUserObj = JSON.parse(SessionUserStringDec);
                    const SessionAccessToken = SessionUserObj.accessToken ?? accessToken;

                    const apiParams = {
                        apiname: 'source_cognito_verify_user_attribute_get',
                        AccessToken: accessToken,
                        type: 'email',
                    };
                    //アカウント削除処理
                    asyncDevAPI(apiParams)
                        .then((res: any) => {
                            const result = res;
                            const statusCode = result['body']['status'];

                            if (statusCode === 200) {
                                // 2段階認証用処理
                                if (sessionLogin) {
                                    const urlParams = {
                                        url: '/account/security',
                                        button: '認証'
                                    }
                                    const params = {
                                        email: c_user.email,
                                        session: sessionLogin,
                                        password: password,
                                        useDeviceAndBrowser: useDeviceAndBrowser,
                                        phoneNumber: sessionPhoneNumber,
                                        keep: sessionJson.keep,
                                    }
                                    const paramsJson = JSON.stringify(params);
                                    sessionStorage.urlData = JSON.stringify(urlParams);
                                    sessionStorage.Session = Encrypto(paramsJson);
                                    sessionStorage.setItem('verifyAfter', 'code')
                                    dispatch(push('/SMSverification'));
                                    PasswordCount(setCount, true);
                                    return;
                                }

                                // コード入力画面に遷移
                                PasswordCount(setCount, true);//ここ追加しました
                                dispatch(apiFinAction());
                                setPage('code');
                            } else {
                                // 200以外のステータス
                                dispatch(apiFinAction());
                                errorFunction(res, setPasswordcheckError, dispatch, setCount);
                            }
                        })
                        .catch(err => {
                            dispatch(apiFinAction());
                            setPasswordcheckError('アカウントの削除に失敗しました。お手数ですがもう一度お試しください。');
                        }
                    )
                } else {
                    // 200以外のステータス
                    dispatch(apiFinAction());
                    errorFunction(res, setPasswordcheckError, dispatch, setCount, false);
                }
            })
            .catch(err => {
                // ログインAPIでエラー返却
                dispatch(apiFinAction());
                PasswordCount(setCount);//ここ追加しました
                setPasswordcheckError('パスワードが異なります。再度入力してください。');
            });
    }

    // アカウント削除 認証コード確認
    const accountDelete = () => {
        // SessionStorageからアクセストークンを取得
        dispatch(apiStartAction());
        const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
        const SessionUserStringEnc = sessionStorage.getItem(envStorageKey);
        const SessionUserStringDec = SessionUserStringEnc ? Decrypto(SessionUserStringEnc) : null;
        const SessionUserObj = JSON.parse(SessionUserStringDec);
        const SessionAccessToken = SessionUserObj.accessToken;

        const apiParams = {
            apiname: 'source_cognito_verify_user_attribute_put',
            AccessToken: SessionAccessToken,
            confirmation_code: code,
            type: 'email'
        };
        // 認証コード確認
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const result = res;
                const statusCode = result['body']['status'];

                // アカウント削除処理
                if (statusCode === 200) {
                    const apiParams = {
                        apiname: 'source_user_delete',
                        AccessToken: SessionAccessToken,
                    };
                    //アカウント削除処理
                    asyncDevAPI(apiParams)
                        .then(res => {
                            const result: any = res;
                            const statusCode = result['body']['status'];

                            if (statusCode === 200) {
                                // 2段階認証であった場合はクリア
                                if (sessionStorage.getItem('verifyAfter')) {
                                    sessionStorage.removeItem('verifyAfter');
                                }
                                // コード入力画面に遷移
                                dispatch(apiFinAction());
                                setPage('comp');
                            } else {
                                dispatch(apiFinAction());
                                errorFunction(res, setErrMsg, dispatch, setCount);
                                /*
                                dispatch(setErrorAction({
                                    errorCode: statusCode
                                }));
                                dispatch(push('/error'));
                                */
                            }
                        })
                        .catch(err => {
                            setErrMsg('アカウントの削除に失敗しました。お手数ですがもう一度お試しください。');
                        })
                } else {
                    // 200以外のステータス
                    // 認証コード確認でエラー返却
                    dispatch(apiFinAction());
                    if (res.body.response.Code === 'CodeMismatchException') {
                        setErrMsg(securityCodeNotVerifyWord);
                    } else {
                        errorFunction(res, setErrMsg, dispatch, setCount);
                    }
                }
            })
            .catch(err => {
                dispatch(apiFinAction());
                switch (err.errorType) {
                    case 'CodeMismatchException':
                        setErrMsg('認証に失敗しました。確認コードをお確かめの上、もう一度お試しください。');
                        break;
                    default:
                        // ログインAPIでエラー返却
                        setErrMsg('アカウントの削除に失敗しました。お手数ですがもう一度お試しください。');
                        break;
                }
            });
    }

    const deletedAccountLogout = () => {
        if (passwordCheckError === deletedAccountErrWord) {
            dispatch(signOutAction());
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/';
        }
    }

    const logoutToTop = () => {
        // ログアウト処理
        dispatch(signOutAction());
        sessionStorage.clear();
        localStorage.clear();
        dispatch(push('/'));
    }


    const accountDelVerify = () => {
        switch (page) {
            //アカウント削除確認画面モーダル
            case 'verify':
                return (
                    <div id="account_delete" className="modal" style={{ display: visibleDel ? "block" : "none" }}>
                        <div className="mail_change">
                            <div className="contents_in">
                                <div className="text_box_ptn01 form_box_ptn02">
                                    <Link className="close"
                                        onClick={
                                            () => {
                                                setVisibleDel(!visibleDel)
                                                deletedAccountLogout()
                                            }
                                        }
                                    ></Link>
                                    <h1 className="title">アカウントの削除</h1>
                                    <div className="lead">
                                        TTS-IDを削除すると登録したデータが削除され、元に戻せません。<br />
                                        本当に削除してもよろしいですか？<br /><br />
                                        セキュリティのため、まずはパスワードを入力ください。<br />
                                        パスワードが認証されると、TTS-IDに登録されたメールアドレス宛に退会用メールを送信します。
                                    </div>
                                    <div className="form">
                                        <div>パスワード</div>
                                        <div>
                                            <InputPassword
                                                passDisp={passDisp}
                                                changedPasswordHandler={(event) => setPassword(event.target.value)}
                                                setPassDisp={setPassDisp}
                                            />
                                            {passCnt()}
                                            <div className="form_error_text">{passwordCheckError}</div>
                                        </div>
                                        <div className="btn_submit red">
                                            <input
                                                type='submit'
                                                value='送信'
                                                onClick={() => {
                                                    passVerification();
                                                }}
                                                disabled={
                                                    getAPIAction(selector) ||
                                                    !(passwordError === '') ||
                                                    !password}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            //アカウント削除確認画面モーダル
            case 'code':
                return (
                    <div id="account_delete" className="modal" style={{ display: visibleDel ? "block" : "none" }}>
                        <div className="mail_change">
                            <div className="contents_in">
                                <div className="text_box_ptn01 form_box_ptn02">
                                    <Link className="close"
                                        onClick={
                                            () => {
                                                setVisibleDel(!visibleDel)
                                                deletedAccountLogout()
                                                setPage('verify');
                                            }
                                        }
                                    ></Link>

                                    <h1 className="title">TTS-IDの削除</h1>
                                    <div className="lead">TTS-IDを削除するとすべてのデータが削除され、<br className="sp" />元に戻すことができなくなります。<br />
                                        以下のフォームに削除コードを入力して送信を押すと、<br />
                                        TTS-IDの削除が完了します。</div>
                                    <div className="form">
                                        <div className="form_text">削除コード</div>
                                        <div>
                                            <input type='text' name='label' placeholder='削除コード' maxLength={6} onChange={(e: any) => changedCodeHandler(e, setCode, setCodeError)} />
                                        </div>
                                        <div className="form_error_text">
                                            {codeError}
                                            {codeError ? <br /> : ''}
                                            {errMsg}
                                        </div>
                                    </div>
                                    <div className="btn_submit red">
                                        <input
                                            type='submit'
                                            value='送信'
                                            onClick={() => {
                                                accountDelete();
                                            }}
                                            disabled={
                                                getAPIAction(selector) ||
                                                !(codeError === '') ||
                                                !code}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            //アカウント削除完了画面のモーダル
            case 'comp':
                
                return (
                    <div id="account_delete_done" className="modal" style={{ display: visibleDel ? "block" : "none" }}>
                        <div className="mail_change_ptn02">
                            <div className="contents_in">
                                <div className="page_title_ptn01"></div>
                                <div className="text_box_ptn01 form_box_ptn02">
                                    <Link className="close" onClick={() => {
                                        setPage('verify');
                                        setVisibleDel(!visibleDel);
                                        // ログアウト処理
                                        logoutToTop();
                                    }}></Link>
                                    <div className="decoration_img"><img src={illust02} alt="人のイラスト" /></div>
                                    <div className="title title01">TTS-IDを<br className="sp" />削除しました</div>
                                    <div className="cnt_text">あなたのTTS-IDを削除しました。<br />これまでTTS-IDをご利用いただき、誠にありがとうございました。<br /></div>
                                    <div className="btn_ptn02 btn_top_link"><Link onClick={() => logoutToTop()}>TTS-ID トップへ</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }
    //ログイン履歴モーダル
    const loginHistoryModal = () => {
        if(visibleLogin){
            const tagManagerArgs = {
                gtmId: 'GTM-5T4HMCR',
                dataLayer: {'login_history': 'login_history'}
            }
            TagManager.dataLayer(tagManagerArgs);
        }
        return (
            <div id="login_history" className="modal" style={{ display: visibleLogin ? "block" : "none" }}>
                <div className="page_contents">
                    <div className="contents_in">
                        <div className="text_box_ptn01">
                            <Link className="close" onClick={() => setVisibleLogin(!visibleLogin)}></Link>
                            <div className="title blue">ログイン履歴</div>
                            <div className="table_box">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>日時</th>
                                            <th>地域(IPアドレス)</th>
                                            <th>機器・ブラウザ</th>
                                            <th>ログインしたサイト</th>
                                        </tr>
                                        {loginHst.map(item => {
                                            return (
                                                <tr>
                                                    <td>{
                                                        item['create_date'].replace(/'/, '').replace(/-/, '年').replace(/-/, '月').replace(/'/, '日 ')
                                                    }</td>
                                                    <td>{item['login_ip']}</td>
                                                    <td className="computer">{item['login_device']}</td>
                                                    <td>{item['mid'] === 0 ? 'TTS-ID' : ''}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="kome_text">※地域は、ログイン時のIPアドレスから推測されたものです。</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const [hanVis, setHanVis] = useState<boolean>(false);
    const changedHanberger = () => {
        dispatch(hanbergerOpenAction());
        setHanVis(!hanVis);
    }


    useEffect(() => {
        const sessionTsvt = sessionStorage.getItem('tsvt') ?? null;
        if (!sessionTsvt) {
        // SessionStorageからアクセストークンを取得
        const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
        const SessionUserStringEnc = sessionStorage.getItem(envStorageKey);
        const SessionUserStringDec = SessionUserStringEnc ? Decrypto(SessionUserStringEnc) : null;
        const SessionUserObj = SessionUserStringDec ? JSON.parse(SessionUserStringDec) : null;
        const SessionAccessToken = SessionUserObj ? SessionUserObj.accessToken :null;

        const apiParams = {
            apiname: 'source_cognito_get',
            AccessToken: SessionAccessToken,
        };
        // 検証コード発行API
        asyncDevAPI(apiParams)
            .then(res => {
                const result: any = res;
                const statusCode = result['body']['status'];

                if (statusCode === 200) {
                    const phoneNumberVerify = result['body']['response']['phone_number_verified'];
                    if(phoneNumberVerify){
                        sessionStorage.setItem('tsvt', Encrypto(phoneNumberVerify));
                        setTwoStepVerifyTxt(phoneNumberVerify);
                    }
                } else {
                    dispatch(setErrorAction({
                        errorCode: statusCode
                    }));
                    dispatch(push('/error'));
                }
            })
            .catch(err => {
                console.log('検証コード発行APIでエラー エラー内容: ' + JSON.stringify(err));
            })
        }
        if (sessionStorage.getItem('mfaURL')) {
            sessionStorage.removeItem('mfaURL')
        }
    }, [])

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <AccountSecurityHead />
                <Header />
                <div id="security" className="setting_page">
                    <main>
                        <div className="page_contents">
                            <h1 className="sp">TTS-ID設定</h1>
                            <div className={hanVis ? "btn_open sp act" : "btn_open sp"} onClick={changedHanberger}><span></span><span></span><span></span></div>
                            <SideBar />
                            <div className="main_contents">
                                <div className="contents_in">
                                    <h2 className="h2_ptn01">セキュリティ</h2>
                                    <h2 className="text">メールアドレス</h2>
                                    <div className="cnt_box01 cnt_box">
                                        <div className="gray">{email}</div>
                                        <div className="btn_ptn03">
                                            <MoveOnryButton buttonName={'メールアドレスを変更'} path={'/account/security/emailChange/mailVerification'} />
                                        </div>
                                    </div>
                                    <div className="line"></div>
                                    <h2 className="text">パスワード</h2>
                                    <div className="cnt_box02 cnt_box">
                                        <div className="gray">前回の変更：{update_time}</div>
                                        <div className="btn_ptn03">
                                            <MoveOnryButton buttonName={'パスワードを変更'} path={'/account/security/passwordChange/passVerification'} />
                                        </div>
                                    </div>
                                    <div className="line"></div>

                                    <h2 className="text">2段階認証</h2>
                                    <div className="cnt_box03 cnt_box">
                                        <div className="gray">
                                            2段階認証は、パスワードが盗まれた場合に備えてあなたのTTS-IDのセキュリティを強固にするものです。<br />
                                            2段階認証を設定するとTTS-IDログイン時に２つの要素を使用してログインします。<br />
                                            1.パスワード<br />
                                            2.あなたがお持ちの携帯電話に送信されるセキュリティコード
                                        </div>
                                        <div className="flex_box">
                                            <div className="btn_ptn03">
                                                <Link onClick={() => mfaNext()}>{'2段階認証を' + mfaButtonWord}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line"></div>
                                    <div className="cnt_box04 cnt_box">
                                        {accountDelVerify()}
                                    </div>
                                    <div className="delete">
                                        <Link
                                            className="red"
                                            onClick={() => {
                                                setVisibleDel(!visibleDel);
                                            }}>TTS-IDを削除する</Link>
                                    </div>
                                </div>

                                <div className="cnt_img">
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollAnimation
                    offset={0}
                    animateIn='fadeIn'
                    animateOut='fadeOut'
                    duration={1}
                    animatePreScroll={true}
                /*initiallyVisible={true}*/
                >

                    <Footer />
                </ScrollAnimation>
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Security

const getLoginHistory = (accessToken, setLoginHst, dispatch) => {
    const params = {
        apiname: 'source_user_login_history_get',
        AccessToken: accessToken,
        past_date: 30,
    }
    asyncDevAPI(params)
        .then((res:any) => {
            const response = res.body
            if(response.status === 200){
                setLoginHst(response.response.reverse());
            } else {
                dispatch(setErrorAction({
                    errorCode: response.status
                }));
                dispatch(push('/error'));
            }
            
        })
        .catch(err => {
            console.log(err);
        });
}


const errorFunction = (res, setErrMsg, dispatch, setCount, type = true) => {
    console.log('res: ' + JSON.stringify(res));
    // 200以外のステータス
    PasswordCount(setCount, type);//ここ追加しました
    const setMsgs = {
        ErrMsg: setErrMsg,
    };
    console.log('setMsgs: ' + JSON.stringify(setMsgs));
    const errorFlg = ErrorSwitchFunctions(res.body, setMsgs);
    dispatch(clearLoadingAction());
    if(errorFlg.flg){
        dispatch(setErrorAction({
            errorCode: res.body.status
        }));
        dispatch(push(errorFlg.url));
    }
    return;
}