import React from 'react'

import {
  CognitoUser
} from "amazon-cognito-identity-js"
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import {getUserPool} from '../../../functions'

//メール認証ページ

const FirstCategorySelection = () => {

  const dispatch = useDispatch();

  const [email, setEmail] = React.useState<string>('')
  const [verificationCode, setVerificationCode] = React.useState<string>('')
  const changedEmailHandler = (event: any) => setEmail(event.target.value)
  const changedVerificationCodeHandler = (event: any) => setVerificationCode(event.target.value)

  const verifyCode = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: getUserPool()
    })
    cognitoUser.confirmRegistration(verificationCode, true, (err: any) => {
      if (err) {
        console.log(err)
        return
      }
      setEmail('')
      setVerificationCode('')
      dispatch(push('/top'));
    })
  }
  return (
    <div className="FirstCategorySelection">
      <h1>メール認証</h1>
      <p>確認コード</p>
      <p><input type="text" placeholder="確認コードを入力してください。" onChange={changedVerificationCodeHandler} /></p>
      <p>メールアドレス</p>
      <p><input type="text" placeholder='メールアドレスを入力してください。' onChange={changedEmailHandler} /></p>
      <p><button onClick={verifyCode}>認証</button></p>
    </div>
  )
}

export default FirstCategorySelection