import { useDispatch } from 'react-redux';
import { setNicknameAction } from '../Reduxs/Users/actions';
import { push } from 'connected-react-router';
import { useLocation } from "react-router-dom";
import { asyncDevAPI, asyncGetFromParts, asyncGetLangParts, asyncGetLocalUser } from './asyncFunctions';
import { Decrypto, Encrypto } from './Crypto';
import { loginOnlyPage } from '../htmlParts';
import { TokenExpirationCheck } from './LoginData';
import { notLoginPage } from '../htmlParts/notLoginpage';

//ページのリロードの際などにユーザーの情報をセッションストレージから引っ張り出す処理

const UserLoginStatus = () => {
    document.title = 'TTS-ID';
    const dispatch = useDispatch();
    // アクセストークの有無チェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const sessionUser = SessionUserString ? Decrypto(SessionUserString) : null;

    const Login = localStorage.getItem('Login');
    if(!Login || Login === 'false'){
        sessionStorage.removeItem(envStorageKey);
        localStorage.removeItem(envStorageKey);
        localStorage.Login = false;
    }

    const LocalUserStirng = localStorage.getItem(envStorageKey);
    const localUser = LocalUserStirng ? Decrypto(LocalUserStirng) : null;

    const URL = useLocation().pathname;

    const loginUserObj = localUser ? JSON.parse(localUser) : null;
    if(loginUserObj){
        userExpirationCheck(loginUserObj);
    }
    if(sessionUser){
        const loginUser = JSON.parse(sessionUser);
        LoginCheck(loginUser, dispatch, URL);
        if(notLoginPage.includes(URL)){
            dispatch(push('/top'));
        }
    } else if(localUser){
          asyncGetLocalUser(URL)
            .then((res:any) => {
                if(notLoginPage.includes(URL)){
                    dispatch(push('/top'));
                }
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            });
    } else {
        if(loginOnlyPage.includes(URL)){
            sessionStorage.clear();
            localStorage.removeItem(envStorageKey);
            window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
        }
    }
    const loginday = localStorage.getItem('lt');
    let now = new Date();
    let updateflg = false;
    if(!loginday){
        now.setDate(now.getDate() + 1);
        localStorage.lt = now;
    } else {
        let checkDay = new Date(loginday);
        if(checkDay.getTime() < now.getTime()){
            updateflg = true;
            now.setDate(now.getDate() + 1);
            localStorage.lt = now;
        }
    }
    const langs = localStorage.getItem('langsParts');
    if (!langs || updateflg) {
        asyncGetLangParts()
            .then((res: any) => {
                const params = {
                    langOptions: res,
                }
                const langsParts = JSON.stringify(params);
                localStorage.langsParts = Encrypto(langsParts);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const froms = localStorage.getItem('fromsParts');
    if (!froms || updateflg) {
        asyncGetFromParts()
            .then((res: any) => {
                const params = {
                    froms: res,
                }
                const fromsParts = JSON.stringify(params);
                localStorage.fromsParts = Encrypto(fromsParts);
            })
            .catch(err => {
                console.log(err);
            });
    }
    const interest = localStorage.getItem('interest');
    if(!interest || updateflg){
        const params = {
            apiname: 'master_code_get',
            atb: 2,
        }
        asyncDevAPI(params)
            .then((res:any) => {
                if(res.body.result){
                    let sortInterest;
                    sortInterest = idxSort(res.body.response);
                    sortInterest = sort(sortInterest);

                    const params = {
                        interest: sortInterest,
                    }
                    const interTmp = JSON.stringify(params);
                    localStorage.interest = Encrypto(interTmp);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    const btd = localStorage.getItem('btd');
    if(!btd || updateflg){
        const params = {
            apiname: 'master_code_get',
            atb: 1
        }
        asyncDevAPI(params)
        .then((res:any) => {
            if(res.body.result){
                const params = {
                    btd: res.body.response,
                }
                const btdTmp = JSON.stringify(params);
                localStorage.btd = Encrypto(btdTmp);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
}

export default UserLoginStatus



const idxSort = (item) => {
    let newItem:object[] = [];
    item.map(key => {
        if(key.idx === 1){
            newItem.push(key);
        }
    });
    return newItem;
}



const sort = (item) => {
    for(var i = 0; item.length -1 > i; i++){
        for(var j = item.length -1; j > i ; j--){
	        // 上の方が大きいときは互いに入れ替えます
            if(item[j].sort < item[j-1].sort){
                let t = item[j];
                item[j] = item[j-1];
                item[j-1] = t;
            }
        }
    }
    return item;
}

const LoginCheck = (loginUser, dispatch, URL) => {

    const FirstLogin = localStorage.getItem('isLogin') ? true : false;
    const cognitoParams = {
        apiname: 'source_cognito_get',
        AccessToken: loginUser.accessToken,
    }
    const rdsParams = {
        apiname: 'source_user_get',
        AccessToken: loginUser.accessToken,
    }
    const userUpdateTimeParams = {
        apiname: 'source_cognito_update_date_get',
        AccessToken: loginUser.accessToken,
    }
    const notificationParams = {
        apiname: 'source_user_notification_get',
        AccessToken: loginUser.accessToken,
    }
    const rssParams = {
        apiname: 'source_rss_article_get',
        type: "new_article",
        AccessToken: loginUser.accessToken,
    };
    const ucpj = sessionStorage.getItem('ucpj');
    if(!ucpj){
        asyncDevAPI(cognitoParams)
        .then((res:any) => {
            if (res.body.status === 200){
                const response = res.body.response;
                const params ={
                    email: response.email,
                }
                const paramsjs = JSON.stringify(params);
                sessionStorage.ucpj = Encrypto(paramsjs);
                if((URL === '/top' && FirstLogin) || URL === '/account'){
                    window.location.reload();
                }
            }
        })
        .catch(err => {
            console.log(err);
            sessionStorage.clear();
            alert('ログイン状態が切れました。再度ログインしてください。');
            dispatch(push('/'));
            }
        );
    }
    let uiData:Number[] = [];
    const urpj = sessionStorage.getItem('urpj');
    if(!urpj){
        asyncDevAPI(rdsParams)
        .then((res:any) => {
            if(res.body.status === 200){
                const m_user = res.body.response.m_user;
                const m_code = res.body.response.m_code;
                const params = {
                    username: m_user.nickname,
                    year: String(m_user.birthday).substr(0,4),
                    month: String(m_user.birthday).substr(4,2),
                    day: String(m_user.birthday).substr(6,2),
                    gender: m_user.gender,
                    m_code: m_code
                };
                const paramsjs = JSON.stringify(params);
                sessionStorage.urpj = Encrypto(paramsjs);
                sessionStorage.unkm = Encrypto(m_user.nickname);
                m_code.map((item:any) => {
                    if(item.atb === 2){
                        uiData.push(item.mid);
                    }
                });
                if(URL === '/account' || URL === '/account/user'){
                    window.location.reload();
                }
            }
        })
        .catch(err => {
            console.log(err);
            sessionStorage.clear();
            alert('ログイン状態が切れました。再度ログインしてください。');
            dispatch(push('/'));
        });
    } else {
        const urpjD = Decrypto(String(urpj));
        const urpjJSON = JSON.parse(urpjD);

        dispatch(setNicknameAction({
            tf_nickname: urpjJSON.username,
        }));
        urpjJSON.m_code.map((item:any) => {
            if(item.atb === 2){
                uiData.push(item.mid);
            }
        });
    }
    const uutm = sessionStorage.getItem('uutm');
    if(!uutm){
        asyncDevAPI(userUpdateTimeParams)
            .then((res:any) => {
                if(res.body.result){
                    const response = res.body.response;
                    const params = {
                        update_date: response.update_date,
                    }
                    const paramsjs = JSON.stringify(params);
                    sessionStorage.uutm = Encrypto(paramsjs);
                    if(URL === '/account/security'){
                        window.location.reload();
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    const nd = sessionStorage.getItem('nd');
    let get_mail_notif:String[] = [];
    let get_browser_notif:String[] = [];
    let mail_notif = {
        receive: false,
        destination: false,
        tag: false,
    };
    let browser_notif = {
        receive: false,
        destination: false,
        tag: false,
    };
    if(!nd){
        asyncDevAPI(notificationParams)
            .then((res:any) => {
                if(res.body.result){
                    if(res.body.response.length != 0){
                        const response = res.body.response;
                        get_mail_notif = response[0].mid_mail.split(',');
                        get_browser_notif = response[0].mid_browser.split(',');
        
                        get_mail_notif.map(item => {
                            if(item == '1'){
                                mail_notif.receive = true;
                            }
                            if(item == '2'){
                                mail_notif.destination = true;
                            }
                            if(item == '3'){
                                mail_notif.tag = true;
                            }
                        });
                        get_browser_notif.map(item => {
                            if(item == '1'){
                                browser_notif.receive = true;
                            }
                            if(item == '2'){
                                browser_notif.destination = true;
                            }
                            if(item == '3'){
                                browser_notif.tag = true;
                            }
                        });
                        const paramsjs = JSON.stringify({
                            mail: mail_notif,
                            browser: browser_notif,
                        });
                        sessionStorage.nd = Encrypto(paramsjs);
                        if(URL === '/account/notification'){
                            window.location.reload();
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
            }
        );
    }
    const userNewsParams = {
        apiname: 'source_rss_article_get',
        type: "my_article",
        AccessToken: loginUser.accessToken,
        my_tag: uiData.join(),
    }
    const articles = sessionStorage.getItem('articles');
    if(!articles){
        asyncDevAPI(rssParams)
        .then((res:any) => {
            const body = res.body;
            if(body.status === 200){
                const newParams = {
                    new_Article: body.response,
                };
                const newArticle = JSON.stringify(newParams);
                asyncDevAPI(userNewsParams)
                .then((res:any) => {
                    const body = res.body;
                    let myArticle;
                    if(body.status === 200){
                        body.response.map(item => {
                            item.category = sort(item.category);
                        });
                        if(body.response.length > 0){
                            const myParams = {
                                mytag_article: body.response,
                            }
                            myArticle = JSON.stringify(myParams);
                        } else {
                            myArticle = null;
                        }
                        const params = {
                            newArticle: newArticle,
                            myArticle: myArticle,
                        }
                        sessionStorage.articles = JSON.stringify(params);

                        if(URL === '/top' && FirstLogin){
                            window.location.reload();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
}

const userExpirationCheck = (localUserObj:any) => {
    const keep = localUserObj.keepLogin;
    let keepFlg = keep === 'keep' ? true : false;
    const refreshTokenExpiration = localUserObj.expiration;
    TokenExpirationCheck(refreshTokenExpiration, keepFlg ? localUserObj.accessTokenExpiration : '');

}