import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js';

export const getUserPool = () => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
        ClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
        Storage: undefined
    });
    return userPool;
}

export const getCognitoUser = (email) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool(),
        Storage: undefined
      })
    return cognitoUser;
}

export const loginStatus = () => {
    const user = getUserPool().getCurrentUser();
    return user;
}

export const AuthenticationUser = (email,password) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool()
      })
    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })
    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
        },
        onFailure: () => {
            console.log('ng');
        },
        mfaRequired: () => {

        }
    })
}

export const Userupdate = (attributeList, email, password,) => {
    const cognitoUser = getCognitoUser(email);

    console.log(cognitoUser);

    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })

    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
            cognitoUser.updateAttributes(attributeList, (err, res) => {
                if(err){
                    console.log(err);
                    return;
                }
                console.log('result :' + res);
                cognitoUser.getAttributeVerificationCode('phone_number', {
                    onSuccess: () => {
                      return true;
                    },
                    onFailure: (err) => {
                      console.log(err);
                      return false;
                    }
                });
            });
        },
        onFailure: () => {
            console.log('ng');
        },
        mfaRequired: () => {
            //2段階認証処理
        }
    })
}
export const VerificationCode = (email, password, code) => {
    const cognitoUser = new CognitoUser({
        Username:  email,
        Pool: getUserPool()
    });
    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })
    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
            cognitoUser.verifyAttribute('email', code, {
                onSuccess: (res) => {
                    console.log(res);
                    return true;
                },
                onFailure: (err) => {
                    console.log(err);
                    return false;
                }
            });
        },
        onFailure: () => {
            console.log('ng');
        }
    })
}

export const SwitchMFA = (email, password, flg) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool()
      })
    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })
    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
            const smsMfaSettings = {
                PreferredMfa: true,
                Enabled: flg,
            };
            cognitoUser.setUserMfaPreference(smsMfaSettings, null, (err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                }
                console.log('call result ' + result);
            });
        },
        onFailure: () => {
            console.log('ng');
        },
        mfaRequired: function () {
            //let mfaCode:string = String(prompt('認証コードを入力してください。'));
            //cognitoUser.sendMFACode(mfaCode, this);
        }
    })
}

export const getUserData = (email, password) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool()
      })
    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })
    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
            cognitoUser.getUserAttributes((err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }
                if (result === void 0){
                    return;
                }
                for (var i = 0; i < result.length; i++) {
                    console.log(
                        'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
                    );
                }
            });
        },
        onFailure: () => {
            console.log('ng');
        },
        mfaRequired: function (codeDeliveryDetails) {
            console.log(codeDeliveryDetails);
            let mfaCode:string = String(prompt('確認コードを入力してください。'));
            cognitoUser.sendMFACode(mfaCode, this);
        }
    })
}

export const changeCognitoPassword = (email, password, newPassword) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool()
      })
    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })
    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
            cognitoUser.changePassword(password, newPassword, (err, res) => {
                if(err){
                    console.log(err.message || JSON.stringify(err));
                    return;
                }
                console.log('result : ' + res);
            });
        },
        onFailure: () => {
            console.log('ng');
        },
        mfaRequired: () => {
            const a = true;
        }
    })
}

export const getUserAttributes = (email, password) => {
    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool()
      })
    const authDetail = new AuthenticationDetails({
        Username: email,
        Password: password,
    })
    cognitoUser.authenticateUser(authDetail, {
        onSuccess: () => {
            console.log('ok');
            cognitoUser.getUserAttributes((err, result: any) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }
                for (var i = 0; i < result.length; i++) {
                    console.log(
                        'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
                    );
                }
            });
        },
        onFailure: () => {
            console.log('ng');
        },
        mfaRequired: function (codeDeliveryDetails) {
            console.log(codeDeliveryDetails);
            let mfaCode:string = String(prompt('確認コードを入力してください。'));
            cognitoUser.sendMFACode(mfaCode, this);
        }
    })
}