export const MODAL_OPEN_ACTION = 'MODAL_OPEN_ACTION';
export const modalOpenAction = () => {
    return {
        type: 'MODAL_OPEN_ACTION',
        payload: {
            isModalOpen: true,
        }
    }
}

export const MODAL_CLOSE_ACTION = 'MODAL_CLOSE_ACTION';
export const modalCloseAction = () => {
    return {
        type: 'MODAL_CLOSE_ACTION',
        payload: {
            isModalOpen: false,
        }
    }
}

export const HANBERGER_OPEN_ACTION = 'HANBERGER_OPEN_ACTION';
export const hanbergerOpenAction = () => {
    return {
        type: 'HANBERGER_OPEN_ACTION',
        payload: {
            isHanbOpen: true,
        }
    }
}

export const HANBEREGER_CLOSE_ACTION = 'HANBEREGER_CLOSE_ACTION';
export const hanbergerCloseAction = () => {
    return {
        type: 'HANBEREGER_CLOSE_ACTION',
        payload: {
            isHanbOpen: false,
        }
    }
}

export const API_START_ACTION = 'API_START_ACTION';
export const apiStartAction = () => {
    return {
        type: 'API_START_ACTION',
        payload: {
            isApiAction: true,
        }
    }
}

export const API_FIN_ACTION = 'API_FIN_ACTION';
export const apiFinAction = () => {
    return {
        type: 'API_FIN_ACTION',
        payload: {
            isApiAction: false,
        }
    }
}