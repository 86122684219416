import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { push } from 'connected-react-router';
import { Footer, Header, InputPassword, PasswordChangeVerifyHead } from '../../../../htmlParts';
import { asyncDevAPI, CheckDeviceBrowser, Decrypto, Encrypto } from '../../../../functions';
import illust03 from '../../../../assets/img/setting/illust03.png';
import { loginAction } from '../../../../Reduxs/Users/actions';
import { MoveOnryButton } from "../../../../functions"
import PasswordCount from '../../../../functions/PasswordCount';

const PassVerification = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const history = useHistory();

    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const sessionUser = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(sessionUser));
    const [count, setCount] = React.useState<Number>(loginUser.verifyCount);

    // state管理系宣言
    const [password, setPassword] = React.useState<string>('');

    // パスワード表示用Stateここから
    const [passDisp, setPassDisp] = React.useState<boolean>(false);

    // バリデーション処理ここから
    const [passwordCheckError, setPasswordcheckError] = React.useState<string>('');

    const session = sessionStorage.getItem('Session');
    const sessionDec = session ? Decrypto(session) : '';
    const sessionJson = sessionDec ? JSON.parse(sessionDec) : '';

    const passVerification = () => {
        // 使用デバイスとブラウザの取得
        const useDeviceAndBrowser = CheckDeviceBrowser();

        // SessionStorageからメールアドレスを取得
        const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
        const ucpj = sessionStorage.getItem('ucpj');
        const ucpjDec = ucpj ? Decrypto(sessionStorage.getItem('ucpj')) : '';
        const ucpjDecJson = ucpj ? JSON.parse(ucpjDec) : '';

        const sissionTsv = sessionStorage.getItem('tsvt') ? Decrypto(sessionStorage.getItem('tsvt')) : false
        const sissionTsvCheck:any = (sissionTsv === 'true') ? 1 : 0;


        let mfaURL;
        let nextURL;
        let mfaButtonWord;
        if (sissionTsvCheck) {
            //有効だったら
            mfaURL = '/account/security/mfa/phoneNumberRelease';
        } else {
            //無効だったら
            mfaURL = '/account/security/mfa/phoneNumber';
        }

        const apiParams = {
            apiname: 'source_login_post',
            user_name: ucpjDecJson.email,
            password: password,
            device: useDeviceAndBrowser
        };

        // 本人確認
        asyncDevAPI(apiParams)
            .then((res:any) => {
                const envPasswordKey = process.env.REACT_APP_SESSION_STORAGE_PASSWORD ?? '';
                sessionStorage[envPasswordKey] = Encrypto(password);
                if(res.body.response.Session){
                    const urlParams = {
                      url: '/account/security/passwordChange/passEdit',
                      button: '認証'
                    }
                    const params = {
                      email: ucpjDecJson.email,
                      password: password,
                      useDeviceAndBrowser: useDeviceAndBrowser,
                      session: res.body.response.Session,
                      phoneNumber: res.body.response.phone_number,
                      keep: sessionJson.keep,
                    }
                    const paramsJson = JSON.stringify(params);
                    sessionStorage.urlData = JSON.stringify(urlParams);
                    sessionStorage.Session = Encrypto(paramsJson);
                    dispatch(push('/SMSverification'));
                    return;
                }
                const result: any = res;
                const statusCode = result['body']['status'];
                const accessToken = result['body']['response']['AccessToken'];
                const apiIdToken = result['body']['response']['IdToken'];
                const ccode = result['body']['response']['ccode'];

                if (statusCode === 200) {
                    dispatch(loginAction({
                        tts_id: ccode,
                        google_username: '',
                        idtoken: apiIdToken,
                        access_token: accessToken,
                        tf_mail: ucpjDecJson.email,
                        password: password,
                    }));
                    const sessionStorageparams = {
                        accessToken: accessToken,
                        verifyCount: 6,
                    }
                    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
                    const sessionparamsString = JSON.stringify(sessionStorageparams);
                    sessionStorage.setItem(envStorageKey, Encrypto(sessionparamsString));
                    dispatch(push('/account/security/passwordChange/passEdit'));
                } else if (statusCode === 204) {
                    setPasswordcheckError('このパスワードは過去に登録済みです。別のパスワードを指定してください。');
                } else {
                    // 200以外のステータス
                    setPasswordcheckError('本人確認に失敗しました。お手数ですがもう一度お試しください。');

                    PasswordCount(setCount);
                }
            })
            .catch(err => {
                // ログインAPIでエラー返却
                setPasswordcheckError('パスワードが異なります。再度入力してください。');

                PasswordCount(setCount);
            });
    }

    const passCnt = () => {
        if(2 < count){
            return (
                <></>
            )
        } else {
            return (
                <p className='deferment_text'>
                    あと{count}回まで間違えられます。
                </p>
            )
        }
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <PasswordChangeVerifyHead />
                <Header />
                <div id="password_change" className="mail_change">
                    <main>
                        <div className="decoration_img"><img src={illust03} alt="人のイラスト" /></div>
                        <div className="contents_in">
                            <div className="back"><Link to='/account/security'>&lt; 戻る</Link></div>
                            <div className="text_box_ptn01 form_box_ptn02">
                                <h1 className="title">パスワードの変更</h1>
                                <div className="lead">アカウント保護のために、<br className="sp" />まず本人確認を行ってください。</div>
                                <div className='form'>
                                    <div>現在のパスワード</div>
                                    <InputPassword
                                        passDisp={passDisp}
                                        changedPasswordHandler={(event) => setPassword(event.target.value)}
                                        setPassDisp={setPassDisp}
                                        placeholder={"現在のパスワードを入力"}
                                    />
                                    {passCnt()}
                                    <div className="form_error_text">{passwordCheckError}</div>
                                    <div className="btn_submit blue">
                                        <button
                                            onClick={() => passVerification()}
                                            disabled={!password}
                                        >次へ</button>
                                    </div>
                                    <div className="btn_ptn02">
                                        <MoveOnryButton buttonName={'キャンセル'} path={'/account'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default PassVerification