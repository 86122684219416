export const CONFIRM = 'CONFIRM';
export const confirmAction = (contactState) => {
    return {
        type: 'CONFIRM',
        payload: {
            name: contactState.name,
            company: contactState.company,
            email: contactState.email,
            contactSelect: contactState.contactSelect,
            contactText: contactState.contactText,
            textCount: contactState.textCount,
        }
    }
}

export const CONFIRM_FIN = 'CONFIRM_FIN';
export const confirmFinAction = () => {
    return {
        type: 'CONFIRM_FIN',
        payload: {
            name: '',
            company: '',
            email: '',
            contactSelect: '',
            contactText: '',
            textCount: '',
        }
    }
}