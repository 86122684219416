import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import { useEffect } from 'react';
import { signOutAction } from '../../../Reduxs/Users/actions';

const ConfirmationMail = () => {

    document.title = '登録完了メール';

    const dispatch = useDispatch();
    const backHome = () => {
        dispatch(push('/top'));
    }

    useEffect(() => {
        dispatch(signOutAction());
        sessionStorage.clear();
    }, [])
    return (
        <div>
            <h1>登録完了しました。</h1>
            <p>入力頂いたメールアドレスへ、「会員登録のご案内」メールを送信しました。</p>

            <button onClick={backHome}>TTS-ID　トップへ</button>
        </div>
    )
}

export default ConfirmationMail