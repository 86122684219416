import { useDispatch, useSelector } from "react-redux"
import {Decrypto, MoveOnryButton} from '../../../functions';
import { Footer, Header, PasswordForgetCompleteHead } from "../../../htmlParts";
import { useEffect } from "react";
import { push } from "connected-react-router";

const ForgetComplete = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const fgma = sessionStorage.getItem('fgma');
    const forgetMail = fgma ? Decrypto(fgma) : null
    if (!forgetMail) {
        dispatch(push('/'));
    }

    useEffect(() => {
        return() => {
            sessionStorage.removeItem('fgma');
        }
    })
    return (
        <div id="wrapper" className="password_done">
            <PasswordForgetCompleteHead />
            <Header />
            <div id="password_done" className="signup_bg">
                <main>
                    <div className="contents_in">
                        <div className="page_title_ptn01 white">
                            <h1>パスワードを設定しました。</h1>
                        </div>

                        <div className="cnt_area">

                        <div className="text_box_ptn01">
                            <div className="cnt_text">
                                <p>
                                    新しく設定したパスワードでログインください。
                                </p>
                            </div>
                            <div className="btn_ptn03"><MoveOnryButton buttonName={'ログイン'} path={'/login'} /></div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default ForgetComplete