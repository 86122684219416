//個人情報取り扱いページ

import { Footer, Header, PrivacyPolicyHead } from "../../htmlParts"
import {useInView} from 'react-intersection-observer';
import privacyPolicyContent from "./privacyPolicyContent";

const PrivacyPolicy = () => {
    document.title = 'プライバシーポリシー|TTS-ID';
    localStorage.privacyPolicy = false;
    window.onscroll = function()
    {
        const scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
        const pageMostBottom = scrollHeight - window.innerHeight;
        const scrollTop =
            document.documentElement.scrollTop || // IE、Firefox、Opera
            document.body.scrollTop;              // Chrome、Safari
    }

    return (
        <div id='wrapper'>
            <PrivacyPolicyHead />
            <Header />
            <div id="privacy_policy" className="public_page policy_page">
                <main>
                    <div className="page_contents">
                        <div className="contents_in">
                            <h1>TTS-ID及びBTHacksに関するプライバシーポリシー</h1>
                            <p>株式会社トッパントラベルサービス（以下、「当社」といいます）は、TTS-ID及びBTHacks上において提供するサービス（以下、「本サービス」といいます）における、会員の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。</p>
                            {privacyPolicyContent.map((section, index) => (
                                <div key={index}>
                                  <h2>{section.title}</h2>
                                  <p>{section.content}</p>
                                </div>
                            ))}
                            <p className="align_right">以上</p>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}
export default PrivacyPolicy