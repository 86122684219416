import * as Actions from './actions';
import initialState from '../Store/initialState';

export const ErrorsReducer = (state = initialState.errors, action) => {
    switch(action.type){
        case Actions.LOGIN_ERROR:
            return{
                ...state,
                ...action.payload
            };
        case Actions.SET_ERROR:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}