//会社概要ページ

import { CompanyHead, Footer, Header } from "../../htmlParts"

const Company = () => {
    document.title = '運営会社|TTS-ID';
    return (
        <div id="wrapper">
            <CompanyHead />
            <Header />
            <div id="company" className="public_page policy_page">
                <main>
                    <div className="page_contents">
                        <div className="contents_in">
                            <h1>運営会社</h1>
                            <dl>
                                <dt><h2>社名</h2></dt>
                                <dd><p>株式会社トッパントラベルサービス<br />（英文社名：TOPPAN TRAVEL SERVICE CORPORATION）</p></dd>
                                <dt><h2>設立</h2></dt>
                                <dd><p>昭和36年（1961）8月1日</p></dd>
                                <dt><h2>登録</h2></dt>
                                <dd><p>観光庁長官登録旅行業51号</p></dd>
                                <dt><h2>資本金</h2></dt>
                                <dd><p>1億円</p></dd>
                                <dt><h2>主たる取引銀行</h2></dt>
                                <dd><p>三井住友銀行 浜松町支店</p></dd>
                                <dt><h2>本社所在地</h2></dt>
                                <dd><p>〒105-0013　<br className="sp" />東京都港区浜松町2-6-2 浜松町262ビル 受付8階<br />電話：03-4570-0600　FAX：03-5403-2502</p></dd>
                                <dt><h2>代表者</h2></dt>
                                <dd><p>代表取締役社長　島宗　真太郎</p></dd>
                                <dt><h2>主な業務</h2></dt>
                                <dd>
                                    <ol>
                                        <li><p>1.業務渡航手配事業（BTM：Business Travel Management（ビジネストラベルマネージメント））等<br />海外・国内の業務渡航に関するコンサルティング、航空券・乗車券類の手配・販売、宿泊施設の手配、企業内トラベルデスク</p></li>
                                        <li><p>2.MICE（マイス）事業<br />海外・国内の企業および団体での会議、視察・研修、社員・報奨旅行等の企画、立案、旅程作成、見積書作成、および関連業務手配</p></li>
                                        <li><p>3.訪日観光旅行事業<br />訪日観光団体の視察・観光旅行の旅程作成、見積書作成、および関連業務手配</p></li>
                                        <li><p>4.個人旅行事業<br />募集型企画手配旅行の企画・販売、および受託契約による代理販売業務</p></li>
                                        <li><p>5.海外航空券の卸売・販売（ホールセール）事業</p></li>
                                        <li><p>6.総代理店（GSA）事業</p></li>
                                        <li><p>7.会員組織向けトラベルデスク代行事業</p></li>
                                    </ol>
                                </dd>
                                <dt><h2>その他の手配業務</h2></dt>
                                <dd><p>損害保険代理業、印刷物の仲介売買および斡旋、両替業務</p></dd>
                                <dt><h2>登録加盟団体</h2></dt>
                                <dd><p>IATA（国際航空運送協会）<br />JATA（日本旅行業協会）</p></dd>
                                <dt><h2>従業員数</h2></dt>
                                <dd><p>135名　（2020年7月1日現在）</p></dd>
                            </dl>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default Company