import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import { Decrypto, MoveOnryButton, SignOutButton } from "../functions"
import { getLoginStatus, getNickName } from "../Reduxs/Users/selectors";
import icon01_account from '../assets/img/common/icon01_account.png';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { noLinksData } from "./noLinksData";
import TTS_ID_Log from '../assets/img/common/footer_logo.png';
import { HeaderLinkExists, HeaderLinkNone, LoginHeaderRight, NotLoginHeaderRight } from "./headerParts";

const Header = () => {

    /*
        $(function(){
            $(".header_right li.account").on("click",function(){
                $(".account_nav").fadeToggle(200);
            });
            });
            
             $(function(){
            $(".setting_page .btn_open").on("click",function(){
                $(".setting_page .side_nav").addClass('show');
            });
            
            $(".setting_page .btn_close").on("click",function(){
                $(".setting_page .side_nav").removeClass('show');
            });
            });
    */

    const selector = useSelector((state) => state);

    // ログイン状況の確認
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const loginUser = SessionUserString ? Decrypto(SessionUserString) : null;

    //URL取得
    const URL = useLocation().pathname;

    // 初期値
    let headerRight = <></>;
    let headerClassName = 'login';
    let headerLeft = <></>;
    let path = '/';

    if (loginUser) {
        headerRight = <LoginHeaderRight />;
        path = '/top';
    } else {
        headerRight = <NotLoginHeaderRight />;
    }

    if (noLinksData.includes(URL)) {
        // リンクなしヘッダー
        headerClassName = 'no_links';
        headerLeft = <HeaderLinkNone path={path} />;
        headerRight = <></>;
    } else {
        // リンクありヘッダー
        headerLeft = <HeaderLinkExists path={path}/>;
    }

    return (
        <header className={headerClassName}>
            <div className="contents_in">
                {headerLeft}
                {headerRight}
            </div>
        </header>
    )
}

export default Header