import * as Actions from './actions';
import initialState from '../Store/initialState';

export const UsersReducer = (state = initialState.users, action) => {
    switch (action.type) {
        case Actions.LOGIN:
            return {
                ...state,
                ...action.payload
            }
        case Actions.ENTRY_FORM:
            return {
                ...state,
                ...action.payload
            }
        case Actions.CLEAR_FORM:
            return {
                ...state,
                ...action.payload
            }
        case Actions.SIGN_UP:
            return {
                ...state,
                ...action.payload
            }
        case Actions.PASSWORD_FORGET:
            return {
                ...state,
                ...action.payload
            }
        case Actions.UPDATE_MAIL_ADDRES:
            return {
                ...state,
                ...action.payload
            }
        case Actions.UPDATE_PASSWORD:
            return {
                ...state,
                ...action.payload
            }
        case Actions.SET_GOOGLE_ID:
            return {
                ...state,
                ...action.payload
            }
        case Actions.SET_TWITTER_ID:
            return {
                ...state,
                ...action.payload
            }
        case Actions.UPDATE_FLG:
            return {
                ...state,
                ...action.payload
            }
        case Actions.UPDATE_FLG_CLEAR:
            return {
                ...state,
                ...action.payload
            }
        case Actions.SET_NICKNAME:
            return {
                ...state,
                ...action.payload
            }
        case Actions.SET_FROM_ACTION:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}