import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { entryFormAction, setFromAction } from '../../../Reduxs/Users/actions';
import {
    getNickName,
    getGender,
    getYear,
    getMonth,
    getDay,
    getNativeLangId,
    getContinent,
    getContinentId,
    getCountry,
    getCountryId,
    getCountryFlag,
    getArea,
    getAreaId,
    getAreaFlag,
    getSelectFlg,
    getSetDispFrom,
    getNativeLangValue,
    getComeFrom,
    getNativeLang,
    getUserAll,
} from "../../../Reduxs/Users/selectors";
import { Footer, Header, NewRegistrationFormHead, SPConfirm } from '../../../htmlParts';
import {
    changedNickName,
    changedBirthday,
    changedNativeLang,
    numCheck,
    Decrypto,
} from '../../../functions';
import clogo02 from '../../../assets/img/common/logo02.png';
import logo01 from '../../../assets/img/signup/logo01.png';
import logo02 from '../../../assets/img/signup/logo02.png';

import { asyncGetFromParts, asyncGetLangParts } from '../../../functions/asyncFunctions';
import DrilDownSample from '../../../htmlParts/inputs/drilDownSample';

const Form = () => {

    document.title = 'アカウント情報登録画面｜TTS-ID';

    const dispatch = useDispatch();
    const selector = useSelector(state => state);

    const newRegistTmp = sessionStorage.getItem('newRegistration');
    if(!newRegistTmp){
      window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
    }

    let froms = localStorage.getItem('fromsParts');
    let langs = localStorage.getItem('langsParts');

    froms = froms ? Decrypto(froms) : null;
    langs = langs ? Decrypto(langs) : null;


    const fromsParts = froms ? JSON.parse(froms) : [];
    const langsParts = langs ? JSON.parse(langs) : [];

    const continentOptions = fromsParts.froms.continentOptions;
    const countryOptions = fromsParts.froms.countryOptions;
    const areaOptions = fromsParts.froms.areaOptions;

    //state管理用宣言
    const sessionNickName = sessionStorage.getItem('NickName') ? Decrypto(sessionStorage.getItem('NickName')) : ''
    const [nickname, setNickName] = useState<string>(sessionNickName);
    const [year, setYear] = useState<string>(getYear(selector));
    const [month, setMonth] = useState<string>(getMonth(selector));
    const [day, setDay] = useState<string>(getDay(selector));
    const [gender, setGender] = useState<string>(getGender(selector));
    const [nativeLangKey, setNativeLangKey] = useState<string>(getNativeLangId(selector));
    const [nativeLangValue, setNativeLangValue] = useState<string>(getNativeLangValue(selector) ? getNativeLangValue(selector) : '');
    const [continent, setContinent] = useState<string>(getContinent(selector));
    const [country, setCountry] = useState<string>(getCountry(selector));
    const [area, setArea] = useState<string>(getArea(selector));

    // セレクトボックスの初期値用
    const [nativeLangId, setNativeLangId] = useState<string>(getNativeLangId(selector) ? getNativeLangId(selector) : '');
    const [continentId, setContinentId] = useState<string>(getContinentId(selector));
    const [countryId, setCountryId] = useState<string>(getCountryId(selector));
    const [countryFlg, setCountryFlg] = useState<boolean>(getCountryFlag(selector));
    const [areaId, setAreaId] = useState<string>(getAreaId(selector));
    const [areaFlg, setAreaFlg] = useState<boolean>(getAreaFlag(selector));
    
    const [inputWord, setInputWord] = useState<string>(getComeFrom(selector) ? getComeFrom(selector) : '出身国を選択ください。');

    const [fromFlg, setFromFlg] = useState<boolean>(true);
    const [langOptions, setLangOptions] = useState<object[]>([{ id: null, value: null, label: null }]);
    const [langFlg, setLangFlg] = useState<boolean>(true);

    const [selectDisp, setSelectDisp] = useState<boolean>(getSelectFlg(selector));

    //バリデーション用変数と関数宣言ここから
    const [nicknameError, setNicknameError] = useState<string>('');
    const [yearErr, setYearErr] = useState<string>('');
    const [monthErr, setMonthErr] = useState<string>('');
    const [dayErr, setDayErr] = useState<string>('');
    const [birthdayErr, setBirthdayErr] = useState<string>('');

    const [nLangError, setNLangError] = useState<string>('');
    const [cFromError, setCFromError] = useState<string>('');
    //バリデーション用変数と関数宣言ここまで

    // 性別チェックボックス処理
    let genderCheckMan = true;
    let genderCheckWoman = false;
    if (gender === '1') {
        genderCheckMan = false;
        genderCheckWoman = true;
    }

    useEffect(() => {
        // 初回レンダー後にチェックボックスの初期値を入れておく
        if (!gender) {
            setGender('0');
        }
    }, [])

    //state変更処理系
    const changedYear = (e: any) => {
        if(!numCheck.test(e.target.value)){
            const y:string = e.target.value;
            e.target.value = y.slice(0, -1);
        }
        const dateType = 'year';
        const temp = e.target.value;
        const values = {
            year: temp,
            month: month,
            day: day
        }
        changedBirthday(
            dateType,
            values,
            setYear,
            setYearErr,
            setBirthdayErr
        )
    }
    const changedMonth = (e: any) => {
        if(!numCheck.test(e.target.value)){
            const y:string = e.target.value;
            e.target.value = y.slice(0, -1);
        }
        const dateType = 'month';
        const temp = e.target.value;
        const values = {
            year: year,
            month: temp,
            day: day
        }
        changedBirthday(
            dateType,
            values,
            setMonth,
            setMonthErr,
            setBirthdayErr
        )
    }
    const changedDay = (e: any) => {
        if(!numCheck.test(e.target.value)){
            const y:string = e.target.value;
            e.target.value = y.slice(0, -1);
        }
        const dateType = 'day';
        const temp = e.target.value;
        const values = {
            year: year,
            month: month,
            day: temp
        }
        changedBirthday(
            dateType,
            values,
            setDay,
            setDayErr,
            setBirthdayErr
        )
    }
    delete continentOptions[0];
    delete countryOptions[0];
    const displaySelect = () => {
        if (getSelectFlg(selector)) {
            return (
                <div className='parent-div'>
                    <DrilDownSample
                        continentOptions={continentOptions}
                        countryOptions={countryOptions}
                        areaOptions={areaOptions}
                        setInputWord={setInputWord}
                        setContinentId={setContinentId}
                        setCountryId={setCountryId}
                    />
                </div>
            )
        } else {
            return undefined;
        }
    }

    //画面表示処理ここから
    if(newRegistTmp){
        return (
            <div id="wrapper">
                <NewRegistrationFormHead />
                <Header/>
                <div id="signup" className="signup_bg">
                <main>
                    <div className="contents_in">
                    <div className="page_title_ptn01 white">
                        <h1>TTS-IDプロフィール入力</h1>
                        <p className="first_text">TTS-IDプロフィールを入力ください。</p>
                    </div>
        
                    <div className="cnt_area">
                        <div className="text_box_ptn01">
                        <h2>プロフィール入力</h2>
                        <div className="form">
                            <dl className="form_g">
                            <dt className="form_text">お名前<span className="required">必須</span></dt>
                            <dd>
                                <input
                                    type='text' maxLength={50} placeholder='お名前を入力' defaultValue={nickname}
                                    onChange={(e: any) => changedNickName(e, setNickName, setNicknameError)}
                                />
                                <div className="attention_text">※ログイン後にお名前として表示されます。</div>
                                <div className="form_error_text">{nicknameError}</div>
                            </dd>
                            <dt className="form_text">生年月日<span className="required">必須</span></dt>
                            <dd>
                                <input
                                    type="text" maxLength={4} pattern='^1[9][2-9][0-9]|20[012][0-9]$' placeholder="2000"
                                    className="year" defaultValue={year} onChange={(e: any) => changedYear(e)}
                                />年
                                <input
                                    type="text" maxLength={2} pattern='^(0[1-9]|1[0-2])$' placeholder="01"
                                    className="month" defaultValue={month} onChange={(e: any) => changedMonth(e)}
                                />月
                                <input type="text" maxLength={2} pattern='^(0[1-9]|[12][0-9]|3[01])$' placeholder="01"
                                    className="day" defaultValue={day} onChange={(e: any) => changedDay(e)}
                                />日
                                <div className="form_error_text">
                                    {
                                        (!yearErr && !monthErr && !dayErr) ? birthdayErr
                                        : yearErr ? yearErr
                                        : monthErr ? monthErr
                                        : dayErr ? dayErr 
                                        : birthdayErr ? birthdayErr
                                        : ''
                                    }
                                </div>
                                {/* <div className="form_error_text">{birthdayErr}</div> */}
                            </dd>
                            <dt className="form_text">性別<span className="required">必須</span></dt>
                            <dd>
                                <div className="radio">
                                    <input
                                        id="male" name="gender" type="radio" value="男性"
                                        defaultChecked={genderCheckMan} onChange={() => setGender('0')}
                                    />
                                    <label htmlFor="male">男性</label>
                                </div>
                                <div className="radio">
                                    <input
                                        id="female" name="gender" type="radio" value="女性"
                                        defaultChecked={genderCheckWoman} onChange={() => setGender('1')}
                                    />
                                    <label htmlFor="female">女性</label>
                                </div>
                            </dd>
                            <dt className="form_text">第一言語<span className="required">必須</span></dt>
                            <dd>
                                <select
                                    name="native_language"
                                    className="select"
                                    defaultValue={nativeLangId + ',' + nativeLangValue}
                                    onChange={(e: any) =>
                                        changedNativeLang(
                                            e,
                                            setNativeLangKey,
                                            setNativeLangValue,
                                            setNLangError,
                                            setNativeLangId,
                                        )
                                    }>
                                    {
                                        Object.keys(langsParts.langOptions).map((key, index) => {
                                            return (
                                                <option key={index} value={langsParts.langOptions[key].value + ',' + langsParts.langOptions[key].label}>{langsParts.langOptions[key].label}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="form_error_text">{nLangError}</div>
                            </dd>
                            <dt className="form_text">出身国<span className="required">必須</span></dt>
                            <dd className="country">
                                <input 
                                    type='text'
                                    className='cursor'
                                    readOnly
                                    value={inputWord}
                                    onClick={() => {
                                        dispatch(setFromAction({
                                            select_disp_flg: !selectDisp,
                                            set_disp_from: getSetDispFrom(selector)
                                        }));
                                        setSelectDisp(!selectDisp);
                                    }}
                                />
                                {displaySelect()}
                                <div className="form_error_text">{cFromError}</div>
                            </dd>
                            </dl>
                            <div className="btn_submit">
                                <button onClick={() => {
                                    dispatch(entryFormAction({
                                        tf_nickname: nickname,
                                        tf_birthday: year + month + day,
                                        b_year: year,
                                        b_month: month,
                                        b_day: day,
                                        tf_gender: gender,
                                        tf_native_lang: nativeLangKey,
                                        tf_native_lang_id: nativeLangId,
                                        tf_native_lang_value: nativeLangValue,
                                        tf_come_from: getSetDispFrom(selector) ?? getComeFrom(selector),
                                        tf_continent: getContinent(selector),
                                        tf_continent_id: continentId,
                                        tf_country: getCountry(selector),
                                        tf_country_id: countryId,
                                        tf_country_flag: countryFlg,
                                        tf_area: area,
                                        tf_area_id: areaId,
                                        tf_area_flag: areaFlg,
                                    }));
                                    dispatch(push('/newRegistration/inputConfirmation'))}}
                                    disabled={
                                        !(birthdayErr === '') ||
                                        !(nLangError === '') ||
                                        !(nicknameError === '') ||
                                        !nickname || 
                                        !year || 
                                        !month || 
                                        !day || 
                                        !gender || 
                                        !nativeLangKey || 
                                        !nativeLangValue || 
                                        !(getSetDispFrom(selector) || 
                                        getComeFrom(selector))
                                    }
                                >入力内容を確認</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        return (
            <></>
        )
    }
    
    //画面表示処理ここまで
}

export default Form