import { loginOnlyPage } from "../htmlParts";
import { asyncDevAPI, asyncServerTime } from "./asyncFunctions";
import { Decrypto, Encrypto } from "./Crypto";

export const LoginData = (refreshToken, keepStatus) => {
    asyncServerTime()
        .then((res:any) => {
            const tomorrow = res.setDate(res.getDate() + 1);
            const oneMonth = res.setDate(res.getDate() + 30);
            let params:object;
            if(keepStatus){
                params ={
                    refreshToken: refreshToken,
                    expiration: oneMonth,
                    accessTokenExpiration:tomorrow,
                    keepLogin: 'keep',
                }
            } else {
                params = {
                    refreshToken: refreshToken,
                    expiration: tomorrow,
                    keepLogin: 'not_keep',
                }
            }
            const paramsJsonStr = JSON.stringify(params);
            localStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(paramsJsonStr);
        })
        .catch(err => {
            console.log(err);
        }
    );
}

export const GetAccessToken = () => {
    const luEnc = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
    const suEnc = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
    let luDec;
    let luDecJson;
    let suDec;
    let suDecJson = {
        accessToken : '',
        verifyCount: 6,
    };
    if(luEnc){
        luDec = Decrypto(luEnc);
        luDecJson = JSON.parse(luDec);
    } else {
        sessionStorage.clear();
        localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);
        window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
    }
    if(suEnc){
        suDec = Decrypto(suEnc);
        suDecJson = JSON.parse(suDec);
    }
    const params = {
        apiname: 'source_cognito_token_get',
        RefreshToken: luDecJson.refreshToken,
    }
    asyncDevAPI(params)
        .then((res:any) => {
            const body = res.body;
            if(body.status === 200){
                suDecJson.accessToken = body.response.AccessToken;
                const suparams = JSON.stringify(suDecJson);
                sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(suparams);
                asyncServerTime()
                    .then((res:any) => {
                        const tomorrow = res.setDate(res.getDate() + 1);
                        luDecJson.accessTokenExpiration = tomorrow;
                        const luparams = JSON.stringify(luDecJson);
                        localStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(luparams);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        })
        .catch(err => {
            console.log(err);
        });
}


export const GetLocalUser = (URL = '') => {
    return new Promise((resolve, reject) => {
        const luEnc = localStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
        const suEnc = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
        let luDec;
        let luDecJson;
        let suDec;
        let suDecJson = {
            accessToken : '',
            verifyCount: 6,
        };

        if(luEnc){
            luDec = Decrypto(luEnc);
            luDecJson = JSON.parse(luDec);
        } else if(loginOnlyPage.includes(URL)){
            sessionStorage.clear();
            localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);
            window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
        } else {
            return ;
        }
        if(suEnc){
            suDec = Decrypto(suEnc);
            suDecJson = JSON.parse(suDec);
        }
    
        const params = {
            apiname: 'source_cognito_token_get',
            RefreshToken: luDecJson.refreshToken,
        }
        asyncDevAPI(params)
            .then((res:any) => {
                const body = res.body;
                suDecJson.accessToken = body.response.AccessToken;
                const suparams = JSON.stringify(suDecJson);
                sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(suparams);
                if(body.status === 200){
                    asyncServerTime()
                        .then((res:any) => {
                            const tomorrow = res.setDate(res.getDate() + 1);
                            luDecJson.accessTokenExpiration = tomorrow;
                            const luparams = JSON.stringify(luDecJson);
                            localStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(luparams);
                            return resolve(suDecJson);
                        })
                        .catch(err => {
                            console.log(err);
                            return reject(err);
                        });
                }
            })
            .catch(err => {
                console.log(err);
                return reject(err);
            });
    });
}



export const TokenExpirationCheck = (rExpirationDate, aExpirationDate) => {
    asyncServerTime()
        .then((res:any) => {
            const now = res.getTime();
            if(now > rExpirationDate){
                localStorage.removeItem(process.env.REACT_APP_STORAGE_KEY_NAME);
                sessionStorage.clear();
                window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
            }
            if(aExpirationDate !== '' && now > aExpirationDate){
                GetAccessToken();
            }
        })
        .catch(err => {
            console.log(err);
        }
    );
}

export const LocalStorageLimitCheck = (sessionUser) => {
    return new Promise((resolve, reject) => {
        // リフレッシュトークンの有無チェック
        if (!sessionUser) {
            const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
            const LocalUserString = localStorage.getItem(envStorageKey) ? Decrypto(localStorage.getItem(envStorageKey)) : null;
            const LocalUserObj = JSON.parse(LocalUserString);
            if (LocalUserObj) {
                // リフレッシュトークンの期限チェック
                TokenExpirationCheck(LocalUserObj.expiration, LocalUserObj.accessTokenExpiration);
            }
        }
    });
}