export const HANDLE_OK = 'HANDLE_OK';
export const handleOkAction = (captchaState) => {
    return {
        type: 'HANDLE_OK',
        payload: {
            token: captchaState.token,
        }
    }
}

export const HANDLE_CLEAR = 'HANDLE_CLEAR';
export const handleClearAction = () => {
    return {
        type: 'HANDLE_CLEAR',
        payload: {
            token: '',
        }
    }
}