const InputPassword = ({passDisp, changedPasswordHandler, setPassDisp, placeholder = 'パスワードを入力'}) => {
  const togglePassword = () => {
    setPassDisp(!passDisp);
  }
  return (
    <div className="pas">
      <input type={passDisp ? 'text' : 'password'} id="input_pass" name="input_pass" maxLength={256} placeholder={placeholder} onChange={changedPasswordHandler}/>
      <span
        onClick={togglePassword}
        role='presentation'
        id="btn_passview"
      >
        {passDisp ? (
          <i className='fas fa-eye' />
        ) : (
          <i className='fas fa-eye-slash' />
        )}
      </span>
    </div>
  )
}
export default InputPassword