import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "../templates/account";

export const EditWarnModal = () => {
    const dispatch = useDispatch();

    const sessionIsModal = sessionStorage.getItem('isModal');
    const [disp, setDisp] = useState<boolean>(Boolean(sessionIsModal) ?? 0);

    // 現在のパス取得
    const sessionNowPath = sessionStorage.getItem('NowPath') ?? '';
    // 遷移先のパス取得
    const sessionNextPath = sessionStorage.getItem('NextPath') ?? '';
    // cancelを押したかのフラグ
    const sessionNoButton = sessionStorage.getItem('CancelButton') ?? '';
    // Myタグ保存ボタン下のフラグ
    const sessionMyTagEdit = sessionStorage.getItem('myTagEdit');

    const isSave = (saveFlag) => {
        switch (saveFlag) {
            case 'save':
                // 保存する場合は、変更保存flagを立てる
                sessionStorage.setItem('editSaveOn', 'true');

                // window.location.href = sessionNowPath;
                sessionStorage.removeItem('isModal');
                window.location.reload();
                break;
            case 'notSave':
                // 保存しない押下時は保存しておいた変更内容を削除
                // プロフィール画面
                sessionStorage.removeItem('NickName');
                sessionStorage.removeItem('year');
                sessionStorage.removeItem('month');
                sessionStorage.removeItem('day');
                sessionStorage.removeItem('Gender');
                sessionStorage.removeItem('NativeLang');
                sessionStorage.removeItem('dispContinent');
                sessionStorage.removeItem('continentTmpId_CountryId');
                sessionStorage.removeItem('countryTmpId_AreaTmpId');
                // Myタグ
                sessionStorage.removeItem('Btd');
                sessionStorage.removeItem('CheckList');
                // 通知
                sessionStorage.removeItem('ReceiveFlg');
                sessionStorage.removeItem('DestinationFlg');
                sessionStorage.removeItem('TagFlg');
                sessionStorage.removeItem('myTagEdit');
                sessionStorage.removeItem('BReceiveFlg');
                sessionStorage.removeItem('BDestinationFlg');
                sessionStorage.removeItem('BTagFlg');
                dispatch(push(sessionNextPath));
                // window.location.href = sessionNextPath;
                sessionStorage.removeItem('isModal');
                window.location.reload();
                break;
            case 'cancel':
                // キャンセルの場合は、モーダルを非表示にする
                // window.location.href = sessionNowPath;
                sessionStorage.removeItem('isModal');
                sessionStorage.removeItem('nextPath');
                window.location.reload();
                // dispatch(push(sessionNowPath));
                break;
        }
    }

    return (
        <div className="page_exit modal" style={{ display: disp ? "block" : "none" }}>
            <div className="mail_change_ptn02">
                <div className="contents_in">
                    <div className="page_title_ptn01">
                    </div>
                    <div className="text_box_ptn01 form_box_ptn03">
                        <div className="title title03">変更はまだ保存されていません</div>
                        <div className="cnt_text">
                            <p>移動してもよろしいですか？変更が保存されていません。 移動すると変更内容が失われます。</p>
                        </div>
                        <div className="btn_g">
                            <div className="btn_ptn02"><a onClick={() => isSave('notSave')}>保存しない</a></div>
                            <div className="btn_submit"><input type="button" value="保存する" onClick={() => isSave('save')} /></div>
                        </div>
                        <div className="link link_close"><span onClick={() => isSave('cancel')} className="link_ptn01">キャンセル</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}