import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changedEmailHaldler,
    GoogleRecaptcha,
    asyncDevAPI,
    limitExceededErrWord,
    passwordForgotSendMailErrWord,
    Encrypto,
} from '../../../functions';
import { Footer, Header, PasswordForgetHead } from '../../../htmlParts';
import { getToken } from '../../../Reduxs/Recaptcha/selectors';
import { passwordForgetAction } from '../../../Reduxs/Users/actions';
import { handleClearAction } from '../../../Reduxs/Recaptcha/actions';
import { clearLoadingAction, setLoadingAction } from '../../../Reduxs/Loading/actions';
import logo02 from '../../../assets/img/common/logo02.png';
import { Link } from 'react-router-dom';
import { setErrorAction } from '../../../Reduxs/Errors/actions';

const PasswordForget = () => {

    document.title = 'パスワードをリセット|TTS-ID';

    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const reChaptchaToken = getToken(selector);

    const [email, setEmail] = React.useState<string>('');
    const [emailError, setEmailError] = React.useState<string>('');
    const [errMsg, setErrMsg] = React.useState<string>('');

    const passwordForgot = () => {
        // ローディング開始
        dispatch(setLoadingAction({ isLoading: true }));
        const params = {
            apiname: 'source_cognito_forgot_password_post',
            user_name: email,
        };
        asyncDevAPI(params)
            .then((res: any) => {
                const result: any = res;
                const statusCode = result['body']['status'];
                if (statusCode === 200) {
                    // SessionStorageに保存
                    sessionStorage.setItem('fgma', Encrypto(email));
                    // リキャプチャトークンの初期化
                    dispatch(handleClearAction());
                    // ローディング終了
                    dispatch(clearLoadingAction());
                    dispatch(push('/forgetNewPassword'));
                } else {
                    // ローディング終了
                    dispatch(clearLoadingAction());
                    dispatch(setErrorAction({
                        errorCode: res.body.status
                    }));
                    dispatch(push('/error'));
                }
            })
            .catch(err => {
                switch (err.errorType) {
                    case 'LimitExceededException':
                        setErrMsg(limitExceededErrWord);
                        break;
                    default:
                        setErrMsg(passwordForgotSendMailErrWord);
                        break;
                }
                // ローディング終了
                dispatch(clearLoadingAction());
            });
    }

    return (
        <div id="wrapper">
            <PasswordForgetHead />
            <Header />
            <div id="password_forget" className="signup_bg">
                <main>
                    <div className="contents_in">
                        <div className="page_title_ptn01 white">
                            <h1 className="title blue">パスワードをリセット</h1>
                            <p>パスワードをお忘れですか？<br />パスワードをリセットしたいメールアドレスを入力ください。<br />本人確認のため、入力いただいたメールアドレスに確認コードを送ります。</p>
                        </div>

                        <div className="text_box_ptn01 form_box_ptn02">
                            <div className="form">
                                <dl className="form_g">
                                    <dt className="form_text">メールアドレス</dt>
                                    <dd>
                                        <input type='text' placeholder={'メールアドレスを入力'} onChange={(event) => changedEmailHaldler(event, setEmail, setEmailError)} />
                                        <div className="form_error_text">{emailError}</div>
                                        <div className="form_error_text">{errMsg}</div>
                                    </dd>
                                </dl>
                                <div className="recaptcha">
                                    <GoogleRecaptcha />
                                </div>
                                <div className="btn_submit">
                                    <input
                                        value='確認コードを送信'
                                        type='submit'
                                        disabled={!email || !reChaptchaToken}
                                        onClick={passwordForgot}
                                    />
                                </div>
                            </div>
                            <div className="support_links">
                                <div className="link"><Link className="link_ptn01" onClick={() => dispatch(push('/login'))}>ログインページ</Link></div>
                                <div className="link"><Link className="link_ptn01" onClick={() => dispatch(push('/faq'))}>サポートページへ</Link></div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default PasswordForget