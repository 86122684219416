import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux"
import {AccountNotificationHead, Footer, Header} from '../../htmlParts';
import { SideBar } from ".";
import illust03 from '../../assets/img/setting/illust03.png';
import { useEffect, useState } from "react";
import { asyncDevAPI, Decrypto, Encrypto, ErrorSwitchFunctions } from "../../functions";
import { getAPIAction, getIsModalOpen } from "../../Reduxs/PageAct/selectors";
import { apiFinAction, apiStartAction, hanbergerOpenAction } from "../../Reduxs/PageAct/actions";
import { setErrorAction } from "../../Reduxs/Errors/actions";
import ScrollAnimation from 'react-animate-on-scroll';

//アカウントページ通知設定ページ

const Notification = () =>{
    document.title = '通知|TTS-ID';
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    // アクセストークの有無チェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = SessionUserObj ? JSON.parse(SessionUserObj) : null;
    const ndTmp = sessionStorage.getItem('nd');
    const nd:string = ndTmp ? Decrypto(ndTmp) : null;

    const notificationData = nd ? JSON.parse(nd) : '';

    let receive = false;
    let destination = false;
    let tag = false;
    let bReceive = false;
    let bDestination = false;
    let bTag = false;

    // sessionの保存したデータを取得する
    const ReceiveFlg = sessionStorage.getItem('ReceiveFlg') ? Decrypto(sessionStorage.getItem('ReceiveFlg')) : null;
    const DestinationFlg = sessionStorage.getItem('DestinationFlg') ? Decrypto(sessionStorage.getItem('DestinationFlg')) : null;
    const TagFlg = sessionStorage.getItem('TagFlg') ? Decrypto(sessionStorage.getItem('TagFlg')) : null;
    const BReceiveFlg = sessionStorage.getItem('BReceiveFlg') ? Decrypto(sessionStorage.getItem('BReceiveFlg')) : null;
    const BDestinationFlg = sessionStorage.getItem('BDestinationFlg') ? Decrypto(sessionStorage.getItem('BDestinationFlg')) : null;
    const BTagFlg = sessionStorage.getItem('BTagFlg') ? Decrypto(sessionStorage.getItem('BTagFlg')) : null;

    if(notificationData !== ''){
        const mail_notif = notificationData.mail;
        const browser_notif = notificationData.browser;
        receive = ReceiveFlg ? Boolean((ReceiveFlg === 'true') ? 1 : 0) : Boolean(mail_notif.receive);
        destination = DestinationFlg ? Boolean((DestinationFlg === 'true') ? 1 : 0) : Boolean(mail_notif.destination);
        tag = TagFlg ? Boolean((TagFlg === 'true') ? 1 : 0) : Boolean(mail_notif.tag);
        bReceive = BReceiveFlg ? Boolean((BReceiveFlg === 'true') ? 1 : 0) : Boolean(browser_notif.receive);
        bDestination = BDestinationFlg ? Boolean((BDestinationFlg === 'true') ? 1 : 0) : Boolean(browser_notif.destination);
        bTag = BTagFlg ? Boolean((BTagFlg === 'true' ? 1 : 0)) : Boolean(browser_notif.tag);
    }

    const [receiveFlg, setReceiveFlg] = useState<boolean>(receive);
    const [destinationFlg, setDestinationFlg] = useState<boolean>(destination);
    const [tagFlg, setTagFlg] = useState<boolean>(tag);
    const [bReceiveFlg, setBReceiveFlg] = useState<boolean>(bReceive);
    const [bDestinationFlg, setBDestinationFlg] = useState<boolean>(bDestination);
    const [bTagFlg, setBTagFlg] = useState<boolean>(bTag);

    // エラーメッセージ
    const [errMsg, setErrMsg] = useState<string>('');

    // 保存ボタン下のメッセージ
    const [messageboxWordOk, setMessageboxWordOk] = useState<boolean>(false);
    const [messageboxWordNg, setMessageboxWordNg] = useState<boolean>(false);

    const changedReceiveFlg = (event) => {
        setReceiveFlg(event.checked);
        sessionStorage.setItem('ReceiveFlg', Encrypto(event.checked));
        if(!event.checked){
            setDestinationFlg(event.checked);
            setTagFlg(event.checked);
            sessionStorage.setItem('DestinationFlg', Encrypto(event.checked));
            sessionStorage.setItem('TagFlg', Encrypto(event.checked));
        }
    }
    const changedBReceiveFlg = (event) => {
        setBReceiveFlg(event.checked);
        sessionStorage.setItem('BReceiveFlg', Encrypto(event.checked));
        if(!event.checked){
            setBDestinationFlg(event.checked);
            setBTagFlg(event.checked);
            sessionStorage.setItem('BDestinationFlg', Encrypto(event.checked));
            sessionStorage.setItem('BTagFlg', Encrypto(event.checked));
        }
    }

    const saveData = () => {
        dispatch(apiStartAction());
        let mid_mail:number[] = [];
        let mid_browser:number[] = [];
        let mFlg = false;
        let bFlg = false;
        if(receiveFlg){
            mFlg = true;
            mid_mail.push(1);
        }
        if(destinationFlg){
            mFlg = true;
            mid_mail.push(2);
        }
        if(tagFlg){
            mFlg = true;
            mid_mail.push(3);
        }
        if(bReceiveFlg){
            bFlg = true;
            mid_browser.push(1);
        }
        if(bDestinationFlg){
            bFlg = true;
            mid_browser.push(2);
        }
        if(bTagFlg){
            bFlg = true;
            mid_browser.push(3);
        }

        let params;
        if(nd){
            params = {
                apiname: 'source_user_notification_put',
                AccessToken: loginUser.accessToken,
                stop_stime: "18:00",
                stop_etime: "09:00",
                not_notify_week: "0,1,3,6",
            }
            if(mFlg){
                params.mid_mail = mid_mail.join();
            }
            if(bFlg){
                params.mid_browser = mid_browser.join()
            }
        } else {
            params = {
                apiname: 'source_user_notification_post',
                AccessToken: loginUser.accessToken,
            }
            if(mFlg){
                params.mid_mail = mid_mail.join();
            }
            if(bFlg){
                params.mid_browser = mid_browser.join()
            }
        }
        changedNotification(params, loginUser, dispatch);
    }
    const [hanVis, setHanVis] = useState<boolean>(false);
    const changedHanberger = () => {
        dispatch(hanbergerOpenAction());
        setHanVis(!hanVis);
    }

    const changedNotification = (params, loginUser, dispatch) => {
        asyncDevAPI(params)
            .then((res:any) => {
                if(res.body.status === 200) {
                    const result = res.body;
                    const notificationParams = {
                        apiname: 'source_user_notification_get',
                        AccessToken: loginUser.accessToken,
                    }
                    let get_mail_notif:String[] = [];
                    let get_browser_notif:String[] = [];
                    let mail_notif = {
                        receive: false,
                        destination: false,
                        tag: false,
                    };
                    let browser_notif = {
                        receive: false,
                        destination: false,
                        tag: false,
                    };
                    asyncDevAPI(notificationParams)
                        .then((res:any) => {
                            if(res.body.status === 200) {
                                const response = res.body.response;
                                get_mail_notif = response[0].mid_mail.split(',');
                                get_browser_notif = response[0].mid_browser.split(',');
                
                                get_mail_notif.map(item => {
                                    if(item === '1'){
                                        mail_notif.receive = true;
                                    }
                                    if(item === '2'){
                                        mail_notif.destination = true;
                                    }
                                    if(item === '3'){
                                        mail_notif.tag = true;
                                    }
                                });
                                get_browser_notif.map(item => {
                                    if(item === '1'){
                                        browser_notif.receive = true;
                                    }
                                    if(item === '2'){
                                        browser_notif.destination = true;
                                    }
                                    if(item === '3'){
                                        browser_notif.tag = true;
                                    }
                                });
                                const paramsjs = JSON.stringify({
                                    mail: mail_notif,
                                    browser: browser_notif,
                                });
                                sessionStorage.nd = Encrypto(paramsjs);
    
                                // 保存が成功したので、sessionのデータは削除
                                sessionStorage.removeItem('ReceiveFlg');
                                sessionStorage.removeItem('DestinationFlg');
                                sessionStorage.removeItem('TagFlg');
                                sessionStorage.removeItem('BReceiveFlg');
                                sessionStorage.removeItem('BDestinationFlg');
                                sessionStorage.removeItem('BTagFlg');
    
                                dispatch(apiFinAction());
                                // alert('変更しました。');
                                // window.location.reload();
                                setMessageboxWordOk(true);
                            } else {
                                // 200以外のステータス
                                const setMsgs = {
                                    ErrMsg: setErrMsg,
                                }
                                const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                if (errorPage.flg) {
                                    dispatch(setErrorAction({
                                        errorCode: res.body.status
                                    }));
                                    dispatch(push(errorPage.url));
                                }
                                return;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            dispatch(apiFinAction());
                            setMessageboxWordNg(true);
                        });
                } else {
                    // 200以外のステータス
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(apiFinAction());
            });
    }

    useEffect(() => {
        if (sessionStorage.getItem('editSaveOn')) {
            // 警告モーダルで保存するを押下していた場合
            saveData();
            sessionStorage.removeItem('editSaveOn');
        }
    }, [])

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper" className={getIsModalOpen(selector) ? 'no-scroll' : ''}>
                <AccountNotificationHead />
                <Header />
                <div id="notification" className="setting_page">
                    <main>
                        <div className="page_contents">
                            <h1 className="sp">TTS-ID設定</h1>
                            <div className={hanVis ? "btn_open sp act" : "btn_open sp"} onClick={changedHanberger}><span></span><span></span><span></span></div>
                            <SideBar />
                            <div className="main_contents">
                                <div className="contents_in">
                                    <h2 className="h2_ptn01">通知</h2>
                                    <div className="form">
                                        <div className="form_g">
                                            <div className="form_box fbox01">
                                                <div className="form_title">お知らせメール設定</div>
                                                <div className={receiveFlg ? "checkbox parent" : "checkbox parent unchecked"}>
                                                    <input id="receive_checkbox" type="checkbox" defaultChecked={receiveFlg} className="check" onChange={(e: any) => changedReceiveFlg(e.target)} />
                                                    <label htmlFor={'receive_checkbox'}>TTS-IDからのメールを受け取る</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input
                                                        id="destination_checkbox" disabled={!receiveFlg} /*defaultChecked={destinationFlg}*/ checked={destinationFlg} type="checkbox" value="" className="check"
                                                        onChange={
                                                            (e: any) => {
                                                                setDestinationFlg(e.target.checked);
                                                                sessionStorage.setItem('DestinationFlg', Encrypto(e.target.checked));
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor="destination_checkbox">よく行く出張先のメールを受け取る</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input
                                                        id="tag_checkbox" type="checkbox" disabled={!receiveFlg} /*defaultChecked={tagFlg}*/ checked={tagFlg} value="" className="check"
                                                        onChange={
                                                            (e: any) => {
                                                                setTagFlg(e.target.checked);
                                                                sessionStorage.setItem('TagFlg', Encrypto(e.target.checked));
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor="tag_checkbox">興味のあるキーワードのメールを受け取る</label>
                                                </div>
                                            </div>
                                            <div className="form_box fbox03">
                                                <div className="form_title">ブラウザ通知</div>
                                                <div className={bReceiveFlg ? "checkbox parent" : "checkbox parent unchecked"}>
                                                    <input id="browser_receive_checkbox" type="checkbox" defaultChecked={bReceiveFlg} value="" className="check" onChange={(e: any) => changedBReceiveFlg(e.target)} />
                                                    <label htmlFor={'browser_receive_checkbox'}>TTS-IDからのブラウザ通知を受け取る</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input id="browser_destination_checkbox" type="checkbox" disabled={!bReceiveFlg} /*defaultChecked={bDestinationFlg}*/ checked={bDestinationFlg} value="" className="check"
                                                        onChange={
                                                            (e: any) => {
                                                                setBDestinationFlg(e.target.checked);
                                                                sessionStorage.setItem('BDestinationFlg', e.target.checked);
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor={'browser_destination_checkbox'}>よく行く出張先のブラウザ通知を受け取る</label>
                                                </div>
                                                <div className="checkbox">
                                                    <input id="browser_tag_checkbox" type="checkbox" disabled={!bReceiveFlg} /*defaultChecked={bTagFlg}*/ checked={bTagFlg} value="" className="check"
                                                        onChange={
                                                            (e: any) => {
                                                                setBTagFlg(e.target.checked);
                                                                sessionStorage.setItem('BTagFlg', e.target.checked);
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor={'browser_tag_checkbox'}>興味のあるキーワードのブラウザ通知を受け取る</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_error_text">{errMsg}</div>
                                    <div className="btn_submit">
                                        <input
                                            type="submit"
                                            value="変更内容を保存"
                                            disabled={getAPIAction(selector)}
                                            onClick={saveData} />
                                    </div>
                                    <div style={{ display: messageboxWordOk ? 'block' : 'none' }} className="messagebox save">設定を保存しました</div>
                                    <div style={{ display: messageboxWordNg ? 'block' : 'none' }} className="messagebox save error">変更内容を保存できませんでした</div>
                                </div>

                                <div className="cnt_img">
                                    <img src={illust03} alt="" />
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollAnimation
                    offset={0}
                    animateIn='fadeIn'
                    animateOut='fadeOut'
                    duration={1}
                    animatePreScroll={true}
                /*initiallyVisible={true}*/
                >

                    <Footer />
                </ScrollAnimation>

            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Notification
