import React from 'react';
import { useDispatch } from "react-redux"
import { push } from 'connected-react-router';
import {
    changedEmailHaldler,
    Encrypto,
    Decrypto,
    noChangedEmailAddressErrWord,
    asyncDevAPI,
    MoveOnryButton,
    ErrorSwitchFunctions
} from '../../../../functions';
import { Link } from 'react-router-dom';
import { updateMailAddresAction } from '../../../../Reduxs/Users/actions';
import { Footer, Header, NewEmailFormHead } from '../../../../htmlParts';
import { setErrorAction } from '../../../../Reduxs/Errors/actions';

const MailVerification = () => {

    document.title = 'メールアドレス変更|TTS-ID';

    const dispatch = useDispatch();


  let developFlg = true;

  if(process.env.REACT_APP_DOCUMENT_ROOT === 'https://tts-id.com' ||
      process.env.REACT_APP_DOCUMENT_ROOT === 'https://www.tts-id.com'){
      developFlg = false;
  }

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));
    
    // state管理系宣言
    const [email, setEmail] = React.useState<string>('');

    // バリデーション処理ここから
    const [emailError, setEmailError] = React.useState<string>('');
    const [errMsg, setErrMsg] = React.useState<string>('');

    const changeMailAddress = () => {
        // SessionStorageからメールアドレスを取得し複合
        const envEmailKey = process.env.REACT_APP_SESSION_STORAGE_EMAIL ?? '';
        const SessionEmail = Decrypto(sessionStorage.getItem(envEmailKey));
        // 入力したメールアドレスに変更があるかチェック
        if (SessionEmail === email) {
            setErrMsg(noChangedEmailAddressErrWord)
            return;
        }
        dispatch(updateMailAddresAction({
            tf_mail: email,
        }));
        // SessionStorageからアクセストークンを取得
        const SessionAccessToken = loginUser.accessToken;

        const apiParams = {
            apiname: 'source_cognito_put',
            AccessToken: SessionAccessToken,
            type: 'email',
            email: email,
        };
        // メールアドレス変更
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const result: any = res;
                const statusCode = result['body']['status'];

                if (statusCode === 200) {
                    // メールアドレス変更認証画面に遷移
                    // SessionStorage.Emailを更新
                    sessionStorage[envEmailKey] = Encrypto(email);
                    dispatch(push('/account/security/emailChange/emailVerificationCheck'));
                } else {
                    console.log('res: ' + JSON.stringify(res));
                    // 200以外のステータス
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                switch (err.errorType) {
                    case 'AliasExistsException':
                        setErrMsg('指定されたメールアドレスは既に登録済みです。');
                        break;
                    default:
                        setErrMsg('メールアドレス変更に失敗しました。お手数ですがもう一度お試しください。');
                        break
                }
            })
    }
    const buttonAble = () => {
        if(developFlg){
            return (
                <button
                    onClick={() => changeMailAddress()}
                    disabled={!email}
                >確認コードを送信</button>
            )
        } else {
            return (
                <button
                    onClick={() => changeMailAddress()}
                    disabled={!(emailError === '') || !email}
                >メールアドレスを変更</button>
            )
        }
    }

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <NewEmailFormHead />
                <Header />
                <div id="new_mail" className="mail_change">
                    <div className="contents_in">
                        <div className="back">
                            <Link to='/account/security/emailChange/mailVerification'>&lt; 戻る</Link>
                        </div>
                        <div className="text_box_ptn01 form_box_ptn02">
                            <h1 className="title">メールアドレスの変更</h1>
                            <div className="lead">
                                新しく設定するメールアドレスを入力してください。<br />
                                入力いただいたメールアドレスが正しいことを確認するために、確認コードを送信します。
                            </div>
                            <div className='form'>
                                <dl className="form_g">
                                    <dt className="form_text">新しく設定するメールアドレス</dt>
                                    <dd>
                                        <input
                                            type="text"
                                            placeholder="メールアドレスを入力してください。"
                                            onChange={(event) => changedEmailHaldler(event, setEmail, setEmailError)}
                                        />
                                        <div className="form_error_text">
                                            {emailError ? emailError : errMsg}
                                        </div>
                                    </dd>
                                </dl>
                                <div className="btn_submit blue">
                                    {buttonAble()}
                                </div>
                                <div className="btn_ptn02">
                                    <MoveOnryButton buttonName={'キャンセル'} path={'/account/security'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default MailVerification