import { Encrypto } from "./Crypto";
import { 
    codeNotVerifyWord, cognitoEmailIdNotAuthWord, cognitoErrorWord,
    emailNotAvailable, expiredCodeErrWord, lambdaResponseErrorWord,
    limitExceededErrWord, manyRequestWord, notSendCodeMailWord,
    paramErrorWord, passwordNotAuthWord, passwordPolicyError,
    resourceNotFoundWord, securityCodeNotVerifyWord, unexpectedLambdaWord,
    userNotConfirmWord, userNotFoundWord
} from "./RegularExpression";

export const ErrorSwitchFunctions = (errorResBody, setMsgs, page = 'normal') => {
    let errorPage = {
        url: '',
        flg: false,
    };
    let types = {
        mfa: '秘密の質問を設定してください。',
        email: 'エラー文言',
        normal: 'エラーが発生しました。',

    }
    const code = errorResBody.response.Code ? errorResBody.response.Code : null;
    switch (code) {
        case 'LimitExceededException':
            setMsgs['ErrMsg'](limitExceededErrWord);
            setMsgs['Email']('');
            setMsgs['Pass']('');
            break;
        case 'UsernameExistsException':
            setMsgs['Email'](emailNotAvailable);
            setMsgs['Pass']('');
            setMsgs['ErrMsg']('');
            break;
        case 'InvalidPasswordException':
            setMsgs['Email']('');
            setMsgs['ErrMsg']('');
            setMsgs['Pass'](passwordPolicyError);
            break;
        case 'ExpiredCodeException':
            setMsgs['ErrMsg'](expiredCodeErrWord);
            break;
        case "NotAuthorizedException":
                setMsgs['ErrMsg'](passwordNotAuthWord);
                break;
        case "CodeMismatchException":
            setMsgs['ErrMsg'](codeNotVerifyWord);
            break;
        case "AliasExistsException":
            setMsgs['ErrMsg'](emailNotAvailable);
            break;
        case 'CodeDeliveryFailureException':
            setMsgs['ErrMsg'](notSendCodeMailWord);
            break;
        case null:
            setMsgs['ErrMsg'](types[page]);
            break;
        case 'InternalErrorException':
            setMsgs['ErrMsg'](cognitoErrorWord);
            break;
        case 'InvalidEmailRoleAccessPolicyException':
            setMsgs['ErrMsg'](cognitoEmailIdNotAuthWord);
            break;
        case 'InvalidLambdaResponseException':
            setMsgs['ErrMsg'](lambdaResponseErrorWord);
            break;
        case 'InvalidParameterException':
            setMsgs['ErrMsg'](paramErrorWord);
            break;
        case 'ResourceNotFoundException':
            setMsgs['ErrMsg'](resourceNotFoundWord);
            break;
        case 'TooManyRequestsException':
            setMsgs['ErrMsg'](manyRequestWord);
            break;
        case 'UnexpectedLambdaException':
            setMsgs['ErrMsg'](unexpectedLambdaWord);
            break;
        case 'UserNotFoundException':
            setMsgs['ErrMsg'](userNotFoundWord);
            break;
        case 'UserNotConfirmedException':
            setMsgs['ErrMsg'](userNotConfirmWord);
            break;
        default:
            errorPage.url = '/error';
            errorPage.flg = true;
            break;
    }
    return errorPage;
}

export const LoginErrorFunction = (response, email, password, setMsgs) => {
    let params;
    let pageFlg = false;
    switch (response.Code) {
        case 'UserNotConfirmedException':
            params = {
                email: email,
                password: password,
                errorCode: response.Code,
                errorMsg: 'お手数ですがメールの認証を行ってください。',
                errorType: 'no_verification',
            }
            const paramsString = JSON.stringify(params);
            sessionStorage.tempData = Encrypto(paramsString);
            pageFlg = true;
            break;
        default:
            console.log(response);
            break;
    }
    return pageFlg;
}