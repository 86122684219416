import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { fromOptions, langOptions } from "../htmlParts";
import { clearLoadingAction } from "../Reduxs/Loading/actions";
import { setNicknameAction } from "../Reduxs/Users/actions";
import { devAPI, serverTime, TwitterAccessToken, TwitterAPI, snsChallengeAPI, serverTiMemillisecond, testGeolocation, GeocodingAPI} from "./API";
import { Encrypto } from "./Crypto";
import { GetLocalUser, LocalStorageLimitCheck } from "./LoginData";

export const asyncDevAPI = async (params:object) => {
    return await devAPI(params);
}
//サーバータイムの取得
export const asyncServerTime = async () => {
    return await serverTime();
}
//サーバータイムの取得(ミリ秒)
export const asyncServerTimeMillisecond = async () => {
    return await serverTiMemillisecond();
}

// ログイン周り
export const asyncLocalStorageLimitCheck = async (sessionUser) => {
    return await LocalStorageLimitCheck(sessionUser);
}

export const asyncGetLocalUser = async (URL) => {
    return await GetLocalUser(URL);
}
// GeolocationAPI
export const asyncTestGeolocation = async () => {
    return await testGeolocation();
}

export const asyncGeocoding = async(latlng) => {
    return await GeocodingAPI(latlng);
}

//Twitterアクセストークンの取得
export const asyncTwitterAccessToken = async(params:any) => {
    const {oauth_token, oauth_verifier} = params;
    return await TwitterAccessToken(oauth_token, oauth_verifier);
}

export const asyncTwitterAPI = async (CallBackURL) => {
    return await TwitterAPI(CallBackURL);
}

export const asyncGetFromParts = async () => {
    return await fromOptions();
}
export const asyncGetLangParts = async () => {
    return await langOptions();
}

export const asyncSnsChallenge = async (body, target) => {
    return await snsChallengeAPI(body, target);
}