import { useSelector } from 'react-redux';
import pngError from '../assets/img/common/error.png';
import { useHistory, Link } from 'react-router-dom';
import { ErrorAther, Footer, Header } from "../htmlParts"
import { getErrorCode } from '../Reduxs/Errors/selectors';

//エラーページ
const Error = () => {
    const selector = useSelector((state) => state);
    const history = useHistory();

    const loginUser = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
    let url:string;
    if(loginUser){
        url = '/top';
    } else {
        url = '/';
    }
    return (
        <div id="wrapper">
            <ErrorAther />
            <Header />
            <div id="error404">
                <main>
                    <div className="contents_in">
                        <div className="page_title_ptn01">
                            <h1>ERROR</h1>
                            <span style={{color: "red"}}>{getErrorCode(selector)}</span>
                            <p className="first_text">予期せぬエラーが発生しました</p>
                        </div>
                        <div className="cnt_img"><img src={pngError} alt="error" /></div>
                        <p>大変申し訳ございませんが、<br className="sp" />処理中に問題が発生したため、<br className="pc" />
                                ページが表示できません<br />
                                お手数ですがもう一度お試しください。</p>
                        <div className="btn_box">
                            <div className="btn_ptn02 btn"><Link onClick={() => history.goBack()}>前のページへ戻る</Link></div>
                            <div className="btn_ptn02 btn"><Link to={url}>TTS-ID トップに戻る</Link></div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default Error