export const SET_LOADING = 'SET_LOADING';
export const setLoadingAction = (loadingState) => {
    return {
        type: 'SET_LOADING',
        payload: {
            isLoading: loadingState.isLoading,
        }
    }
}

export const CLEAR_LOADING = 'CLEAR_LOADING';
export const clearLoadingAction = () => {
    return {
        type: 'CLEAR_LOADING',
        payload: {
            isLoading: false,
        }
    }
}