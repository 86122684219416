export const loginOnlyPage = [
    '/top',
    '/account',
    '/account/user',
    '/account/notification',
    '/account/security',
    '/account/security/emailChange/emailVerificationCheck',
    '/account/security/emailChange/mailVerification',
    '/account/security/emailChange/mailEdit',
    '/account/security/emailChange/mailSendMail',
    '/account/security/emailChange/mailComplete',
    '/account/security/passwordChange/passVerification',
    '/account/security/passwordChange/passEdit',
    '/account/security/passwordChange/passComplete',
    '/account/security/mfa/phoneNumber',
    '/account/security/mfa/codeInput',
    '/account/security/mfa/phoneNumberRelease',
    '/account/security/mfa/codeInputRelease',
    '/account/security/loginHistory',
    '/account/security/delete/accountDelVerification',
    '/account/security/delete/accountDelConfirm',
    '/account/security/delete/accountDelComplete',
    '/account/service',
    '/account/service/link',
    '/account/service/unlink',
    '/account/contact',
    '/account/contact/confirm',
    '/account/contact/complete',
    '/account/Update',
];