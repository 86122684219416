import { Decrypto } from "../../../functions";
import { Footer, Header } from "../../../htmlParts";

//アカウント、サービス連携ページ

const Link = () => {
    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <Header />
                <div id="status_start" className="signup_bg mail_change_ptn02">
                    <main>
                        <div className="contents_in">
                            <div className="text_box_ptn01 form_box_ptn02">
                                <h1 className="title blue">サービス連携</h1>
                                <div className="cnt_text">
                                    <p>TTS-IDと他のサービスを連携することで<br className="sp" />連携したサービスの情報からおすすめの情報を<br className="sp" />表示することなどができます。<br />以下のボタンから連携したいサービスにログインすることでTTS-IDと連携することができます。</p>
                                </div>
                                <div className="together">連携するサービス：TTSコミュニティ</div>
                                <div className="btn_ptn03"><a href="">サービスにログイン</a></div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div >
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Link