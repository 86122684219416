import {
    createStore as reduxCreateStore,
    combineReducers,
    applyMiddleware
} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {UsersReducer} from '../Users/reducers';
import {RecaptchaReducer} from '../Recaptcha/reducers';
import {TwitterTokenReducer} from '../TwitterToken/reducers';
import { ContactReducer } from '../Contacts/reducers';
import { ErrorsReducer } from '../Errors/reducers';
import {MfaReducer} from '../Mfa/reducers';
import {LoadingReducer} from '../Loading/reducers';
import { PageActReducer } from '../PageAct/reducers';

const createStore = (history) => {
    return reduxCreateStore(
        //分割したReducerをまとめる機能。オブジェクトをリターンする。
        combineReducers({
            router: connectRouter(history),
            users: UsersReducer,
            recaptcha: RecaptchaReducer,
            twitterToken: TwitterTokenReducer,
            contact: ContactReducer,
            errors: ErrorsReducer,
            mfa: MfaReducer,
            loading: LoadingReducer,
            pageAct: PageActReducer,
        }),
        applyMiddleware(
            routerMiddleware(history)
        )
    );
}

export default createStore