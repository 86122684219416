import {Helmet} from "react-helmet";

export const TopHead = () => {
    return (
        <Helmet>
            <title>TTS-ID - あなたの出張に新しい体験をプラスする</title>
            <meta name="description" content="TTS-IDはビジネスでの旅行を快適にする複数のサービスがひとつのIDで利用できるようになるサービスです。TTS-IDを通じてサービスと連携することで、あなたの出張に新しい体験をプラスします。TTS-IDは誰でも無料で作成することができます。" />
            <meta property="og:title" content="TTS-ID - あなたの出張に新しい体験をプラスする" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDはビジネスでの旅行を快適にする複数のサービスがひとつのIDで利用できるようになるサービスです。TTS-IDを通じてサービスと連携することで、あなたの出張に新しい体験をプラスします。TTS-IDは誰でも無料で作成することができます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const LoginPageHead = () => {
    return (
        <Helmet>
            <title>ログイン | TTS-ID</title>
            <meta name="description" content="TTS-IDにはここからログインすることができます。ソーシャルアカウントまたはメールアドレスからログインください。" />
            <meta property="og:title" content="ログイン | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/login" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDにはここからログインすることができます。ソーシャルアカウントまたはメールアドレスからログインください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const NewRegistrationHead = () => {
    return (
        <Helmet>
            <title>TTS-IDを作成する | TTS-ID</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="TTS-IDは誰でも無料で作成できます。ソーシャルアカウントまたはメールアドレスで作成できます。" />
            <meta property="og:title" content="TTS-IDを作成する | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDは誰でも無料で作成できます。ソーシャルアカウントまたはメールアドレスで作成できます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const NewRegistrationInputHead = () => {
    return (
        <Helmet>
            <title>TTS-IDを作成 - メールアドレス・パスワード入力 | TTS-ID</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="TTS-IDを作成するためにメールアドレスとパスワードを入力ください。" />
            <meta property="og:title" content="TTS-IDを作成 - メールアドレス・パスワード入力 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDを作成するためにメールアドレスとパスワードを入力ください。" />
            <meta name="twitter:card" content="summary_large_image" />   
        </Helmet>
    )
}

export const TempRegistrationHead = () => {
    return (
        <Helmet>
            <title>TTS-IDを作成 - 確認コード入力 | TTS-ID</title>
            <meta name="description" content="TTS-IDを作成するために確認コードを入力してください。" />
            <meta property="og:title" content="TTS-IDを作成 - 確認コード入力 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDを作成するために確認コードを入力してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const NewRegistrationFormHead = () => {
    return (
        <Helmet>
            <title>TTS-IDを作成 - プロフィール入力 | TTS-ID</title>
            <meta name="description" content="TTS-IDを作成するためにプロフィールを入力してください。" />
            <meta property="og:title" content="TTS-IDの作成 - プロフィール入力 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDを作成するためにプロフィールを入力してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const NewRegistrationConfirmHead = () => {
    return (
        <Helmet>
            <title>TTS-IDの作成 - プロフィールの確認 | TTS-ID</title>
            <meta name="description" content="入力したプロフィール内容に間違いがないか、ご確認ください。" />
            <meta property="og:title" content="TTS-IDの作成 - プロフィールの確認 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="入力したプロフィール内容に間違いがないか、ご確認ください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const PasswordForgetHead = () => {
    return (
        <Helmet>
            <title>パスワードをリセット | TTS-ID</title>
            <meta name="description" content="TTS-IDのパスワードを忘れた場合などは、ここからパスワードをリセットできます。" />
            <meta property="og:title" content="パスワードをリセット | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/passwordForget" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDのパスワードを忘れた場合などは、ここからパスワードをリセットできます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const PasswordForgetNewFormHead = () => {
    return (
        <Helmet>
            <title>新しいパスワードを入力 | TTS-ID</title>
            <meta name="description" content="TTS-IDの新しいパスワードを入力して設定してください。" />
            <meta property="og:title" content="新しいパスワードを入力 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDの新しいパスワードを入力して設定してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const PasswordForgetCompleteHead = () => {
    return (
        <Helmet>
            <title>新しいパスワードの設定完了 | TTS-ID</title>
            <meta name="description" content="TTS-IDの新しいパスワードを設定しましたので、再度ログインしてください。" />
            <meta property="og:title" content="新しいパスワードの設定完了 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDの新しいパスワードを設定しましたので、再度ログインしてください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const MFAHead = () => {
    return (
        <Helmet>
            <title>セキュリティコードの入力 | TTS-ID</title>
            <meta name="description" content="TTS-IDにログインするために、SMSで受け取ったセキュリティコードを入力してログインしてください。" />
            <meta property="og:title" content="セキュリティコードの入力 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDにログインするために、SMSで受け取ったセキュリティコードを入力してログインしてください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const LoginTopHead = () => {
    return (
        <Helmet>
            <title>TTS-ID - あなたの出張に新しい体験をプラスする</title>
            <meta name="description" content="あなたの出張に新しい体験をプラス！TTS-IDを通じてサービスと連携すると、出張管理の最適化から、出張先でのお役立ち情報のピックアップなど新しい体験ができます。" />
            <meta property="og:title" content="TTS-ID - あなたの出張に新しい体験をプラスする" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="あなたの出張に新しい体験をプラス！TTS-IDを通じてサービスと連携すると、出張管理の最適化から、出張先でのお役立ち情報のピックアップなど新しい体験ができます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const AccountProfileHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - プロフィール | TTS-ID</title>
            <meta name="description" content="ここではTTS-IDのプロフィールを変更できます。" />
            <meta property="og:title" content="TTS-ID設定 - プロフィール | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="ここではTTS-IDのプロフィールを変更できます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}
export const AccountMyTagHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - Myタグ | TTS-ID</title>
            <meta name="description" content="ここではTTS-ID Myタグの設定を変更できます。" />
            <meta property="og:title" content="TTS-ID設定 - Myタグ | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="ここではTTS-ID Myタグの設定を変更できます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}
export const AccountNotificationHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - 通知 | TTS-ID</title>
            <meta name="description" content="ここではお知らせメールやブラウザ通知など、TTS-ID通知設定を変更できます。" />
            <meta property="og:title" content="TTS-ID設定 - 通知 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="ここではお知らせメールやブラウザ通知など、TTS-ID通知設定を変更できます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const AccountSecurityHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - セキュリティ | TTS-ID</title>
            <meta name="description" content="ここではTTS-IDのセキュリティに関連した設定を変更できます。" />
            <meta property="og:title" content="TTS-ID設定 - セキュリティ | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/account/security" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="ここではTTS-IDのセキュリティに関連した設定を変更できます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const AccountServiceHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - 連携サービス | TTS-ID</title>
            <meta name="description" content="TTS-IDの設定ページです。他のサービスとの連携設定ができます。" />
            <meta property="og:title" content="TTS-ID設定 - 連携サービス | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDの設定ページです。他のサービスとの連携設定ができます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const EmailChangeVerifyHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - メールアドレス変更 | TTS-ID</title>
            <meta name="description" content="TTSーIDメールアドレスの変更のため本人確認をしてください。" />
            <meta property="og:title" content="TTS-ID設定 - メールアドレス変更 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTSーIDメールアドレスの変更のため本人確認をしてください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const NewEmailFormHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - メールアドレス変更 | TTS-ID</title>
            <meta name="description" content="メールアドレスの変更のため新しいメールアドレスを入力してください。" />
            <meta property="og:title" content="TTS-ID設定 - メールアドレス変更 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="メールアドレスの変更のため新しいメールアドレスを入力してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const EmailCheckHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - メールアドレス変更 | TTS-ID</title>
            <meta name="description" content="新しいメールアドレスの確認のため確認コードを入力してください。" />
            <meta property="og:title" content="TTS-ID設定 - メールアドレス変更 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="新しいメールアドレスの確認のため確認コードを入力してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const EmailChangeCompleteHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - メールアドレス変更完了 | TTS-ID</title>
            <meta name="description" content="メールアドレスの設定が完了しました。" />
            <meta property="og:title" content="TTS-ID設定 - メールアドレス変更完了 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="メールアドレスの設定が完了しました。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}


export const PasswordChangeVerifyHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - パスワード変更 | TTS-ID</title>
            <meta name="description" content="パスワードの変更のため本人確認をしてください。" />
            <meta property="og:title" content="TTS-ID設定 - パスワード変更 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="パスワードの変更のため本人確認をしてください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const NewPasswordFormHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - パスワード変更 | TTS-ID</title>
            <meta name="description" content="パスワードの変更のため新しいパスワードを入力してください。" />
            <meta property="og:title" content="TTS-ID設定 - パスワード変更 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="パスワードの変更のため新しいパスワードを入力してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const PasswordChangeCompleteHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - パスワード変更完了 | TTS-ID</title>
            <meta name="description" content="新しいパスワードの設定が完了しました。" />
            <meta property="og:title" content="TTS-ID設定 - パスワード変更完了 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="新しいパスワードの設定が完了しました。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const MFAVerifyHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - 2段階認証設定 | TTS-ID</title>
            <meta name="description" content="2段階認証の設定のため本人確認をしてください。" />
            <meta property="og:title" content="TTS-ID設定 - 2段階認証設定 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="2段階認証の設定のため本人確認をしてください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const MFAPhoneNumberHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - 2段階認証設定 | TTS-ID</title>
            <meta name="description" content="ここでは2段階認証の設定ができます。セキュリティコードを送信する携帯電話番号を入力してください。" />
            <meta property="og:title" content="TTS-ID設定 - 2段階認証設定 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="ここでは2段階認証の設定ができます。セキュリティコードを送信する携帯電話番号を入力してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const MFACodeInputHead = () => {
    return (
        <Helmet>
            <title>TTS-ID設定 - 2段階認証設定 | TTS-ID</title>
            <meta name="description" content="ここでは2段階認証の設定ができます。送信されたセキュリティコードを入力して2段階認証を設定してください。" />
            <meta property="og:title" content="TTS-ID設定 - 2段階認証設定 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="ここでは2段階認証の設定ができます。送信されたセキュリティコードを入力して2段階認証を設定してください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const Error404Head = () => {
    return (
        <Helmet>
            <title>404 Not Found | TTS-ID</title>
        </Helmet>
    )
}

export const ErrorAther = () => {
    return (
        <Helmet>
            <title>予期せぬエラーが発生しました|TTS-ID</title>
        </Helmet>
    )
}
export const FAQHead = () => {
    return (
        <Helmet>
            <title>サポート | TTS-ID</title>
            <meta name="description" content="あなたの出張に新しい体験をプラスする、TTS-IDについてよくある質問を記載しています。" />
            <meta property="og:title" content="サポート | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/faq" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="あなたの出張に新しい体験をプラスする、TTS-IDについてよくある質問を記載しています。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const CompanyHead = () => {
    return (
        <Helmet>
            <title>運営会社 | TTS-ID</title>
            <meta name="description" content="あなたの出張に新しい体験をプラスする、TTS-IDの運営会社情報になります。会社概要・事業内容等をご紹介いたします。" />
            <meta property="og:title" content="運営会社 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="あなたの出張に新しい体験をプラスする、TTS-IDの運営会社情報になります。会社概要・事業内容等をご紹介いたします。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const ContactHead = () => {
    return (
        <Helmet>
            <title>お問い合わせ | TTS-ID</title>
            <meta name="description" content="TTS-IDについてお問い合わせのある方はこちらからお問い合わせください。" />
            <meta property="og:title" content="お問い合わせ | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/contact" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="TTS-IDについてお問い合わせのある方はこちらからお問い合わせください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const ContactConfirmHead = () => {
    return (
        <Helmet>
            <title>お問い合わせ - 入力内容の確認 | TTS-ID</title>
            <meta name="description" content="お問い合わせのご入力内容を確認ください。" />
            <meta property="og:title" content="お問い合わせ - 入力内容の確認 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/contact" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="お問い合わせのご入力内容を確認ください。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    ) 
}

export const ContactCompleteHead = () => {
    return (
        <Helmet>
            <title>お問い合わせ - 完了 | TTS-ID</title>
            <meta name="description" content="お問い合わせを受け付けました。" />
            <meta property="og:title" content="お問い合わせ - 完了 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/contact" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="お問い合わせを受け付けました。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const CookiePolicyHead = () => {
    return (
        <Helmet>
            <title>クッキーポリシー | TTS-ID</title>
            <meta name="description" content="あなたの出張に新しい体験をプラスする、TTS-IDのクッキーポリシーを記載しています。クッキーを通じて収集する情報の利用目的について確認いただけます。" />
            <meta property="og:title" content="クッキーポリシー | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/cookiePolicy" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="あなたの出張に新しい体験をプラスする、TTS-IDのクッキーポリシーを記載しています。クッキーを通じて収集する情報の利用目的について確認いただけます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const PrivacyPolicyHead = () => {
    return (
        <Helmet>
            <title>プライバシーポリシー | TTS-ID</title>
            <meta name="description" content="あなたの出張に新しい体験をプラスする、TTS-IDのプライバシーポリシー・個人情報の利用目的について記載しています。" />
            <meta property="og:title" content="プライバシーポリシー | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/privacyPolicy" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="あなたの出張に新しい体験をプラスする、TTS-IDのプライバシーポリシー・個人情報の利用目的について記載しています。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export const TermHead = () => {
    return (
        <Helmet>
            <title>利用規約 | TTS-ID</title>
            <meta name="description" content="あなたの出張に新しい体験をプラスする、TTS-IDの利用規約を記載しています。ご利用にあたっての条件、規則、約束事を確認いただけます。" />
            <meta property="og:title" content="利用規約 | TTS-ID" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://tts-id.com/terms" />
            <meta property="og:site_name" content="TTS-ID" />
            <meta property="og:description" content="あなたの出張に新しい体験をプラスする、TTS-IDの利用規約を記載しています。ご利用にあたっての条件、規則、約束事を確認いただけます。" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}