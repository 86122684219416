import CryptoJS from 'crypto-js';

export const Encrypto = (item) => {
    const encrypto = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(item), process.env.REACT_APP_CRYPTO_KEY);
    return encrypto.toString();
}

export const Decrypto = (item) => {
    const decrypto = CryptoJS.AES.decrypt(item, process.env.REACT_APP_CRYPTO_KEY)
    return decrypto.toString(CryptoJS.enc.Utf8);
}