import React from 'react';
import { useDispatch, useSelector } from "react-redux"
import { push } from 'connected-react-router';
import { Footer, Header, InputPassword, NewPasswordFormHead } from '../../../../htmlParts';
import {
    changedPasswordHandler,
    passwordDifferWord,
    Decrypto,
    asyncDevAPI
} from '../../../../functions';
import { useHistory, Link } from 'react-router-dom';
import { updatePasswordAction } from '../../../../Reduxs/Users/actions';
import illust01_account from '../../../../assets/img/setting/illust01_account.png';
import { MoveOnryButton } from "../../../../functions"
import { setErrorAction } from '../../../../Reduxs/Errors/actions';

const PassEdit = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const history = useHistory();

    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const sessionUser = JSON.parse(String(SessionUserObj));

    // state管理系宣言
    const [preNewPassword, setPreNewPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [url, setUrl] = React.useState<string>('');

    // パスワード表示用Stateここから
    const [passDisp, setPassDisp] = React.useState<boolean>(false);
    const [passCheckDisp, setPassCheckDisp] = React.useState<boolean>(false);

    // バリデーション処理ここから
    const [prePasswordError, setPrePasswordError] = React.useState<string>('');
    const [passwordError, setPasswordError] = React.useState<string>('');

    const [errMsg, setErrMsg] = React.useState<string>('');

    const checkMfaPassword = () => {
        if (preNewPassword === newPassword) {
            dispatch(updatePasswordAction({
                new_password: newPassword
            }));
            // SessionStorageからパスワードを取得し複合
            const envPasswordKey = process.env.REACT_APP_SESSION_STORAGE_PASSWORD ?? '';
            const SessionPassword = Decrypto(sessionStorage.getItem(envPasswordKey));

            // SessionStorageからアクセストークンを取得
            const SessionAccessToken = sessionUser.accessToken;

            const apiParams = {
                apiname: 'source_cognito_put',
                AccessToken: SessionAccessToken,
                type: 'password',
                old_password: SessionPassword,
                new_password: newPassword,
            };
            // パスワード変更
            asyncDevAPI(apiParams)
                .then(res => {
                    const result: any = res;
                    const statusCode = result['body']['status'];

                    switch (statusCode) {
                        case 200:
                            // パスワード変更完了画面に遷移
                            dispatch(push('/account/security/passwordChange/passComplete'));
                            break;
                        case 204:
                            setErrMsg('このパスワードは過去に登録されています。別のパスワードを入力してください。');
                            break;
                        case 301:
                            setErrMsg('このパスワードは過去に登録されています。別のパスワードを入力してください。');
                            break;
                        default:
                            dispatch(setErrorAction({
                                errorCode: statusCode
                            }));
                            dispatch(push('/error'));
                    }
                })
                .catch(err => {
                    setErrMsg('パスワード変更に失敗しました。お手数ですがもう一度お試しください。');
                })
        } else {
            setErrMsg(passwordDifferWord);
        }
    }

    if (sessionUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <NewPasswordFormHead />
                <Header />
                <div id="new_password" className="mail_change">
                    <main>
                        <div className="decoration_img"><img src={illust01_account} alt="人のイラスト" /></div>
                        <div className="contents_in">
                            <div className="back">
                                <Link to='/account/security/passwordChange/passVerification'>&lt; 戻る</Link>
                            </div>
                            <div className="text_box_ptn01 form_box_ptn02">
                                <h1 className="title">パスワードの変更</h1>
                                <div className="lead">新しく設定するパスワードを入力ください。</div>
                                <div className='form'>
                                    <dl className="form_g">
                                        <dt className="form_text">新しいパスワード</dt>
                                        <dd>
                                            <InputPassword
                                                passDisp={passDisp}
                                                changedPasswordHandler={(event) => changedPasswordHandler(event, setPreNewPassword, setPrePasswordError)}
                                                setPassDisp={setPassDisp}
                                                placeholder={'新しいパスワードを入力'}
                                            />
                                            <div className="form_error_text">{prePasswordError}</div>
                                        </dd>
                                        <dt className="form_text">新しいパスワードの確認</dt>
                                        <dd>
                                            <InputPassword
                                                passDisp={passCheckDisp}
                                                changedPasswordHandler={(event) => changedPasswordHandler(event, setNewPassword, setPasswordError)}
                                                setPassDisp={setPassCheckDisp}
                                                placeholder={'確認のためもう一度入力'}
                                            />
                                            <div className="form_error_text">
                                                {passwordError}
                                                {passwordError ? <br /> : ''}
                                                {errMsg}
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="btn_submit blue">
                                        <button onClick={() => {
                                            checkMfaPassword();
                                        }}
                                            disabled={!(prePasswordError === '') || !(passwordError === '') || !preNewPassword || !newPassword}
                                        >新しいパスワードに変更
                                        </button>
                                    </div>
                                    <div className="btn_ptn02">
                                        <MoveOnryButton buttonName={'キャンセル'} path={'/account/security'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default PassEdit