import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { push } from 'connected-react-router';
import { Footer, Header, InputPassword } from '../../../../htmlParts';
import { asyncDevAPI, CheckDeviceBrowser, Decrypto, Encrypto } from '../../../../functions';
import { getLoginStatus } from '../../../../Reduxs/Users/selectors';
import illust03 from '../../../../assets/img/setting/illust03.png';
import { loginAction } from '../../../../Reduxs/Users/actions';
import { MoveOnryButton } from "../../../../functions"
import PasswordCount from '../../../../functions/PasswordCount';
import { MFAVerifyHead } from '../../../../htmlParts/headData';

const MfaVerification = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const history = useHistory();

    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const sessionUser = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(sessionUser));
    const [count, setCount] = React.useState<Number>(loginUser.verifyCount);

    // state管理系宣言
    const [password, setPassword] = React.useState<string>('');

    // パスワード表示用Stateここから
    const [passDisp, setPassDisp] = React.useState<boolean>(false);

    // バリデーション処理ここから
    const [passwordCheckError, setPasswordcheckError] = React.useState<string>('');

    // 次のURL(2段階認証の有効無効)
    const mfaURL = sessionStorage.getItem('mfaURL');
    let sessionMfaUrl = null;
    if (mfaURL) {
        sessionMfaUrl = Decrypto(mfaURL);
    }

    const session = sessionStorage.getItem('Session');
    const sessionDec = session ? Decrypto(session) : '';
    const sessionJson = sessionDec ? JSON.parse(sessionDec) : '';

    const mfaVerification = () => {
        // 使用デバイスとブラウザの取得
        const useDeviceAndBrowser = CheckDeviceBrowser();

        // SessionStorageからメールアドレスを取得
        const ucpj = sessionStorage.getItem('ucpj');
        const ucpjDec = ucpj ? Decrypto(sessionStorage.getItem('ucpj')) : '';
        const ucpjDecJson = ucpj ? JSON.parse(ucpjDec) : '';
        const SessionEmail = ucpjDecJson.email;

        const apiParams = {
            apiname: 'source_login_post',
            user_name: SessionEmail,
            password: password,
            device: useDeviceAndBrowser
        };

        // 本人確認
        asyncDevAPI(apiParams)
            .then((res: any) => {
                const result: any = res;
                const statusCode = result['body']['status'];
                const accessToken = result['body']['response']['AccessToken'];
                const apiIdToken = result['body']['response']['IdToken'];
                const ccode = result['body']['response']['ccode'];

                if (statusCode === 200) {
                    dispatch(loginAction({
                        tts_id: ccode,
                        google_username: '',
                        idtoken: apiIdToken,
                        access_token: accessToken,
                        tf_mail: SessionEmail,
                        password: password,
                    }));

                    // 2段階認証用
                    const params = {
                        email: ucpjDecJson.email,
                        password: password,
                        useDeviceAndBrowser: useDeviceAndBrowser,
                        session: res.body.response.Session,
                        phoneNumber: res.body.response.phone_number,
                        keep: sessionJson.keep,
                    }
                    const paramsJson = JSON.stringify(params);
                    sessionStorage.Session = Encrypto(paramsJson);

                    const envPasswordKey = process.env.REACT_APP_SESSION_STORAGE_PASSWORD ?? '';
                    sessionStorage[envPasswordKey] = Encrypto(password);
                    sessionStorage.removeItem('mfaURL');
                    dispatch(push(sessionMfaUrl));
                } else {
                    // 200以外のステータス
                    setPasswordcheckError('本人確認に失敗しました。お手数ですがもう一度お試しください。');

                    PasswordCount(setCount);
                }
            })
            .catch(err => {
                // ログインAPIでエラー返却
                setPasswordcheckError('パスワードが異なります。再度入力してください。');

                PasswordCount(setCount);
            });
    }

    const passCnt = () => {
        if(2 < count){
            return (
                <></>
            )
        } else {
            return (
                <p className='deferment_text'>
                    あと{count}回まで間違えられます。
                </p>
            )
        }
    }

    return (
        <div id="wrapper">
            <MFAVerifyHead />
            <Header />
            <div id="password_change" className="mail_change">
                <main>
                    <div className="decoration_img"><img src={illust03} alt="人のイラスト" /></div>
                    <div className="contents_in">
                        <div className="back"><Link to='/account/security'>&lt; 戻る</Link></div>
                        <div className="text_box_ptn01 form_box_ptn02">
                            <h1 className="title">2段階認証の設定</h1>
                            <div className="lead">2段階認証の設定を変更するには、<br className="sp" />まず本人確認をしてください。</div>
                            <div className='form'>
                                <div>現在のパスワード</div>
                                <InputPassword
                                    passDisp={passDisp}
                                    changedPasswordHandler={(event) => setPassword(event.target.value)}
                                    setPassDisp={setPassDisp}
                                />
                                {passCnt()}
                                <div className="form_error_text">{passwordCheckError}</div>
                                <div className="btn_submit blue">
                                    <button
                                        onClick={() => mfaVerification()}
                                        disabled={
                                            !password
                                        }
                                    >次へ</button>
                                </div>
                                <div className="btn_ptn02">
                                    <MoveOnryButton buttonName={'キャンセル'} path={'/account/security'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default MfaVerification