import { useSelector } from "react-redux";
import { getIsLoading } from "../Reduxs/Loading/selectors";

// APIとの接続中に表示させるローディングアニメーション
export const Loading = () => {
    const selector = useSelector((state) => state);
    const isLoading = getIsLoading(selector);

    if (isLoading) {
        return (
            <div>
                <main className="wrapper">
                    <section className="container">
                        <div className="loader-1"></div>
                    </section>
                </main>
            </div>
        );
    } else {
        return(<></>);
    }
}