import ReactDOM from 'react-dom';
import './assets/css/reset.css';
import './assets/css/common.css';
import './assets/css/local.css';
import './assets/css/local_sp.css';
//import './assets/css/local_kg.css';
//import './assets/css/local_kg02.css';
//import './assets/css/local_s.css';
import './assets/css/loading.css';
import './assets/css/slide.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import createStore from './Reduxs/Store/store';
import * as History from 'history';
import { ConnectedRouter } from 'connected-react-router';

const history = History.createBrowserHistory();
export const store = createStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
