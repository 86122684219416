export const SET_TWITTER_OAUTH_TOKEN = 'SET_TWITTER_OAUTH_TOKEN';
export const setTwitterOauthToken = (twitterTokenState) => {
    return {
        type: 'SET_TWITTER_OAUTH_TOKEN',
        payload: {
            oauth_token: twitterTokenState.oauth_token,
            oauth_verifier: twitterTokenState.oauth_verifier
        }
    }
}

export const OAUTH_CLEAR = 'OAUTH_CLEAR';
export const oauthClearAction = () => {
    return {
        type: 'OAUTH_CLEAR',
        payload: {
            oauth_token: '',
            oauth_verifier: '',
        }
    }
}