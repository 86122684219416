import { asyncServerTime } from "./asyncFunctions";
import { Decrypto, Encrypto } from "./Crypto";

const PasswordCount = (setCount, type:boolean = false) => {
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const sessionUser = SessionUserString ? Decrypto(SessionUserString) : null;

    if(!sessionUser){
        window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
    }
    const loginUser = JSON.parse(String(sessionUser));

    const accessToken = loginUser.accessToken;
    let count = Number(loginUser.verifyCount);
    let countTime = loginUser.countTime;
    let now;
    let params:object;
    if(type){
        params = {
            accessToken: accessToken,
            verifyCount: 6,
        }
        const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
        const sessionparamsString = JSON.stringify(params);
        sessionStorage.setItem(envStorageKey, Encrypto(sessionparamsString));
        setCount(6);

    } else {
        asyncServerTime()
            .then((res:any) => {
                count = count - 1;
                now = res.getTime();
                if(countTime){
                    count = now > loginUser.countTime ? 6 : count;
                } else {
                    countTime = now + (30 * 60 * 1000);
                }
                if(count <= 0){
                    sessionStorage.clear();
                    localStorage.removeItem(envStorageKey);
                    window.location.href = process.env.REACT_APP_DOCUMENT_ROOT;
                } else {
                    params = {
                        accessToken: accessToken,
                        verifyCount: count,
                        countTime: loginUser.countTime ? loginUser.countTime : countTime,
                    }
                    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
                    const sessionparamsString = JSON.stringify(params);
                    sessionStorage.setItem(envStorageKey, Encrypto(sessionparamsString));
                    setCount(count);
                }
            })
            .catch(err => {
                console.log(err);
                setCount(count);
            }
        );
    }
}

export default PasswordCount