import { useDispatch } from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import { hanbergerCloseAction } from './Reduxs/PageAct/actions';
import {
    Home,
    TopContact,
    TopConfirm,
    TopComplete,
    Error,
    NotFound,
} from './templates';
import {
    Profile,
    Notification,
    Service,
    Link,
    Unlink,
    UserContact,
    UserConfirm,
    UserComplete,
    Update,
    UserData,
} from './templates/account';

import {
    AcountDelComplete,
    AccountDelConfirm,
    AccountDelVerification,
    MailComplete,
    MeilEdit,
    MailSendMail,
    MailVerification,
    MfaVerification,
    CodeInput,
    PhoneNumber,
    TestPhoneNumber,
    PhoneNumberRelease,
    CodeInputRelease,
    PassComplete,
    PassEdit,
    PassVerification,
    LoginHistory,
    Security,
    MailVerificationCheck,
} from './templates/account/security';

import {
    Login,
    NewRegistration,
    Form,
    InputConfirmation,
    CallBack,
    ConfirmationMail,
    PasswordForget,
    VerificationSMS,
    ForgetNewPassword,
    ForgetComplete,
    TempRegistSendMail,
    NewRegistrationInput,
} from './templates/auth';
import {
    FirstCategorySelection,
    Top,
    PrivacyPolicy,
    Terms,
    Faq,
    CookiePolicy,
    Company,
} from './templates/contents';

import SNSChallenge from './test/SNSchallenge';

//ルーティングファイル
const Router = () => {
    const dispatch = useDispatch();
    dispatch(hanbergerCloseAction());
    return (
        <Switch>
            {/* 会員登録系のルーティングここから */}
            <Route exact path={'/login'} component={Login} />
            <Route exact path={'/newRegistration'} component={NewRegistration} />
            <Route exact path={'/newRegistration/input'} component={NewRegistrationInput} />
            <Route exact path={'/newRegistration/tempRegistSendMail'} component={TempRegistSendMail} />
            <Route exact path={'/newRegistration/form'} component={Form} />
            <Route exact path={'/newRegistration/inputConfirmation'} component={InputConfirmation} />
            <Route exact path={'/newRegistration/confirmationMail'} component={ConfirmationMail} />
            <Route exact path={'/top/firstCategorySelection'} component={FirstCategorySelection} />
            <Route exact path={'/SMSverification'} component={VerificationSMS} />
            <Route exact path={'/auth/callback'} component={CallBack} />
            <Route exact path={'/passwordForget'} component={PasswordForget} />
            <Route exact path={'/forgetNewPassword'} component={ForgetNewPassword} />
            <Route exact path={'/forgetComplete'} component={ForgetComplete} />
            {/* 会員登録系のルーティングここまで */}

            {/* 一般コンテンツ系のルーティングここから */}
            <Route exact path={'/top'} component={Top} />
            <Route exact path={'/privacyPolicy'} component={PrivacyPolicy} />
            <Route exact path={'/cookiePolicy'} component={CookiePolicy} />
            <Route exact path={'/company'} component={Company} />
            <Route exact path={'/terms'} component={Terms} />
            <Route exact path={'/faq'} component={Faq} />
            <Route exact path={'/contact'} component={TopContact} />
            <Route exact path={'/contact/confirm'} component={TopConfirm} />
            <Route exact path={'/contact/complete'} component={TopComplete} />
            <Route exact path={'(/)?'} component={Home} />
            {/* 一般コンテンツ系のルーティングここまで */}

            {/* ユーザーアカウント画面ここから */}
            <Route exact path={'/account'} component={Profile}　/>
            <Route exact path={'/account/user'} component={UserData} />
            <Route exact path={'/account/notification'} component={Notification} />
            <Route exact path={'/account/security'} component={Security} />
            <Route exact path={'/account/security/emailChange/emailVerificationCheck'} component={MailVerificationCheck} />
            <Route exact path={'/account/security/emailChange/mailVerification'} component={MailVerification} />
            <Route exact path={'/account/security/emailChange/mailEdit'} component={MeilEdit} />
            <Route exact path={'/account/security/emailChange/mailSendMail'} component={MailSendMail} />
            <Route exact path={'/account/security/emailChange/mailComplete'} component={MailComplete} />
            <Route exact path={'/account/security/passwordChange/passVerification'} component={PassVerification} />
            <Route exact path={'/account/security/passwordChange/passEdit'} component={PassEdit} />
            <Route exact path={'/account/security/passwordChange/passComplete'} component={PassComplete} />
            <Route exact path={'/account/security/mfa/mfaVerification'} component={MfaVerification} />
            <Route exact path={'/account/security/mfa/phoneNumber'} component={PhoneNumber} />
            <Route exact path={'/account/security/mfa/codeInput'} component={CodeInput} />
            {/* <Route exact path={'/account/security/mfa/phoneNumberRelease'} component={PhoneNumberRelease} /> */}
            <Route exact path={'/account/security/mfa/codeInputRelease'} component={CodeInputRelease} />
            {/* <Route exact path={'/account/security/loginHistory'} component={LoginHistory} /> */}
            {/* <Route exact path={'/account/security/delete/accountDelVerification'} component={AccountDelVerification} /> */}
            {/* <Route exact path={'/account/security/delete/accountDelConfirm'} component={AccountDelConfirm} /> */}
            {/* <Route exact path={'/account/security/delete/accountDelComplete'} component={AcountDelComplete} /> */}
            <Route exact path={'/account/service'} component={Service} />
            <Route exact path={'/account/service/link'} component={Link} />
            <Route exact path={'/account/service/unlink'} component={Unlink} />
            <Route exact path={'/account/contact'} component={UserContact} />
            <Route exact path={'/account/contact/confirm'} component={UserConfirm} />
            <Route exact path={'/account/contact/complete'} component={UserComplete} />
            <Route exact path={'/account/Update'} component={Update} />
            {/* ユーザーアカウント画面ここまで */}

            {/* テスト用URLここから */}
            <Route exact path={'/account/testPhoneNumber'} component={TestPhoneNumber} />
            <Route exact path={'/snsChallenge'} component={SNSChallenge} />
            {/* テスト用URLここまで */}

            {/* エラー画面 */}
            <Route exact path={'/error'} component={Error} />
            {/* エラー画面 */}

            {/* 404ページなど。おそらくこれ以降にルーティングするとそのページは表示されずにエラーページになってしまう。 */}
            <Route component={NotFound} />
            {/* 404ページここまで */}
        </Switch>
    );
};
export default Router