import {useState} from 'react';
import { asyncDevAPI, changedCodeHandler, CheckDeviceBrowser, Decrypto, Encrypto, ErrorSwitchFunctions, LoginData } from '../../functions';
import { Footer, Header, MFAHead } from '../../htmlParts';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { setErrorAction } from '../../Reduxs/Errors/actions';
import { setNicknameAction } from '../../Reduxs/Users/actions';
import { clearLoadingAction, setLoadingAction } from '../../Reduxs/Loading/actions';
import PhoneNumberMask from '../../functions/PhoneNumberMask';
import { loginHistoryCheck, setArticle } from './login';

const VerificationSMS = () =>{
    const dispatch = useDispatch();
    const [code, setCode] = useState<string>('');
    const [codeError, setCodeError] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [msg, setMsg] = useState<string>('');

    const changedCode = (e:any) => setCode(e.target.value);

    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;

    const loginUser = JSON.parse(String(SessionUserObj));

    const session = sessionStorage.getItem('Session');
    const sessionDec = session ? Decrypto(session) : null;
    const sessionJson = sessionDec ? JSON.parse(sessionDec) : null;
    const urlDatasession = sessionStorage.getItem('urlData');
    const urlDataDecJson = urlDatasession ? JSON.parse(urlDatasession) : null;

    const phone_number = sessionJson.phoneNumber ? sessionJson.phoneNumber : null;
    let pn = PhoneNumberMask(phone_number);

    /*
     urlDataJson{
        url: 遷移先URL
        button: ボタン表示
     }
     */
    let url = '/top';
    let button = 'ログイン';
    if(urlDataDecJson.url){
        url = urlDataDecJson.url;
        button = urlDataDecJson.button;
    }
    const getVerificationCode = () => {
        dispatch(setLoadingAction({
            isLoading: true,
        }));
        const params = {
            apiname: 'source_cognito_auth_challenge',
            session: sessionJson.session,
            mfa_code: code,
            user_name: sessionJson.email,
        }
        asyncDevAPI(params)
            .then((res:any) => {
                const result: any = res;
                const statusCode = result['body']['status'];
                if(statusCode === 200){
                    const accessToken = result['body']['response']['AccessToken'];
                    const refreshToken = result['body']['response']['RefreshToken'];
                    const sessionStorageparams = {
                        accessToken: accessToken,
                        verifyCount: 6,
                    }
                    const useDeviceAndBrowser = CheckDeviceBrowser();
                    loginHistoryCheck(accessToken, useDeviceAndBrowser);
                    const suEnc = JSON.stringify(sessionStorageparams);
                    sessionStorage[process.env.REACT_APP_STORAGE_KEY_NAME] = Encrypto(suEnc);

                    if(button === 'ログイン'){
                        const rdsParams = {
                            apiname: 'source_user_get',
                            AccessToken: accessToken,
                          }
                          asyncDevAPI(rdsParams)
                            .then((res: any) => {
                                if (res.body.status && res.body.status === 200) {
                                    const m_user = res.body.response.m_user;
                                    const m_code = res.body.response.m_code;
                                    const params = {
                                        username: m_user.nickname,
                                        year: String(m_user.birthday).substr(0, 4),
                                        month: String(m_user.birthday).substr(4, 2),
                                        day: String(m_user.birthday).substr(6, 2),
                                        gender: m_user.gender,
                                        m_code: m_code
                                    };
                                    let uiData:Number[] = [];
                                    m_code.map((item:any) => {
                                      if(item.atb === 2){
                                          uiData.push(item.mid);
                                      }
                                    });
                                    
                                    const paramsjs = JSON.stringify(params);
                                    sessionStorage.urpj = Encrypto(paramsjs);
                                    sessionStorage.unkm = Encrypto(m_user.nickname);

                                    localStorage.Login = true;

                                    LoginData(refreshToken, sessionJson.keep);
                                    setArticle(accessToken, uiData, dispatch, url);
                                    //dispatch(push(url));
                                } else {
                                    // 200以外のステータス
                                    const setMsgs = {
                                        ErrMsg: setErrMsg,
                                    }
                                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                                    if (errorPage.flg) {
                                        dispatch(setErrorAction({
                                            errorCode: res.body.status
                                        }));
                                        dispatch(push(errorPage.url));
                                    }
                                    return;
                                }
                            })
                            .catch(err => {
                              console.log(err);
                              sessionStorage.clear();
                              dispatch(clearLoadingAction());
                              alert('ログイン状態が切れました。再度ログインしてください。');
                            }
                        );
                    }else {
                        dispatch(clearLoadingAction());
                        dispatch(push(url));
                    }
                } else {
                    // 200以外のステータス
                    dispatch(clearLoadingAction());
                    const setMsgs = {
                        ErrMsg: setErrMsg,
                    }
                    const errorPage = ErrorSwitchFunctions(res.body, setMsgs);
                    if (errorPage.flg) {
                        dispatch(setErrorAction({
                            errorCode: res.body.status
                        }));
                        dispatch(push(errorPage.url));
                    }
                    return;
                }
            })
            .catch(err => {
                dispatch(clearLoadingAction());
                console.log(err);
            }
        );
    }
    // 認証コード再送
    const reSend = () => {
        setMsg('');
        // ローディング開始
        dispatch(setLoadingAction({ isLoading: true }));
        // ログイン時の2段階認証の場合再度ログイン処理を行う
        const apiParams = {
            apiname: 'source_login_post',
            user_name: sessionJson.email,
            password: sessionJson.password,
            device: sessionJson.useDeviceAndBrowser,
        };
        asyncDevAPI(apiParams)
            .then((res: any) => {
                if (res.body.response.Session) {
                    const params = {
                        email: sessionJson.email,
                        password: sessionJson.password,
                        useDeviceAndBrowser: sessionJson.useDeviceAndBrowser,
                        session: res.body.response.Session,
                        phoneNumber: res.body.response.phone_number,
                        keep: sessionJson.keepLogin,
                    }
                    const paramsJson = JSON.stringify(params);
                    sessionStorage.Session = Encrypto(paramsJson);
                    setMsg('コードを再送しました');
                    dispatch(clearLoadingAction());
                    return;
                } else {
                    setErrMsg('コードの送信に失敗しました。お手数ですがもう一度お試しください。');
                }
            })
            .catch((err) => {
                setErrMsg('コードの送信に失敗しました。お手数ですがもう一度お試しください。');
            })
    }

    const resendMsg = () => {
        if(msg){
            return (
            <div className="messagebox resend">
                {msg}
            </div>
            )
        } else {
            return (
            <></>
            )
        }
    }
    console.log('testtest');

    return (
        <div id="wrapper">
            <MFAHead />
            <Header />
            <div id="two_step_login" className="signup_bg">
                <main>
                    <div className="contents_in">
                    <div className="page_title_ptn01 white">
                        <h1>2段階認証:セキュリティコードを入力</h1>
                        <p>SMSで受け取ったセキュリティコードを<br className="sp" />入力してログインください。</p>
                    </div>
            
                    <div className="cnt_area">
                        <div className="box_r text_box_ptn01">
                        <div className="lead">セキュリティコードを送信した電話番号</div>
                        <p className="first_text"><span className="send_tel_number">電話番号: {pn}</span></p>
                        <div className='form'>
                            <dl className="form_g">
                            <dt className="form_text">セキュリティコード</dt>
                                <dd className="">
                                    <input type="text" placeholder="6桁のセキュリティコードを入力" onChange={(e: any) => changedCodeHandler(e, setCode, setCodeError, true)} />
                                    <div className="form_error_text">
                                        {codeError ? codeError : errMsg}
                                    </div>
                                </dd>
                            </dl>
                            <div className="btn_submit">
                            <button type="submit" id="login-button" onClick={getVerificationCode}>{button}</button>
                            </div>
                        </div>
                        <div className="support_links">
                            {resendMsg()}
                            <div className="link "><span className="link_ptn01" style={{cursor: 'pointer'}} onClick={() => reSend()}>コードを再送する</span></div>
                            <div className="link"><a className="link_ptn01" href="/faq">サポートページへ</a></div>
                        </div>
                        </div>
            
                    </div>
                    </div>
                </main>
            </div>
        <Footer />
        </div>

    )
}

export default VerificationSMS