import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';
import { push } from "connected-react-router";
import { confirmAction } from '../../Reduxs/Contacts/actions';
import { Header, Footer, SPConfirm, ContactsType, ContactHead } from '../../htmlParts';
import { GoogleRecaptcha } from '../../functions';
import { handleClearAction } from '../../Reduxs/Recaptcha/actions';
import {
    getContactName,
    getContactEmail,
    getContactSelect,
    getContactText,
    getTextCount
} from '../../Reduxs/Contacts/selectors';
import { getToken } from '../../Reduxs/Recaptcha/selectors';
import {
    changedName,
    changedEmail,
    changedContactSelect,
    changedContactText,
} from '../../functions';

const TopContact = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    const token = getToken(selector);

    const [name, setName] = useState<string>(getContactName(selector));
    const [email, setEmail] = useState<string>(getContactEmail(selector));
    const [contactSelect, setContactSelect] = useState<string>(getContactSelect(selector));
    const [contactText, setContactText] = useState<string>(getContactText(selector));
    const [textCount, setTextCount] = useState<string>(getTextCount(selector));

    const [service, setService] = useState<boolean>(false);
    const [privacy, setPrivacy] = useState<boolean>(false);

    const [serviceError, setServiceError] = useState<string>('');
    const [privacyError, setPrivacyError] = useState<string>('');

    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [contactSelectError, setContactSelectError] = useState<string>('');
    const [contactTextError, setContactTextError] = useState<string>('');

    return (
        <div id="wrapper">
            <ContactHead />
            <Header />
            <div id="contact" className="public_page contact_page">
                <main>
                    <div className="page_contents">
                        <div className="contents_in">
                            <h1>お問い合わせ</h1>
                            <p>下記フォームよりお問い合わせ内容を<br className="sp" />記入のうえ、お問い合わせください。</p>
                            <div className="text_box_ptn01">
                                <dl className="form_g">
                                    <dt className="form_text">お名前<span className="required">必須</span></dt>
                                    <dd>
                                        <input
                                            type="text"
                                            placeholder="お名前を入力"
                                            defaultValue={name}
                                            onChange={(event) => changedName(event, setName, setNameError)}
                                        />
                                        <div className="form_error_text">{nameError}</div>
                                    </dd>
                                    <dt className="form_text">メールアドレス<span className="required">必須</span></dt>
                                    <dd className="email_text">
                                        <input
                                            type="email"
                                            placeholder="メールアドレスを入力"
                                            defaultValue={email}
                                            onChange={(event) => changedEmail(event, setEmail, setEmailError)}
                                        />
                                        <div className="form_error_text">{emailError}</div>
                                    </dd>
                                    <dt className="form_text">お問い合わせの種類<span className="required">必須</span></dt>
                                    <dd>
                                        <select
                                            name="inquiry_type"
                                            id=""
                                            className="select"
                                            defaultValue={contactSelect}
                                            onChange={(event) => changedContactSelect(event, setContactSelect, setContactSelectError)}
                                        >
                                            <option value="">お問い合わせの種類を選択</option>
                                            <option value="TTS-IDのサービス内容などについて">TTS-IDのサービス内容などについて</option>
                                            <option value="TTS-IDアカウントの登録・設定などについて">TTS-IDアカウントの登録・設定などについて</option>
                                            <option value="その他のお問い合わせ">その他のお問い合わせ</option>
                                        </select>
                                        <div className="form_error_text">{contactSelectError}</div>
                                    </dd>
                                    <dt className="form_text">お問い合わせ内容<span className="required">必須</span></dt>
                                    <dd>
                                        <textarea
                                            name="inquiry_details"
                                            maxLength={1000}
                                            defaultValue={contactText}
                                            onChange={(event) => changedContactText(event, setContactText, setContactTextError, setTextCount)}
                                        ></textarea>
                                        <div>{textCount} / 1000文字まで</div>
                                        <div className="form_error_text">{contactTextError}</div>
                                    </dd>
                                </dl>
                                <SPConfirm serviceConfirm={service} setServiseConfirm={setService} privacyConfirm={privacy} setPrivacyConfirm={setPrivacy} serviceError={serviceError} setServiceError={setServiceError} privacyError={privacyError} setPrivacyError={setPrivacyError} />
                                <div className='recaptcha'>
                                    <GoogleRecaptcha />
                                </div>
                                <div className="btn_submit blue">
                                    <button
                                        onClick={() => {
                                            dispatch(confirmAction({
                                                name: name,
                                                email: email,
                                                contactSelect: contactSelect,
                                                contactText: contactText,
                                                textCount: textCount,
                                            }));
                                            // リキャプチャトークンの初期化
                                            dispatch(handleClearAction());
                                            dispatch(push('/contact/confirm'));
                                        }}
                                        disabled={
                                            !!nameError || !!emailError || !!contactTextError || !!contactSelectError ||
                                            !token || !name || !contactSelect || !contactText || !service || !privacy
                                        }
                                    >確認画面へ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}

export default TopContact