import { countryNum } from "./RegularExpression";

const PhoneNumberMask = (pn:string) => {
    let phone_number = pn;
    let cn:any = pn.match(countryNum);
    cn = '+' + cn[1];
    let pNumber = '0' + pn.replace(cn, '');
    const h = pNumber.slice(-2);
    const remaining =  pNumber.slice(0,-2).replace(/./g , '*');
    pNumber = remaining + h;
    return cn + ' ' + pNumber;
}

export default PhoneNumberMask