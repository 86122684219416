import { ContactCompleteHead, Footer, Header } from "../../../htmlParts"
import illust01_contact from '../../../assets/img/contact/illust01_contact.png';
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { getLoginStatus } from "../../../Reduxs/Users/selectors";
import { Link } from 'react-router-dom';
import { Decrypto } from "../../../functions";

const Complete = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const sessionUser = sessionStorage.getItem(process.env.REACT_APP_STORAGE_KEY_NAME);
    let URL: string = '';
    if (sessionUser) {
        URL = '/top';
    } else {
        URL = '/';
    }

    // アクセストークのチェック
    const envStorageKey = process.env.REACT_APP_STORAGE_KEY_NAME ?? '';
    const SessionUserString = sessionStorage.getItem(envStorageKey);
    const SessionUserObj = SessionUserString ? Decrypto(SessionUserString) : null;
    const loginUser = JSON.parse(String(SessionUserObj));

    if (loginUser) {
        // ログイン後の画面
        return (
            <div id="wrapper">
                <ContactCompleteHead />
                <Header />
                <div id="contact_done" className="public_page contact_page">
                    <main>
                        <div className="page_contents">
                            <div className="contents_in">
                                <h1>お問い合わせを<br className="sp" />受け付けました。</h1>
                                <p className="lead">お問い合わせいただき<br className="sp" />ありがとうございました。<br />お問い合わせを受け付けました。<br />担当者より折り返しご連絡いたしますので、<br className="sp" />恐れ入りますがお待ち下さい。</p>
                                <p>なお、ご入力いただいたメールアドレス宛に<br className="sp" />受付完了メールを配信しております。<br />受付完了メールが届かない場合は入力したメールアドレスが誤っている可能性などがございます。<br />その場合は大変お手数ですが、再度お問い合わせいただきますようお願いいたします。</p>
                                <div className="btn_ptn02">
                                    <Link onClick={() => dispatch(push(URL))}>TTS-ID トップに戻る</Link>
                                </div>
                            </div>
                            <div className="cnt_img"><img src={illust01_contact} alt="人のイラスト" /></div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        )
    } else {
        // ログイン前は何も表示させない
        return (
            <></>
        )
    }
}

export default Complete